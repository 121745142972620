import React, { useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import OfficeLicenseAgreement from './OfficeLicenseAgreement'

import Input from './components/Input'
import SavedAlert from './components/SavedAlert'
import Form from './components/Form'
import FormCol from './components/FormCol'

function SignupFlow3(props) {

  const checkUsioValue = (value, fieldName) => {
    if (!value) {
      alert(`${fieldName} must not be empty`)
      return false
    }
    else if (value.length < 2 || value.length > 50) {
      alert(`${fieldName} must be between 2-50 characters`)
      return false
    }
    else if (!/^[a-zA-Z0-9 ,.]*$/.test(value)) {
      alert(`${fieldName} can only contain alphanumeric characters, periods and commas.`)
      return false
    }

    return true
  }

  const handleSubmitFlow = (e) => {

    // TODO: Validate the useio
    if (!checkUsioValue(props.usioDbaName, 'DBA Name') || !checkUsioValue(props.usioLegalName, 'Legal Name')) {
      return
    }

    props.handleSubmitFlow(e)
  }

  return (
    <div className="w-full h-full">
      <Form onSubmit={props.handleSubmitFlow} saved={props.submitted} savedLabel={"Your registration application has been submitted! The QDP team will reach out soon regarding your application's status."}
       title={"Merchant Application Request"}
       hideButton={true} className="card mt-8 px-8 pt-8 bg-white border rounded-lg w-5/6 mx-auto " >
        <FormCol>
          <Input label={"DBA (Doing Business As) Name"} onChange={props.handleUsioDbaNameChange}  value={props.usioDbaName} name={"usioDbaName"} />
          <Input label={"Legal Name"} onChange={props.handleUsioLegalNameChange}  value={props.usioLegalName} name={"usioLegalName"} />
          <Input label={"Email"} onChange={props.handleUsioEmailChange}  value={props.usioEmail} name={"usioEmail"} />
        </FormCol>
      </Form>
      <div className="w-5/6 mx-auto card mb-8">
        <p className="font-bold">***By submitting this form, you give our processing partner, USIO, permission to email you the one page merchant enrollment form.</p>
        {!props.submitting ?
        <div className="w-full">
            <button onClick={props.handlePrevFlow} className="gradient-btn my-4 mr-2">Previous</button>
            <button onClick={handleSubmitFlow} className="gradient-btn my-4">Register</button>
        </div>
        : null}
        <p className="">Already have an account? <Link className="text-blue-700" to="/login">Sign In</Link></p>
      </div>
    </div>
  )
  }

export default SignupFlow3
    