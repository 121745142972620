import React from 'react'
import { Media, fs, AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun } from "docx";
import { saveAs } from "file-saver";

const createBullet = (text) =>{  
        return new Paragraph({
            text: text,
            bullet: {
                level: 0,
            },
        });
    }

const PlanWordDocument = (props) => {

	const printBasicBenefits = () =>{

		let listBenefits = []
		let benefit	
		for(let i =0; i<props.basicBenefits.length; i++){
			benefit = props.basicBenefits[i]
			if(typeof benefit == "string"){
				if(benefit.includes('$$')){
					benefit = benefit.replace('$$', '$')
				}
				else if (benefit.includes('%%')){
					benefit = benefit.replace('%%', '%')
				}
			}
			listBenefits.push(createBullet(benefit))
		}

		return(listBenefits)
		}



	const reviewCustomBenefits = () => {
		let newBenefits = props.customBenefits.map(benefit => {
		if(benefit.included){
			if(benefit.benefit.includes('X')){
				if(benefit.benefit.includes('%') && !(benefit.name.includes('Additional value'))){
					if(benefit.value != undefined){
					if(benefit.value.includes('%')){
						return benefit.benefit.replace('X%', benefit.value)
					}
					else{
						return benefit.benefit.replace('X', benefit.value)
					}}
					else{
						return benefit.benefit.replace('X', benefit.value)
					}
				}
				else if(benefit.benefit.includes('$')){
					if(benefit.value != undefined){
						if(benefit.value.includes('$')){
						return benefit.benefit.replace('$X', benefit.value)
						}
						else{
							return benefit.benefit.replace('X', benefit.value)
						}
					}
					else{
						return benefit.benefit.replace('X', benefit.value)
					}					
				} 
				else{
					return benefit.benefit.replace('X', benefit.value)
				}

			}
			else if(benefit.benefit.includes('Additional')){
				let newValue
				if(benefit.value != undefined){
					newValue = benefit.value.replace('percent1', '%')
					newValue = newValue.replace('dollar1', '$')
				}
				else{
					newValue = benefit.value
				}
				return newValue
			}
			else if(!benefit.benefit.includes('Missed appointment')){
				return benefit.benefit
			}
		}
		return undefined
	})
		return newBenefits.filter(x => x !== undefined)}


	const disclaimerText = "The fee paid for our IOP savings plan is for included standard of care services and represents a courtesy accounting adjustment for payment, made in full, at the time of service. Eligible family members include spouse and dependent children under the age of 19 (up to age 23 if dependent child is a full-time student). Fees are due and payable when services are rendered and are non-refundable when services have been provided. Savings duration is for one year from registration date. All patient portions for services received are due at the time of services to receive IOP savings. Anniversary dates are 12 months after signing up. Interest-free payment options may be provided in 3, 6- or 12-month durations upon request and with approved credit. Repayment duration is based on service totals and procedural type. If you choose to use a repayment/financial plan, your IOP Savings will be adjusted around the third-party fee and interest free payment options will be customized for your repayment needs. Fees charged are not membership fees and all fees paid are for provided services only."

    const createInstitutionHeader = (doc) =>{
       
       	let headerDoc = new Document()
       	let image
       	if(props.officeLogo != undefined){
			image = Media.addImage(doc, fs.readFileSync(props.officeLogo));
	        return new Paragraph({
	            tabStops: [
	                {
	                    type: TabStopType.RIGHT,
	                    position: TabStopPosition.MAX,
	                },
	            ],
	            children: [
	                new TextRun({
	                    text: props.name,
	                    bold: true,
	                }),
	                image
	            ],
	        })
       	}
       	else{
       		new Paragraph({text: `${props.name}`, heading: HeadingLevel.TITLE})
       	}

    }



    const doc = new Document();

    doc.addSection({
        children: [new Paragraph({text: `${props.name}`, heading: HeadingLevel.TITLE}),
        new Paragraph("\r\n"),
        new Paragraph("\r\n"),
        new Paragraph("We are proud to announce that we offer an In-House Savings Plan (IOP) in our dental office. Our IOP is an annual dental savings program we offer for families and individuals that allows all IOP patients to receive quality dental services at greatly reduced prices in our office. Unlike conventional insurance plans, with our IOP program there are no deductibles, no yearly maximums, and no waiting periods to begin treatment. IOP benefits and savings begin immediately upon registration."),
        new Paragraph("\r\n"),
        new Paragraph("\r\n"),
        new Paragraph({text: "Our Savings Include:", heading: HeadingLevel.HEADING_2}),
        new Paragraph("\r\n"),
        ...props.basicBenefits.map(benefit => new Paragraph({text: benefit, bullet: {level: 0}})),
        ...reviewCustomBenefits().map(benefit => new Paragraph({text: benefit, bullet: {level: 0}})),
		new Paragraph("\r\n"),
        new Paragraph("\r\n"),
		new Paragraph({text: "Our Fees:", heading: HeadingLevel.HEADING_2}),
    	new Paragraph("\r\n"),
    	new Paragraph({text: `Full Payment: $${typeof props.finalFee == "string" ? Number(props.finalFee.replace('$', '').replace(',', '')).toFixed(2) : props.finalFee.toFixed(2)}`, bullet: {level: 0}}),
       	new Paragraph({text: `Monthly Payment: $${Number(props.recommendedMonth.replace('$', '')).toFixed(2)}`, bullet: {level: 0}}),
    	new Paragraph({text: `Down Payment: $${typeof props.recommendedDown == "string" ? Number(props.recommendedDown.replace('$', '').replace(',', '')).toFixed(2) : props.recommendedDown.toFixed(2)}`, bullet: {level: 0}}),
    	new Paragraph("\r\n"),
        new Paragraph("\r\n"),
    	new Paragraph(disclaimerText),
		new Paragraph("\r\n"),
        new Paragraph("\r\n"),
		new Paragraph({text: "IOP SAVINGS PLAN LIMITATIONS & EXCLUSIONS", heading: HeadingLevel.HEADING_2}),
       	new Paragraph({text: "Dentist provides any medications not regularly stocked by our office.", bullet: {level: 0}}),
       	new Paragraph({text: "Any procedure that is considered to be experimental.", bullet: {level: 0}}),
       	new Paragraph({text: "Hospitalization for any procedures.", bullet: {level: 0}}),
       	new Paragraph({text: "Services unable to be completed due to the patient’s medical health, mental health, or other unhealthy status.", bullet: {level: 0}}),
       	new Paragraph({text: "Conditions or services under Worker’s Compensation or Employer’s Liability laws.", bullet: {level: 0}}),
       	new Paragraph({text: "Congenital malformations other than congenital anomaly of tooth/teeth from birth.", bullet: {level: 0}}),
       	new Paragraph({text: "Any diagnosis or treatment of myofacial pain dysfunction syndrome.", bullet: {level: 0}}),
       	new Paragraph({text: "Any whitening kit included complimentary with our IOP program are included once annually and nontransferable.", bullet: {level: 0}}),
       	new Paragraph({text: "Any alterations, restorations, or treatments of the temporomandibular joint.", bullet: {level: 0}}),
       	new Paragraph({text: "Any procedures for full mouth rehabilitation requiring appliances or restorations.", bullet: {level: 0}}),
       	new Paragraph({text: "Any services provided for free by a county, government, municipality, or other agency.", bullet: {level: 0}}),
       	new Paragraph({text: "Any appliances, diagnosis or treatments conducted by a referral made to another dentist or specialist outside of the providers of our office in order to complete treatments in connection with any dental procedure in this office.", bullet: {level: 0}}),
       	new Paragraph({text: "Patients cannot use their own dental insurance benefits or other dental coverage in conjunction with any part of IOP.", bullet: {level: 0}}),
       	new Paragraph({text: "The fee paid for our IOP program is for included services and represents a courtesy accounting adjustment for payment, made in full, at the time of services.", bullet: {level: 0}}),
       	new Paragraph({text: "The Fee for our IOP program is not a membership fee.", bullet: {level: 0}}),
       	new Paragraph({text: "Payment plans can be made upon request and depend on the total amount due and type of dental procedure and by approved credit.  Any patient using a financial payment plan will have their IOP savings adjusted around the fee charged by the third party. Interest free payment options will be offered in lieu of IOP savings when a third party company is requested to pay for treatment.", bullet: {level: 0}}),
       	new Paragraph({text: "All Fees are due and payable when services are rendered and are non-refundable when services have been provided.", bullet: {level: 0}}),
       	new Paragraph({text: "Specifically, Fees for prosthodontic (dentures) and cast restorations (crowns, in-lays, on-lays, veneers, implants, etc.) are due at the prep appointment, in order to receive IOP savings.", bullet: {level: 0}}),
       	new Paragraph({text: "There are no refunds on your IOP Savings Plan Fee WHEN any services have been rendered.", bullet: {level: 0}}),
       	new Paragraph({text: "Any unused services within your IOP Dental Savings Plan are nontransferable to other patients regardless of the service and do not roll over from year to year.", bullet: {level: 0}}),
       	new Paragraph({text: "***Please note: If you have Periodontal Disease, additional perio maintenance cleanings may be recommended for your individual treatment plan needs. Two perio maintenance appointments are included with your plan fee.", bullet: {level: 0}}),
		new Paragraph("\r\n"),
        new Paragraph("\r\n"),
		new Paragraph({text: "PLEASE READ DISCLAIMER AND SIGN BELOW:", heading: HeadingLevel.HEADING_2}),
        new Paragraph("\r\n"),
       	new Paragraph({text: "I acknowledge that I am financially responsible for payment, in full, at time of services in order to take advantage of the savings being offered on my IOP Savings Plan. If I choose not to pay at the time of service, I understand that I shall pay the customary fees for the services delivered. Furthermore, I understand the benefits, limitations, exclusions, and requirements of my IOP program and have been given a copy for my personal records."}),
        new Paragraph("\r\n"),
        new Paragraph("I authorize regularly scheduled charges to my credit card for plan fees, yearly or monthly. The charge will appear on your credit card statement. You agree that no prior-notification will be provided unless the date or amount changes, in which case you will receive notice from us at least 10 days prior to the payment being collected."),
        new Paragraph("\r\n"),
        new Paragraph("I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify 30 days in advance of my next billing date, in writing, of any changes in my account information or a wish to terminate my agreement. I acknowledge that the origination of Credit Card transactions to my account must comply with the provisions of U.S. law. I certify that I am an authorized user of this Credit Card and will not dispute these scheduled transactions."),
        new Paragraph("\r\n"),
        new Paragraph("\r\n"),
        new Paragraph("SIGNATURE: ______________________________________ DATE: ____________"),
        new Paragraph("\r\n"),
]})


	const handleDownload = () => {
	    Packer.toBlob(doc).then(blob => {
	      saveAs(blob, "plan.docx");
	    });
	}

	return(<button className={props.className} onClick={handleDownload}>{props.buttonName || "Download Plan"}</button>)
}



export default PlanWordDocument

