import React, { useEffect, useState } from 'react'
import createReactContext from 'create-react-context'
import { Hub, Auth, API, graphqlOperation } from 'aws-amplify'
const UserContext = createReactContext()

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState'

const UserContextProvider = props => {
  

  const [auth, setAuth] = useState({})
  const [account, setAccount] = useState({})
  const [offices, setOffices] = useState([])



  useEffect(() => {

    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, 'signedIn')
        setAuth({ state: 'signIn', user: user.username })
      }
      catch (err) {
        const cachedAuthState = await localStorage.getItem(AUTHENTICATOR_AUTHSTATE)
        cachedAuthState === 'signedIn' && Auth.signOut()
      }
    })()
  }, [])


  useEffect(() => {

    if (!auth || !auth.user) {
      setAccount({})
      return
    }

    (async () => {
      try{
        const accountId = auth.user
        // TODO: If no email, then let's lazily update it here.
        const accountData = await API.graphql(graphqlOperation(`query GetAccount {
          getAccount(id: "${accountId}") {
              id
              accountType
              officeId
              name
              email
          }
          getOffice(id: "${accountId}") {
            id
            name
            email
          }

            listOfficeAccounts(filter: { accountId: { eq: "${auth.user}" } }, limit: 99999) {
              items {
                roles
                office {
                  id
                  name
                  email
                }
              }
            }
          }`))

          const ownerOffice = accountData.data.getOffice
          const officeRoles = accountData.data.listOfficeAccounts.items
          if (ownerOffice && !officeRoles.find(o => o.office.id == ownerOffice.id)) {
            officeRoles.push({
              roles: ['owner', 'admin'],
              office: ownerOffice
            })
          }
          setOffices(officeRoles)
        
          let account = accountData.data.getAccount
          account = await updateAccountEmailIfNecessary(account)
          setAccount(account)
      }
      catch (ex) {
        console.error(ex)
        console.info("Account not fetched2")
      }
    })()  

  }, [auth])

  const updateAccountEmailIfNecessary = async (account) => {
    if (!account || (account.email && account.email.length > 0)) {
      return account
    }

    try {
      /// Update the user account to add email address to their record. This is 
      /// used for the user creation later on for multi-account setup
      const cu = await Auth.currentAuthenticatedUser()
      const email = cu.attributes.email
      await API.graphql(graphqlOperation(`
        mutation UpdateAccount {
          updateAccount(
            input: { id: "${account.id}", email: "${email}" }
          ) { 
              id
              email
          }
        }
      `))

      return {
        ...account,
        email
      }
    }
    catch (ex) {
      return account
    }
  }


  const updateOfficeId = async (officeId) => {
    console.log(`Update officeId ${officeId} on account `, account)
    setAccount({
      ...account,
      officeId
    })

    API.graphql(graphqlOperation(`
      mutation UpdateAccount {
        updateAccount(
          input: { id: "${account.id}", officeId: "${officeId}" }
        ) {
          id
        }
      }
    `))
    .then(r => console.log(`Updated officeId: `, r))
    .catch(ex => console.error(ex))
  }

  useEffect(() => {
    const listener = ({ payload }) => {
      const { event, data } = payload
      if (event === 'signOut') {
        setAuth({ state: event, user: null })
        // setOfficeId(null)
        //localStorage.deleteItem(AUTHENTICATOR_AUTHSTATE)
        return
      }
      console.log(payload)
      if(event !== 'signUp' && !data.attributes['custom:tmp_pass']){
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, 'signedIn')
        setAuth({ state: event, user: data.username})
        // setOfficeId(data.username)
      }
    }

    Hub.listen('auth', listener)

    return () => {
      Hub.remove('auth', listener)
    }

    // Hub.listen('auth', ({ payload }) => {
    //   const { event, data } = payload
    //   if (event === 'signOut') {
    //     setAuth({ state: event, user: null })
    //     setOfficeId(null)
    //     //localStorage.deleteItem(AUTHENTICATOR_AUTHSTATE)
    //     return
    //   }
    //   if(event !== 'signUp'){
    //     localStorage.setItem(AUTHENTICATOR_AUTHSTATE, 'signedIn')
    //     setAuth({ state: event, user: data.username})
    //     setOfficeId(data.username)
    //   }
    // })
  }, [])

  return <UserContext.Provider value={{
    ...auth,
    currentOffice: (account.officeId && offices.find(o => o.office.id)) || offices[0],
    officeRecord: offices.find(o => o.id === (account.officeId || account.userId)),
    account,
    offices,
    officeId: account.officeId,
    setOfficeId: updateOfficeId
  }}>{props.children}</UserContext.Provider>
}

export { UserContext, UserContextProvider, }