import React, { useState, useEffect, useContext, useCallback }  from "react";
import './App.css';
import './Curran.css';

import CustomerRoutes from './CustomerRoutes'

import {useParams, Link, Redirect} from "react-router-dom"

import usePath from 'react-use-path';


import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { grey } from '@material-ui/core/colors';

import {UserContext} from './UserContext'

import CreatePractice from './CreatePractice'


import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

/*import awsconfig from './aws-exports';

Amplify.configure(awsconfig);*/

function makeComparator(key, order='desc') {
  return (a, b) => {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0; 

    const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === 'desc' ? (comparison * -1) : comparison
  };
}


function CustomerApp(props) {
  const [accountType, setAccountType] = useState(props.accountType)

  const [path, setPath] = usePath()
  //const [auth, setAuth] = useState({user: "e230e3f4-5fa5-4a98-b80b-939997b3751c"})
  const [loading, setLoading] = useState(true)

  const auth = useContext(UserContext)

  const [office, setOffice] = useState("")
  const [officeLogo, setOfficeLogo] = useState("")

  const [memberFetched, setMemberFetched] = useState(false)

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState(path.path.includes('plan') ? "Plans" : path.path.includes('practice') ? "Connections" : path.path.includes('info') ? "Info" : path.path.includes('payment') ? "Payments" : "Billing")
  const [showNav, setShowNav] = useState(true)

  const [showConnectionsDropdown, setShowConnectionsDropdown] = useState(false)
  const [showPlansDropdown, setShowPlansDropdown] = useState(false)
  const [showMembersDropdown, setShowMembersDropdown] = useState(false)
  const [showPaymentsDropdown, setShowPaymentsDropdown] = useState(false)

  const [showProfileDropdown, setShowProfileDropdown] = useState(false)

  const [contactName, setContactName] = useState("")
  const [patientName, setPatientName] = useState("")
  const [contactAddress, setContactAddress] = useState("")
  const [contactCity, setContactCity] = useState("")
  const [contactZip, setContactZip] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [active, setActive] = useState(true)
  const [createdAt, setCreatedAt] = useState("")

  const [dueDay, setDueDay] = useState(1)
  const [lateFeeDays, setLateFeeDays] = useState(1)
  const [lateFeeCharge, setLateFeeCharge] = useState(0)
  const [interestRate, setInterestRate] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [initialDeposit, setInitialDeposit] = useState(0)

  const [remainingBalance, setRemainingBalance] = useState(0)
  const [paymentInterval, setPaymentInterval] = useState("")
  const [periodAmount, setPeriodAmount] = useState(0)

  const [paymentToken, setPaymentToken] = useState("")
  const [responseCount, setResponseCount] = useState(0)


  const [memberId, setMemberId] = useState("")
  const [memberFinanceId, setMemberFinanceId] = useState("")

  const [edit, setEdit] = useState(props.edit)

  const [plans, setPlans] = useState([])

  const [plan, setPlan] = useState("None")
  const [planId, setPlanId] = useState("")

  const [selectedPlan, setSelectedPlan] = useState(false)

  const [agreed, setAgreed] = useState(false)
  const [agreementDate, setAgreementDate] = useState("")

  const [fixedBenefits, setFixedBenefits] = useState("")
  const [customBenefits, setCustomBenefits] = useState([])

  const [practices, setPractices] = useState([])

  const [payments, setPayments] = useState([])

  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value)
  }

  const handleContactNameChange = (event) => {
    setContactName(event.target.value)
  }

  const handleContactAddressChange = (event) => {
      setContactAddress(event.target.value)
    }

  const handleContactCityChange = (event) => {
      setContactCity(event.target.value)
    }

  const handleContactZipChange = (event) => {
      setContactZip(event.target.value)
    }

  const handleContactPhoneChange = (event) => {
      setContactPhone(event.target.value)
    }

  const handleContactEmailChange = (event) => {
      setContactEmail(event.target.value)
    }

  const handleDueDayChange = (event) => {
      setDueDay([event.target.value][0])
    }

  const handleInitialDepositChange = (event) => {
      setInitialDeposit(event.target.value)
    }

  const handleRemainingBalanceChange = (event) => {
      setRemainingBalance(event.target.value)
    }

  const handlePaymentIntervalChange = (event) => {
      setPaymentInterval([event.target.value][0])
    }

  const handlePeriodAmountChange = (event) => {
      setPeriodAmount(event.target.value)
    }

  const handleLateFeeDaysChange = (event) => {
      setLateFeeDays(event.target.value)

    }

  const handleLateFeeChargeChange = (event) => {
      setLateFeeCharge(event.target.value)

    }

  const handleInterestRateChange = (event) => {
      setInterestRate(event.target.value)

    }

  const handleTotalBalanceChange = (event) => {
      setTotalBalance(event.target.value)

    }

  const handleMonthlyPaymentChange = (event) => {
      setMonthlyPayment(event.target.value)

    }


  const handlePlanChange = (event) =>{
    const planName = [event.target.value][0]
    if(planName != "None"){
      const newPlan = plans.filter(plan => plan.name == [event.target.value][0])[0]
      setPlanId(newPlan.id)
      setPlan(planName)

      setSelectedPlan(true)

      const atobBenefits = JSON.parse(atob(newPlan.benefits))
      setFixedBenefits(atobBenefits)

      let newFixedBenefits = fixedBenefits
      for(let i=0; i<atobBenefits.length; i++){
        if(atobBenefits[i]['included']){
          if(atobBenefits[i]['benefit'].includes("INCLUDED")){
            newFixedBenefits = newFixedBenefits.concat({...atobBenefits[i], redeemed: false, redeemDate: ""})
          }
        }
      }

      setFixedBenefits(newFixedBenefits)

    }
    else{
      setPlan("None")
      setSelectedPlan(false)
    }
  }

  const [practice, setPractice] = useState("")
  
  const handlePracticeChange = (event) =>{
    const practiceName = [event.target.value][0]
    if(practiceName != "all"){
      setPractice(practiceName)
    }
    else{
      setPractice("")
    }
  }



  function handleLogout() {
    Auth.signOut()
    props.userHasAuthenticated(false);
    props.setAccountType("")
    props.setUser(null)

  }

  const handleNavChange = () =>{
    const newShowNav = !showNav
    setShowNav(newShowNav)
  }

  const handleConnectionsDropdownChange = () =>{
    const newDropdown = !showConnectionsDropdown
    setShowConnectionsDropdown(newDropdown)
  }

  const handlePlansDropdownChange = () =>{
    const newDropdown = !showPlansDropdown
    setShowPlansDropdown(newDropdown)
  }

  const handleMembersDropdownChange = () =>{
    const newDropdown = !showMembersDropdown
    setShowMembersDropdown(newDropdown)
  }

  const handleProfileDropdown = () => {
    const newDropdown = !showProfileDropdown  
    setShowProfileDropdown(newDropdown)
  }

  const handleProfileUndropdown = () => {
    if(showProfileDropdown)  
    setShowProfileDropdown(false)
  }

  const handleESignature = async () =>{
    if(!agreed){
      const today = new Date().toISOString().split('T')[0]
      setAgreementDate(today)
      const updateMember = await API.graphql(graphqlOperation(
            `mutation update {
              updateMember(input: {id: "${auth.officeId || auth.user}", customerSignature: ${true}, planAgreementDate: "${today}"
            }){
                id
                customerSignature
                planAgreementDate
              }
            }`))
    }
    setAgreed(true)


  }


  const assessmentGray = <AssessmentIcon style={{color: grey[500]}}/>
  const assessmentWhite = <AssessmentIcon/>

  const addCircleGray = <AddCircleOutlineIcon style={{ color: grey[500]}}/>
  const addCircleWhite = <AddCircleOutlineIcon />

  const assignmentGray = <AssignmentIcon style={{ color: grey[500]}}/>
  const assignmentWhite = <AssignmentIcon/>

  const assignmentIndGray = <AssignmentIndIcon style={{ color: grey[500]}}/>
  const assignmentIndWhite = <AssignmentIndIcon />

  const attachMoneyGray = <AttachMoneyIcon style={{ color: grey[500]}}/>
  const attachMoneyWhite = <AttachMoneyIcon/>

  const arrowRightGray = <ArrowRightIcon style={{ color: grey[500]}}/>
  const arrowRightWhite = <ArrowRightIcon/>

  const arrowDownGray = <ArrowDropDownIcon style={{ color: grey[500]}}/>
  const arrowDownWhite = <ArrowDropDownIcon/>

    const fetchMember = useCallback(
       async () => {
        const memberData = await API.graphql(graphqlOperation(`query GetMember {
      getMember(id: "${auth.officeId || auth.user}"){
          id
          name
          active
          contactName
          contactAddress
          contactCity
          contactPhone
          contactZip
          contactEmail
          planAgreement
          planAgreementDate
          customerSignature
          plan
          practice
          office
          fixedBenefits
          createdAt
      }
  }`))
        const userMemberData = memberData.data.getMember

        setContactName(userMemberData['contactName'])
        setPatientName(userMemberData['name'])
        setContactAddress(userMemberData['contactAddress'])
        setContactCity(userMemberData['contactCity'])
        setContactZip(userMemberData['contactZip'])
        setContactPhone(userMemberData['contactPhone'])
        setContactEmail(userMemberData['contactEmail'])
        setPractice(userMemberData['practice'])
        setSelectedPlan(userMemberData['planAgreement'])
        setAgreed(userMemberData['customerSignature'])
        setAgreementDate(userMemberData['planAgreementDate'])
        setPlan(userMemberData['plan'])
        setActive(userMemberData['active'])
        setMemberId(props.memberId)
        setOffice(userMemberData['office'])
        setCreatedAt(userMemberData['createdAt'])
        if(userMemberData['fixedBenefits'] !== null){
          setFixedBenefits(JSON.parse(atob(userMemberData['fixedBenefits'])))
        }

        const userPlan = plans.filter(plan => plan.name == userMemberData['plan'])
        //setPlanId(userPlan['id'])

        const memberFinanceData = await API.graphql(graphqlOperation(`query ListMemberFinances {
        listMemberFinances(filter: {memberId: {eq: "${auth.officeId || auth.user}"}}, limit: 9999){
        items{
            id
            memberId
            office
            dayDue
            initialDeposit
            paymentInterval
            periodAmount
            remainingBalance
            interestRate
            totalBalance
            paymentToken
        }}
    }`))
        const userMemberFinanceData = memberFinanceData.data.listMemberFinances.items[0]

        if((auth.officeId || auth.user != undefined) && (userMemberFinanceData != undefined)){
          setDueDay(userMemberFinanceData['dayDue'])
          setInterestRate(userMemberFinanceData['interestRate'])
          setTotalBalance(userMemberFinanceData['totalBalance'])
          setPaymentInterval(userMemberFinanceData['paymentInterval'])
          setPeriodAmount(userMemberFinanceData['periodAmount'])
          setInitialDeposit(userMemberFinanceData['initialDeposit'])
          setRemainingBalance(userMemberFinanceData['remainingBalance'])
          setPaymentToken(userMemberFinanceData['paymentToken'])
          setMemberFinanceId(userMemberFinanceData['id'])
        }

  }, [auth.officeId, auth.user, plans, props.memberId])

    async function fetchPlan(planId){
      let userPlan="userPlan"
      const planData = await API.graphql(graphqlOperation(`query GetPlan {
        getPlan(id: "${planId}"){
              id
              name
              benefits
        }
    }`))
          const userPlanData = planData.data.getPlan
      setCustomBenefits(JSON.parse(atob(userPlanData.benefits)))
    }

    const fetchPlans = useCallback(
      async () => {

      const plansData = await API.graphql(graphqlOperation(`query ListPlans {
        listPlans(filter: {office: {eq:"${office}"}}, limit: 9999) {
          items {
              id
              name
              benefits
          }
        }
        }`))

      let plansList = plansData.data.listPlans.items
      let rowCount = 1
      let newPlan = []

      let newPlansList = []
      for(let i=0; i<plansList.length; i++){
    newPlan = {...plansList[i], rowId: rowCount}
    newPlansList.push(newPlan)
    rowCount = rowCount + 1
    }

      setPlans(newPlansList)
    }, [office])

  const fetchMemberPayments = useCallback(
    async () => {
        const memberPaymentsData = await API.graphql(graphqlOperation(`query ListPayments {
        listPaymentss(filter: {and: [{memberId: {eq: "${auth.officeId || auth.user}"}}, {office: {eq:"${office}"}}]}, limit: 9999){
          items{
              id
              memberId
              memberName
              description
              status
              grossPay
              fee
              netPay
              office
              practice
              createdAt
          }
      }
    }`))
  
        const userPaymentsData = memberPaymentsData.data.listPaymentss.items

      let newPaymentsList = []
      let rowCount = 1
      for(let i=0; i<userPaymentsData.length; i++){
      const newPayment = {...userPaymentsData[i], rowId: rowCount, timestamp: new Date(userPaymentsData[i]['createdAt']).getTime()}
      newPaymentsList.push(newPayment)
      rowCount = rowCount + 1
    }

    const newPaymentsList2 = newPaymentsList.sort(makeComparator('timestamp'))

      newPaymentsList = []
      rowCount = 1
      for(let i=0; i<newPaymentsList2.length; i++){
      const newPayment = {...newPaymentsList2[i], rowId: rowCount, timestamp: new Date(userPaymentsData[i]['createdAt']).getTime()}
      newPaymentsList.push(newPayment)
      rowCount = rowCount + 1
    }

        setPayments(newPaymentsList)

  }, [auth.officeId, auth.user, office])

  const fetchOffice = useCallback(
    async () => {
        const officeData = await API.graphql(graphqlOperation(`query GetOffice {
          getOffice(id: "${office}"){
            id
            logo
            }
          }`))

        const userOfficeData = officeData.data.getOffice
        setOfficeLogo(userOfficeData['logo'])
    }, [office])


  useEffect(() => {
    setLoading(false)
    if(auth.officeId || auth.user != undefined){
      fetchMember()
      setMemberFetched(true)
      if(office != ""){
        fetchPlans()
        fetchMemberPayments()
        fetchOffice()
      }
      setLoading(true)
    }
  }, [auth, fetchMember, fetchMemberPayments, fetchOffice, fetchPlans, office])

  return (
    <div onClick={handleProfileUndropdown} className="flex w-full h-full bg-gray-100">
      <div className={showNav ? "h-full bg-white border-r nav-sidebar" : "hidden"}>
          <div className={showNav ? "flex flex-col" : "hidden"}>
            <div className="text-blue-500 p-8"><Link to="/login"><img alt='QDP Logo' src="https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDP-Brand-Logo-jpg.jpg"></img></Link></div>
            <div className={currentPage == "Billing" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"} >
              <div className="my-auto nav-icon">{currentPage == "Billing" ? assessmentWhite : assessmentGray}</div>
              <Link onClick={() => setCurrentPage("Billing")} className="menu-item flex my-auto py-4 px-2" to="/">My Bill</Link>
            </div>

            <button className="focus:outline-none" onClick={handlePlansDropdownChange}>
              <div className={currentPage == "Plan" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Plan" ? assignmentWhite : assignmentGray}</div>
                <Link onClick={() => setCurrentPage("Plan")} className="flex my-auto py-4 px-2 menu-item" to="/customer/plan">My Plan</Link>
              </div>
            </button>
              <div className={currentPage == "Info" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Info" ? assignmentIndWhite : assignmentIndGray}</div>
                <Link onClick={() => setCurrentPage("Info")} className="flex my-auto py-4 px-2 menu-item" to="/customer/info">My Info</Link>
              </div>
          </div>
      </div>

      <div className="w-full h-full bg-gray-100 overflow-auto ">
        <div className="w-full flex justify-between p-4 bg-gradient text-white">
          <button onClick={handleNavChange} className="bg-blue-500 hover:bg-blue-500"><DehazeIcon /></button>
          <div className="flex">
            <button onClick={handleProfileDropdown} className="focus:outline-none bg-blue-700 text-white w-10 h-10 rounded-full hover:bg-blue-700 text-s">{patientName[0]}</button>
            {/*officeHasPractice ? <button onClick={handleProfileDropdown} className="focus:outline-none bg-blue-700 text-white w-10 h-10 rounded-full hover:bg-blue-700 text-s">{officeName[0]}</button> : <Link to="/login">Login</Link>*/}
                  <div className={showProfileDropdown ? "profile-dropdown" : "hidden"}>
                      <div className="profile-mi"><Link to="/customer/password" className="profile-mi">Change Password</Link></div>
                      <button onClick={handleLogout} className="profile-mi">Logout</button>

                  </div>
          </div>
        </div>


        <CustomerRoutes appProps={{officeLogo, createdAt, agreed, agreementDate, handleESignature, accountType,
          isAuthenticated, userHasAuthenticated, showNav,
        dueDay, lateFeeDays, lateFeeCharge, interestRate, totalBalance, monthlyPayment,
              handleDueDayChange, handleLateFeeDaysChange, handleLateFeeChargeChange, handleInterestRateChange,
              handleTotalBalanceChange, handleMonthlyPaymentChange, contactName, contactAddress,
                contactCity, contactZip, contactPhone, contactEmail, patientName, handleContactNameChange,
                handleContactAddressChange, handleContactCityChange,
                handleContactZipChange, handleContactPhoneChange, handleContactEmailChange, practice,
                handlePracticeChange, practices, initialDeposit, handleInitialDepositChange,
                plans, plan, selectedPlan, handlePlanChange, remainingBalance, paymentInterval, periodAmount,
                handleRemainingBalanceChange, handlePaymentIntervalChange, handlePeriodAmountChange, fixedBenefits, customBenefits, fetchPlan, payments,
                fetchMemberPayments, memberId, auth}} />
      </div>
    </div>
  );
}

export default CustomerApp;