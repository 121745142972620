import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
	import Iframe from 'react-iframe'
import ResourceCard from './ResourceCard'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function PMSGuides(props){

	const s3Prefix = "https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/"
	
	const materials = [{id:1, link:"Dentrix-Tracking-of-IOP-patients.pdf", image:"", description: "Dentrix"},
	{id:2, link:"OpenDental-Tracking-of-IOP-patients.pdf", image:"", description: "OpenDental"},
	{id:3, link:"Eaglesoft-Tracking-of-IOP-Patients.pdf", image:"", description: "Eaglesoft"},
	{id:3, link:"Tracking-your-IOP-within-your-PMS-system-General_.pdf", image:"", description: "All Other"}]

	const displayMaterials = materials.map(material => {
		return(
				<div className="w-1/2 px-2"><ResourceCard key={material.id} pms={true} s3Prefix={s3Prefix} link={material.link} image={material.image} description={material.description}/></div>
			)
	})

	return(
		<div className="w-full h-full m-auto">
			<div className="w-1/2 rounded-sm p-10"><h1 className="ch1">PMS Guides</h1></div>
			<div className="flex flex-wrap justify-around mx-4">
				{displayMaterials}
			</div>
        </div>
		)
} 