import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
	import Iframe from 'react-iframe'
import ResourceCard from './ResourceCard'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function QuickStart(props){

	return(
		<div className="w-full h-full m-auto">
			<div className="w-5/6 h-full mx-auto my-10">
				<iframe title='QuickStart video' className="w-full h-full" src='https://vimeo.com/showcase/7403192/embed' allowfullscreen frameborder='0'></iframe>
			</div>
        </div>
		)
} 