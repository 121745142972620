import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";


import { UserContext } from '../../UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Select from '../../components/Select'

import SearchBar from '../../SearchBar'
import Table2 from '../../components/Table2'
import { Button, TextField } from "@material-ui/core";
import useDebounce from "../../hooks/useDebounce";
import { listMembersByOffice } from "../../graphql/queries";


export default function Members(props) {

	const auth = useContext(UserContext)

	const [response, setResponse] = useState({ items: [], nextToken: null })

	// const [members, setMembers] = useState([])

	const [practice, setPractice] = useState("All")
	const [currMember, setCurrMember] = useState("All")

	const [practices, setPractices] = useState([])

	const [filters, setFilters] = useState({hideInactive: true, nameQuery: ''})
	const debouncedNameQuery = useDebounce(
		useMemo(
			() => ({
				query: filters.nameQuery
			}),
			[filters.nameQuery]
		)
		, 500);

	const [expirationFilter, setExpirationFilter] = useState(false)

	const isMemberCardExpiring = (member) => {

		let mem = member

		// No expiration or empty expiration means non-expiring
		if (!mem.expiration || mem.expiration.length === 0) { return false }

		const expM = Number(mem.expiration.substring(0, 2))
		const expY = Number(mem.expiration.substring(2, 6))

		const now = new Date()
		const currYear = now.getFullYear()
		const currMonth = now.getMonth() + 1

		// If cur year is less than the expiration year then we're good to go
		if (currYear < expY) { return false }

		return currYear > expY || currMonth >= expM || (currMonth + 1) >= expM
	}


	const filterMembers = (members) => {
		let newMembers = [...members]
		if (practice != "All") {
			newMembers = newMembers.filter(member => member.practice == practice)
		}
		// if (currMember != "All") {
		// 	newMembers = newMembers.filter(member => member.name == currMember)
		// }
		if (expirationFilter) {
			newMembers = newMembers.filter(member => member.expiring)
		}

		// if (activeFilter) {

		// 	newMembers = newMembers.filter(m => m.active)
		// }
		return newMembers
	}

	const handlePracticeChange = (event) => {
		const practiceName = [event.target.value][0]
		if (practiceName != "all") {
			const pickedPractice = practices.filter(practice => practice.name == practiceName)[0]
			setPractice(pickedPractice.name)

			let rowCount = 1
			let newMember

			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				if (members[i]['practice'] == pickedPractice.name) {
					newMember = { ...members[i], rowId: rowCount }
					newMembersList.push(newMember)
					rowCount = rowCount + 1
				}
				else {
					newMember = members[i]
					newMembersList.push(newMember)
				}
			}
			// setMembers(newMembersList)
		}
		else {
			setPractice("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				newMember = { ...members[i], rowId: rowCount }
				newMembersList.push(newMember)
				rowCount = rowCount + 1
			}
			// setMembers(newMembersList)

		}
	}

	const loadMore = async () => {
		/// Using the nextToken, load more
		fetchMembers()
	}

	const handleChangeMember = (event) => {
		const memberName = event.value
		if (memberName != "all") {
			const pickedMember = members.filter(member => member.name == memberName)[0]
			setCurrMember(pickedMember.name)

			let rowCount = 1
			let newMember

			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				if (members[i]['name'] == pickedMember.name) {
					newMember = { ...members[i], rowId: rowCount }
					newMembersList.push(newMember)
					rowCount = rowCount + 1
				}
				else {
					newMember = members[i]
					newMembersList.push(newMember)
				}
			}
			// setMembers(newMembersList)
		}
		else {
			let rowCount = 1
			let newMember
			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				newMember = { ...members[i], rowId: rowCount }
				newMembersList.push(newMember)
				rowCount = rowCount + 1
			}
			// setMembers(newMembersList)
			setCurrMember("All")

		}
	}

	const confirmMemberActiveChange = (event) => {
		const targetMemberId = event.target.id
		const targetMemberChecked = event.target.checked
		const targetMemberName = event.target.name

		confirmAlert({
			title: 'Confirm to submit',
			message: !event.target.checked ? "Are you sure you want to deactivate member?" : "Are you sure you want to activate member?",
			buttons: [
				{
					label: 'Yes',
					onClick: () => { handleMemberActiveChange(targetMemberId, targetMemberChecked, targetMemberName) }
				},
				{
					label: 'No',
					onClick: () => null
				}
			]
		});
	}

	const handleExpirationFilter = () => {
		const newExpirationFilter = !expirationFilter
		setExpirationFilter(newExpirationFilter)
	}

	const handleMemberActiveChange = async (memberId, memberChecked, memberName) => {

		const targetMemberId = memberId
		const targetMemberChecked = memberChecked
		const targetMemberName = memberName

		const memberFinanceData = await API.graphql(graphqlOperation(`query ListMemberFinances {
		    listMemberFinances(filter: {and: [{memberId: {eq: "${targetMemberId}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		    items{
	          id
	          memberId
	          office
	          dayDue
	          initialDeposit
	          paymentInterval
	          periodAmount
	          remainingBalance
	          interestRate
	          totalBalance
	          paymentToken
		    }}
		}`))

		const userMemberFinanceData = memberFinanceData.data.listMemberFinances.items[0]
		const financeId = userMemberFinanceData['id']

		setResponse({
			...response,
			items: response.items.map(member => {
				if (member.id == targetMemberId) {
					return ({ ...member, active: targetMemberChecked })
				}
				return member
			})
		})

		const newMember = response.items.filter(member => member.id == targetMemberId)[0]

		const updateMember = await API.graphql(graphqlOperation(
			`mutation update {
		        updateMember(input: {id: "${targetMemberId}", active: ${targetMemberChecked}}){
		          id
		          active
		        }
		      }`))

		const updateMemberFinance = await API.graphql(graphqlOperation(
			`mutation update {
		        updateMemberFinance(input: {id: "${financeId}", active: ${targetMemberChecked}}){
		          id
		          active
		        }
		      }`))


		const createMemberLog = await API.graphql(graphqlOperation(
			`mutation create {
		        createMemberLog(input: {memberId: "${targetMemberId}", memberName: "${targetMemberName}" practice: "${newMember.practice}",
		    	activity: "${targetMemberChecked ? "Active Member" : "Inactive Member"}", office: "${props.officeId}"}){
		          id
		          memberId
		          memberName
		          practice
		          activity
		          office
		        }
		      }`))

		// fetchMembers()
	}

	const fetchMembers = useCallback(
		async (clear = false) => {
			const filter = { }

			if (filters.hideInactive) {
				filter.active = { eq: true }
			}

			if (filters.nameQuery && filters.nameQuery.length > 0) {
				filter.or = [
					{ name: { eq: filters.nameQuery }},
					{ name: { contains: filters.nameQuery }},
					{ name: { contains: filters.nameQuery.toLowerCase() }},
					{ name: { beginsWith: filters.nameQuery }},
					{ name: { beginsWith: filters.nameQuery.toLowerCase() }}
				]
			}

			const realFilter = Object.keys(filter).length > 0 ? filter : null

			const membersData = await API.graphql(graphqlOperation(listMembersByOffice, {
				office: auth.officeId || auth.user,
				// sortDirection: 'ASC', 
				filter: realFilter,
				limit: 100,
				nextToken: clear ? null : response.nextToken
			}))

			const { data: { listMembersByOffice: { items: members, nextToken } } } = membersData

			const mappedMembers = members.map((record, index) => {
				return {
					...record,
					rowId: index+1,
					expiring: isMemberCardExpiring(record)
				}
			})

			const items = (clear || response.nextToken == null) ? mappedMembers : response.items.concat(mappedMembers)

			setResponse({
				items,
				nextToken
			})
		}, 
		[auth.user, auth.officeId, response, filters]
	)

	const fetchPractices = useCallback(
		async () => {
		const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
        listPractices(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              name
              city
              zip
          }
        }
        }`))

		let practicesList = practiceData.data.listPractices.items
		let rowCount = 1
		let newPractice = []

		let newPracticeList = []
		for (let i = 0; i < practicesList.length; i++) {
			newPractice = { ...practicesList[i], rowId: rowCount }
			newPracticeList.push(newPractice)
			rowCount = rowCount + 1
		}


		setPractices(newPracticeList)
	}, [auth.officeId, auth.user])


	useEffect(() => {
		fetchPractices()
		// fetchMembers()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.officeId, auth.user])

	useEffect(() => {
		fetchMembers(true)
	}, [debouncedNameQuery, filters.hideInactive, auth.officeId, auth.user])


	const { items: members, nextToken } = response
	const selectMembers = members.map(member => ({ key: member.id, value: member.name, label: member.name }))

	return (
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm">
						<h1 className="ch1">
							All Members 
							<span className={expirationFilter ? "my-auto text-sm bg-red-500 text-white" : "my-auto text-sm text-red-500"}>
							<button onClick={handleExpirationFilter}>
								Expiring Cards: {members.filter(member => member.expiring == true).length}
							</button>
							</span>
						</h1>
					</div>
					<div className="w-1/2 rounded-sm text-right"><Link to="/createmember/info" className="gradient-btn">Add Member</Link></div>
				</div>

				<div className="flex w-full bg-white w-5/6 bg-white tiny-card mb-2 items-center">
					<form className="w-1/3" onSubmit={(e) => e.preventDefault() && false}>
					{/* <form className='w-1/2 mx-4'> */}
						<TextField 
							className='w-full' 
							placeholder='Search...' 
							value={filters.nameQuery} 
							onChange={(e) => setFilters({...filters, nameQuery: e.target.value})} />
					{/* </form> */}

						{/* <SearchBar options={selectMembers} onChange={handleChangeMember} /> */}
					</form>
					{props.officeMultiplePractices ? <Select displayField="name" onChange={handlePracticeChange} options={practices} value={practice} name={'practice'} /> : null}

					<div className=''>
						<input className='mx-2' name='active' type='checkbox' checked={filters.hideInactive} onChange={e => setFilters({...filters, hideInactive: e.target.checked})} />
							Hide inactive members
						</div>
				</div>

				<Table2 sortField='createdAt' sortOrder='desc'
					edit={true} pathPrefix={'member'} pathSuffix={'info'} columns={[{ column: 'Name', value: 'name' }, { column: 'Active', 'value': 'active' }, { column: 'Practice', value: 'practice' },
					{ column: 'Plan', value: 'plan' }, { column: 'Signup', value: 'createdAt' }, { column: 'Card Exp.', value: 'expiration' }]}
					fields={['name', 'active', 'practice', 'plan', 'createdAt', 'expiration']}
					handlers={{ 'active': confirmMemberActiveChange }} values={filterMembers(members)} />

				{ nextToken != null && (
				<div className='w-full'>
					<button onClick={loadMore} >Load More</button>
				</div>
				)}
			</div>
		</div>

	)
}