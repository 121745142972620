import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'


export default function BusinessEnroll(props){
	const auth = useContext(UserContext)

	const [fullName, setFullName] = useState("")
	const [employer, setEmployer] = useState("")
	const [city, setCity] = useState("")
	const [zip, setZip] = useState("")
	const [address, setAddress] = useState("")
	const [email, setEmail] = useState("")
	const [state, setState] = useState("")
	const [website, setWebsite] = useState("")
	const [country, setCountry] = useState("")
	const [dentalOfficeEmail, setDentalOfficeEmail] = useState("")

	const [editedForm, setEditedForm] =useState(false)
	const [createdForm, setCreatedForm] =useState(false)

	const [yourName, setYourName] = useState("")
	const [notes, setNotes] = useState("")
	const [phone, setPhone] = useState()

	const displayBESaved = editedForm ? <div className="w-5/6 py-2 bg-white text-green-700 flex content-center"><div className="px-2">Business Enroll Saved </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null
	const displayBECreated = createdForm ? <div className="w-5/6 py-2 bg-white text-green-700 flex content-center"><div className="px-2">Business Enroll Created </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null 



	const handleFullNameChange = (event) => {
		setFullName(event.target.value)
	}

	const handleYourNameChange = (event) => {
		setYourName(event.target.value)
	}	

	const handleNotesChange = (event) => {
		setNotes(event.target.value)
	}

	const handleEmployerChange = (event) => {
		setEmployer(event.target.value)
	}

	const handleStateChange = (event) => {
		setState(event.target.value)
	}

	const handleWebsiteChange = (event) => {
		setWebsite(event.target.value)
	}

	const handleCountryChange = (event) => {
		setCountry(event.target.value)
	}

	const handleCityChange = (event) => {
		setCity(event.target.value)
	}

	const handleZipChange = (event) => {
		setZip(event.target.value)
	}

	const handleEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const handlePhoneChange = (event) => {
		setPhone(event.target.value)
	}

	const handleAddressChange = (event) => {
		setAddress(event.target.value)
	}


    async function fetchPractice(){
        const practiceData = await API.graphql(graphqlOperation(`query GetPractice {
	    getPractice(id: "${props.practiceId}"){
	          id
	          name
	          businessName
	          city
	          zip
	          state
	    }
	}`))
        const userPracticeData = practiceData.data.getPractice
        setFullName(userPracticeData.name)
		setCity(userPracticeData.city)
		setZip(userPracticeData.zip)
		setState(userPracticeData.state)
	}

    useEffect(
	    () => {
	    if(props.edit){
	   		//fetchPractice()
	    }
	    }, [props.edit])

	const validateForm = async (event) =>{
		event.preventDefault()

		if(props.edit){
			/*const updatedPractice = await API.graphql(graphqlOperation(`mutation update {
			  updatePractice (input: {
			    id: "${props.practiceId}",
			    name: "${fullName}",
			    businessName: "${employer}",
			    city: "${city}"
			    zip: "${parseInt(zip)}",
			    state: "${state}"
			  }){
		          id
		          name
		          businessName
		          city
		          zip
		          state
			  }
			}`))
			setEditedForm(true)*/
			setEditedForm(true)
		}
		else{
			if((address == "") || (employer == "")){
				console.log(address)
				console.log(employer)
				console.log(dentalOfficeEmail)
				alert("Practice fields cannot be empty")
			}
			else{
				event.preventDefault()
				setCreatedForm(true)
				
		}

	}
}

	return(
		<div className="w-full h-full"> 
			<form target="_blank" rel="noopener noreferrer"action="https://service.capsulecrm.com/service/newlead" accept-charset="UTF-8" method="post" className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<input type="hidden" name="FORM_ID" value="bda93bdf-fefe-4cd6-aa36-08bf30f4b33a"></input>
				<input name="COMPLETE_URL" type="hidden" value="https://app.qdpdentist.com/"></input>
				<input type="hidden" name="TAG" value="Employer"></input>
				<input type="hidden" name="TAG" value="Lead"></input>
				<input type="hidden" name="TAG" value="Dental Referral"></input>
				<h1 className="text-2xl">Business Enroll</h1>

				<div className='video-wrapper'>
					<video 
						className='mx-auto my-2'
						controls={true}
						src='https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3.us-west-2.amazonaws.com/public/Business+Enroll+QDP.mp4' 
						width='50%' height='auto'></video>
				</div>

				<p className="my-4">Want to grow your practice the easy way? Looking for more Fee for Service patients? Want to help local employers save money and give employees a better dental benefit? <span className='font-bold'>START USING BUSINESS ENROLL TODAY.  It’s super simple!</span></p>
				<p>Let us do the heavy lifting of helping local businesses in your community organize a dental benefit that works legally and within their specific accounting structure. Using our Business Enroll feature is like having an entire employee benefit group at your fingertips! The best part is “Business Enroll” works whether a company has an existing dental insurance plan or not. Why is this great? It’s great when employers can see how much they can save, offering their employees a benefit, using the in-office plan (IOP) model. </p>
				<p className="my-4">Companies switching from dental insurance and using IOPs have the opportunity to save anywhere from 30 – 45%! Now, you have more new patients and less insurance hassles and your local employers save a lot of money. This money helps the local economy in your community. You are the hero helping them improve an often overlooked part of their employee benefit package.</p>
				<h2 className="font-bold text-lg">START TODAY:</h2>
				<ol className="my-2">
					<li className="pl-4 mb-2">1. Simply fill out the form below with business contacts you already know in your community. I.E.; Small business owners, HR managers, executive level personnel like CFOs, all work great.</li>
					<li className="pl-4 mb-2">2. We’ll take it from there! The introduction, follow-ups, and onboarding process will be handled by our team.</li>
					<li className="pl-4 mb-6">3. You continue to do what you do best, take great care of patients, and we’ll handle the rest! As local employers come on board, watch your new patient list grow.</li>
				</ol>
				<div className="flex">
				<div className="w-1/2 flex flex-col">
					
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Your Name:</p>
						<input name="CUSTOMFIELD[Source]" className="border w-1/2" onChange={handleYourNameChange} type="text" value={yourName} id="yourName"></input>
					</div>

					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Employer:*</p>
						<input name="ORGANISATION_NAME" className="border w-1/2" onChange={handleEmployerChange} type="text" value={employer} id="employer"></input>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Contact's Full Name:</p>
						<input name="FIRST_NAME" className="border w-1/2" onChange={handleFullNameChange} type="text" value={fullName} id="fullName"></input>
					</div>

					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Email:</p>
					    <MaskedInput name="EMAIL" mask={emailMask} className="border w-1/2" onChange={props.handleContactEmailChange} type="text" value={props.contactEmail} id="contactEmail"/>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Phone Number:</p>
					    <MaskedInput name="PHONE" mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={props.handleContactPhoneChange} type="text" value={props.contactPhone} id="contactPhone"/>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Website:</p>
						<input name="WEBSITE" className="border w-1/2" onChange={handleWebsiteChange} type="text" value={website} id="website"></input>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Number of Employees:</p>
						<input name="EMPLOYEES" className="border w-1/2" type="text" id="employees"></input>
					</div>			
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Current Dental Insurance Company:</p>
						<input name="INSURANCE" className="border w-1/2" type="text" id="insurance"></input>
					</div>			
				</div>
				<div className="w-1/2 flex flex-col">
					<div className="flex my-4 w-full ">
						<p className="my-auto pr-4 w-1/3 text-right">Address:*</p>
						<input name='STREET' className="border w-1/2" onChange={handleAddressChange} type="text" value={address} id="address"></input>
					</div>
					<div className="flex my-4 w-full ">
						<p className="my-auto pr-4 w-1/3 text-right">City:</p>
						<input name='CITY' className="border w-1/2" onChange={handleCityChange} type="text" value={city} id="city"></input>
					</div>
					<div className="flex my-4 w-full ">
						<p className="my-auto pr-4 w-1/3 text-right">State:</p>
						<input name='STATE' className="border w-1/2" onChange={handleStateChange} type="text" value={state} id="state"></input>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Zip:</p>
              			<MaskedInput name='ZIP' mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={handleZipChange} type="text" value={zip} id="zip"/>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Dental Office Email:*</p>
					    <MaskedInput name="CUSTOMFIELD[SourceEmail]" mask={emailMask} className="border w-1/2" type="text" value={props.officeEmail} id="officeEmail"/>
					</div>	
				</div>	
			</div>
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/6 text-right">Notes:</p>
			    <textarea onChange={handleNotesChange} name="NOTE" className="border w-4/5" type="text" value={notes} id="notes"></textarea>
			</div>
	        {displayBECreated}
	        {displayBESaved}
			<button className="px-6 py-2 bg-blue-500 text-white rounded-sm">Submit</button>
			</form>
		</div>
		)
}