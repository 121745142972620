import React, { useEffect } from "react"
import useScript from '../hooks/useScript'

const HelpWidget = ({ languageId }) => {
  const [loaded, error] = useScript(
    "https://widget.freshworks.com/widgets/66000000293.js"
  )

  useEffect(() => {
    if (loaded) {
      console.log("it loaded!")
    }
    if (typeof window !== undefined) {
      window.fwSettings = {
        widget_id: 66000000293,
        locale: languageId
      };

      (function(){
        if ("function" != typeof window.FreshworksWidget) {
          var n = function() {
            n.q.push(arguments)
          }
          n.q = []
          window.FreshworksWidget = n
        }
      })()
    }
    return () => {}
  }, [languageId, loaded])

  useEffect(() => {
    if (typeof window !== undefined) {
      if ("function" == typeof window.FreshworksWidget) {
        window.FreshworksWidget("destroy")
        window.fwSettings = {
          widget_id: 66000000293,
          locale: languageId
        }
        const oldScript = document.querySelector(
          'script[src="https://widget.freshworks.com/widgets/66000000293.js"]'
        )
        if (oldScript) oldScript.remove()

        const script = document.createElement("script")
        script.async = true
        script.src = 'https://widget.freshworks.com/widgets/66000000293.js'
        document.head.appendChild(script)
      }
    }
  }, [languageId])

  return <></>
}
export default HelpWidget