import React, { useState, useEffect, useContext, useCallback }  from "react";
import { Link } from "react-router-dom";

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { grey } from '@material-ui/core/colors';

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import Table from './components/Table'

export default function AdminViewOfficePractices(props){
	
	const auth = useContext(UserContext)
	const [practices, setPractices] = useState([])
	const [members, setMembers] = useState([]) 

    const fetchPractices = useCallback(
		async () => {
      const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
        listPractices(filter: {office: {eq:"${props.officeId}"}}, limit: 9999) {
          items {
              id
              name
              city
              zip
              state
          }
        }
        }`))

      let practicesList = practiceData.data.listPractices.items
      let rowCount = 1
      let newPractice = []

      let newPracticeList = []
      for(let i=0; i<practicesList.length; i++){
		newPractice = {...practicesList[i], rowId: rowCount}
		newPracticeList.push(newPractice)
		rowCount = rowCount + 1
		}

	
      setPractices(newPracticeList)
    }, [props.officeId])

    const fetchMembers = useCallback(
		async () => {
      const membersData = await API.graphql(graphqlOperation(`query ListMembers {
        listMembers(filter: {office: {eq:"${props.officeId}"}}, limit: 9999) {
          items {
              id
              active
              practice
              plan
              paymentInterval
          }
        }
        }`))

      let membersList = membersData.data.listMembers.items
      let rowCount = 1
      let newMember = []

      let newMembersList = []

      for(let i=0; i<membersList.length; i++){

		newMember = {...membersList[i], rowId: rowCount}
		newMembersList.push(newMember)
		rowCount = rowCount + 1
		}

      setMembers(newMembersList)
    }, [props.officeId])

  const sumPlans = (memberPlans, typeFilter=null, intervalFilter=null, practiceFilter=null) => {
  	let planMembers = memberPlans.filter(member => member.active == true)
  	if((typeFilter != null) && (typeFilter != "All")){
  		planMembers = planMembers.filter(member => member.plan.includes(typeFilter))
  	}
  	if((intervalFilter != null) && (intervalFilter != "All")){
  		planMembers = planMembers.filter(member => member.paymentInterval == intervalFilter)
  	}
  	if(practiceFilter != null){
  		planMembers = planMembers.filter(member => member.practice == practiceFilter)
  	}
  	return planMembers.length
  }

  const calcPlanStats = (members, practice=null) => {
  	let planTypes = ['Adult', 'Perio', 'Child']
  	let planIntervals = ['monthly', 'yearly', 'All']

  	let statDict = {}
  	let currStat
  	for(let i =0; i<planTypes.length; i++){
  		for(let j = 0; j<planIntervals.length; j++){
  			if(j !== 'All'){
  				currStat = sumPlans(members, planTypes[i], planIntervals[j], practice)
  			}
  			else{
  				currStat = sumPlans(members, planTypes[i], null, practice)
  			}
  			statDict[`${planTypes[i]} - ${planIntervals[j]}`] = currStat
  		}
  	}
  	return statDict
  }

	const calcPlanStatsAll = (members, practice=null) => {
		let planTypes = ['All']
		let planIntervals = ['monthly', 'yearly']

		let statDict = {}
		let currStat
		for(let i =0; i<planTypes.length; i++){
			for(let j = 0; j<planIntervals.length; j++){
				if(j !== 'All'){
					currStat = sumPlans(members, planTypes[i], planIntervals[j], practice)
				}
				else{
					currStat = sumPlans(members, planTypes[i], null, practice)
				}
				statDict[`${planTypes[i]} - ${planIntervals[j]}`] = currStat
			}
		}
		return statDict
	}

	const listPractices = practices.map(practice =>{
		if (practice.deleted == true && members && members.length > 0) { return undefined }
		
		const background = practice.rowId % 2 == 1 ? "bg-gray-100" : ""
		const practicePlanStats = calcPlanStats(members, practice.name)
		const practicePlanStatsAll = calcPlanStatsAll(members, practice.name)
		return(
			<tr key={practice.id} className={background}>
				<td className="w-1/4 p-4 text-left table-content">{practice.name}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Adult - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Adult - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Perio - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Perio - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Child - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStats['Child - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStatsAll['All - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{practicePlanStatsAll['All - yearly']}</td>

			</tr>
		)
	})

	const OfficePlans = (members) => {
		let officePlanStats = calcPlanStats(members)
		let officePlanStatsAll = calcPlanStatsAll(members)
		return(
			<tr key={props.officeId} className="font-bold">
				<td className="w-1/4 p-4 text-left table-content">Total</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Adult - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Adult - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Perio - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Perio - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Child - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStats['Child - yearly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStatsAll['All - monthly']}</td>
				<td className="w-1/4 p-4 text-left table-content">{officePlanStatsAll['All - yearly']}</td>

			</tr>
			)

	}

	useEffect(() => {
		fetchPractices()
		fetchMembers()
	}, [auth, fetchMembers, fetchPractices])

	return(
		<div className="w-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">Practices</h1></div>
				</div>
				<Table columns={['Practice', 'Adult Mn', 'Adult Yr',
					 'Perio Mn', 'Perio Yr', 'Child Mn', 'Child Yr', 'All Mn', 'All Yr']}
					fields={['name', 'city', 'zip']}>
					{listPractices}
					{OfficePlans(members)}
				</Table>

			</div>
		</div>

		)
}