import React, { useState, useEffect, useContext, useCallback }  from "react";
import './App.css';
import './Curran.css';

import AdminRoutes from './AdminRoutes'

import {useParams, Link, Redirect} from "react-router-dom"

import usePath from 'react-use-path';


import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { grey } from '@material-ui/core/colors';

import {UserContext} from './UserContext'

import CreatePractice from './CreatePractice'


import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

/*import awsconfig from './aws-exports';

Amplify.configure(awsconfig);*/



function AdminApp(props) {

  const [path, setPath] = usePath()
  const auth = useState(props.auth)

  const [accountType, setAccountType] = useState(props.accountType)

  const [isAuthenticated, userHasAuthenticated] = useState(props.isAuthenticated);
  const [currentPage, setCurrentPage] = useState(path.path.includes('plan') ? "Plans" : path.path.includes('practice') ? "Connections" : path.path.includes('member') ? "Members" : path.path.includes('payment') ? "Payments" : "Dashboard")
  const [showNav, setShowNav] = useState(true)

  const [showConnectionsDropdown, setShowConnectionsDropdown] = useState(false)
  const [showPlansDropdown, setShowPlansDropdown] = useState(false)
  const [showMembersDropdown, setShowMembersDropdown] = useState(false)
  const [showPaymentsDropdown, setShowPaymentsDropdown] = useState(false)

  const [showProfileDropdown, setShowProfileDropdown] = useState(false)

  const [officeName, setOfficeName] = useState("")
  const [officePrimaryContact ,setOfficePrimaryContact] = useState("")
  const [officePhone, setOfficePhone] = useState("")
  const [officeEmail, setOfficeEmail] = useState("")
  const [officeAddress, setOfficeAddress] = useState("")
  const [officeZip, setOfficeZip] = useState("")
  const [officeMultiplePractices, setOfficeMultiplePractices] = useState(false)
  const [officeHasPractice, setOfficeHasPractice] = useState(false)
  const [officeHasPlan, setOfficeHasPlan] = useState(false)
  const [officeFetched, setOfficeFetched] =useState(false)

  const handleOfficeNameChange = (event) => {
    setOfficeName(event)
  }

  const handleOfficePrimaryContactChange = (event) => {
    setOfficePrimaryContact(event)
  }

  const handleOfficePhoneChange = (event) => {
    setOfficePhone(event)
  }

  const handleOfficeEmailChange = (event) => {
    setOfficeEmail(event)
  }

  const handleOfficeAddressChange = (event) => {
    setOfficeAddress(event)
  }

  const handleOfficeZipChange = (event) => {
    setOfficeZip(event)
  }

  const handleOfficeMultiplePracticesChange = (event) => {
    setOfficeMultiplePractices(event)
  }

  const handleOfficeHasPracticeChange = (bool) => {
    setOfficeHasPractice(bool)
  }

  const handleOfficeHasPlanChange = (bool) => {
    setOfficeHasPlan(bool)
  }

  function handleLogout() {
    Auth.signOut()
    props.userHasAuthenticated(false);
    setOfficeHasPractice(false)
    props.setAccountType("")
    props.setUser(null)

  }

  const handleNavChange = () =>{
    const newShowNav = !showNav
    setShowNav(newShowNav)
  }

  const handleMultiplePracticesChange = (event) => {
    setOfficeMultiplePractices([event.target.checked][0])
  }

  const handleConnectionsDropdownChange = () =>{
    const newDropdown = !showConnectionsDropdown
    setShowConnectionsDropdown(newDropdown)
  }

  const handlePlansDropdownChange = () =>{
    const newDropdown = !showPlansDropdown
    setShowPlansDropdown(newDropdown)
  }

  const handleMembersDropdownChange = () =>{
    const newDropdown = !showMembersDropdown
    setShowMembersDropdown(newDropdown)
  }

  const handleProfileDropdown = () => {
    const newDropdown = !showProfileDropdown  
    setShowProfileDropdown(newDropdown)
  }

  const handleProfileUndropdown = () => {
    if(showProfileDropdown)  
    setShowProfileDropdown(false)
  }


  const assessmentGray = <AssessmentIcon style={{color: grey[500]}}/>
  const assessmentWhite = <AssessmentIcon/>

  const addCircleGray = <AddCircleOutlineIcon style={{ color: grey[500]}}/>
  const addCircleWhite = <AddCircleOutlineIcon />

  const assignmentGray = <AssignmentIcon style={{ color: grey[500]}}/>
  const assignmentWhite = <AssignmentIcon/>

  const assignmentIndGray = <AssignmentIndIcon style={{ color: grey[500]}}/>
  const assignmentIndWhite = <AssignmentIndIcon />

  const attachMoneyGray = <AttachMoneyIcon style={{ color: grey[500]}}/>
  const attachMoneyWhite = <AttachMoneyIcon/>

  const arrowRightGray = <ArrowRightIcon style={{ color: grey[500]}}/>
  const arrowRightWhite = <ArrowRightIcon/>

  const arrowDownGray = <ArrowDropDownIcon style={{ color: grey[500]}}/>
  const arrowDownWhite = <ArrowDropDownIcon/>


  const fetchOffice = useCallback(
    async () => {
      //not actually needed in the admin view

      const officeData = await API.graphql(graphqlOperation(`query GetOffice {
        getOffice(id: "${auth.officeId || auth.user}"){
          id
          name
          primaryContact
          phone
          email
          address
          zip
          multiplePractices
          hasPractice
          hasPlan
          }
        }`))

      const userOfficeData = officeData.data.getOffice

      try{
        setOfficeName(userOfficeData['name'])
        setOfficePrimaryContact(userOfficeData['primaryContact'])
        setOfficePhone(userOfficeData['phone'])
        setOfficeEmail(userOfficeData['email'])
        setOfficeAddress(userOfficeData['address'])
        setOfficeZip(userOfficeData['zip'])
        setOfficeMultiplePractices(userOfficeData['multiplePractices'])
        setOfficeHasPractice(userOfficeData['hasPractice'])
        setOfficeHasPlan(userOfficeData['hasPlan'])
      }
      catch{
        console.info("")
      }

  }, [auth.officeId, auth.user])

  useEffect(() => {
    if(props.auth.officeId || auth.user != undefined){
      //not actually needed in admin
      fetchOffice()
      setOfficeFetched(true)
    }
  }, [auth, fetchOffice, officeHasPractice, props.auth.officeId])

  const renderCreateFirstPractice = () =>{
    //not necessary in admin view
    return(
        <div className="w-full h-full">
          <Link to="/login" className="flex my-auto py-4 px-2 menu-item" >Login (shown for amplify demo only)</Link>
              <h1 className="text-xl text-center my-6">Welcome to QDP! Please create a practice to continue</h1>
              <CreatePractice officeHasPractice={officeHasPractice} handleOfficeHasPracticeChange={handleOfficeHasPracticeChange} />
            </div>
      )
  }


  return (
    <div onClick={handleProfileUndropdown} className="flex w-full h-full bg-gray-100">
      <div className={showNav ? "h-full bg-white border-r nav-sidebar" : "hidden"}>
          <div className={showNav ? "flex flex-col" : "hidden"}>
            <div className="text-blue-500 p-8">
              <Link to="/login">
                <img alt='QDP Logo' src="https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDP-Brand-Logo-jpg.jpg"></img>
              </Link>
            </div>
            <div className={currentPage == "Dashboard" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"} >
              <div className="my-auto nav-icon">{currentPage == "Dashboard" ? assessmentWhite : assessmentGray}</div>
              <Link onClick={() => setCurrentPage("Dashboard")} className="menu-item flex my-auto py-4 px-2" to="/admin">Dashboard</Link>
            </div>

            <button className="focus:outline-none" onClick={handlePlansDropdownChange}>
              <div className={currentPage == "Plans" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Plans" ? assignmentWhite : assignmentGray}</div>
                <Link onClick={() => setCurrentPage("Plans")} className="flex my-auto py-4 px-2 menu-item" to="/admin/plans">Plans</Link>
              </div>
            </button>
              <div className={currentPage == "Members" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Members" ? assignmentIndWhite : assignmentIndGray}</div>
                <Link onClick={() => setCurrentPage("Members")} className="flex my-auto py-4 px-2 menu-item" to="/admin/offices">Offices</Link>
              </div>

              <div className={currentPage == "Applications" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Applications" ? assignmentIndWhite : assignmentIndGray}</div>
                <Link onClick={() => setCurrentPage("Applications")} className="flex my-auto py-4 px-2 menu-item" to="/admin/applications">Applications</Link>
              </div>
              
            <div className={currentPage == "Payments" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
              <div className="my-auto nav-icon">{currentPage == "Payments" ? attachMoneyWhite : attachMoneyGray}</div>
              <Link onClick={() => setCurrentPage("Payments")} className="flex my-auto py-4 px-2 menu-item" to="/admin/payments">Payments</Link>
            </div>
          </div>
      </div>

      <div className="w-full h-full bg-gray-100 overflow-auto ">
        <div className="w-full flex justify-between p-4 bg-gradient text-white">
          <button onClick={handleNavChange} className="bg-blue-500 hover:bg-blue-500"><DehazeIcon /></button>
          <div className="flex">
            <button onClick={handleProfileDropdown} className="focus:outline-none bg-blue-700 text-white w-10 h-10 rounded-full hover:bg-blue-700 text-s">Q</button>
            {/*officeHasPractice ? <button onClick={handleProfileDropdown} className="focus:outline-none bg-blue-700 text-white w-10 h-10 rounded-full hover:bg-blue-700 text-s">{officeName[0]}</button> : <Link to="/login">Login</Link>*/}
                  <div className={showProfileDropdown ? "profile-dropdown" : "hidden"}>
                      <div className="profile-mi"><Link to="/admin/password" className="profile-mi">Change Password</Link></div>
                      <button onClick={handleLogout} className="profile-mi">Logout</button>

                  </div>
          </div>
        </div>

        <AdminRoutes appProps={{accountType, officeFetched, officeName, officePrimaryContact, officePhone, officeEmail,
         officeZip, officeAddress, officeHasPractice, officeHasPlan,
          officeMultiplePractices, isAuthenticated, userHasAuthenticated,
          handleOfficeNameChange, handleOfficeZipChange, handleOfficePrimaryContactChange, handleOfficePhoneChange,
          handleOfficeEmailChange, handleOfficeAddressChange, handleOfficeMultiplePracticesChange,
          handleOfficeHasPlanChange, handleOfficeHasPracticeChange, showNav}} />
      </div>
    </div>
  );
}

export default AdminApp;