import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Input from '../../components/Input'
import Form from '../../components/Form'
import FormCol from '../../components/FormCol'

import SelectCreditCardType from '../../components/SelectCreditCardType'
import SelectCreditCardExpiration from '../../components/SelectCreditCardExpiration'
import SelectState from '../../components/SelectState'

import SavedAlert from '../../components/SavedAlert'
import ErrorAlert from '../../components/ErrorAlert'

import CreateIcon from '@material-ui/icons/Create';
import { getCardTypeFromNumber } from "../../hooks/useCardType";

// TODO: Tokenization should really be handled in here.
//       But that requires some extra information about the card?

export default function MemberCardDetail(props){
	

	const tokenizeButton = <button onClick={props.handleTokenization} className="gradient-btn pr-4">Save Card</button>

	const onBlurCardNumber = () => {
		const cardType = getCardTypeFromNumber(props.cardNumber)
		props.handleCardTypeChange(cardType)
	}

	return(
		<div onClick={props.resetFocus} className={props.paymentType != "cash" ? "w-full" : "hidden"}>
			{!props.paymentToken || props.paymentToken == "" ? 
			<div>
				<form className="card p-8 bg-white border rounded-lg w-5/6 mx-auto " onSubmit={e => e.preventDefault()}>
					<h1 className="text-2xl">{props.title || 'Card Details'} <button className={props.swipeNow ? " border border-white hover:underline hover:font-bold font-bold bg-white text-blue-700 rounded-full text-xl" : " border border-white hover:underline hover:font-bold bg-white text-blue-700 rounded-full text-xl"}>{props.swipeNow ? "Swipe Now!" : "Click to Swipe Card"}</button></h1>
					<div className="flex">
						<FormCol>
							<Input onClick={props.resetFocus2} label={"Cardholder Name"} onChange={props.handleCardNameChange} value={props.cardName} name={"cardName"} />
							<Input onBlur={onBlurCardNumber} onClick={props.resetFocus2} label={"Card Number"} mask={"creditCard"} onChange={props.handleCardNumberChange} value={props.cardNumber} name={"cardNumber"} />
							<SelectCreditCardType onClick={props.resetFocus2} onChange={props.handleCardTypeChange} value={props.cardType} name={'cardType'} />
							<SelectCreditCardExpiration onClick={props.resetFocus2} onMonthChange={props.handleExpireMChange} monthValue={props.expireM} monthName={'expireM'}
							onYearChange={props.handleExpireYChange} yearValue={props.expireY} yearName={'expireY'} />
						</FormCol>
						<FormCol>
							<Input onClick={props.resetFocus2} label={"Address"} onChange={props.handleCardAddressChange} value={props.cardAddress} name={"cardAddress"} />
							<Input onClick={props.resetFocus2} label={"City"} onChange={props.handleCardCityChange} value={props.cardCity} name={"cardCity"} />
							<SelectState onClick={props.resetFocus2} onChange={props.handleCardStateChange} value={props.cardState} name={'state'} />
							<Input onClick={props.resetFocus2} label={"Zip"} onChange={props.handleCardZipChange} value={props.cardZip} name={"cardZip"} />
						</FormCol>
					</div>
				</form>

			{/* If we have tokenization handler, then call the tokenization flow */}
			{props.handleTokenization && (
				<div className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				{(props.paymentStatus && props.paymentStatus != "") ? props.paymentStatus == "success" ? <SavedAlert saved={true} label={'Card Saved'} /> : <ErrorAlert error={true} label={"Failure: " + props.paymentStatus} /> : null }
		    	<button onClick={props.handleTokenization} className="gradient-btn">Save Card</button>
		    </div>
			)}
		    </div>
			:
			<Form onSubmit={e => e.preventDefault()} title={"Card Details"} hideButton={true} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<FormCol>
					<button className="underline text-blue-500" onClick={() => props.setPaymentToken("")}>Update Card Info</button>
				</FormCol>
				<FormCol>
					<button className="my-auto" onClick={() => props.setPaymentToken("")}><CreateIcon className="table-icon"/></button>
				</FormCol>

			</Form>
		}
		</div>
		)
}

/*			<Form onSubmit={e => e.preventDefault()} hideButton={true} title={"Card Details"} buttonTitle={"Save Card"} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<h1 className="text-2xl"><button className={props.swipeNow ? " border border-white hover:underline hover:font-bold font-bold bg-white text-blue-700 rounded-full" : " border border-white hover:underline hover:font-bold bg-white text-blue-700 rounded-full"}>{props.swipeNow ? "Swipe Now!" : "Click to Swipe Card"}</button></h1>
				<FormCol>
					<Input onClick={props.resetFocus2} label={"Card Name"} onChange={props.handleCardNameChange} value={props.cardName} name={"cardName"} />
					<Input onClick={props.resetFocus2} label={"Card Number"} mask={"creditCard"} onChange={props.handleCardNumberChange} value={props.cardNumber} name={"cardNumber"} />
					<SelectCreditCardType onClick={props.resetFocus2} onChange={props.handleCardTypeChange} value={props.cardType} name={'cardType'} />
					<SelectCreditCardExpiration onClick={props.resetFocus2} onMonthChange={props.handleExpireMChange} monthValue={props.expireM} monthName={'expireM'}
					onYearChange={props.handleExpireYChange} yearValue={props.expireY} yearName={'expireY'} />
				</FormCol>
				<FormCol>
					<Input onClick={props.resetFocus2} label={"Address"} onChange={props.handleCardAddressChange} value={props.cardAddress} name={"cardAddress"} />
					<Input onClick={props.resetFocus2} label={"City"} onChange={props.handleCardCityChange} value={props.cardCity} name={"cardCity"} />
					<Input onClick={props.resetFocus2} label={"State"} onChange={props.handleCardStateChange} value={props.cardState} name={"cardState"} />
					<Input onClick={props.resetFocus2} label={"Zip"} onChange={props.handleCardZipChange} value={props.cardZip} name={"cardZip"} />
				</FormCol>
			</Form>*/
