import React, { useState, useEffect, useContext }  from "react";
import SearchIcon from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';

import {UserContext} from './UserContext'
import { API, graphqlOperation } from 'aws-amplify';
import Select from 'react-select'
import SearchBar from './SearchBar'
import {CSVLink} from 'react-csv';
import Table from './components/Table'


export default function Offices(props){

	const auth = useContext(UserContext)

	const [members, setMembers] = useState([])

	const [practice, setPractice] = useState("All")
	const [office, setOffice] = useState("All")
	const [status, setStatus] = useState("pending")

	const [practices, setPractices] = useState([])

	const filterApplications = (members) => {
		let newMembers = members

		//filter application by member value
		if(office != "All"){
			newMembers = newMembers.filter(member => member.name == office)
		}

		//filter by status value
		if(status != "All"){
			newMembers = newMembers.filter(member => member.status == status)
		}
		return newMembers
	}

	const handleChangeOffice = (event) =>{

		//similar to handleChangePractice() on Members.js (see for more commentary)
		const officeName = event.value
		if(officeName != "all"){
			const pickedOffice = members.filter(office => office.name == officeName)[0]
			setOffice(pickedOffice.name)

	        let rowCount = 1
	        let newMember

	        let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  if(members[i]['practice'] == pickedOffice.name){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			  }
			  else{
			  	newMember = members[i]
			  	newMembersList.push(newMember)
			  }
			}
      		setMembers(newMembersList)
      	}
		else{
			setOffice("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			}
			setMembers(newMembersList)

		}
	}

	const handleChangeStatus = (event) =>{

		//updates status State on page, filtered logic is archived in favor of filterApplications()
		const officeStatus = event.value
		if(officeStatus != "all"){
			const pickedOffices = members.filter(office => office.status == officeStatus)
			setStatus(officeStatus)

	        let rowCount = 1
	        let newMember

	        let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  if(members[i]['status'] == officeStatus){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			  }
			  else{
			  	newMember = members[i]
			  	newMembersList.push(newMember)
			  }
			}
      		setMembers(newMembersList)
      	}
		else{
			setStatus("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			}
			setMembers(newMembersList)

		}
	}


    async function fetchPendingOffices(){

    	//queries all pending offices into page

      const membersData = await API.graphql(graphqlOperation(`query ListPendingOffices {
        listPendingOffices(limit: 9999) {
          items {
              id
              name
              primaryContact
              phone
              email
              state
              status
          }
        }
        }`))

      let membersList = membersData.data.listPendingOffices.items
      let rowCount = 1
      let newMember = []

      let newMembersList = []
      for(let i=0; i<membersList.length; i++){
		newMember = {...membersList[i], rowId: rowCount}
		newMembersList.push(newMember)
		rowCount = rowCount + 1
		}

      setMembers(newMembersList)
    }


    const selectOffice = members.map(member => <option key={member.id} value={member.name}>{member.name}</option>)
    const selectOffice2 = members.map(member => ({key:member.id, value: member.name, label: member.name}))
    const selectOffice3 = selectOffice2.unshift({key:"all", label:"All Offices", value:"all"})

	//archived in favor of SearchBar component
	const Search = () => {return(<SearchIcon style={{ color: grey[400]}} />)}

    const selectStatus = [{key:"pending", label:"Pending", value:"pending"}, {key:"all", label:"All Statuses", value:"all"}, {key:"approved", label:"Approved", value:"approved"},
    {key:"rejected", label:"Rejected", value:"rejected"}]

	const customStyles = {
	  option: (provided, state) => ({
	    ...provided,
	    padding: 6,
	    textTransform: 'uppercase',
	    fontSize: 11,
	    letterSpacing: 1.2
	  }),
	  control: () => ({
	    // none of react-select's styles are passed to <Control />
	    display: 'flex'
	  }),
		container: (base) => ({
		    ...base,
		    flex: 1
		  })
		,
		placeholder: (provided) => ({
		    ...provided,
		    padding: 6,
		    textTransform: 'uppercase',
		    fontSize: 11,
		    letterSpacing: 1.2,
		    marginY: 'auto'
		}),
		indicatorsContainer: () => ({
			border: 'none',
			alignItems: 'center',
  			alignSelf: 'stretch',
  			display: 'flex'
		}),
		indicatorSeparator: () => ({
			width: 4
		}),
	    singleValue: (provided, state) => {
	    const opacity = state.isDisabled ? 0.5 : 1;
	    const transition = 'opacity 300ms';

	    return { ...provided,
	    		 opacity,
	    		  transition,
	    		   textTransform: 'uppercase',
	    		   fontSize: 11,
	    			letterSpacing: 1.2,
	    			display: 'inline'
	    		    };
	  }
	}

	const listOfficesCsv = members.map(office => {
		return([office.name, office.active ? "active" : "inactive", office.primaryContact, office.phone, office.email])
	})

	useEffect(() => {
		fetchPendingOffices()
	}, [auth])

	return(
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">Offices</h1></div>
				</div>				

		        <div className="card text-left">
		        	<div className="w-full my-2 flex justify-between">
						<form className="w-2/3 mx-2 my-auto flex">
							<SearchBar options={selectOffice2} onChange={handleChangeOffice} />
							<div className="mx-8"></div>
							<Select onChange={handleChangeStatus} styles={customStyles} defaultValue={selectStatus[0]} options={selectStatus} />

			            </form>
			            <CSVLink className="my-auto gradient-btn mt-4 mx-2" filename={"offices.csv"} data={listOfficesCsv} >Export</CSVLink>
		            </div>

				</div>
				<Table edit={true} pathPrefix={'admin/application'} 
					columns={['Name', 'Status', 'Primary Contact', 'Phone', 'Email']}
					fields={['name', 'status', 'primaryContact', 'phone', 'email']}
					values={filterApplications(members)} />

			</div>
		</div>

		)
}