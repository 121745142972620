import React, { useState, useEffect, useContext, forwardRef, useRef, useCallback } from "react";
import { Route, Switch, Link } from "react-router-dom";

import MemberAgreement from './MemberAgreement'
import MemberBenefitTracking from './MemberBenefitTracking'
import MemberPayments from './MemberPayments'
import MemberInfoDetail from './MemberInfoDetail'

import { API, graphqlOperation, Auth } from 'aws-amplify';

import CreateMemberPayment from './CreateMemberPayment'

import { UserContext } from '../../UserContext'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import Moment from 'moment'
import { tokenizePayment } from "../../usio/payments";
import { listMemberFinances } from "../../graphql/queries";
import { updateMemberFinance } from "../../graphql/mutations";

function makeComparator(key, order = 'desc') {
	//a simple sorting function
	return (a, b) => {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

		const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
		const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (aVal > bVal) comparison = 1;
		if (aVal < bVal) comparison = -1;

		return order === 'desc' ? (comparison * -1) : comparison
	};
}

async function signUp(username, password, patientName, dependent = false) {
	let user
	try {

		//queries members with contactEmail = the email the patient has provided on signup.  This prevents patients with duplicate emails from being created
		const membersData = await API.graphql(graphqlOperation(`query ListMembers {
        listMembers(filter: {contactEmail: {eq:"${username}"}}, limit: 9999) {
          items {
              id
              contactEmail
          }
        }
        }`))

		let membersList = membersData.data.listMembers.items

		//if no other members have the same email, sign the user up
		if (membersList.length == 0) {
			//user creation in cognito
			user = await Auth.signUp({
				username,
				password,
			});
			//creates an account record for the user in dynamodb
			const createAccount = await API.graphql(graphqlOperation(
				`mutation create {
	            createAccount(input: {id: "${user.userSub}", email: "${username}", accountType: "customer"}){
	              id
				  email
	              accountType
	            }
	          }`))

		}

	} catch (error) {
		console.log('error signing up:', error);
	}

	if (user != undefined) {
		return {
			username,
			id: user.userSub
		}
	}
	else if (dependent) {
		/// Dependent already exists
		alert('Dependent already exists')
	}
	else if (window.confirm(`User ${username} already exists. Do you want to add ${patientName} as a dependent?`)) {
		const dependentUsername = `${patientName.toLowerCase().replace(/ /, '_')}+${username}`
		return signUp(dependentUsername, password, patientName, true)
	}
	else {
		alert("An account with the given email already exists.")
	}

}

const debugData = {
	contactEmail: "test@email.com",
	contactName: "Test Member",
	patientName: "Kid LastName",
	contactAddress: "123 TEst Rd.",
	contactCity: "Beverly Hills",
	contactZip: "90210",
	contactPhone: "8675309",
	contactState: "CA",

	cardName: "Test Card",
	cardNumber: "4111111111111111",
	cardType: "VISA",
	cardExpireM: "02",
	cardExpireY: "2022",
	cardAddress: "123 Test Rd.",
	cardCity: "Beverly Hills",
	cardState: "CA",
	cardZip: "90210",
}

const data = {
	contactEmail: "",
	contactName: "",
	patientName: "",
	contactAddress: "",
	contactCity: "",
	contactZip: "",
	contactPhone: "",
	contactState: "",

	cardName: "",
	cardNumber: "",
	cardType: "",
	cardExpireM: "",
	cardExpireY: "",
	cardAddress: "",
	cardCity: "",
	cardState: "",
	cardZip: "",
	...(
		localStorage.getItem('@wellify/debug-data') === 'YES_PLEASE' ? debugData : {}
	)
	// ...debugData
}

const CreateMember = forwardRef((props, ref) => {

	const cardRef = useRef(null)

	const auth = useContext(UserContext)

	const [timeZoneOffset, setTimeZoneOffset] = useState(new Date().getTimezoneOffset() / 60)


	const [currentPage, setCurrentPage] = useState("PrimaryContact")

	const [contactName, setContactName] = useState(data.contactName)
	const [patientName, setPatientName] = useState(data.patientName)
	const [contactAddress, setContactAddress] = useState(data.contactAddress)
	const [contactCity, setContactCity] = useState(data.contactCity)
	const [contactZip, setContactZip] = useState(data.contactZip)
	const [contactPhone, setContactPhone] = useState(data.contactPhone)
	const [contactEmail, setContactEmail] = useState(data.contactEmail)
	const [active, setActive] = useState(true)

	const [paymentType, setPaymentType] = useState("card")
	const [paymentToken, setPaymentToken] = useState("")
	const [responseCount, setResponseCount] = useState(0)
	const [tokenizationStatus, setTokenizationStatus] = useState("")

	const [swiped, setSwiped] = useState(false)
	const [cardAll, setCardAll] = useState("")
	const [swipeNow, setSwipeNow] = useState(false)

	const [memberId, setMemberId] = useState("")
	const [memberFinanceId, setMemberFinanceId] = useState("")

	const [edit, setEdit] = useState(props.edit)

	const [fixedBenefits, setFixedBenefits] = useState([{ benefit: "INCLUDED: teeth cleaning benefits (2/yr)", included: true, redeemed: false, redeemDate: "" },
	{ benefit: "INCLUDED: All x-rays needed to complete annual exam", included: true, redeemed: false, redeemDate: "" }])

	const [customBenefits, setCustomBenefits] = useState([])

	const [editedForm, setEditedForm] = useState(false)
	const [createdForm, setCreatedForm] = useState(false)

	const displayPracticeSaved = editedForm ? <div className="w-5/6 mx-auto bg-white py-2 px-6 text-green-700 flex content-center"><div className="pr-2">Member Saved </div> <CheckCircleOutlineIcon style={{ color: green[600] }} /></div> : null
	const displayPracticeCreated = createdForm ? <div className="w-5/6 mx-auto bg-white py-2 px-6 text-green-700 flex content-center"><div className="pr-2">Member Created </div> <CheckCircleOutlineIcon style={{ color: green[600] }} /></div> : null

	const handleAddPaymentsPage = () => {
		//renders New Payments page on screen
		setCurrentPage('New Payment')
		setPaymentLogged(false)
	}

	const handleLogFixedBenefit = id => {

		//archived

		//event.preventDefault()
		const newFixedBenefits = fixedBenefits.map(benefit => {
			if (benefit.benefit == id) {
				const newRedeemed = !benefit.redeemed
				const newRedeemDate = newRedeemed ? new Date().toISOString().split('T')[0] : ""

				return { ...benefit, redeemed: !benefit.redeemed, redeemDate: newRedeemDate }
			}
			else {
				return (benefit)
			}
		})
		setFixedBenefits(newFixedBenefits)

	}

	const handlePatientNameChange = (event) => {
		setPatientName(event.target.value)
	}

	const handleContactNameChange = (event) => {
		setContactName(event.target.value)
	}

	const handleContactAddressChange = (event) => {
		setContactAddress(event.target.value)
	}

	const handleContactCityChange = (event) => {
		setContactCity(event.target.value)
	}

	const handleContactZipChange = (event) => {
		setContactZip(event.target.value)
	}

	const handleContactPhoneChange = (event) => {
		setContactPhone(event.target.value)
	}

	const handleContactEmailChange = (event) => {
		setContactEmail(event.target.value)
	}


	const [dueDay, setDueDay] = useState(1)
	const [lateFeeDays, setLateFeeDays] = useState(1)
	const [lateFeeCharge, setLateFeeCharge] = useState(0)
	const [interestRate, setInterestRate] = useState(0)
	const [totalBalance, setTotalBalance] = useState(0)
	const [monthlyPayment, setMonthlyPayment] = useState(0)
	const [initialDeposit, setInitialDeposit] = useState(0)

	const [remainingBalance, setRemainingBalance] = useState(0)
	const [paymentInterval, setPaymentInterval] = useState("yearly")
	const [periodAmount, setPeriodAmount] = useState(0)
	const [submitting, setSubmitting] = useState(false)

	const handleDueDayChange = (event) => {
		setDueDay([event.target.value][0])
	}

	const handleInitialDepositChange = (event) => {
		let deposit = event.target.value
		setInitialDeposit(deposit)

		let newRecommendedMonth
		let newRecommendedFull

		//recalculates monthly payments when initial deposits change
		//more details on this process in Fee Generator code and documentation
		const dp = `${deposit || 0}`
		if (paymentInterval == "monthly") {
			if (typeof totalBalance == 'string') {
				newRecommendedMonth = ((Number(totalBalance.replace('$', '')) + 50 - Number(dp.replace('$', ''))) / 12).toFixed(2)
			}
			else {
				newRecommendedMonth = ((Number(totalBalance) + 50 - Number(dp.replace('$', ''))) / 12).toFixed(2)
			}
			setPeriodAmount(newRecommendedMonth)
		}
		else {
			if (typeof totalBalance == 'string') {
				newRecommendedFull = ((Number(totalBalance.replace('$', '')) - Number(deposit.replace('$', '')))).toFixed(2)
			}
			else {
				newRecommendedFull = ((Number(totalBalance) - Number(dp.replace('$', '')))).toFixed(2)
			}
			setPeriodAmount(newRecommendedFull)
		}
	}

	const handleRemainingBalanceChange = (event) => {
		setRemainingBalance(event.target.value)
	}

	const handlePaymentTypeChange = (event) => {
		setPaymentType([event.target.value][0])
	}

	const handlePaymentIntervalChange = (event) => {
		setPaymentInterval([event.target.value][0])
		if (plan != "None") {
			//sense changing from monthly to yearly and vice versa changes the calculated payments, these need to be redone when the interval is changed
			const newPlan = plans.filter(nplan => nplan.name == plan)[0]
			const atobFees = JSON.parse(atob(newPlan.recommendedFee))
			let recFee = typeof atobFees.finalFee == "string" ? atobFees.finalFee.replace('$', '') : atobFees.finalFee
			let downPay = typeof initialDeposit == "string" ? initialDeposit.replace('$', '') : `${initialDeposit || 0}`
			if ([event.target.value][0] == "yearly") {
				setPeriodAmount(recFee)
				setRemainingBalance(Number(remainingBalance) - 50)
			}
			else {
				setPeriodAmount((recFee - downPay + 50) / 12)
				//setPeriodAmount(atobFees.recommendedMonth)
				setRemainingBalance(Number(remainingBalance) + 50)
			}
		}
	}

	const handlePeriodAmountChange = (event) => {
		setPeriodAmount(event.target.value)
	}

	const handleLateFeeDaysChange = (event) => {
		setLateFeeDays(event.target.value)

	}

	const handleLateFeeChargeChange = (event) => {
		setLateFeeCharge(event.target.value)
	}

	const handleInterestRateChange = (event) => {
		setInterestRate(event.target.value)
	}

	const handleTotalBalanceChange = (event) => {
		setTotalBalance(event.target.value)
	}

	const handleMonthlyPaymentChange = (event) => {
		setMonthlyPayment(event.target.value)
	}

	const [cardName, setCardName] = useState(data.cardName)
	const [cardNumber, setCardNumber] = useState(data.cardNumber)
	const [cardType, setCardType] = useState(data.cardType)
	const [expireM, setExpireM] = useState(data.cardExpireM)
	const [expireY, setExpireY] = useState(data.cardExpireY)
	const [securityCode, setSecurityCode] = useState("")
	const [cardAddress, setCardAddress] = useState(data.cardAddress)
	const [cardCity, setCardCity] = useState(data.cardCity)
	const [cardState, setCardState] = useState(data.cardState)
	const [cardZip, setCardZip] = useState(data.cardZip)
	const [expiration, setExpiration] = useState("")

	const handleCardZipChange = (event) => {
		setCardZip(event.target.value)
	}

	const handleCardCityChange = (event) => {
		setCardCity(event.target.value)
	}

	const handleCardAddressChange = (event) => {
		setCardAddress((event.target || event).value)
	}

	const handleCardStateChange = (event) => {
		setCardState((event.target || event).value)
	}

	const handleCardTypeChange = (event) => {
		console.log(`CARD TYPE CHANGE ${event}`)
		setCardType((event.target || event).value)
	}

	const handleCardNameChange = (event) => {
		setCardName(event.target.value)
	}

	const handleCardNumberChange = (event) => {
		setCardNumber(event.target.value)
	}

	const handleExpireMChange = (event) => {
		setExpireM([event.target.value][0])
	}

	const handleExpireYChange = (event) => {
		setExpireY([event.target.value][0])
	}


	const handleSecurityCodeChange = (event) => {
		setSecurityCode(event.target.value)
	}

	const [plans, setPlans] = useState([])

	const [plan, setPlan] = useState("None")
	const [planId, setPlanId] = useState("")

	const [selectedPlan, setSelectedPlan] = useState(false)

	const [paymentLogged, setPaymentLogged] = useState(false)

	const handlePlanChange = (event) => {
		const planName = [event.target.value][0]

		//basically anytime a plan, payment interval, or initial deposit changes, fee calculation will need to be recalculated
		if (planName != "None") {
			const newPlan = plans.filter(plan => plan.name == [event.target.value][0])[0]
			setPlanId(newPlan.id)
			setPlan(planName)

			setSelectedPlan(true)

			const atobBenefits = JSON.parse(atob(newPlan.benefits))
			setFixedBenefits(atobBenefits)

			const atobFees = JSON.parse(atob(newPlan.recommendedFee))
			let finalFee = typeof atobFees.finalFee == 'string' ? atobFees.finalFee.replace('$', '') : atobFees.finalfee
			if (typeof atobFees.finalFee == 'number') {
				finalFee = atobFees.finalFee
			}

			setTotalBalance(finalFee)
			setInitialDeposit((atobFees.downPayment.replace('$', '') || '0'))
			if (paymentInterval == "yearly") {
				setRemainingBalance(finalFee)
				setPeriodAmount(finalFee)
			}
			else {
				setPeriodAmount(atobFees.recommendedMonth)
				setRemainingBalance(Number(finalFee) + 50)
			}
			setMonthlyPayment(atobFees)
			//setPaymentInterval(atobFees.)

			let newFixedBenefits = fixedBenefits
			for (let i = 0; i < atobBenefits.length; i++) {
				if (atobBenefits[i]['included']) {
					if (atobBenefits[i]['benefit'].includes("INCLUDED")) {
						newFixedBenefits = newFixedBenefits.concat({ ...atobBenefits[i], redeemed: false, redeemDate: "" })
					}
				}
			}

			setFixedBenefits(newFixedBenefits)

		}
		else {
			setPlan("None")
			setSelectedPlan(false)
		}
	}

	const [practice, setPractice] = useState("")

	const handlePracticeChange = (event) => {
		const practiceName = [event.target.value][0]
		if (practiceName != "all") {
			setPractice(practiceName)
		}
		else {
			setPractice("")
		}
	}

	const [practices, setPractices] = useState([])

	const fetchPractices = useCallback(
		async () => {
		//fetch list of office practices to populate practice selection on the page
		const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
        listPractices(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              name
              city
              zip
          }
        }
        }`))

		let practicesList = practiceData.data.listPractices.items
		let rowCount = 1
		let newPractice = []

		let newPracticeList = []
		for (let i = 0; i < practicesList.length; i++) {
			newPractice = { ...practicesList[i], rowId: rowCount }
			newPracticeList.push(newPractice)
			rowCount = rowCount + 1
		}


		setPractices(newPracticeList)
		if (!props.edit) {
			if (newPracticeList.length > 0) {
				setPractice(newPracticeList[0]['name'])
			}
		}
	}, [auth, props.edit])


	const fetchPlans = useCallback(
		async () => {
		//fetches list of plan options to be passed to plan selection on the page
		const plansData = await API.graphql(graphqlOperation(`query ListPlans {
        listPlans(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              name
              benefits
              recommendedFee
          }
        }
        }`))

		let plansList = plansData.data.listPlans.items
		let rowCount = 1
		let newPlan = []

		let newPlansList = []
		for (let i = 0; i < plansList.length; i++) {
			newPlan = { ...plansList[i], rowId: rowCount }
			newPlansList.push(newPlan)
			rowCount = rowCount + 1
		}

		setPlans(newPlansList)
	}, [auth.officeId, auth.user])


	const handleTokenization = async (e) => {
		e.preventDefault()

	// 	var obj = `{"MerchantID":"${props.officeMerchantKey}",
	// 	"Login":"${props.officeUsioLogin}",
	// 	"Password":"${props.officeUsioPassword}",
	// 	"CardNumber": "${cardNumber.split(' ').join('').replace('_', '')}", 
	// 	"CardType": "${cardType}",
	// 	"ExpDate" : "${newExpiration}",
	// 	"Address1": "${cardAddress}",
	// 	"City": "${cardCity}",
	// 	"State": "${cardState}",
	// 	"Country": "US",
	// 	"Amount":"0.00", 	
	// 	"FirstName": "${firstName}",
	//  "LastName": "${lastName}",
	//  "EmailAddress": "${contactEmail}",
	//  "Zip": "${cardZip}"}`

	if (paymentToken && paymentToken.length > 0) {
		return
	}
	
	const newExpiration = expireM + expireY
	const firstName = cardName.split(" ")[0]
	const lastName = cardName.split(" ")[1]

	setExpiration(newExpiration)
		try {
			const token = await tokenizePayment({
				MerchantID: props.officeMerchantKey,
				Login: props.officeUsioLogin,
				Password: props.officeUsioPassword,
				CardNumber: cardNumber.split(' ').join('').replace('_', ''),
				CardType: cardType,
				ExpDate: newExpiration,
				Address1: cardAddress,
				City: cardCity,
				State: cardState,
				Zip: cardZip,
				Amount: '0.00',
				FirstName: firstName,
				LastName: lastName,
				EmailAddress: contactEmail
			})

			setPaymentToken(token.confirmation)
			setTokenizationStatus(token.status)
		}
		catch (ex) {
			console.error(ex)
			throw ex
		}
		return

		//tokenizes user card information when card details are saved

		// let token = paymentToken

		// const newExpiration = expireM + expireY
		// const firstName = cardName.split(" ")[0]
		// const lastName = cardName.split(" ")[1]

		// //posts the tokenization to usio
		// if ((token == undefined) || (token == "") || (token == "undefined")) {
		// 	var xhttp = new XMLHttpRequest();
		// 	xhttp.onreadystatechange = function () {
		// 		if (this.readyState != 4) { return }
		// 		if (this.status == 200) {
		// 			setPaymentToken(JSON.parse(this.responseText)['Confirmation'])
		// 			setTokenizationStatus(JSON.parse(this.responseText)['Message'])
		// 		}
		// 		else {
		// 			try {
		// 				token = JSON.parse(this.responseText)['Confirmation']
		// 			}
		// 			catch {
		// 				setTokenizationStatus(JSON.parse(this.responseText)['Confirmation'])
		// 			}
		// 		}
		// 	};
		// 	xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitCCPayment", true);
		// 	xhttp.setRequestHeader("Content-type", "application/json");

		// 	var obj = `{"MerchantID":"${props.officeMerchantKey}",
		// 		   				"Login":"${props.officeUsioLogin}",
		// 		   				"Password":"${props.officeUsioPassword}",
		// 		   				"CardNumber": "${cardNumber.split(' ').join('').replace('_', '')}", 
		// 		   				"CardType": "${cardType}",
		// 		   				"ExpDate" : "${newExpiration}",
		// 		   				"Address1": "${cardAddress}",
		// 		   				"City": "${cardCity}",
		// 		   				"State": "${cardState}",
		// 		   				"Country": "US",
		// 		   				"Amount":"0.00", 	
		// 		   				"FirstName": "${firstName}",
		// 						"LastName": "${lastName}",
		// 						"EmailAddress": "${contactEmail}",
		// 						"Zip": "${cardZip}"}`

		// 	xhttp.send(obj);

		// 	var resultText = xhttp.statusText;
		// }
		// setExpiration(newExpiration)
		// setPaymentToken(token)
	}

	const handlePostInitialDeposit = async (memberId) => {
		let count = 0
		
		let responseStatus = ""
		let responseConfirmation = ""
		let responseText = ""

		//post the initial deposit to USIO with patient's payment token
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = async function () {
			if (count == 0) {
				if (this.readyState != 4) { return }
				if (this.status == 200) {
					alert(this.responseText)
					responseStatus = await JSON.parse(this.responseText)['Status']
					responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
					responseText = await JSON.parse(this.responseText)['Message']
					//setPaymentStatus(responseStatus)
				}
				else {
					if (this.responseText != "") {
						alert(this.responseText)

						responseStatus = await JSON.parse(this.responseText)['Status']
						responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
						responseText = await JSON.parse(this.responseText)['Message']

						//setPaymentStatus(JSON.parse(this.responseText)['Status'])
					}

				}
				//if payment has been posted, create records in dynamodb
				if ((responseStatus != "") && (count == 0)) {
					count++
					const result = await API.graphql(graphqlOperation(
						`mutation create {
                  createPayments(input: {
					memberId: "${memberId}",
					grossPay: ${initialDeposit.replace('$', '')}, 
					description: "Initial Deposit", 
					status: "${responseStatus}",
					fee: ${0}, 
					netPay: ${initialDeposit.replace('$', '')}, 
					practice: "${practice}", 
					memberName: "${patientName}", 
					office: "${auth.officeId || auth.user}", 
					confirmationToken: "${responseStatus + responseText + responseConfirmation}",
					paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
					contactName: "${contactName}"
				}) {
                    id
                    memberId
                    memberName
                    description
                    grossPay
                    fee
                    netPay
                    practice
                    office
                    confirmationToken
                  }
                }`))

					const resultOffice = await API.graphql(graphqlOperation(
						`mutation create {
                  createOfficePayments(input: {
					  officeId: "${auth.officeId || auth.user}", 
					  description: "Initial Deposit",
					  grossPay: ${.83},
					  status: "${responseStatus}",
                	  fee: ${0}, 
					  netPay: ${initialDeposit.replace('$', '')}, 
					  name: "${props.officeName}", 
					  practice: "${practice}", 
					  confirmationToken: "${responseStatus + responseText + responseConfirmation}",
					  paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
					  contactName: "${contactName}"
					}){
                    id
                    officeId
                    description
                    grossPay
                    fee
                    netPay
                    name
                    practice
                  }
                }`))

					//update local payment state
					const newPayments = payments.concat({
						memberId: memberId, memberName: contactName, description: description,
						grossPay: grossPay, fee: fee, netPay: grossPay - fee, practice: practice, status: responseStatus,
						createdAt: new Date().toISOString()
					})

					count++
				}
				let newResponseCount = responseCount + 1
				setResponseCount(newResponseCount)
			}
		}
		xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitTokenPayment", true);
		xhttp.setRequestHeader("Content-type", "application/json");


		var obj = `{"MerchantID":"${props.officeMerchantKey}",
            "Login":"${props.officeUsioLogin}",
            "Password":"${props.officeUsioPassword}",
              "Token": "${paymentToken}",
            "Amount": "${initialDeposit.replace('$', '')}"
            }`

		if (count == 0) {
			if (paymentInterval == "monthly") {
				xhttp.send(obj);
			}
		}


		var resultText = await xhttp.statusText;

		return (responseStatus)

	}

	const handlePostYearlyPayment = async (memberId) => {
		let count = 0

		let responseStatus = ""
		let responseConfirmation = ""
		let responseText = ""

		//posts the yearly payment to dynamodb
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = async function () {
			if (count == 0) {
				if (this.readyState !== 4) { return }
				if (this.status == 200) {
					alert(this.responseText)
					responseStatus = await JSON.parse(this.responseText)['Status']
					responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
					responseText = await JSON.parse(this.responseText)['Message']
					//setPaymentStatus(responseStatus)
				}
				else {
					if (this.responseText != "") {
						alert(this.responseText)

						responseStatus = await JSON.parse(this.responseText)['Status']
						responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
						responseText = await JSON.parse(this.responseText)['Message']
						//setPaymentStatus(JSON.parse(this.responseText)['Status'])
					}

				}
				//if payment is posted, add records in dynamodb
				if ((responseStatus != "") && (count == 0)) {
					count++
					const result = await API.graphql(graphqlOperation(
						`mutation create {
                  createPayments(input: {
					memberId: "${memberId}", 
					, 
					grossPay: ${remainingBalance.replace('$', '')}, 
					description: "Annual Payment", 
					status: "${responseStatus}",
                  	fee: ${0}, 
					netPay: ${String(remainingBalance).replace('$', '')},
					practice: "${practice}",
					memberName: "${patientName}",
					office: "${auth.officeId || auth.user}",
					confirmationToken: "${responseStatus + responseText + responseConfirmation}",
					paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
					contactName: "${contactName}"
				}){
                    id
                    memberId
                    memberName
                    description
                    grossPay
                    fee
                    netPay
                    practice
                    office
                    confirmationToken
                  }
                }`))

					const resultOffice = await API.graphql(graphqlOperation(
						`mutation create {
                  createOfficePayments(input: {
					  officeId: "${auth.officeId || auth.user}", 
					  description: "Annual Payment", 
					  grossPay: ${.83}, 
					  status: "${responseStatus}",
                  	fee: ${0}, 
				  	netPay: ${String(remainingBalance).replace('$', '')},
				  	name: "${props.officeName}", 
					  practice: "${practice}", 
					  confirmationToken: "${responseStatus + responseText + responseConfirmation}",
				  	paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
				  contactName: "${contactName}"
				}){
                    id
                    officeId
                    description
                    grossPay
                    fee
                    netPay
                    name
                    practice
                  }
                }`))

					//update local payment state
					const newPayments = payments.concat({
						memberId: memberId, memberName: contactName, description: "Annual Payment",
						grossPay: String(remainingBalance).replace('$', ''), fee: fee, netPay: String(remainingBalance).replace('$', ''), practice: practice, status: responseStatus,
						createdAt: new Date().toISOString()
					})

					count++
				}
				let newResponseCount = responseCount + 1
				setResponseCount(newResponseCount)
			}
		}
		xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitTokenPayment", true);
		xhttp.setRequestHeader("Content-type", "application/json");

		var objAnnual = `{"MerchantID":"${props.officeMerchantKey}",
            "Login":"${props.officeUsioLogin}",
            "Password":"${props.officeUsioPassword}",
              "Token": "${paymentToken}",
            "Amount": "${String(remainingBalance).replace('$', '')}"
            }`

		if (count == 0) {
			if (paymentInterval == "yearly") {
				xhttp.send(objAnnual);
			}
		}


		var resultText = await xhttp.statusText;
		return responseStatus
	}

	const handlePostCashPayment = async (memberId) => {

		//we don't actually process these (USIO or otherwise), this is just a logging mechanism for offices


		//post payment records to dynamodb
		const result = await API.graphql(graphqlOperation(
			`mutation create {
                  createPayments(input: {
					memberId: "${memberId}",
					,
					grossPay: ${remainingBalance.replace('$', '')},
					description: "Member Payment (Cash)", status: "completed",
                  	fee: ${0},
					netPay: ${String(remainingBalance).replace('$', '')},
					practice: "${practice}",
					memberName: "${patientName}",
					office: "${auth.officeId || auth.user}",
					confirmationToken: "",
					cash: ${true},
					paymentInfo: "Cash",
					contactName: "${contactName}"
				}){
                    id
                    memberId
                    memberName
                    description
                    grossPay
                    fee
                    netPay
                    practice
                    office
                    confirmationToken
                    cash
                  }
                }`))

		const resultOffice = await API.graphql(graphqlOperation(
			`mutation create {
                  createOfficePayments(input: {
					  officeId: "${auth.officeId || auth.user}", 
					  description: "Member Payment (Cash)", 
					  grossPay: ${.83}, 
					  status: "completed",
                  fee: ${0}, 
				  netPay: ${String(remainingBalance).replace('$', '')},
				  name: "${props.officeName}", 
				  practice: "${practice}", 
				  cash: ${true},
				  paymentInfo: "Cash",
				  contactName: "${contactName}"
				}){
                    id
                    officeId
                    description
                    grossPay
                    fee
                    netPay
                    name
                    practice
                    cash
                  }
                }`))

		//update local payment state
		const newPayments = payments.concat({
			memberId: memberId, memberName: contactName, description: "Member Payment (Cash)",
			grossPay: String(remainingBalance).replace('$', ''), fee: fee, netPay: String(remainingBalance).replace('$', ''), practice: practice, status: "completed",
			createdAt: new Date().toISOString()
		})

		setRemainingBalance(0)
	}

	const handleSubmit = async (event) => {
		if (submitting) { return }
		setSubmitting(true)
		//updates info for existing members, and creates member/posts first plan payment for newly created members

		/// The duedate should always be tomorrow. Any deposit should be captured today, while first payment gets processed tomorrow
		const isAnnual = paymentInterval === 'yearly'
		const dueDate = Moment().add(isAnnual ? 0 : 1, 'day')
		const dueDay = dueDate.get('date')

		try {

		//if we're editing an existing member, just update the Member and MemberFinance objects in dynamodb
		if (edit) {

			const updateMember = await API.graphql(graphqlOperation(
				`mutation update {
			        updateMember(input: {id: "${props.memberId}", name: "${patientName}", active: ${true}, contactName: "${contactName}",
			        contactAddress: "${contactAddress}", contactCity: "${contactCity}", contactZip: "${contactZip}",
			    	contactPhone: "${contactPhone}", contactEmail: "${contactEmail}",
			    	plan: "${plan}" practice: "${practice}", fixedBenefits: "${btoa(JSON.stringify(fixedBenefits))}",
			    	paymentInterval: "${paymentInterval}", expiration: "${expiration}", paymentType: "${paymentType}"}){
			          id
			          name
			          active
			          contactName
			          contactAddress
			          contactCity
			          contactPhone
			          contactEmail
			          planAgreement
			          plan
			          practice
			          fixedBenefits
			          paymentInterval
			          expiration
			          paymentType
			        }
			      }`))

			
			const numberCleaning = (v) => {
				return parseFloat(String(v).replace('$', '')).toFixed(2)
			}

			
			const descUpdates = (cardNumber && cardNumber.length > 0) ? 
				{
					paymentDescription: `${cardType} ****${cardNumber.substr(cardNumber.length - 4)}`
				} : {}

			await API.graphql(
				graphqlOperation(updateMemberFinance, {
					input: {
						id: memberFinanceId,
						memberId,
						memberName: patientName,
						practice,
						dayDue: dueDay,
						interestRate: numberCleaning(interestRate),
						totalBalance: numberCleaning(totalBalance),
						periodAmount: numberCleaning(periodAmount),
						paymentInterval,
						initialDeposit: numberCleaning(initialDeposit),
						remainingBalance: numberCleaning(remainingBalance),
						paymentToken,
						expiration,
						paymentType,
						...descUpdates
					}
				})
			)
			// const updateMemberFinance = await API.graphql(graphqlOperation(
			// 	`mutation update {
			//         updateMemberFinance(input: {id: "${memberFinanceId}", memberId: "${memberId}", memberName: "${patientName}", practice: "${practice}", dayDue: ${dueDay},
			//         interestRate: ${String(interestRate).replace('%', '')}, totalBalance: ${String(totalBalance).replace('$', '')}, periodAmount: ${String(periodAmount).replace('$', '')}
			//     	paymentInterval: "${paymentInterval}", initialDeposit: ${String(initialDeposit).replace('$', '')}, remainingBalance: ${String(remainingBalance).replace('$', '')}, 
			//     	paymentToken: "${paymentToken}", expiration: "${expiration}", paymentType: "${paymentType}"}){
			//           id
			//           memberId
			//           memberName
			//           practice
			//           dayDue
			//           paymentInterval
			//           periodAmount
			//           initialDeposit
			//           interestRate
			//           totalBalance
			//           paymentToken
			//           expiration
			//           paymentType
			//         }
			//       }`))
			setEditedForm(true)
		}
		else {

			//guard to make sure everything has been filled in before trying to save
			if (((auth.officeId || auth.user) == undefined) || (practice == "Dental Practice") || (patientName == "") || (active == "") || (contactName == "") || (contactAddress == "") || (contactCity == "") || (contactZip == "") || (contactPhone == "") || (contactEmail == "") || (plan == "None") || (totalBalance == 0)) {
				alert("Member Fields cannot be empty")
			}
			//guard to make sure the member saved payment credentials before trying to save
			else if ((paymentToken == "") && (paymentType != "cash")) {
				alert("Save card details before creating member.")
			}
			else {
				//creates a new patient account with the password QPDmember1234 that can be changed on login
				//patients account view is not currently being used
				const res = await signUp(contactEmail, 'QDPMember1234', patientName)

				if (!res || !res.id) return
				const { id: memberId, username } = res
				let usioResponse

				//post the appropriate initial payment
				if ((paymentType != "cash") && (initialDeposit != 0) && (memberId != undefined) && (paymentInterval == "monthly")) {
					console.log('posting initial deposit for monthly...')
					usioResponse = await handlePostInitialDeposit(memberId)
				}
				else if ((paymentType != "cash") && (memberId != undefined) && (paymentInterval == "yearly")) {
					console.log('posting annual payment')
					usioResponse = await handlePostYearlyPayment(memberId)
				}
				else if (paymentType == "cash") {
					console.log('posting cash payment')
					setRemainingBalance(0)
					handlePostCashPayment(memberId)
					usioResponse = 'success'
				}

				if (memberId != undefined) {
					//creates the new member and memberfinance and member log records (for livestream component on home/dashboard page) in dynamodb
					const createMember = await API.graphql(graphqlOperation(
						`mutation create {
					        createMember(input: {id: "${memberId}" name: "${patientName}", office: "${auth.officeId || auth.user}", active: ${active}, contactName: "${contactName}",
					        contactAddress: "${contactAddress}", contactCity: "${contactCity}", contactZip: "${contactZip}",
					    	contactPhone: "${contactPhone}", contactEmail: "${username}", planAgreement: ${true}, customerSignature: ${false},
					    	plan: "${plan}" practice: "${practice}", fixedBenefits: "${btoa(JSON.stringify(fixedBenefits))}",
					    	paymentInterval: "${paymentInterval}", expiration: "${expiration}", paymentType: "${paymentType}"}){
					          id
					          name
					          office
					          active
					          contactName
					          contactAddress
					          contactCity
					          contactPhone
					          contactEmail
					          planAgreement
					          customerSignature
					          practice
					          fixedBenefits
					          paymentInterval
					          expiration
					          paymentType
					        }
					      }`))

					const createMemberFinance = await API.graphql(graphqlOperation(
						`mutation create {
					        createMemberFinance(input: {
								memberId: "${memberId}", 
								memberName: "${patientName}", 
								practice: "${practice}",
								office: "${auth.officeId || auth.user}", 
								dayDue: ${dueDay},
					        	interestRate: ${String(interestRate).replace('%', '')}, 
								totalBalance: ${String(totalBalance).replace('$', '')}, 
								remainingBalance: ${String(remainingBalance).replace('$', '')},
								paymentInterval: "${paymentInterval}", 
								periodAmount: ${String(periodAmount).replace('$', '')},
					    		paymentToken: "${paymentToken}", 
								initialDeposit: ${String(initialDeposit).replace('$', '')}, 
								expiration: "${expiration}", 
								paymentType: "${paymentType}",
								paymentDescription: "****${cardNumber.substr(cardNumber.length - 4)}",
								contactName: "${contactName}"
							}){
					          id
					          memberId
					          memberName
					          practice
					          office
					          dayDue
					          initialDeposit
					          paymentInterval
					          periodAmount
					          remainingBalance
					          interestRate
					          totalBalance
					          paymentToken
					          expiration
					          paymentType
					        }
					      }`))

					const createMemberLog = await API.graphql(graphqlOperation(
						`mutation create {
					        createMemberLog(input: {memberId: "${memberId}", office: "${auth.officeId || auth.user}", memberName: "${patientName}" practice: "${practice}",
					    	activity: "Active Member"}){
					          id
					          memberId
					          office
					          memberName
					          practice
					          activity
					        }
					      }`))

					setMemberId(memberId)
					setContactEmail(username)
					setCreatedForm(true)

				}
			}
		}

		}
		catch (ex) {
			alert(`Error: ${ex.message}`)
		}

		setSubmitting(false)

	}


	const fetchMember = useCallback(
		async () => {

		//fetches member and memberfinance fields for existing patient

		const memberData = await API.graphql(graphqlOperation(`query GetMember {
	    getMember(id: "${props.memberId}"){
          id
          name
          active
          contactName
          contactAddress
          contactCity
          contactPhone
          contactZip
          contactEmail
          planAgreement
          plan
          practice
          paymentType
          fixedBenefits
          expiration
	    }
	}`))
		const userMemberData = memberData.data.getMember

		setPaymentType(userMemberData['paymentType'])
		setContactName(userMemberData['contactName'])
		setPatientName(userMemberData['name'])
		setContactAddress(userMemberData['contactAddress'])
		setContactCity(userMemberData['contactCity'])
		setContactZip(userMemberData['contactZip'])
		setContactPhone(userMemberData['contactPhone'])
		setContactEmail(userMemberData['contactEmail'])
		setPractice(userMemberData['practice'])
		setSelectedPlan(userMemberData['planAgreement'])
		setPlan(userMemberData['plan'])
		setActive(userMemberData['active'])
		setMemberId(props.memberId)
		setExpiration(userMemberData['expiration'])
		if (userMemberData['fixedBenefits'] !== null) {
			setFixedBenefits(JSON.parse(atob(userMemberData['fixedBenefits'])))
		}

		//this is poor schematics, but member's id is used to query and filter all memberfinance records to the relevant one
		

		const memberFinanceData = await API.graphql(graphqlOperation(
			listMemberFinances,
			{
				filter: {
					and: [
						{memberId: { eq: props.memberId } },
						{office: { eq: auth.officeId || auth.user } } 
					]
				},
				limit: 9999999
			}
		))
		// const memberFinanceData = await API.graphql(graphqlOperation(`query ListMemberFinances {
		//     listMemberFinances(filter: {and: [{memberId: {eq: "${props.memberId}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		//     items{
	    //       id
	    //       memberId
	    //       office
	    //       dayDue
	    //       initialDeposit
	    //       paymentInterval
	    //       periodAmount
	    //       remainingBalance
	    //       interestRate
	    //       totalBalance
	    //       paymentToken
		// 	  paymentDescription
		//     }}
		// }`))
		const userMemberFinanceData = memberFinanceData.data.listMemberFinances.items[0]

		if ((auth != undefined) && (userMemberFinanceData != undefined)) {
			setDueDay(userMemberFinanceData['dayDue'])
			setInterestRate(userMemberFinanceData['interestRate'])
			setTotalBalance(userMemberFinanceData['totalBalance'])
			setPaymentInterval(userMemberFinanceData['paymentInterval'])
			setPeriodAmount(userMemberFinanceData['periodAmount'])
			setInitialDeposit(userMemberFinanceData['initialDeposit'])
			setRemainingBalance(userMemberFinanceData['remainingBalance'])
			setPaymentToken(userMemberFinanceData['paymentToken'])
			setMemberFinanceId(userMemberFinanceData['id'])
		}

	}, [props.memberId, auth])

	const [newBenefitLog, setNewBenefitLog] = useState("")
	const [benefitsLog, setBenefitsLog] = useState([])

	const handleLogRecurringBenefit = async (event) => {

		//archived

		event.preventDefault()
		if (memberId != "") {
			const recurBenefit = await API.graphql(graphqlOperation(
				`mutation create {
			        createMemberBenefitsLog(input: {memberId: "${memberId}",
			         benefit: "${newBenefitLog}", office: "${props.officeId}"}){
			          id
			          memberId
			          benefit
			          office
			        }
			      }`))
			const newBenefitsLog = benefitsLog.concat({ benefit: newBenefitLog, createdAt: new Date().toISOString() })
			setNewBenefitLog("")
			setBenefitsLog(newBenefitsLog)
		}
		else {
			alert("Member must be created before benefits can be logged.")
		}
	}

	const handleNewBenefitLogChange = (event) => {
		//archived 

		setNewBenefitLog(event.target.value)
	}

	async function fetchRecurringBenefits() {

		//archived

		const recurringBenefitsLogData = await API.graphql(graphqlOperation(`query ListMemberBenefitsLogs {
		    listMemberBenefitsLogs(filter: {and: [{memberId: {eq: "${memberId}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		    	items{
		          id
		          memberId
		          benefit
		          createdAt
			    }
			}
		}`))
		const userRecurringBenefitsLogData = recurringBenefitsLogData.data.listMemberBenefitsLogs.items

		let newBenefitsList = []
		let rowCount = 1
		let benDate
		for (let i = 0; i < userRecurringBenefitsLogData.length; i++) {

			benDate = new Date(userRecurringBenefitsLogData[i]['createdAt'])
			benDate = benDate.setHours(benDate.getHours() - timeZoneOffset)
			benDate = new Date(benDate)

			const newBenefit = { ...userRecurringBenefitsLogData[i], rowId: rowCount, createdAt: benDate.toISOString(), timestamp: new Date(userRecurringBenefitsLogData[i]['createdAt']).getTime() }
			newBenefitsList.push(newBenefit)
			rowCount = rowCount + 1
		}


		setBenefitsLog(newBenefitsList)
	}

	async function fetchBenefitTrackingPlan() {

		//archived 

		const planData = await API.graphql(graphqlOperation(`query ListPlans {
		    listPlans(filter: {and: [{name: {eq: "${plan}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		    	items{
		          id
		          name
		          benefits
			    }
			}
		}`))
		const userPlanData = planData.data.listPlans.items[0]
		try {
			setPlan(userPlanData.name)
		}
		catch {
			console.info("couldn't set plan name")
		}

		const custBenefits = JSON.parse(atob(userPlanData.benefits))

		let newFixedBenefits = fixedBenefits

		if (newFixedBenefits.length == 2) {
			for (let i = 0; i < custBenefits.length; i++) {
				if (custBenefits[i]['included']) {
					newFixedBenefits = newFixedBenefits.concat({ ...custBenefits[i], redeemed: false, redeemDate: "" })
				}
			}

			setFixedBenefits(newFixedBenefits)
		}
	}

	const [paymentStatus, setPaymentStatus] = useState("")
	const [description, setDescription] = useState("")
	const [grossPay, setGrossPay] = useState(0)
	const [fee, setFee] = useState(0)

	const [payments, setPayments] = useState([])




	const handlePaymentStatusChange = (event) => {
		setPaymentStatus(event.target.value)
	}

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value)
	}

	const handleGrossPayChange = (event) => {
		setGrossPay(event.target.value)
	}

	const handleFeeChange = (event) => {
		setFee(event.target.value)
	}

	const handleLogPayment = async (event) => {

		//archived 

		event.preventDefault()
		let count = 0

		let responseStatus = ""
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = async function () {
			if (count == 0) {
				if (this.readyState == 4 && this.status == 200) {
					alert(this.responseText)
					responseStatus = await JSON.parse(this.responseText)['Status']
					setPaymentStatus(responseStatus)
				}
				else {
					if (this.responseText != "") {
						alert(this.responseText)

						responseStatus = await JSON.parse(this.responseText)['Status']
						setPaymentStatus(JSON.parse(this.responseText)['Status'])
					}

				}
				if ((responseStatus != "") && (count == 0)) {
					count++
					const result = await API.graphql(graphqlOperation(
						`mutation create {
					        createPayments(input: {
								memberId: "${memberId}",
								description: "${description}",
								grossPay: ${grossPay},
								status: "${responseStatus}",
					        	fee: ${parseInt(fee)},
								netPay: ${remainingBalance},
								practice: "${practice}",
								memberName: "${contactName}",
								office: "${auth.officeId || auth.user}",
								paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
								contactName: "${contactName}"
							}){
					          id
					          memberId
					          memberName
					          description
					          grossPay
					          fee
					          netPay
					          practice
					          office
					        }
					      }`))

					const resultOffice = await API.graphql(graphqlOperation(
						`mutation create {
					        createOfficePayments(input: {
								
								officeId: "${auth.officeId || auth.user}", 
								description: "one time payment", 
								grossPay: ${.83}, 
								status: "${responseStatus}",
					        	fee: $${parseInt(fee)},
								netPay: ${.83}, 
								name: "${props.officeName}", 
								practice: "${practice}",
								paymentInfo: "${cardType} ****${cardNumber.substr(cardNumber.length - 4)}",
								contactName: "${contactName}"
							}){
					          id
					          officeId
					          description
					          grossPay
					          fee
					          netPay
					          name
					          practice
					        }
					      }`))

					setPaymentLogged(true)

					const newPayments = payments.concat({
						memberId: memberId, memberName: contactName, description: description,
						grossPay: remainingBalance, fee: fee, netPay: remainingBalance, practice: practice, status: responseStatus,
						createdAt: new Date().toISOString()
					})

					setPayments(newPayments)
					count++
				}
				let newResponseCount = responseCount + 1
				setResponseCount(newResponseCount)
			}
		}
		xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitTokenPayment", true);
		xhttp.setRequestHeader("Content-type", "application/json");


		var obj = `{"MerchantID":"${props.officeMerchantKey}",
	       		"Login":"${props.officeUsioLogin}",
	       		"Password":"${props.officeUsioPassword}",
	            "Token": "${paymentToken}",
	       		"Amount": ${grossPay + fee}	
	        	}`

		if (count == 0) {
			xhttp.send(obj);
		}


		var resultText = xhttp.statusText;

		setDescription("")
		setGrossPay(0)
		setFee(0)
	}

	async function fetchMemberPayments() {

		//fetches from dynamodb to populate the View Member Payments sub-page

		const memberPaymentsData = await API.graphql(graphqlOperation(`query ListPayments {
		    listPaymentss(filter: {and: [{memberId: {eq: "${props.memberId}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		    	items{
		          id
		          memberId
		          memberName
		          description
		          status
		          grossPay
		          fee
		          netPay
		          office
		          practice
		          createdAt
			    }
			}
		}`))

		const userPaymentsData = memberPaymentsData.data.listPaymentss.items

		let newPaymentsList = []
		let rowCount = 1
		let payDate
		for (let i = 0; i < userPaymentsData.length; i++) {
			payDate = new Date(userPaymentsData[i]['createdAt'])
			payDate = payDate.setHours(payDate.getHours() - timeZoneOffset)
			payDate = new Date(payDate)

			const newPayment = { ...userPaymentsData[i], rowId: rowCount, createdAt: payDate.toISOString(), timestamp: payDate.getTime() }
			newPaymentsList.push(newPayment)
			rowCount = rowCount + 1
		}


		const newPaymentsList2 = newPaymentsList.sort(makeComparator('timestamp'))

		newPaymentsList = []
		rowCount = 1
		for (let i = 0; i < newPaymentsList2.length; i++) {
			const newPayment = { ...newPaymentsList2[i], rowId: rowCount, timestamp: new Date(userPaymentsData[i]['createdAt']).getTime() }
			newPaymentsList.push(newPayment)
			rowCount = rowCount + 1
		}

		setPayments(newPaymentsList)

	}

	async function fetchPlan(planId) {

		//used to populate plan detials on View Member Plan sub-page

		let userPlan = "userPlan"
		const planData = await API.graphql(graphqlOperation(`query GetPlan {
	    getPlan(id: "${planId}"){
	          id
	          name
	          benefits
	    }
	}`))
		const userPlanData = planData.data.getPlan
		setCustomBenefits(JSON.parse(atob(userPlanData.benefits)))
	}

	const handleCardAllChange = (e) => {

		//handles adding patient card details via card swiper
		//see OneTimePayment.js and One Time Payment documentation for more details

		if (!swiped) {
			const newCardAll = e.target.value
			setCardAll(newCardAll)

			let swipeName = ""
			let swipeNumber = ""
			let swipeExpire = ""
			let swipeExpire2
			let swipeExpireY = ""
			let swipeExpireM = ""
			let swipeType = ""
			let swipeDig = ""
			let swipeCCV = ""
			let fullName

			if ((newCardAll != undefined) && (newCardAll.includes('/'))) {
				swipeName = newCardAll.split('^')[1]
				let swipeNames
				try {
					swipeNames = swipeName.replace(/ /g, "").split('/')
				}
				catch {
					swipeNames = swipeName.split('/')
				}
				let firstName = swipeNames[0]
				if (firstName.includes(' ')) {
					firstName = firstName.replaceAll(' ', '')
				}
				fullName = [swipeNames[1], firstName].join(' ')
				setCardName(fullName)
			}
			if (newCardAll.includes(';')) {
				swipeNumber = newCardAll.split(';')[1].split('=')[0]
				swipeDig = swipeNumber[0]
				setCardNumber(swipeNumber)
				if (swipeDig == 4) {
					swipeType = 'VISA'
				}
				else if (swipeDig == 5) {
					swipeType = 'MSTR'
				}
				else if (swipeDig == 6) {
					swipeType = 'DISC'
				}
				else {
					swipeType = 'AMEX'
				}
				setCardType(swipeType)

			}
			if (newCardAll.includes('=')) {
				swipeExpire = newCardAll.split('=')[1].substring(0, 4)
				if (swipeExpire.length == 4) {
					swipeExpireY = "20" + swipeExpire.substring(0, 2)
					swipeExpireM = swipeExpire.substring(2, 4)
					setExpireY(swipeExpireY)
					setExpireM(swipeExpireM)

				}
			}
			if (swipeNumber.length >= 15 && swipeExpireY.length == 4) {
				setSwiped(true)
			}
		}
	}

	const resetFocus = (e) => {

		//see OneTimePayment.js and One Time Payment documentation for more details

		let clickType = e.target.type
		if (swiped != true) {
			if ((clickType != 'select-one') && (clickType != 'text') && (clickType != 'email')) {
				cardRef.current.focus()
				setSwipeNow(true)
			}
		}
	}

	const resetFocus2 = (e) => {

		//see OneTimePayment.js and One Time Payment documentation for more details

		if (cardRef.current != null) {
			cardRef.current.blur()
			setSwipeNow(false)
		}
	}


	const saveButton = (
		<button disabled={submitting} onClick={submitting ? null : handleSubmit} className="gradient-btn">
			{submitting ? 'Submitting' : 'Save Member'}
		</button>
	)

	useEffect(() => {
		fetchPractices()
		fetchPlans()
		if (edit) {
			fetchMember()
		}
	}, [auth, auth.officeId, edit, fetchMember, fetchPlans, fetchPractices])

	return (
		<div className="w-full h-full">
			<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
				{currentPage != "New Payment" ?
					<Link className="text-blue-500" to="/members">{"<<"} Members</Link>
					: <button onClick={() => setCurrentPage("Payment History")}><Link className="text-blue-500" to={`/member/${props.memberId}/payments`}>{"<<"} Member Payments</Link></button>
				}
			</div>
			<div className="w-5/6 flex mt-12 mx-auto">
				<Link to={edit ? `/member/${props.memberId}/info` : "/createmember/info"} onClick={() => setCurrentPage("PrimaryContact")} className={currentPage == "PrimaryContact" ? "interval-active" : "interval"}>Member Info</Link>
				<Link to={edit ? `/member/${props.memberId}/agreement` : "/createmember/agreement"} onClick={() => setCurrentPage("Agreement")} className={currentPage == "Agreement" ? "interval-active" : "interval"}>Agreement</Link>
				<Link to={edit ? `/member/${props.memberId}/benefits` : "/createmember/benefits"} onClick={() => setCurrentPage("Benefit Tracking")} className={currentPage == "Benefit Tracking" ? "hidden interval-active" : " hidden interval"}>Benefit Usage</Link>
				<Link to={edit ? `/member/${props.memberId}/payments` : "/createmember/payments"} onClick={() => setCurrentPage("Payment History")} className={currentPage == "Payment History" ? "interval-active" : "interval"}>Payment History</Link>
			</div>


			<Switch>
				<Route path={`/member/${props.memberId}/info`}>
					<MemberInfoDetail 
						edit={props.edit}
						paymentType={paymentType} 
						handlePaymentTypeChange={handlePaymentTypeChange} 
						setPaymentToken={setPaymentToken} 
						resetFocus2={resetFocus2} 
						tokenizationStatus={tokenizationStatus} 
						resetFocus={resetFocus} 
						swipeNow={swipeNow} 
						paymentToken={paymentToken} 
						handleTokenization={handleTokenization} 
						dueDay={dueDay} 
						lateFeeDays={lateFeeDays} 
						lateFeeCharge={lateFeeCharge}
						interestRate={interestRate} 
						totalBalance={totalBalance} 
						monthlyPayment={monthlyPayment}
						handleDueDayChange={handleDueDayChange} handleLateFeeDaysChange={handleLateFeeDaysChange}
						handleLateFeeChargeChange={handleLateFeeChargeChange} handleInterestRateChange={handleInterestRateChange}
						handleTotalBalanceChange={handleTotalBalanceChange} handleMonthlyPaymentChange={handleMonthlyPaymentChange}
						cardName={cardName} cardNumber={cardNumber} expireM={expireM} expireY={expireY}
						securityCode={securityCode} handleCardNameChange={handleCardNameChange}
						handleCardNumberChange={handleCardNumberChange} handleExpireMChange={handleExpireMChange}
						handleExpireYChange={handleExpireYChange} handleSecurityCodeChange={handleSecurityCodeChange}
						contactName={contactName} patientName={patientName} contactAddress={contactAddress}
						contactCity={contactCity} contactZip={contactZip} contactPhone={contactPhone}
						contactEmail={contactEmail} handleContactNameChange={handleContactNameChange}
						handlePatientNameChange={handlePatientNameChange} handleContactAddressChange={handleContactAddressChange} handleContactCityChange={handleContactCityChange}
						handleContactZipChange={handleContactZipChange} handleContactPhoneChange={handleContactPhoneChange}
						handleContactEmailChange={handleContactEmailChange} practice={practice}
						handlePracticeChange={handlePracticeChange} practices={practices} cardType={cardType}
						cardAddress={cardAddress} cardCity={cardCity} cardState={cardState} cardZip={cardZip}
						handleCardTypeChange={handleCardTypeChange} handleCardAddressChange={handleCardAddressChange}
						handleCardCityChange={handleCardCityChange} handleCardStateChange={handleCardStateChange} handleCardZipChange={handleCardZipChange}
						initialDeposit={initialDeposit} handleInitialDepositChange={handleInitialDepositChange}
						plans={plans} plan={plan} selectedPlan={selectedPlan} handlePlanChange={handlePlanChange}
						remainingBalance={remainingBalance} paymentInterval={paymentInterval} periodAmount={periodAmount}
						handleRemainingBalanceChange={handleRemainingBalanceChange} handlePaymentIntervalChange={handlePaymentIntervalChange} handlePeriodAmountChange={handlePeriodAmountChange} />
				</Route>
				<Route path={`/member/${props.memberId}/agreement`}>
					<MemberAgreement officeLogo={props.officeLogo} fetchPlan={fetchPlan} customBenefits={customBenefits} plans={plans} plan={plan} selectedPlan={selectedPlan} handlePlanChange={handlePlanChange} />
				</Route>
				<Route path={`/member/${props.memberId}/benefits`}>
					<MemberBenefitTracking newBenefitLog={newBenefitLog} fetchRecurringBenefits={fetchRecurringBenefits} benefitsLog={benefitsLog} handleLogFixedBenefit={handleLogFixedBenefit} handleLogRecurringBenefit={handleLogRecurringBenefit} handleNewBenefitLogChange={handleNewBenefitLogChange} fetchBenefitTrackingPlan={fetchBenefitTrackingPlan} fixedBenefits={fixedBenefits} memberId={memberId} planId={planId} plan={plan} selectedPlan={selectedPlan} />
				</Route>
				<Route path="/createmember/info">
					<MemberInfoDetail paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} setPaymentToken={setPaymentToken} resetFocus2={resetFocus2} tokenizationStatus={tokenizationStatus} resetFocus={resetFocus} swipeNow={swipeNow} ref={cardRef} paymentToken={paymentToken} handleTokenization={handleTokenization} patientName={patientName} handlePatientNameChange={handlePatientNameChange} dueDay={dueDay} lateFeeDays={lateFeeDays} lateFeeCharge={lateFeeCharge}
						interestRate={interestRate} totalBalance={totalBalance} monthlyPayment={monthlyPayment}
						handleDueDayChange={handleDueDayChange} handleLateFeeDaysChange={handleLateFeeDaysChange}
						handleLateFeeChargeChange={handleLateFeeChargeChange} handleInterestRateChange={handleInterestRateChange}
						handleTotalBalanceChange={handleTotalBalanceChange} handleMonthlyPaymentChange={handleMonthlyPaymentChange}
						cardName={cardName} cardNumber={cardNumber} expireM={expireM} expireY={expireY}
						securityCode={securityCode} handleCardNameChange={handleCardNameChange}
						handleCardNumberChange={handleCardNumberChange} handleExpireMChange={handleExpireMChange}
						handleExpireYChange={handleExpireYChange} handleSecurityCodeChange={handleSecurityCodeChange}
						contactName={contactName} contactAddress={contactAddress}
						contactCity={contactCity} contactZip={contactZip} contactPhone={contactPhone}
						contactEmail={contactEmail} handleContactNameChange={handleContactNameChange}
						handleContactAddressChange={handleContactAddressChange} handleContactCityChange={handleContactCityChange}
						handleContactZipChange={handleContactZipChange} handleContactPhoneChange={handleContactPhoneChange}
						handleContactEmailChange={handleContactEmailChange} practice={practice}
						handlePracticeChange={handlePracticeChange} practices={practices} cardType={cardType}
						cardAddress={cardAddress} cardCity={cardCity} cardState={cardState} cardZip={cardZip}
						handleCardTypeChange={handleCardTypeChange} handleCardAddressChange={handleCardAddressChange}
						handleCardCityChange={handleCardCityChange} handleCardStateChange={handleCardStateChange} handleCardZipChange={handleCardZipChange}
						initialDeposit={initialDeposit} handleInitialDepositChange={handleInitialDepositChange}
						plans={plans} plan={plan} selectedPlan={selectedPlan} handlePlanChange={handlePlanChange}
						remainingBalance={remainingBalance} paymentInterval={paymentInterval} periodAmount={periodAmount}
						handleRemainingBalanceChange={handleRemainingBalanceChange} handlePaymentIntervalChange={handlePaymentIntervalChange} handlePeriodAmountChange={handlePeriodAmountChange} />
				</Route>
				<Route path={edit ? `/member/${props.memberId}/payments/add` : "/createmember/payments/add"}>
					<CreateMemberPayment officeMerchantKey={props.officeMerchantKey} officeUsioLogin={props.officeUsioLogin} officeUsioPassword={props.officeUsioPassword}
						paymentStatus={paymentStatus} paymentLogged={paymentLogged} currentPage={currentPage} description={description}
						grossPay={grossPay} fee={fee} handlePaymentStatusChange={handlePaymentStatusChange}
						handleDescriptionChange={handleDescriptionChange} handleGrossPayChange={handleGrossPayChange}
						handleFeeChange={handleFeeChange} edit={props.edit} memberId={props.memberId}
						handleLogPayment={handleLogPayment} payments={payments} fetchMemberPayments={fetchMemberPayments} />
				</Route>
				<Route path={edit ? `/member/${memberId}/payments` : "/createmember/payments"}>
					<MemberPayments paymentToken={paymentToken} handleAddPaymentsPage={handleAddPaymentsPage} currentPage={currentPage} paymentStatus={paymentStatus} description={description}
						grossPay={grossPay} fee={fee} handlePaymentStatusChange={handlePaymentStatusChange}
						handleDescriptionChange={handleDescriptionChange} handleGrossPayChange={handleGrossPayChange}
						handleFeeChange={handleFeeChange} edit={props.edit} memberId={memberId}
						handleLogPayment={handleLogPayment} payments={payments} fetchMemberPayments={fetchMemberPayments} />
				</Route>


				<Route path="/createmember/agreement">
					<MemberAgreement officeLogo={props.officeLogo} fetchPlan={fetchPlan} customBenefits={customBenefits} plans={plans} plan={plan} selectedPlan={selectedPlan} handlePlanChange={handlePlanChange} />
				</Route>
				<Route path="/createmember/benefits">
					<MemberBenefitTracking fetchRecurringBenefits={fetchRecurringBenefits} benefitsLog={benefitsLog} handleLogFixedBenefit={handleLogFixedBenefit} handleLogRecurringBenefit={handleLogRecurringBenefit} handleNewBenefitLogChange={handleNewBenefitLogChange} fetchBenefitTrackingPlan={fetchBenefitTrackingPlan} fixedBenefits={fixedBenefits} memberId={memberId} planId={planId} plan={plan} selectedPlan={selectedPlan} />
				</Route>
			</Switch>
			{displayPracticeCreated}
			{displayPracticeSaved}
			<div className="w-5/6 mx-auto bg-white px-6 py-8 mb-20">
				{((currentPage != "Payment History") && (currentPage != "New Payment") && (currentPage != "Agreement")) ? currentPage == "Benefit Tracking" ? selectedPlan ? saveButton : null : saveButton : null}
			</div>
			<form onSubmit={(e) => e.preventDefault()}>
				<input ref={cardRef} className="opacity-0" onChange={handleCardAllChange} value={cardAll}></input>
			</form>
		</div>
	)
})

export default CreateMember


/*
			   var obj = `{"MerchantID":"0000000001",
									"Login":"API0000000001",
									"Password":"Temp1234!",
									"CardNumber": "4111111111111111",
									"CardType": "VISA",
									"ExpDate" : "092021",
									"Address1": "111 Hogwarts Lane",
									"City": "Denver",
									"State": "CO",
									"Country": "US",
									"Amount":"0.00",
									"FirstName": "Albus",
							"LastName": "Dumbledore",
							"EmailAddress": "albus.dumbledore@hogwarts.com",
							"Zip": "80218"}`

			   var obj = `{"MerchantID":"0000000001",
									"Login":"API0000000001",
									"Password":"Temp1234!",
									"CardNumber": "${cardNumber}",
									"CardType": "${cardType}",
									"ExpDate" : "${expiration}",
									"Address1": "${cardAddress}",
									"City": "${cardCity}",
									"State": "${cardState}",
									"Country": "US",
									"Amount":"0.00",
									"FirstName": "${firstName}",
							"LastName": "${lastName},
							"EmailAddress": "${contactEmail}",
							"Zip": "${cardZip}"}`

							*/