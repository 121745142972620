import React, { useState, useRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import OfficeLicenseAgreement from './OfficeLicenseAgreement'

import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { grey } from '@material-ui/core/colors';

import SignatureCanvas from 'react-signature-canvas'

import Input from './components/Input'
import SavedAlert from './components/SavedAlert'
import Form from './components/Form'
import FormCol from './components/FormCol'

 const SignupFlow2 = forwardRef((props, ref) => {

  const [displayAgreement, setDisplayAgreement] = useState(false)

  const handleDisplayAgreementChange = (e) => {
    e.preventDefault()
    const newDisplayAgreement = !displayAgreement
    //toggles the agreement text dropdown between expanded/condensed views
    setDisplayAgreement(newDisplayAgreement)
  }

  const arrowRightGray = <ArrowRightIcon style={{ color: grey[500]}}/>
  const arrowRightWhite = <ArrowRightIcon/>

  const arrowDownGray = <ArrowDropDownIcon style={{ color: grey[500]}}/>
  const arrowDownWhite = <ArrowDropDownIcon/>

    return (
      <div className="w-full h-full">
        <Form onSubmit={props.handleSubmitFlow} title={"Merchant Application Request"}
         hideButton={true} className="card mt-8 px-8 pt-8 bg-white border rounded-lg w-5/6 mx-auto " >
          <div>
            <button className="mt-4" onClick={handleDisplayAgreementChange}>Order and License Agreement {displayAgreement ? arrowDownGray : arrowRightGray}</button>
            {displayAgreement ? <OfficeLicenseAgreement /> : null}
            <div className="my-2">IN WITNESS WHEREOF, the Parties have caused their duly authorized representatives to execute this Agreement as of the date set forth below.</div>
            <FormCol>
             <Input label={"Licensee (practice owner)"} onChange={props.handleLicenseeChange}  value={props.licensee} name={"licensee"} />
              {/* <Input label={"By"} onChange={props.handleByChange}  value={props.by} name={"by"} /> */}
              <Input label={"Name (person completing the form)"} onChange={props.handleContractNameChange}  value={props.contractName} name={"contractName"} />
              <Input label={"Title"} onChange={props.handleTitleChange}  value={props.title} name={"title"} />
              <Input label={"Date"} disabled={true} value={new Date().toISOString().split('T')[0]} name={"date"} />
              <div className="flex my-4">
                <p className="my-auto pr-4 w-1/3 text-right">Signature:</p>
                <div className="flex flex-col">
                  <SignatureCanvas ref={ref} penColor='green'
                    canvasProps={{width: 300, height: 100, className: 'sigCanvas border'}} />
                  <button className="text-blue-500 text-left pl-4" onClick={(e) => {
                    e.preventDefault()
                    ref.current.clear()}}>Clear</button>
                </div>
              </div>
            </FormCol>
          </div>
        </Form>
        <div className="w-5/6 mx-auto card mb-8">
          <button onClick={props.handlePrevFlow} className="gradient-btn my-4 mr-2">Previous</button>
          <button onClick={props.handleSubmitFlow} className="gradient-btn my-4">Next</button>
          <p className="">Already have an account? <Link className="text-blue-700" to="/login">Sign In</Link></p>
        </div>
      </div>
    )
  })

export default SignupFlow2