import React, { useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import FileViewer from 'react-file-viewer';

export default function OfficeLicenseAgreement(){
	const file = 'https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDPAgreement.pdf'
	const type = 'pdf'
	return(
		<div><FileViewer
        fileType={type}
        filePath={file} />
        </div>

		)
}



