import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from './AppliedRoute.js'


import Login from './Login2'
import Signup from './Signup'
import ResetLogin from './ResetLogin'
import NewPassword from './NewPassword'

import SignupFlow from './SignupFlow'


export default function Routes({appProps}) {
  return (
    <Switch>
      <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
      <AppliedRoute path="/login/reset" exact component={ResetLogin} appProps={appProps}/>
      <AppliedRoute path="/signup" exact component={SignupFlow} appProps={appProps}/>
      <AppliedRoute path="/" component={Login} appProps={appProps}/>
    </Switch>
  );
}