import React, { useState, useEffect, useContext }  from "react";
import { Link, useParams } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import {useFormQL} from './hooks/useFormQL'

import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'

import {practiceSchema} from './schemas/schemas'

export default function PracticeForm(props){
	
	const auth = useContext(UserContext)
	const params = useParams()

	//practice field changes and object saves are handled using the useFormQL hook
  	const {schemaName, saved, stateDict, handleSubmit, handleInputChange} = useFormQL(practiceSchema, params.practiceId, "office") //get form state
  	
	return(
		<div className="w-full h-full">
			<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
	      	    <Link className="text-blue-500" to="/practices">{"<<"} Practices</Link>
	        </div> 
			<Form onSubmit={handleSubmit} title={"Practice"} saved={saved} savedLabel={"Practice Saved"} buttonTitle={"Save"}>
				<FormCol>
					<Input label={"Full Name"} onChange={handleInputChange} value={stateDict.name} name={"name"} />
					<Input label={"Business Name"} onChange={handleInputChange} value={stateDict.businessName} name={"businessName"} />
					<Input label={"City"} onChange={handleInputChange} value={stateDict.city} name={"city"} />
					<Input label={"State"} onChange={handleInputChange} value={stateDict.state} name={"state"} />
					<Input label={"Zip"} onChange={handleInputChange} value={stateDict.zip} name={"zip"} />
				</FormCol>
			</Form>
		</div>
		)
}