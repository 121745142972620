import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';


export default function MemberBenefitTracking(props){
	const listFixedBenefits = props.fixedBenefits.map(benefit => {
		let usageDiv
		if(benefit.redeemDate !== ""){
			usageDiv = <div key={benefit.id} className="bg-green-500 text-white p-2 rounded-sm"><span className="font-semibold">Redeemed:</span> {benefit.redeemDate.split('T')[0]}</div>
		}
		else{
			usageDiv = <div key={benefit.id}> </div>
		}

		return(
				<div key={benefit.benefit} className="flex my-2">
					<div className="flex w-1/2">
						<input onChange={() => props.handleLogFixedBenefit(benefit.benefit)} className="my-auto" type="checkbox" id={benefit.benefit} checked={benefit.redeemed}></input>
						<div className="my-auto">{benefit.benefit}</div>
					</div>
					{usageDiv}
				</div>
			)
	})

	const listRecurringBenefits = props.benefitsLog.map(benefit => {
		let usageDiv
		if(benefit.createdAt !== undefined){
			usageDiv = <div key={benefit.id} className="bg-green-500 text-white p-2 rounded-sm"><span className="font-semibold">Redeemed:</span> {benefit.createdAt.split('T')[0]}</div>
		}
		else{
			usageDiv = <div key={benefit.id}> </div>
		}

		return(
				<div className="flex my-2">
					<div className="flex w-1/2">
						<div className="my-auto">{benefit.benefit}</div>
					</div>
					{usageDiv}
				</div>
			)
	})


    useEffect(
	    () => {
	    if(props.selectedPlan){
	   		props.fetchBenefitTrackingPlan()
	   		props.fetchRecurringBenefits()
	    }
	    }, [props, props.selectedPlan])

	return(
		<div className="w-full">
			{props.selectedPlan ? 
				<form className="card flex flex-col p-8 bg-white border rounded-lg w-5/6 mx-auto ">
					<h1 className="text-2xl my-4">Benefit Usage</h1>
					<h2 className="text-lg my-4 font-semibold">Fixed Benefits</h2>
					<div className="py-4 mb-4 border-b">{listFixedBenefits}</div>
					<h2 className="text-lg my-2 font-semibold">Recurring Benefits Log</h2>
					<p className="my-4">Describe custom benefits applied to account.</p>
					<textarea onChange={props.handleNewBenefitLogChange} className="border my-2" type="text" name="newBenefitLog" value={props.newBenefitLog}  id="newBenefitLog"></textarea>
					<button onClick={props.handleLogRecurringBenefit} className="rounded-sm w-1/6 px-2 py-2 bg-blue-500 text-white">Log Benefit</button>
					<div className="py-4 mb-4 border-b">{listRecurringBenefits}</div>
				</form> : 
				<div className="w-5/6 mx-auto flex justify-center bg-white">
					<div className="my-20">Member does not have plan. Select plan <Link className="text-blue-500 underline" to="/createmember/info">here</Link>.</div>
				</div>
			}
		</div>
		)
}