import React, { useState, useEffect, useContext }  from "react";
import Input from '../../components/Input'
import Form from '../../components/Form'
import FormCol from '../../components/FormCol'
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { UserContext } from "../../UserContext";

export default function MemberPrimaryContact(props){
	const [noEmail, setNoEmail] = useState(false)
	const { currentOffice } = useContext(UserContext)

	/// If no email, then use the phoneNumber@test.com for the email
	const toggleNoEmail = (e) => {
		const val = e.target.checked
		setNoEmail(val)
		if (!val) {
			// Allowing an email address
			props.handleContactEmailChange({target: { value: '' }})
		}
		else if (!props.contactPhone || props.contactPhone.length === 0) {
			alert('Please input phone number')
		}
	}

	const { contactPhone, handleContactEmailChange } = props
	useEffect(() => {
		if (!noEmail) { return }
		if (!((currentOffice && currentOffice.office && currentOffice.office.email))) { return }
		
		const phone = contactPhone.replace(/(\(|\)|-| )/g, '')
		handleContactEmailChange({target: { value: `${phone}@${currentOffice.office.email.split('@').pop()}` }})

	}, [currentOffice, noEmail, contactPhone, handleContactEmailChange])

	const contactFieldsDisabled = props.patientSameAsCardHolder && props.paymentType !== 'cash'
	return(
		<div className="w-full">

			{ props.practices && props.practices.length > 0 && <Form onSubmit={e => e.preventDefault()} hideButton={true} className='card px-8 bg-white border rounded-lg w-5/6 mx-auto'>
				<FormCol>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Practice:</p>
			            <select className="border c-dropdown focus:outline-none p-2 " id="connections" name="connections" value={props.practice} onChange={props.handlePracticeChange}>
			              {props.practices.map(practice => <option key={practice.id} value={practice.name}>{practice.name}</option>)}
			            </select>						
			        </div>
				</FormCol>
			</Form> }
			<Form onSubmit={e => e.preventDefault()} title={"Member (Patient)"} hideButton={true} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">		
				<FormCol>		
			        <Input disabled={contactFieldsDisabled} label={"Name"} onChange={props.handlePatientNameChange} value={props.patientName} name={"patientName"} />
			        <Input disabled={contactFieldsDisabled} label={"Address"} onChange={props.handleContactAddressChange} value={props.contactAddress} name={"contactAddress"} />
				</FormCol>
				<FormCol>
					<Input disabled={contactFieldsDisabled} label={"City"} onChange={props.handleContactCityChange} value={props.contactCity} name={"contactCity"} />
			    	<Input disabled={contactFieldsDisabled} label={"Zip"} onChange={props.handleContactZipChange} value={props.contactZip} name={"contactZip"} />
				</FormCol>
			</Form>
			<Form onSubmit={e => e.preventDefault()} title={"Account Holder"} hideButton={true} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<FormCol>
					<Input disabled={props.accountHolderSameAsCardHolder && props.paymentType !== 'cash'} label={"Name"} onChange={props.handleContactNameChange} value={props.contactName} name={"contactName"} />
					<Input label={"Email"} disabled={noEmail} onChange={props.handleContactEmailChange} value={props.contactEmail} name={"contactEmail"} />
					{
						!props.edit  && (
							<FormControlLabel
							control={
								<Checkbox checked={noEmail} onChange={toggleNoEmail} />
							}
							label="No Email" />
						)
					}
				</FormCol>
				<FormCol>
					<Input label={"Phone Number"} onChange={props.handleContactPhoneChange} value={props.contactPhone} name={"contactPhone"} />
				</FormCol>
			</Form>
		</div>
		)
}
