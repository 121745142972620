import React, { useState, useEffect, useContext, useCallback }  from "react";
import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import AdminPlanBenefits from './AdminPlanBenefits'

function uuidv4() {
  //creates random 16-digit id 
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line no-mixed-operators
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default function CreatePlan(props){

	const auth = useContext(UserContext)

	const [name, setName] = useState("")
	const [termLength, setTermLength] = useState("Year")
	const [pricingType, setPricingType] = useState("Per Individual")
	const [pricingRule, setPricingRule] = useState("Rule A")
	const [setupFee, setSetupFee] = useState(0)
	const [benefits, setBenefits] = useState("")

	const [hasAdultPlan, setHasAdultPlan] = useState(false)
	const [hasPerioPlan, setHasPerioPlan] = useState(false)
	const [hasChildPlan, setHasChildPlan] = useState(false)

	const [adultPlanId, setAdultPlanId] = useState("")
	const [perioPlanId, setPerioPlanId] = useState("")
	const [childPlanId, setChildPlanId] = useState("")

	const [adultPlanName, setAdultPlanName] = useState("")
	const [perioPlanName, setPerioPlanName] = useState("")
	const [childPlanName, setChildPlanName] = useState("")
	const [planName, setPlanName] = useState("")


	const [editedForm, setEditedForm] =useState(false)
	const [deletedBenefit, setDeletedBenefit] =useState(false)

	const [planType, setPlanType] = useState("Adult")

	const [plans, setPlans] = useState([])

	const [basicBenefits, setBasicBenefits] = useState([])

	const [adultCustomBenefits, setAdultCustomBenefits] = useState([])

	const [childCustomBenefits, setChildCustomBenefits] = useState([])

	const [perioCustomBenefits, setPerioCustomBenefits] = useState([])


	const [customBenefits, setCustomBenefits] = useState(adultCustomBenefits)

	const [newBasicBenefit, setNewBasicBenefit] = useState("")
	const [newCustomBenefitDescription, setNewCustomBenefitDescription] = useState("")
	const [newCustomBenefitDisplay, setNewCustomBenefitDisplay] = useState("")

	const [dummyCustomDisplay, setDummyCustomDisplay] = useState("")

	const [selectedBenefits, setSelectedBenefits] = useState(false)


	const handleDeleteBasicBenefit = (benefitName) => {

		//removes basic benefit from selected plan's local state
		let newBasicBenefits = basicBenefits.map(benefit => {
			if(benefit !== benefitName){
				return benefit
			}
			return undefined
		})
		setBasicBenefits(newBasicBenefits.filter(benefit => benefit != undefined))
	}

	  const handleConfirmCustomDelete = (benefitName) => {
	    
	    //confirms delete intention
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: 'Are you sure you want to delete?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleDeleteCustomBenefit(benefitName)}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }

	  const handleConfirmBasicDelete = (benefitName) => {

	  	//confirms delete intention
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: 'Are you sure you want to delete?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleDeleteBasicBenefit(benefitName)}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }

	const handleDeleteCustomBenefit = (benefitName) => {
		
		//removes custom benefit from selected plan's local state
		let newCustomBenefits = customBenefits.map(benefit => {
			if(benefit.name !== benefitName){
				return benefit
			}
			return undefined
		})
		setCustomBenefits(newCustomBenefits.filter(benefit => benefit != undefined))

		setDeletedBenefit(true)
	}
	

	const handleSaveBenefits = async (event) => {

		//any deleted/modified custom and basic benefits have not been saved until this point

		event.preventDefault()

		//update the relevant plans in dynamodb with the amplify api
		//remember, these AdminPlans represent the default/blank plans users will see when creating a new plan
		if(planType == "Adult"){
			const updatedAdminPlan = await API.graphql(graphqlOperation(`mutation update {
			  updateAdminPlan (input: {
			    id: "${adultPlanId}",
			    customBenefits: "${btoa(JSON.stringify(customBenefits))}"
			    basicBenefits: "${btoa(basicBenefits)}"
			  }){
		          id
		          customBenefits
		          basicBenefits
			  }
			}`))
		}
		else if(planType == "Child"){
			const updatedAdminPlan = await API.graphql(graphqlOperation(`mutation update {
			  updateAdminPlan (input: {
			    id: "${childPlanId}",
			    customBenefits: "${btoa(JSON.stringify(customBenefits))}"
			    basicBenefits: "${btoa(basicBenefits)}"
			  }){
		          id
		          customBenefits
		          basicBenefits
			  }
			}`))
		}
		else if(planType == "Perio"){
			const updatedAdminPlan = await API.graphql(graphqlOperation(`mutation update {
			  updateAdminPlan (input: {
			    id: "${perioPlanId}",
			    customBenefits: "${btoa(JSON.stringify(customBenefits))}"
			    basicBenefits: "${btoa(basicBenefits)}"
			  }){
		          id
		          customBenefits
		          basicBenefits
			  }
			}`))
		}


		setEditedForm(true)
	}

	const handleAddBasicBenefit = () => {
		//adds new basic benefit to local state
		let newBasicBenefits = basicBenefits
		newBasicBenefits.push(newBasicBenefit)
		setBasicBenefits(newBasicBenefits)
		setNewBasicBenefit("")
	}

	const handleAddCustomBenefit = (event) => {
		//adds new custom benefit to local state
		event.preventDefault()

		const benefitId = uuidv4()

		let newCustomBenefits = customBenefits
		let newCustomBenefit = {id: benefitId, name: newCustomBenefitDisplay, benefit: newCustomBenefitDescription, included: false}
		newCustomBenefits.push(newCustomBenefit)
		setCustomBenefits(newCustomBenefits)
		setNewCustomBenefitDescription("")
		setNewCustomBenefitDisplay("")
		setDummyCustomDisplay("weofij")
	}

	const handleNewBasicBenefitChange = (event) => {
		setNewBasicBenefit(event.target.value)
	}

	const handleNewCustomBenefitDescriptionChange = (event) => {
		setNewCustomBenefitDescription(event.target.value)
		setNewCustomBenefitDisplay(event.target.value)
	}

	const handleNewCustomBenefitDisplayChange = (event) => {
		setNewCustomBenefitDisplay(event.target.value)
	}

	const handleAdultPlanType = () =>{
		setPlanType("Adult")
		setCustomBenefits(adultCustomBenefits)
		setName(adultPlanName)
	}

	const handlePerioPlanType = () =>{
		setPlanType("Perio")
		setCustomBenefits(perioCustomBenefits)
		setName(perioPlanName)
	}

	const handleChildPlanType = () =>{
		setPlanType("Child")
		setCustomBenefits(childCustomBenefits)
		setName(childPlanName)

	}

	const handleNameChange = (event) => {
		setName(event.target.value)
	}

	const handleTermLengthChange = (event) => {
	    setTermLength([event.target.value][0])
	  }

	const handlePricingTypeChange = (event) => {
	    setPricingType([event.target.value][0])
	  }

	const handlePricingRuleChange = (event) => {
	    setPricingType([event.target.value][0])
	  }

	const handleSetupFeeChange = (event) => {
		setSetupFee(event.target.value)
	}

	const handleBenefitsChange = (event) =>{
		setBenefits(event.target.value)
	}

	const handleCustomBenefitsChange = useCallback((event) =>{
		
		//archived
		event.preventDefault()
		const newCustomBenefits = customBenefits.map(benefit =>{
			if(benefit.id == event.target.id){
				const newCheck = event.target.checked
				return({...benefit,  included: newCheck})
			}
			else{
				return benefit
			}
		})

		if(planType == "Adult"){
			setAdultCustomBenefits(newCustomBenefits)
		}
		else if (planType == "Perio"){
			setPerioCustomBenefits(newCustomBenefits)
		}
		else{
			setChildCustomBenefits(newCustomBenefits)
		}
		setCustomBenefits(newCustomBenefits)
	}, [customBenefits, planType])

	const handleCustomBenefitsValueChange = (event) =>{
		event.preventDefault()

		//maps through existing benefits, comparing ids to the value input's id - when equal, updates the stored value
		//values are for benefits that say things like $5, 20% off this plan, etc.
		const newCustomBenefits = customBenefits.map(benefit =>{
			if(benefit.id == event.target.id){
				const newValue = event.target.value
				return({...benefit,  value: newValue})
			}
			else{
				return benefit
			}
		})

		if(planType == "Adult"){
			setAdultCustomBenefits(newCustomBenefits)
		}
		else if (planType == "Perio"){
			setPerioCustomBenefits(newCustomBenefits)
		}
		else{
			setChildCustomBenefits(newCustomBenefits)
		}
		setCustomBenefits(newCustomBenefits)
	}

	const handlePreviousPage = () =>{
		setSelectedBenefits(false)
	}

    async function fetchPlans(){

    	//similar to fetchAdminPlans on CreatePlanV3.js
      const adminPlansData = await API.graphql(graphqlOperation(`query ListAdminPlans {
        listAdminPlans(limit: 9999) {
          items {
              id
              customBenefits
              basicBenefits
              planType
          }
        }
        }`))

      let plansList = adminPlansData.data.listAdminPlans.items
      let rowCount = 1
      let newPlan = []

      let newPlansList = []
      for(let i=0; i<plansList.length; i++){
		newPlan = {...plansList[i], rowId: rowCount}
		newPlansList.push(newPlan)
		rowCount = rowCount + 1
		}


		if(plansList.filter(plan => plan.planType == "Adult").length > 0){
			setBasicBenefits(atob(plansList.filter(plan => plan.planType == "Adult")[0].basicBenefits).split(','))
			setHasAdultPlan(true)
			setAdultCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Adult")[0].customBenefits)))
			setCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Adult")[0].customBenefits)))
			setAdultPlanId(plansList.filter(plan => plan.planType == "Adult")[0].id)
			setAdultPlanName(plansList.filter(plan => plan.planType == "Adult")[0].name)
			setName(plansList.filter(plan => plan.planType == "Adult")[0].name)
		}

		if(plansList.filter(plan => plan.planType == "Perio").length > 0){
			setHasPerioPlan(true)
			setPerioCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Perio")[0].customBenefits)))
			setPerioPlanId(plansList.filter(plan => plan.planType == "Perio")[0].id)
			setPerioPlanName(plansList.filter(plan => plan.planType == "Perio")[0].name)
		}

		if(plansList.filter(plan => plan.planType == "Child").length > 0){
			setHasChildPlan(true)
			setChildCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Child")[0].customBenefits)))
			setChildPlanId(plansList.filter(plan => plan.planType == "Child")[0].id)
			setChildPlanName(plansList.filter(plan => plan.planType == "Child")[0].name)
		}
	
      setPlans(newPlansList)
    }

    async function fetchPlan(){

    	//archived

        const planData = await API.graphql(graphqlOperation(`query GetPlan {
	    getPlan(id: "${props.planId}"){
	          id
	          name
	          benefits
	    }
	}`))
        const userPlanData = planData.data.getPlan
        setName(userPlanData.name)
		//setCustomBenefits(JSON.parse(atob(userPlanData.benefits)))

	}

    useEffect(
	    () => {
	    	fetchPlans()
	   		//fetchPlan()
	    }, [])



	const validateForm = (event) =>{
		event.preventDefault()

		setSelectedBenefits(true)
	}


	//all of the benefit mappings here work like they do on CreatePlanV3.js - see this component for more documentation 

	const listBasicBenefits = basicBenefits.map(benefit => {
		return(
			<div key={benefit} className="flex w-full mb-2">
				<li key={benefit.id} className="my-auto px-2 flex justify-between w-full">{benefit} <button key={benefit.id} onClick={() => handleConfirmBasicDelete(benefit)} ><DeleteIcon key={benefit.id} className="table-icon"/></button></li>
			</div>
			)
	})

	const listCustomBenefits = customBenefits.map(benefit => {
		return(
			<div key={benefit.benefit} className="flex w-full mb-2">
				<li key={benefit.id} className="my-auto px-2 flex justify-between w-full">{benefit.benefit} <button onClick={() => handleConfirmCustomDelete(benefit.name)} ><DeleteIcon className="table-icon"/></button></li>
			</div>
			)
	})


	const listCustomBenefitInputs = customBenefits.map(benefit => {
		let displayStyle
		if(benefit.benefit.includes("$X")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit.benefit} className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + " price $"}</p>
						<input onChange={handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
					<div className="w-2/3 flex-col text-sm">
						<p className="my-2 font-bold">{benefit.name + " text"}</p>
						<input onChange={handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.text} className="border w-full" type="text"></input>
					</div>
				</div>
				)	
		}
		else if (benefit.benefit.includes("X%")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit} className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + " %"}</p>
						<input onChange={handleCustomBenefitsValueChange} key={benefit.id} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
					<div className="w-2/3 flex-col">
						<p className="my-2 font-bold text-sm">{benefit.name + " text"}</p>
						<input onChange={handleCustomBenefitsValueChange} key={benefit.id} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
				</div>
				)
		}
		else if (benefit.benefit.includes("Additional")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit} className={displayStyle}>
					<div className="w-full flex-col">
						<p className="my-2 font-bold text-sm">{benefit.name}</p>
						<input id={benefit.id} name={benefit.name} value={benefit.value} onChange={handleCustomBenefitsValueChange} className="border w-full" type="text"></input>
					</div>
				</div>
				)
		}
		else {
			return <></>
		}
	})

	const displayPlanSaved = editedForm ? <div className="w-full mx-auto mt-4 text-green-700 flex"><div className="px-2">Plan Saved </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null


	return(
		<div className="w-full h-full">
			<AdminPlanBenefits planType={planType} handleAdultPlanType={handleAdultPlanType} handlePerioPlanType={handlePerioPlanType} handleChildPlanType={handleChildPlanType} validateForm={validateForm} handleNameChange={handleNameChange} name={name}
				basicBenefits={basicBenefits} customBenefits={planType == "Adult" ? adultCustomBenefits : planType == "Child" ? childCustomBenefits : perioCustomBenefits} listBasicBenefits={listBasicBenefits} listCustomBenefits={listCustomBenefits}
				listCustomBenefitInputs={listCustomBenefitInputs}
				newCustomBenefitDescription={newCustomBenefitDescription} newCustomBenefitDisplay={newCustomBenefitDisplay}
				handleNewCustomBenefitDisplayChange={handleNewCustomBenefitDisplayChange}
				handleNewCustomBenefitDescriptionChange={handleNewCustomBenefitDescriptionChange}
				newBasicBenefit={newBasicBenefit} handleNewBasicBenefitChange={handleNewBasicBenefitChange}
				handleCustomBenefitsChange={handleCustomBenefitsChange} handleCustomBenefitsValueChange={handleCustomBenefitsValueChange}
				handleAddBasicBenefit={handleAddBasicBenefit} handleAddCustomBenefit={handleAddCustomBenefit} 
				handleSaveBenefits={handleSaveBenefits} displayPlanSaved={displayPlanSaved} />
		</div>
		)
}

