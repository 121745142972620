import { FilterDramaRounded } from '@material-ui/icons'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import Table2 from '../../components/Table2'
import { financesByDate } from '../../graphql/queries'
import { updateMemberFinance } from '../../graphql/mutations'
import { UserContext } from '../../UserContext'
import { Button, TextField } from "@material-ui/core";
import useDebounce from '../../hooks/useDebounce'
import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda'; // npm install aws-sdk
import { confirmAlert } from 'react-confirm-alert'
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react'

const initialState = {
    items: [],
    data: [],
    nextToken: null,
    initialized: false,
    allChecked: false
}

const reducer = (currentState, action) => {
    const updatedState = {
        ...currentState,
        ...action
    }

    if (action.items) {

        /// Do our data manipulation here
        const members = [...updatedState.items]
        const finalList = []
        let currentItem = members.shift()
        while(currentItem) {

            /// Renewal date is the createdDate + dayDue + currentYear
            /// TODO: Read `nextRenewalDate`
            const renewalDate = currentItem.nextRenewalDate ? moment(currentItem.nextRenewalDate) : (
                () => {
                    
                    const createdDate = moment(currentItem.createdAt)
                    let curYear = (new Date()).getFullYear()
                    if (createdDate.year() == curYear) {
                        curYear += 1
                    }
                    return createdDate
                    .year(curYear)
                    .hour(6)
                    .minute(0)
                    .date(currentItem.dayDue)
                }
            )()

            const member = currentItem.member
            
            finalList.push({
                id: currentItem.memberId,
                paymentInterval: currentItem.paymentInterval,
                financeId: currentItem.id,
                createdAt: moment(currentItem.createdAt),
                name: currentItem.contactName || currentItem.memberName || currentItem.name,
                plan: (member && member.plan) || 'NA',
                renewalDate,
                paymentType: currentItem.paymentType,
                renewalNotifications: typeof currentItem.renewalNotifications == "boolean" ? currentItem.renewalNotifications : true,
                pastDue: renewalDate.isBefore(moment()),
                email: currentItem.member.contactEmail,
                autoRenew: currentItem.autoRenew !== false
            })
            currentItem = members.shift()
        }

        updatedState.data = [
            ...(action.clear ? [] : updatedState.data),
            ...finalList,
        ]
    }
    

    updatedState.allChecked = updatedState.data.filter(m => m.renewalNotifications == false).length == 0

    return updatedState 
}

export default () => {

    const auth = useContext(UserContext)

    const [state, dispatch] = useReducer(reducer, initialState)
    const [searchQuery, setSearchQuery] = useState('')
    const [numberOfDays, setNumberOfDays] = useState(30)
    const debouncedSearchData = useDebounce(
		useMemo(
			() => ({
				query: searchQuery
			}),
			[searchQuery]
		)
		, 500
    )
    const [allChecked, setAllChecked] = [state.allChecked, (v) => dispatch({ allChecked: v })]


    ////// Lifecycle
    const office = auth.office || auth.user
    const nextToken = state.nextToken

    const fetchMemberships = useCallback(
        async (clear = false) => {
            if (!office || office.length === 0) {
                return
            }

            // TODO: Remove this later
            const tmpFilter = {
                not: {
                    nextRenewalDate: {
                        gt: moment().add(numberOfDays, 'days').subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss') 
                    }
                },
                active: { ne: false }
            }
            if (debouncedSearchData.query && debouncedSearchData.query.length > 0) {
                /** @type {String}  */
                const nameQuery = debouncedSearchData.query
                /// Generate every variation of `uppercased` combo
                /// Since we're dealing with names it's likely lower or capitalized
                let variations = [
                    nameQuery,
                    nameQuery.toLowerCase(),
                    nameQuery.slice(0, 1).toUpperCase() + nameQuery.slice(1).toLowerCase()
                ]

                tmpFilter.or = variations.map(v => {
                    return [
                        { eq: v },
                        { contains: v },
                        { beginsWith: v }
                    ]
                })
                .reduce((cum, val) => {
                    return cum.concat(val)
                }, [])
                .map(f => ({memberName: f}))
            }

            // if (numberOfDays) {
            //     tmpFilter.or = [
            //         ...(tmpFilter.or || []),
            //         {
            //             createdAt: { lt: moment().subtract(numberOfDays, 'days').subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss')}
            //         }
            //     ]
            // }

            const filter = Object.keys(tmpFilter).length > 0 ? tmpFilter : null
            const results = await API.graphql(graphqlOperation(financesByDate, { 
                filter,
                office,
                createdAt: { 
                    lt: moment().add(numberOfDays, 'days').subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss') 
                },
                limit: 20,
                sortDirection: 'ASC',
                nextToken: clear ? null : nextToken
            }))

            const { data: { financesByDate: itemsResult } } = results
            
            dispatch({
                clear,
                ...itemsResult,
                initialized: true
            })


        // console.log(results)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, 
        [office, nextToken, debouncedSearchData, numberOfDays]
    )


    const loadMore = async () => {
        fetchMemberships()
    }

    const processRenewal = async (memberId) => {

        try {
            const creds = await Auth.currentCredentials()
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(creds),
                region: 'us-west-2'
            })
            const res = await new Promise((resolve, reject) => {
                lambda.invoke({
                    FunctionName: 'renewalProcess-dev',
                    Payload: JSON.stringify({
                        memberId,
                        officeId: office
                    })
                }, (err, data) => {
                    if (err) reject(err)
                    else resolve(data)
                })
            })
            console.log(res)
            alert('Success')
            fetchMemberships(true)
        }
        catch (ex) {
            console.error(ex)
            alert(`Error processing renewal: ${ex.message}`)
        }
    }

    useEffect(() => {
        (async () => {
            await fetchMemberships(true)
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchData, numberOfDays])

    ////// Actions
    const updateReminders = async (record, isActive, shouldDispatch = true) => {
        try {
            const currentData = state.data
            const result = await API.graphql(graphqlOperation(updateMemberFinance, {
                // condition: { id: record.id },
                input: {
                    id: record.financeId,
                    renewalNotifications: isActive
                }
            }))

            // Don't dispatch if shouldDispatch is false
            if (!shouldDispatch) { return }
            dispatch({data: currentData.map(r => {
                if (r.id == record.id) {
                    return {
                        ...r,
                        renewalNotifications: isActive
                    }
                }
                return r
            })})
        }
        catch (ex) {
            console.error(ex)
        }
    }

    const updateAutoRenew = async (record, isActive, shouldDispatch = true) => {
        try {
            const currentData = state.data

            /// Whenever toggling off autoRenew, we should also toggle
            /// off reminders 
            const extraUpdates = (!isActive ? {renewalNotifications: false} : {})
            const result = await API.graphql(graphqlOperation(updateMemberFinance, {
                // condition: { id: record.id },
                input: {
                    id: record.financeId,
                    autoRenew: isActive,
                    ...extraUpdates
                }
            }))

            // Don't dispatch if shouldDispatch is false
            if (!shouldDispatch) { return }
            dispatch({data: currentData.map(r => {
                if (r.id == record.id) {
                    return {
                        ...r,
                        autoRenew: isActive,
                        ...extraUpdates
                    }
                }
                return r
            })})
        }
        catch (ex) {
            console.error(ex)
        }
    }

    const toggleAllReminders = () => {
        const reallyToggle = async () => {
            const result = []
            var i,j, temporary, chunk = 10;
            for (i = 0,j = state.data.length; i < j; i += chunk) {
                temporary = state.data.slice(i, i + chunk);
                // do whatever

                result.push(await Promise.all(state.data.slice(i, i + chunk).map(r => updateReminders(r, !allChecked, false))))
            }

            dispatch({
                action: 'toggleAll', results: result, 
                data: state.data.map(d => ({...d, renewalNotifications: !allChecked})), 
                allChecked: !allChecked
            })
        }

        confirmAlert({
            title: 'Are you sure?',
            message: 'This will override all individual settings?',
            buttons: [
                { label: 'NO', onClick: () => { }},
                { label: 'YES', onClick: reallyToggle}
            ]
        })
    }

    ////// Rendering
    const filterData = (inputData) => {
        return inputData || []
    }

    /**
     * @typedef RenewalButtonProps
     * @property {Function<Promise<void>>} onClick
     * 
     * @param {RenewalButtonProps} props 
     */

    const RenewalButton = (props) => {
        const [processing, setProcessing] = useState(false)


        const onClick = async () => {
            if (processing) { return }

            setProcessing(true)

            props.onClick()
            .finally(() => {
                setProcessing(false)
            })        
        }


        if (processing) {
            return (
                <div className='w-full align-center'>
                    <AmplifyLoadingSpinner  />
                </div>
            )
        }

        return (
            <button className='gradient-btn' onClick={onClick}>
                Renew
            </button>
        )
    }

    const columns = [
        { column: 'Member', value: 'name' },
        { column: 'Email', value: 'email' },
        { column: 'Plan', value: 'plan' },
        { column: 'Interval', value: 'paymentInterval' },
        { column: 'Payment Type', value: 'paymentType' },
        { column: 'Signup Date', value: 'createdAt' }, 
        { column: 'Renewal Date', value: 'renewalDate' },
        { column: 'Remind', value: 'renewalNotifications', render: () => {
            return (
                <button onClick={toggleAllReminders}>
                    Remind
                    <input className='mx-2' readOnly type='checkbox' checked={allChecked} onChange={e => setAllChecked(e.target.checked)} />
                </button>
            )
        } },
        { column: 'Auto Renew', value: 'autoRenew' }
    ]
    return (
        <div className="w-full h-full">
            <div className="w-full px-10 py-6">

                <div className="flex w-full bg-white tiny-card p-4">
					<form className='flex w-full bg-white w-5/6 items-center px-5 py-2' onSubmit={e => e.preventDefault()}>
						<TextField 
                            className='w-1/2 m-auto' 
                            placeholder='Search...' 
                            value={searchQuery} 
                            onChange={(e) => setSearchQuery(e.target.value)} />
					
                        {/* 30 / 6O / 90 days */}
                        <div className='ml-auto text-center'>
                            <p className="py-1"><b>Upcoming Renewal Date:</b></p>
                        {
                            [30,60,90,365].map(period => (
                                <button 
                                id={period}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setNumberOfDays(period)
                                }}
                                className={`px-2 mx-1 ${numberOfDays == period && 'gradient-btn'}`}>
                                    { period <= 90 ? `${period} days` : 'All'}
                                </button>
                            ))
                        }
                        </div>
                    </form>
				</div>

                <Table2 
                    sortField='renewalDate' 
                    sortOrder='asc'
                    edit={true} 
                    pathPrefix={'member'} 
                    pathSuffix={'info'} 
                    columns={columns}
                    fields={[
                        ...columns.map(c => c.value),
                        'renewAction'
                    ]}
                    handlers={{
                        autoRenew: (e, record) => {
                            console.log(`Auto-renew for record `, record, e.target.value)
                            updateAutoRenew(record, !record.autoRenew)

                        },
                        renewalNotifications: (e, record) => {
                            console.log(`Renewal for record `, record, e.target.value)
                            updateReminders(record, !record.renewalNotifications)
                        }
                    }}
                    customComponents={{
                        renewAction: (_unused, record) => (
                            <RenewalButton 
                                key={`${record.id}-renew`} 
                                onClick={() => processRenewal(record.id)} />
                        )
                    }}
                    values={filterData(state.data)} />
                {
                    !state.initialized && (
                        <p>Loading</p>
                    )
                }
            </div>

            { nextToken != null && (
				<div className='w-full'>
					<button onClick={loadMore} >Load More</button>
				</div>
				)
            }
        </div>
    )
}