import React, { useState, useEffect, useContext, useCallback }  from "react";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import {UserContext} from './UserContext'

import { Link } from "react-router-dom";


import '../node_modules/react-vis/dist/style.css';


import DashboardChart from './DashboardChart'

import { API, graphqlOperation, Auth } from 'aws-amplify';

import SearchBar from './SearchBar'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


function makeComparator(key, order='desc') {
  return (a, b) => {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0; 

    const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === 'desc' ? (comparison * -1) : comparison
  };
}

function arraySum(total, num) {
  return total + num;
}

export default function Dashboard(props){

	
	const auth = useContext(UserContext)

	const [isLoading, setIsLoading] = useState(true)

	const [timeZoneOffset, setTimeZoneOffset] = useState(new Date().getTimezoneOffset()/60)

	const [mrr, setMrr] = useState("4,500")
	const [revYtd, setRevYtd] = useState("12,800")
	const [totalMembers, setTotalMembers] = useState(132)
	const [churn, setChrn] = useState(14.2)
	const [growth, setGrowth] = useState(11.1)
	const [interval, setGraphInterval] = useState("Day")

	const [currentPlot, setCurrentPlot] = useState(null)

	const [streams, setStreams] = useState([{name: "Harry Potter", activity: "Payment - Complete"},
	{name: "Lord Voldemort", activity: "Member - Created"},
	{name: "Neville Longbottom", activity: "Payment - Declined"},
	{name: "Hermione Granger", activity: "Payment - Complete"},
	{name: "Albus Dumbledore", activity: "Member - Created"},
	{name: "Rubeus Hagrid", activity: "Payment - Declined"}])

	const [payments, setPayments] = useState([])
	const [practice, setPractice] = useState("All")
	const [practices, setPractices] = useState([])

	const [filteredPayments, setFilteredPayments] = useState([])
	const [filteredMemberLogs, setFilteredMemberLogs] = useState([])

	const [dayPayments, setDayPayments] = useState([])
	const [monthPayments, setMonthPayments] = useState([])
	const [quarterPayments, setQuarterPayments] = useState([])
	const [yearPayments, setYearPayments] = useState([])

	const [memberLogs, setMemberLogs] = useState([])

	const [newMembersToday, setNewMembersToday] = useState(0)
	const [churnedMembersToday, setChurnedMembersToday] = useState(0)
	const [newMembersMonth, setNewMembersMonth] = useState(0)
	const [churnedMembersMonth, setChurnedMembersMonth] = useState(0)
	const [newMembersQuarter, setNewMembersQuarter] = useState(0)
	const [churnedMembersQuarter, setChurnedMembersQuarter] = useState(0)
	const [newMembersYear, setNewMembersYear] = useState(0)
	const [churnedMembersYear, setChurnedMembersYear] = useState(0)

	const [numPaymentsToday, setNumPaymentsToday] = useState(0)

	const [benefitsLog, setBenefitsLog] = useState([])
	const [numBenefitsToday, setNumBenefitsToday] = useState(0)

	const [dayBenefits, setDayBenefits] = useState([])
	const [monthBenefits, setMonthBenefits] = useState([])
	const [quarterBenefits, setQuarterBenefits] = useState([])
	const [yearBenefits, setYearBenefits] = useState([])


	const [dayRev, setDayRev] =	useState([{'x':"3/31", 'y':720}, {'x':"4/1", 'y':814}, {'x':"4/2", 'y':872}, 
						{'x':"4/3", 'y':1102}, {'x':"4/4", 'y':0}, {'x':"4/5", 'y':0}
						,{'x':"4/6", 'y':894},{'x':"4/7", 'y':1044},{'x':"4/8", 'y':1001},{'x':"4/9", 'y':889}, {'x':"4/10", 'y':1213}, {'x':"4/11", 'y':0}, 
						{'x':"4/12", 'y':0}, {'x':"4/13", 'y':994}, {'x':"4/14", 'y':842}
						,{'x':"4/15", 'y':1398},{'x':"4/16", 'y':1104},{'x':"4/17", 'y':997},{'x':"4/18", 'y':0}, {'x':"4/19", 'y':0}, {'x':"4/20", 'y':1341}, 
						{'x':"4/21", 'y':1177}, {'x':"4/22", 'y':1078}, {'x':"4/23", 'y':899}
						,{'x':"4/24", 'y':1424},{'x':"4/25", 'y':0},{'x':"4/26", 'y':0},
						{'x':"4/27", 'y':1133},{'x':"4/28", 'y':1124},{'x':"4/29", 'y':540}])


	const [dayMembers, setDayMembers] =	useState([{'x':"3/31", 'y':132}, {'x':"4/1", 'y':132}, {'x':"4/2", 'y':133}, 
						{'x':"4/3", 'y':133}, {'x':"4/4", 'y':131}, {'x':"4/5", 'y':131}
						,{'x':"4/6", 'y':132},{'x':"4/7", 'y':132},{'x':"4/8", 'y':133},{'x':"4/9", 'y':133}, {'x':"4/10", 'y':133}, {'x':"4/11", 'y':133}, 
						{'x':"4/12", 'y':135}, {'x':"4/13", 'y':135}, {'x':"4/14", 'y':136}
						,{'x':"4/15", 'y':136},{'x':"4/16", 'y':136},{'x':"4/17", 'y':136},{'x':"4/18", 'y':134}, {'x':"4/19", 'y':134}, {'x':"4/20", 'y':134}, 
						{'x':"4/21", 'y':133}, {'x':"4/22", 'y':133}, {'x':"4/23", 'y':133}
						,{'x':"4/24", 'y':133},{'x':"4/25", 'y':133},{'x':"4/26", 'y':133},
						{'x':"4/27", 'y':132},{'x':"4/28", 'y':132},{'x':"4/29", 'y':132}])

	const [monthRev, setMonthRev] =	useState([{'x':"May '19", 'y':30455}, {'x':"Jun '19", 'y':33680}, {'x':"Jul '19", 'y':24868}, 
						{'x':"Aug '19", 'y':32133}, {'x':"Sep '19", 'y':35453}, {'x':"Oct '19", 'y':33211}
						,{'x':"Nov '19", 'y':31546},{'x':"Dec '19", 'y':33764},{'x':"Jan '20", 'y':34868},{'x':"Feb '20", 'y':35764}, {'x':"Mar '20", 'y':34765}, {'x':"Apr '20", 'y':32111}])

	const [monthMembers, setMonthMembers] =	useState([{'x':"May '19", 'y':111}, {'x':"Jun '19", 'y':116}, {'x':"Jul '19", 'y':118}, 
						{'x':"Aug '19", 'y':117}, {'x':"Sep '19", 'y':122}, {'x':"Oct '19", 'y':124}
						,{'x':"Nov '19", 'y': 124},{'x':"Dec '19", 'y':128},{'x':"Jan '20", 'y':130},{'x':"Feb '20", 'y':131}, {'x':"Mar '20", 'y':132}, {'x':"Apr '20", 'y':132}])
	
const [qtrRev, setQtrRev] =	useState([{'x':"Q2 '17", 'y':89311}, {'x':"Q3 '17", 'y':92342}, {'x':"Q4 '17", 'y':84452}, 
						{'x':"Q1 '18", 'y':93521}, {'x':"Q2 '18", 'y':95632}, {'x':"Q3 '18", 'y':93711}
						,{'x':"Q4 '18", 'y':89247},{'x':"Q1'19", 'y':98755},{'x':"Q2 '19", 'y':100456},{'x':"Q3 '19", 'y':102413}, {'x':"Q4 '19", 'y':98713}, {'x':"Q1 '20", 'y':99823}])

const [quarterMembers, setQuarterMembers] =	useState([{'x':"Q2 '17", 'y':89}, {'x':"Q3 '17", 'y':94}, {'x':"Q4 '17", 'y':97}, 
						{'x':"Q1 '18", 'y':96}, {'x':"Q2 '18", 'y':102}, {'x':"Q3 '18", 'y':107}
						,{'x':"Q4 '18", 'y':112},{'x':"Q1'19", 'y':116},{'x':"Q2 '19", 'y':120},{'x':"Q3 '19", 'y':127}, {'x':"Q4 '19", 'y':128}, {'x':"Q1 '20", 'y':132}])

const [yearMembers, setYearMembers] =	useState([{'x':"Q2 '17", 'y':89}, {'x':"Q3 '17", 'y':94}, {'x':"Q4 '17", 'y':97}, 
						{'x':"Q1 '18", 'y':96}, {'x':"Q2 '18", 'y':102}, {'x':"Q3 '18", 'y':107}
						,{'x':"Q4 '18", 'y':112},{'x':"Q1'19", 'y':116},{'x':"Q2 '19", 'y':120},{'x':"Q3 '19", 'y':127}, {'x':"Q4 '19", 'y':128}, {'x':"Q1 '20", 'y':132}])
	

	const [streamsPerPage, setStreamsPerPage] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [indexOfLastPost, setIndexOfLastPost] = useState(currentPage*streamsPerPage)
	const [indexOfFirstPost, setIndexOfFirstPost] = useState(indexOfLastPost - streamsPerPage)

	const [showedVerified, setShowedVerified] = useState(false)

	const handleStreamPageIncrease = () => {
		//toggles livestream values
		const newCurrentPage = currentPage + 1
		const newIndexOfLastPost = newCurrentPage*streamsPerPage
		const newIndexOfFirstPost = newIndexOfLastPost - streamsPerPage

		setCurrentPage(newCurrentPage)
		setIndexOfLastPost(newIndexOfLastPost)
		setIndexOfFirstPost(newIndexOfFirstPost)

 	}

	const handleStreamPageDecrease = () => {
		//toggles livestream values
		const newCurrentPage = currentPage - 1
		const newIndexOfLastPost = newCurrentPage*streamsPerPage
		const newIndexOfFirstPost = newIndexOfLastPost - streamsPerPage

		setCurrentPage(newCurrentPage)
		setIndexOfLastPost(newIndexOfLastPost)
		setIndexOfFirstPost(newIndexOfFirstPost)
 	}


	const handleMouseLeave = () =>{
		//controls tooltip display on charts
		setCurrentPlot(null)
	}

	const handlePracticeChange = (event) =>{

		//sets the selected practice, and filters dashboard/livestream values based on the selected practice

		const practiceName = event.value
		if(practiceName != "all"){
			const pickedPractice = practices.filter(practice => practice.name == practiceName)[0]
			setPractice(pickedPractice.name)

			const filteredPaymentsList = payments.filter(payment => payment.practice == practiceName)

	        const dayPaymentsCalc = calcDayPayments(filteredPaymentsList)
	        setDayPayments(dayPaymentsCalc)

	        const monthPaymentsCalc = calcMonthPayments(filteredPaymentsList)
	        setMonthPayments(monthPaymentsCalc)

	        const quarterPaymentsCalc = calcQuarterPayments(filteredPaymentsList)
	        setQuarterPayments(quarterPaymentsCalc)

	        const yearPaymentsCalc = calcYearPayments(filteredPaymentsList)
	        setYearPayments(yearPaymentsCalc)

			const filteredMemberLogsList = memberLogs.filter(log => log.practice == practiceName)

	        const dayMembersCalc = calcDayMembers(filteredMemberLogsList)
	        setDayMembers(dayMembersCalc)

	        const monthMembersCalc = calcMonthMembers(filteredMemberLogsList)
	        setMonthMembers(monthMembersCalc)

	        const quarterMembersCalc = calcQuarterMembers(filteredMemberLogsList)
	        setQuarterMembers(quarterMembersCalc)

	        const yearMembersCalc = calcYearMembers(filteredMemberLogsList)
	        setYearMembers(yearMembersCalc)

		}
		else{
			setPractice("All")

	        const dayPaymentsCalc = calcDayPayments(payments)
	        setDayPayments(dayPaymentsCalc)

	        const monthPaymentsCalc = calcMonthPayments(payments)
	        setMonthPayments(monthPaymentsCalc)

	        const quarterPaymentsCalc = calcQuarterPayments(payments)
	        setQuarterPayments(quarterPaymentsCalc)

	        const yearPaymentsCalc = calcYearPayments(payments)
	        setYearPayments(yearPaymentsCalc)

	        const dayMembersCalc = calcDayMembers(memberLogs)
	        setDayMembers(dayMembersCalc)

	        const monthMembersCalc = calcMonthMembers(memberLogs)
	        setMonthMembers(monthMembersCalc)

	        const quarterMembersCalc = calcQuarterMembers(memberLogs)
	        setQuarterMembers(quarterMembersCalc)

	        const yearMembersCalc = calcYearMembers(memberLogs)
	        setYearMembers(yearMembersCalc)
		}
	}

    const fetchPractices = useCallback( 
		async () => {

      //fetches all practices for the office.  If multiple practices is enabled, these will populate a select component that can filter dashboard/livestream values

      const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
        listPractices(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              name
              city
              zip
          }
        }
        }`))

      let practicesList = practiceData.data.listPractices.items
      let rowCount = 1
      let newPractice = []

      let newPracticeList = []
      for(let i=0; i<practicesList.length; i++){
		newPractice = {...practicesList[i], rowId: rowCount}
		newPracticeList.push(newPractice)
		rowCount = rowCount + 1
		}

	
      setPractices(newPracticeList)
    }, [auth])

    function calcDayPayments(paymentsList){
    	paymentsList = paymentsList.filter(payment => payment.status != "failure")

    	let dayPaymentsList = []

    	let daysAgo = 0
    	let currDate = new Date()
    	currDate.setDate(currDate.getDate() - 29)
    	let currDay
    	let currPaymentsList
    	let sumPayments
    	while(daysAgo < 30){
	    	currDay = currDate.toISOString().split('T')[0]

	    	currPaymentsList = paymentsList.filter(payment => new Date(payment.createdAt).toISOString().split('T')[0] == currDay)
	    	sumPayments = currPaymentsList.length > 0 ? currPaymentsList.map(payment => payment.netPay).reduce(arraySum) : 0
	    	
	    	dayPaymentsList = dayPaymentsList.concat({x:currDay.split("-").slice(1,3).join("-"), y:sumPayments})

	    	daysAgo++

        	currDate.setDate(currDate.getDate() + 1);

        	if(daysAgo == 30){
        		setNumPaymentsToday(currPaymentsList.length)
        	}

    	}

    	return dayPaymentsList

    }

    function calcMonthPayments(paymentsList){

    	paymentsList = paymentsList.filter(payment => payment.status != "failure")
    	let monthPaymentsList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 11)
    	let currMonth
    	let currPaymentsList
    	let sumPayments
    	while(monthsAgo < 12){
	    	currMonth = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currPaymentsList = paymentsList.filter(payment => new Date(payment.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-") == currMonth)
	    	sumPayments = currPaymentsList.length > 0 ? currPaymentsList.map(payment => payment.netPay).reduce(arraySum) : 0
	    	
	    	monthPaymentsList = monthPaymentsList.concat({x:currMonth, y:sumPayments})

	    	monthsAgo++
        	currDate.setMonth(currDate.getMonth() + 1);

    	}

    	return monthPaymentsList

    }

    function calcQuarterPayments(paymentsList){
    	paymentsList = paymentsList.filter(payment => payment.status != "failure")

    	let quarterPaymentsList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 35)
    	let currMonth
    	let currPaymentsList
    	let sumPayments
    	let currQuarter
    	let currYear
    	let monthInt
    	let quarters = []
    	while(monthsAgo < 36){
	    	currMonth = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currYear = currDate.toISOString().split('T')[0].split('-')[0]
	    	currPaymentsList = paymentsList.filter(payment => new Date(payment.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-") == currMonth)
	    	sumPayments = currPaymentsList.length > 0 ? currPaymentsList.map(payment => payment.netPay).reduce(arraySum) : 0

	    	monthInt = currMonth.split('-')[1]
	    	if(monthInt == "01" || monthInt == "02" || monthInt == "03"){
	    		currQuarter = "Q1"
	    	}
	    	else if (monthInt == "04" || monthInt == "05" || monthInt == "06"){
	    		currQuarter = "Q2"
	    	}
	    	else if (monthInt == "07" || monthInt == "08" || monthInt == "09"){
	    		currQuarter = "Q3"
	    	}
	    	else{
	    		currQuarter = "Q4"
	    	}

	    	quarters = quarters.concat(currQuarter+"-"+currYear)
	    	quarterPaymentsList = quarterPaymentsList.concat({x:currQuarter+"-"+currYear, y:sumPayments})

	    	monthsAgo++
        	currDate.setMonth(currDate.getMonth() + 1);
    	}

    	const quarterSet = [...new Set(quarters)]
    	let newQuarterPaymentsList = []
    	for(let i = 0; i < quarterSet.length; i++){
			let quarterSumList = quarterPaymentsList.filter(quarterPayment => quarterPayment.x == quarterSet[i]).map(quarterPayment => quarterPayment.y)
			let quarterSum = quarterSumList.reduce(arraySum)
			newQuarterPaymentsList = newQuarterPaymentsList.concat({x:quarterSet[i], y: quarterSum})
		}

    	return newQuarterPaymentsList

    }

    function calcYearPayments(paymentsList){
    	paymentsList = paymentsList.filter(payment => payment.status != "failure")

    	let yearPaymentsList = []

    	let yearsAgo = 0
    	let currDate = new Date()
    	currDate.setYear(currDate.getFullYear() - 5)
    	let currYear
    	let currPaymentsList
    	let sumPayments
    	while(yearsAgo < 6){
	    	currYear = currDate.toISOString().split('T')[0].split('-')[0]
	    	currPaymentsList = paymentsList.filter(payment => new Date(payment.createdAt).toISOString().split('T')[0].split('-')[0] == currYear)
	    	sumPayments = currPaymentsList.length > 0 ? currPaymentsList.map(payment => payment.netPay).reduce(arraySum) : 0
	    	
	    	yearPaymentsList = yearPaymentsList.concat({x:currYear, y:sumPayments})

	    	yearsAgo++
        	currDate.setFullYear(currDate.getFullYear() + 1);

    	}

    	return yearPaymentsList
    }

    function calcDayMembers(memberLogsList){
    	let dayMembersList = []

    	let daysAgo = 0
    	let currDate = new Date()
    	currDate.setDate(currDate.getDate() - 29)
    	let currDay
    	let currDayAxis
    	let currMembersList
    	let newMembersList
    	let sumMembers
    	while(daysAgo < 30){
    		currDayAxis = currDate.toISOString().split('T')[0]
	    	currDay = new Date(currDate.toISOString().split('T')[0])

	    	currMembersList = memberLogsList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0]) <= currDay)
	    	
	    	currMembersList = currMembersList.map(member => {
	    		if(member.activity == "Active Member"){
	    			return {...member, memberCount: 1}
	    		}
	    		else{
	    			return {...member, memberCount: -1}
	    		}
	    	})

	    	newMembersList= currMembersList.filter(member => (new Date(new Date(member.createdAt).toISOString().split('T')[0]).getDate() == currDay.getDate()) && (new Date(new Date(member.createdAt).toISOString().split('T')[0]).getMonth() == currDay.getMonth()))


	    	sumMembers = currMembersList.length > 0 ? currMembersList.map(member => member.memberCount).reduce(arraySum) : 0
	    	
	    	dayMembersList = dayMembersList.concat({x:currDayAxis.split('-').slice(1,3).join('-'), y:sumMembers})

	    	daysAgo++

        	currDate.setDate(currDate.getDate() + 1);

        	if(daysAgo == 30){
        		let currNewMembersList = newMembersList.filter(member => member.activity == "Active Member")
        		let currChurnMembersList = newMembersList.filter(member => member.activity != "Active Member")

        		let sumNewMembers = currNewMembersList.length > 0 ? currNewMembersList.map(member => member.memberCount).reduce(arraySum) : 0
        	    let sumChurnedMembers = currChurnMembersList.length > 0 ? currChurnMembersList.map(member => member.memberCount).reduce(arraySum)*-1 : 0
        		setNewMembersToday(sumNewMembers)
        		setChurnedMembersToday(sumChurnedMembers)
        	}

    	}
    	return dayMembersList
    }

    function calcMonthMembers(memberLogsList){
    	let monthMembersList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 11)
    	let currMonth
    	let currMonthAxis
    	let currMembersList
    	let newMembersList
    	let sumMembers
    	while(monthsAgo < 12){
    		currMonthAxis = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currMonth = new Date(currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-"))
	    	currMembersList = memberLogsList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-")) <= currMonth)
	    	
	    	currMembersList = currMembersList.map(member => {
	    		if(member.activity == "Active Member"){
	    			return {...member, memberCount: 1}
	    		}
	    		else{
	    			return {...member, memberCount: -1}
	    		}
	    	})


	    	newMembersList = currMembersList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-")).getMonth() == currMonth.getMonth())

	    	sumMembers = currMembersList.length > 0 ? currMembersList.map(member => member.memberCount).reduce(arraySum) : 0
	    	
	    	monthMembersList = monthMembersList.concat({x:currMonthAxis, y:sumMembers})

	    	monthsAgo++

        	currDate.setMonth(currDate.getMonth() + 1);

        	if(monthsAgo == 12){
        		let currNewMembersList = newMembersList.filter(member => member.activity == "Active Member")
        		let currChurnMembersList = newMembersList.filter(member => member.activity != "Active Member")

        		let sumNewMembers = currNewMembersList.length > 0 ? currNewMembersList.map(member => member.memberCount).reduce(arraySum) : 0
        	    let sumChurnedMembers = currChurnMembersList.length > 0 ? currChurnMembersList.map(member => member.memberCount).reduce(arraySum)*-1 : 0
        		setNewMembersMonth(sumNewMembers)
        		setChurnedMembersMonth(sumChurnedMembers)
        	}

    	}
    	return monthMembersList
    }


    function calcQuarterMembers(memberLogsList){
    	let quarterMembersList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 35)
    	let currMonth
    	let currQuarter
    	let currYear
    	let currMonthAxis
    	let currMembersList
    	let newMembersList
    	let sumMembers
    	let monthInt
    	let quarters = {}

    	for(let i=0; i< memberLogsList.length; i++){
    		let memberCurrQuarter
    		let memberCurrYear = new Date(memberLogsList[i].createdAt).getFullYear()
    		let memberMonthInt = memberLogsList[i].createdAt.split('T')[0].split('-').slice(0, 2).join("-").split('-')[1]
		    	if(memberMonthInt == "01" || memberMonthInt == "02" || memberMonthInt == "03"){
		    		memberCurrQuarter = "Q1"
		    	}
		    	else if (memberMonthInt == "04" || memberMonthInt == "05" || memberMonthInt == "06"){
		    		memberCurrQuarter = "Q2"
		    	}
		    	else if (memberMonthInt == "07" || memberMonthInt == "08" || memberMonthInt == "09"){
		    		memberCurrQuarter = "Q3"
		    	}
		    	else{
		    		memberCurrQuarter = "Q4"
		    	}
		    	memberLogsList[i]['memberQuarter'] = memberCurrQuarter+"-"+memberCurrYear
	    	}
    	while(monthsAgo < 36){
    		currMonthAxis = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currMonth = new Date(currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-"))
	    	currYear = currDate.toISOString().split('T')[0].split('-')[0]
	    	currMembersList = memberLogsList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-")) <= currMonth)
	    	currMembersList = currMembersList.map(member => {
	    		if(member.activity == "Active Member"){
	    			return {...member, memberCount: 1}
	    		}
	    		else{
	    			return {...member, memberCount: -1}
	    		}
	    	})


	    	sumMembers = currMembersList.length > 0 ? currMembersList.map(member => member.memberCount).reduce(arraySum) : 0
	    	
	    	monthInt = currMonthAxis.split('-')[1]
	    	if(monthInt == "01" || monthInt == "02" || monthInt == "03"){
	    		currQuarter = "Q1"
	    	}
	    	else if (monthInt == "04" || monthInt == "05" || monthInt == "06"){
	    		currQuarter = "Q2"
	    	}
	    	else if (monthInt == "07" || monthInt == "08" || monthInt == "09"){
	    		currQuarter = "Q3"
	    	}
	    	else{
	    		currQuarter = "Q4"
	    	}


	    	newMembersList = currMembersList.filter(member => member.memberQuarter == currQuarter+"-"+currYear)

	    	quarters[currQuarter+"-"+currYear] = sumMembers 
	    	monthsAgo++

        	if(monthsAgo == 36){
        		let currNewMembersList = newMembersList.filter(member => member.activity == "Active Member")
        		let currChurnMembersList = newMembersList.filter(member => member.activity != "Active Member")

        		let sumNewMembers = currNewMembersList.length > 0 ? currNewMembersList.map(member => member.memberCount).reduce(arraySum) : 0
        	    let sumChurnedMembers = currChurnMembersList.length > 0 ? currChurnMembersList.map(member => member.memberCount).reduce(arraySum)*-1 : 0
        		setNewMembersQuarter(sumNewMembers)
        		setChurnedMembersQuarter(sumChurnedMembers)
        	}

        	currDate.setMonth(currDate.getMonth() + 1);

    	}
    	for (let key in quarters) {
			  quarterMembersList = quarterMembersList.concat({x: key, y: quarters[key]})
			}

    	return quarterMembersList
    }




    function calcYearMembers(memberLogsList){

    	let yearMembersList = []

    	let yearsAgo = 0
    	let currDate = new Date()
    	currDate.setFullYear(currDate.getFullYear() - 4)
    	let currYear
    	let currYearAxis
    	let currMembersList
    	let newMembersList
    	let sumMembers
    	while(yearsAgo < 5){
    		currYearAxis = currDate.toISOString().split('T')[0].split('-')[0]
	    	currYear = new Date(currDate.toISOString().split('T')[0].split('-')[0])
	    	currMembersList = memberLogsList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0].split('-')[0]) <= currYear)
	    	currMembersList = currMembersList.map(member => {
	    		if(member.activity == "Active Member"){
	    			return {...member, memberCount: 1}
	    		}
	    		else{
	    			return {...member, memberCount: -1}
	    		}
	    	})

	    	newMembersList = currMembersList.filter(member => new Date(new Date(member.createdAt).toISOString().split('T')[0].split('-')[0]).getFullYear() == currYear.getFullYear())

	    	sumMembers = currMembersList.length > 0 ? currMembersList.map(member => member.memberCount).reduce(arraySum) : 0
	    	
	    	yearMembersList = yearMembersList.concat({x:currYearAxis, y:sumMembers})

	    	yearsAgo++

	    	if(yearsAgo == 5){
	    		let currNewMembersList = newMembersList.filter(member => member.activity == "Active Member")
        		let currChurnMembersList = newMembersList.filter(member => member.activity != "Active Member")

        		let sumNewMembers = currNewMembersList.length > 0 ? currNewMembersList.map(member => member.memberCount).reduce(arraySum) : 0
        	    let sumChurnedMembers = currChurnMembersList.length > 0 ? currChurnMembersList.map(member => member.memberCount).reduce(arraySum)*-1 : 0
        		setNewMembersYear(sumNewMembers)
        		setChurnedMembersYear(sumChurnedMembers)
	    	}

        	currDate.setFullYear(currDate.getFullYear() + 1);

    	}
    	return yearMembersList
    }

	const fetchPayments = useCallback(async () => {

		//in the dashboard component, fetchPayments is used to query all patient payments for an office so that they can be aggregated into appropriate day, month, quarter and year groups
		//values defined by these groupings are prepared to be passed to the Dashboard's line chart components

		//query all patient payments for the office
        const memberPaymentsData = await API.graphql(graphqlOperation(`query ListPayments {
		    listPaymentss(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999){
		    	items{
		          id
		          status
		          memberId
		          memberName
		          description
		          grossPay
		          fee
		          netPay
		          practice
		          createdAt
			    }
			}
		}`))
	
        const userPaymentsData = memberPaymentsData.data.listPaymentss.items

	    let newPaymentsList = []
	    let rowCount = 1
	    let payDate
	    let payDate2
	    //formatting the payment date based on the office's timezone
	    for(let i=0; i<userPaymentsData.length; i++){
	    	payDate = new Date(userPaymentsData[i]['createdAt'])
	    	payDate = payDate.setHours(payDate.getHours() - timeZoneOffset)
	    	payDate = new Date(payDate)

			const newPayment = {...userPaymentsData[i], rowId: rowCount, createdAt: payDate.toISOString(), timestamp: payDate.getTime()}
			newPaymentsList.push(newPayment)
			rowCount = rowCount + 1
		}

		const newPaymentsList2 = newPaymentsList.sort(makeComparator('timestamp'))

		//the payments state variable is the source of truth for all payments on the page
        setPayments(newPaymentsList)
        //filteredPayments is originally the same as payments but this can be changed based on filtering data on the dashboard, so this state is used primarily for display purposes
        setFilteredPayments(newPaymentsList)

        //payments aggregated by desired periods
        
        const dayPaymentsCalc = calcDayPayments(newPaymentsList)
        setDayPayments(dayPaymentsCalc)

        const monthPaymentsCalc = calcMonthPayments(newPaymentsList)
        setMonthPayments(monthPaymentsCalc)

        const quarterPaymentsCalc = calcQuarterPayments(newPaymentsList)
        setQuarterPayments(quarterPaymentsCalc)

        const yearPaymentsCalc = calcYearPayments(newPaymentsList)
        setYearPayments(yearPaymentsCalc)
	}, [auth.officeId, auth.user, timeZoneOffset])

    function calcDayBenefits(benefitsList){

    	//an archived component intended to show the total number of benefits being used by an office's patients per day

    	let dayBenefitsList = []

    	let daysAgo = 0
    	let currDate = new Date()
    	currDate.setDate(currDate.getDate() - 29)
    	let currDay
    	let currBenefitsList
    	let sumBenefits
    	while(daysAgo < 30){
	    	currDay = currDate.toISOString().split('T')[0]

	    	currBenefitsList = benefitsList.filter(benefit => new Date(benefit.createdAt).toISOString().split('T')[0] == currDay)
	    	sumBenefits = currBenefitsList.length > 0 ? currBenefitsList.length : 0
	    	
	    	dayBenefitsList = dayBenefitsList.concat({x:currDay.split("-").slice(1,3).join("-"), y:sumBenefits})

	    	daysAgo++

        	currDate.setDate(currDate.getDate() + 1);

        	if(daysAgo == 30){
        		setNumBenefitsToday(currBenefitsList.length)
        	}

    	}

    	return dayBenefitsList

    }

    function calcMonthBenefits(benefitsList){


    	//an archived component intended to show the total number of benefits being used by an office's patients per month

    	let monthBenefitsList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 11)
    	let currMonth
    	let currBenefitsList
    	let sumBenefits
    	while(monthsAgo < 12){
	    	currMonth = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currBenefitsList = benefitsList.filter(benefit => new Date(benefit.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-") == currMonth)
	    	sumBenefits = currBenefitsList.length > 0 ? currBenefitsList.length : 0
	    	
	    	monthBenefitsList = monthBenefitsList.concat({x:currMonth, y:sumBenefits})

	    	monthsAgo++
        	currDate.setMonth(currDate.getMonth() + 1);

    	}

    	return monthBenefitsList

    }

    function calcQuarterBenefits(benefitsList){

    	//an archived component intended to show the total number of benefits being used by an office's patients per quarter

    	let quarterBenefitsList = []

    	let monthsAgo = 0
    	let currDate = new Date()
    	currDate.setMonth(currDate.getMonth() - 35)
    	let currMonth
    	let currBenefitsList
    	let sumBenefits
    	let currQuarter
    	let currYear
    	let monthInt
    	let quarters = []
    	while(monthsAgo < 36){
	    	currMonth = currDate.toISOString().split('T')[0].split('-').slice(0, 2).join("-")
	    	currYear = currDate.toISOString().split('T')[0].split('-')[0]
	    	currBenefitsList = benefitsList.filter(benefit => new Date(benefit.createdAt).toISOString().split('T')[0].split('-').slice(0, 2).join("-") == currMonth)
	    	sumBenefits = currBenefitsList.length > 0 ? currBenefitsList.length : 0

	    	monthInt = currMonth.split('-')[1]
	    	if(monthInt == "01" || monthInt == "02" || monthInt == "03"){
	    		currQuarter = "Q1"
	    	}
	    	else if (monthInt == "04" || monthInt == "05" || monthInt == "06"){
	    		currQuarter = "Q2"
	    	}
	    	else if (monthInt == "07" || monthInt == "08" || monthInt == "09"){
	    		currQuarter = "Q3"
	    	}
	    	else{
	    		currQuarter = "Q4"
	    	}

	    	quarters = quarters.concat(currQuarter+"-"+currYear)
	    	quarterBenefitsList = quarterBenefitsList.concat({x:currQuarter+"-"+currYear, y:sumBenefits})

	    	monthsAgo++
        	currDate.setMonth(currDate.getMonth() + 1);
    	}

    	const quarterSet = [...new Set(quarters)]
    	let newQuarterBenefitsList = []
    	for(let i = 0; i < quarterSet.length; i++){
			let quarterSumList = quarterBenefitsList.filter(quarterBenefit => quarterBenefit.x == quarterSet[i]).map(quarterBenefit => quarterBenefit.y)
			let quarterSum = quarterSumList.reduce(arraySum)
			newQuarterBenefitsList = newQuarterBenefitsList.concat({x:quarterSet[i], y: quarterSum})
		}

    	return newQuarterBenefitsList

    }

    function calcYearBenefits(benefitsList){

    	//an archived component intended to show the total number of benefits being used by an office's patients per year

    	let yearBenefitsList = []

    	let yearsAgo = 0
    	let currDate = new Date()
    	currDate.setYear(currDate.getFullYear() - 5)
    	let currYear
    	let currBenefitsList
    	let sumBenefits
    	while(yearsAgo < 6){
	    	currYear = currDate.toISOString().split('T')[0].split('-')[0]
	    	currBenefitsList = benefitsList.filter(benefit => new Date(benefit.createdAt).toISOString().split('T')[0].split('-')[0] == currYear)
	    	sumBenefits = currBenefitsList.length > 0 ? currBenefitsList.length : 0
	    	
	    	yearBenefitsList = yearBenefitsList.concat({x:currYear, y:sumBenefits})

	    	yearsAgo++
        	currDate.setFullYear(currDate.getFullYear() + 1);

    	}

    	return yearBenefitsList
    }

	const fetchRecurringBenefits = useCallback(async () => {

		//another archived component - we don't actually track recurring benefits of any kind for offices
        const recurringBenefitsLogData = await API.graphql(graphqlOperation(`query ListMemberBenefitsLogs {
		    listMemberBenefitsLogs(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999){
		    	items{
		          id
		          memberId
		          benefit
		          createdAt
			    }
			}
		}`))
        const userBenefitsData = recurringBenefitsLogData.data.listMemberBenefitsLogs.items

	    let newBenefitsList = []
	    let rowCount = 1
	    let benDate
	    for(let i=0; i<userBenefitsData.length; i++){

	    	benDate = new Date(userBenefitsData[i]['createdAt'])
	    	benDate = benDate.setHours(benDate.getHours() - timeZoneOffset)
	    	benDate = new Date(benDate)	

			const newBenefit = {...userBenefitsData[i], rowId: rowCount, createdAt: benDate.toISOString(), timestamp: new Date(userBenefitsData[i]['createdAt']).getTime()}
			newBenefitsList.push(newBenefit)
			rowCount = rowCount + 1
		}

        setBenefitsLog(userBenefitsData)

        const dayBenefitsCalc = calcDayBenefits(newBenefitsList)
        setDayBenefits(dayBenefitsCalc)	

        const monthBenefitsCalc = calcMonthBenefits(newBenefitsList)
        setMonthBenefits(monthBenefitsCalc)


        const quarterBenefitsCalc = calcQuarterBenefits(newBenefitsList)
        setQuarterBenefits(quarterBenefitsCalc)

        const yearBenefitsCalc = calcYearBenefits(newBenefitsList)
        setYearBenefits(yearBenefitsCalc)
	}, [auth.officeId, auth.user, timeZoneOffset])

    const fetchMemberLogs = useCallback(async () => {
	
		//in the dashboard component, fetchMemberLogs is used to query all member activations/deactivations for an office so that they can be aggregated into appropriate day, month, quarter and year groups
		//values defined by these groupings are prepared to be passed to the Dashboard's line chart components

	  //query all member logs for the office
      const memberLogsData = await API.graphql(graphqlOperation(`query ListMemberLogs {
        listMemberLogs(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              memberId
              memberName
              practice
              activity
              createdAt
          }
        }
        }`))

      let memberLogsList = memberLogsData.data.listMemberLogs.items

	    let newMemberLogsList = []
	    let rowCount = 1
	    let memberDate
	    //format log date based on office's time zone
	    for(let i=0; i<memberLogsList.length; i++){
	    	memberDate = new Date(memberLogsList[i]['createdAt'])
	    	memberDate = memberDate.setHours(memberDate.getHours() - timeZoneOffset)
	    	memberDate = new Date(memberDate)

			const newPayment = {...memberLogsList[i], createdAt: memberDate.toISOString()}
			newMemberLogsList.push(newPayment)
			rowCount = rowCount + 1
		}

	  //aggregate member activations/deactivations for appropriate time ranges
      const dailyMembersCalc =  calcDayMembers(newMemberLogsList)
      setDayMembers(dailyMembersCalc)

      const monthlyMembersCalc =  calcMonthMembers(newMemberLogsList)
      setMonthMembers(monthlyMembersCalc)

      const quarterMembersCalc =  calcQuarterMembers(newMemberLogsList)
      setQuarterMembers(quarterMembersCalc)

      const yearMembersCalc =  calcYearMembers(newMemberLogsList)
      setYearMembers(yearMembersCalc)

      setMemberLogs(newMemberLogsList)
      setFilteredMemberLogs(newMemberLogsList)
    }, [auth.officeId, auth.user, timeZoneOffset])

    //format description of payment event for the live stream component and append these to livestream activies list
    let activities = []
    for(let i=0; i < payments.length; i ++){
    	let currentPayment = payments[i]
    	if(currentPayment['status'] == 'failure'){
    		currentPayment['activity'] = 'Payment - Failed'
    	}
    	else{
    		currentPayment['activity'] = 'Payment'
    	}
    	activities = activities.concat(currentPayment)
    }

    // format description of memberlog event for the livestream component and append these to livestream activities list
    for(let i = 0; i < memberLogs.length; i ++){
    	let currentMemberLog = memberLogs[i]
    	currentMemberLog['timestamp'] = new Date(currentMemberLog['createdAt']).getTime()
    	activities = activities.concat(memberLogs[i])
    }


    //show most recent livestream activities first
    activities = activities.sort(makeComparator('timestamp'))

    //creates the livestream JSX component
	const streamPayments = activities.map(stream => {
		if(practice != "All"){
			if(practice == stream.practice){
				let streamText
				let streamIcon
				if(stream.activity == "Payment"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/payments"><span className="stream-h stream-pc">Payment</span>Member <strong>{stream.memberName}</strong> was billed</Link></div> 
					streamIcon = <CheckIcon className="stream-icon-pc" />
				}
				else if(stream.activity == "Payment - Failed"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/payments"><span className="stream-h stream-pc">Payment</span>Member <strong>{stream.memberName}</strong> payment was declined</Link></div>
					streamIcon = <CloseIcon className="stream-icon-md" />
				}
				else if (stream.activity == "Active Member"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/members"><span className="stream-h stream-pc">New Member</span>Member <strong>{stream.memberName}</strong> was activated</Link></div>
					streamIcon = <CheckIcon className="stream-icon-pc" />
				}
				else{
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/members"><span className="stream-h stream-pc">Churned Member</span>Member <strong>{stream.memberName}</strong> was deactivated</Link></div>
					streamIcon = <CloseIcon className="stream-icon-md" />
				}
				
				const currTime = new Date().getTime()

				const streamTime = new Date(stream.createdAt).getTime() - timeZoneOffset*1000*60*60

				const streamMinutes = Math.round((currTime - streamTime)/(1000*60))

				return(
					<div key={stream.id} className="w-full flex border-b border-gray-200">
						<div className="">{streamIcon}</div>
						<div className="my-auto p-4 pr-0 stream-desc">{streamText}<span className="stream-timestamp">{streamMinutes < 60 ? streamMinutes + " minutes ago" : streamMinutes < 1440 ? Math.round(streamMinutes/60) + " hours ago" : Math.round(streamMinutes/1440) + " days ago"}</span></div>
					</div>
					)
			}
			return undefined
		}
		else{
				let streamText
				let streamIcon
				if(stream.activity == "Payment"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/payments"><span className="stream-h stream-pc">Payment</span>Member <strong>{stream.memberName}</strong> was billed</Link></div> 
					streamIcon = <CheckIcon className="stream-icon-pc" />
				}
				else if(stream.activity == "Payment - Failed"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/payments"><span className="stream-h stream-pc">Payment</span>Member <strong>{stream.memberName}</strong> payment was declined</Link></div>
					streamIcon = <CloseIcon className="stream-icon-md" />
				}
				else if (stream.activity == "Active Member"){
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/members"><span className="stream-h stream-pc">New Member</span>Member <strong>{stream.memberName}</strong> was activated</Link></div>
					streamIcon = <CheckIcon className="stream-icon-pc" />
				}
				else{
					streamText = <div className="hover:text-blue-500 hover:underline"><Link to="/members"><span className="stream-h stream-pc">Churned Member</span>Member <strong>{stream.memberName}</strong> was deactivated</Link></div>
					streamIcon = <CloseIcon className="stream-icon-md" />
				}
				
				const currTime = new Date().getTime() - timeZoneOffset*1000*60*60

				const streamTime = new Date(stream.createdAt).getTime()

				const streamMinutes = Math.round((currTime - streamTime)/(1000*60))

				return(
					<div key={stream.id} className="w-full flex border-b border-gray-200">
						<div className="">{streamIcon}</div>
						<div className="my-auto p-4 pr-0 stream-desc">{streamText}<span className="stream-timestamp">{streamMinutes < 60 ? streamMinutes + " minutes ago" : streamMinutes < 1440 ? Math.round(streamMinutes/60) + " hours ago" : Math.round(streamMinutes/1440) + " days ago"}</span></div>
					</div>
					)
			}
	})

	//pretty sure this is an archived component, and that streamPayments is the only variable returning the live stream component
	const streamList = streams.map(stream => {


		let streamText
		let streamIcon
		if (stream.activity == "Member - Created"){
			streamText = <div><span className="stream-h stream-mc">New Member</span>Member <strong>{stream.name}</strong> created</div>
			streamIcon = <AddIcon className="stream-icon-mc" />
		}
		else if(stream.activity == "Payment - Complete"){
			streamText = <div><span className="stream-h stream-pc">Payment</span>Member <strong>{stream.name}</strong> was billed</div>
			streamIcon = <CheckIcon className="stream-icon-pc" />
		}
		else{
			streamText = <div><span className="stream-h stream-md">Failed Payment</span>Member <strong>{stream.name}</strong> was declined</div>
			streamIcon = <CloseIcon className="stream-icon-md" />

		}

		return(
			<div className="w-full flex border-b border-gray-200">
				<div className="">{streamIcon}</div>
				<div className="my-auto p-4 pr-0 stream-desc">{streamText}<span className="stream-timestamp">5 minutes ago</span></div>
			</div>
			)
	})


	//creates list of options for practice filter component
    const selectPractice = practices.map(practice => <option key={practice.id} value={practice.name}>{practice.name}</option>)
    const selectPractice2 = practices.map(practice => ({key:practice.id, value: practice.name, label: practice.name}))
    const selectPractice3 = selectPractice2.unshift({key:"all", label:"All Practices", value:"all"})

//archived style parameters
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 6,
    textTransform: 'uppercase',
    fontSize: 11,
    letterSpacing: 1.2
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex'
  }),
	container: (base) => ({
	    ...base,
	    flex: 1
	  })
	,
    singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided,
    		 opacity,
    		  transition,
    		   textTransform: 'uppercase',
    		   fontSize: 11,
    			letterSpacing: 1.2,
    			display: 'inline'
    		    };
  }
}
   //popup alert for accounts whose email is not yet verified
   const confirmVerified = (event) => {

   	confirmAlert({
	  customUI: ({ onClose }) => {
	    return (
	      <div className="bg-white rounded-lg p-6 text-gray-700">
	        <h1>Warning: Email not yet verified</h1>
	        <br></br>
	        <p>Verifications are important for things like password resets.</p>
	        <br></br>
	        <p>How to verify:</p>
	        <p>1) Check email (address listed in account settings)</p>
	    	<p>2) Enter the code sent to your email (may need to check spam/junk)</p>
	        <br></br>
	        <button className="text-white bg-blue-500 px-4 py-2 font-semibold rounded-lg" onClick={onClose}>Ok</button>
	      </div>
	    )
	  }
	})

    }

    //archived duplicate of the email verification function in settings
    const handleVerifyEmail = () =>{
	    Auth.verifyCurrentUserAttribute('email')
	    .then(() => {
	         console.log('a verification code is sent');
	    }).catch((e) => {
	         console.log('failed with error', e);
	    });  
    } 

	useEffect(() => {
		//fetch all practices for the office (used to filter livestream and dashboards)
		fetchPractices()
		//fetch all payments for the office
		fetchPayments()
		//fetch all memberlogs for the office
		fetchMemberLogs()
		//this is being called, but we don't track recurring benefits for patients currently, so effectively archived
		fetchRecurringBenefits()

		if((auth.officeId || auth.user != undefined) && props.officeFetched){
			//loading variable was an attempt to keep any accountType JSX from rendering until an auth.officeId || auth.user value was appropriately populated
			setIsLoading(false)
		}
	}, [auth, fetchMemberLogs, fetchPayments, fetchPractices, fetchRecurringBenefits, props.officeFetched])

	return(
		<div className="w-full h-full"> 
			
				<div className="w-full bg-white bs-b">
					{props.officeIsVerified != true ? <Link to={{pathname:"/settings", state: {verifying: true}}} className="text-red-500 underline px-6">Email not verified-- click to send verification code.</Link> : null}
					{props.officeMultiplePractices ? 
					<form className="w-1/3">
		            	<SearchBar options={selectPractice2} onChange={handlePracticeChange} />
		            </form>
		            : null }
		        </div>
	        <div className="w-full h-full flex justify-around">
				<div className="w-2/3 h-full flex flex-col items-center overflow-auto pb-32 px-6 scrollbar-custom">
						<div className="text-white w-full my-6 mx-2 p-4 w-1/2 bg-blue-200 rounded-lg border border-blue-700 card-highlight">
							<div className="highlight-h font-semibold text-xl text-blue-700">Today<span className="pl-2" style={{opacity: "0.8"}}>{new Date().toString().split(" ").slice(1, 3).join(" ")}</span></div>
							<div className="w-full flex justify-between">
								<div className="flex flex-col py-2 border-blue-500 w-1/4">
									<div className="text-2xl text-blue-500 font-bold pt-1 highlight-figs">${dayPayments.length > 0 ? dayPayments[dayPayments.length-1]['y'].toFixed(2) : null}</div>
									<div className="text-blue-700 text-lg font-semibold highlight-desc">Gross Volume</div>
								</div>
								<div className="flex flex-col py-2 border-blue-500 w-1/4">
									<div className="highlight-figs text-2xl text-blue-500 font-bold pt-1">{numPaymentsToday}</div>
									<div className="highlight-desc text-blue-700 text-lg font-semibold">Payments</div>
								</div>
								<div className="flex flex-col py-2 w-1/4">
									<div className="highlight-figs text-2xl text-blue-500 font-bold pt-1">{newMembersToday}</div>
									<div className="highlight-desc text-blue-700 text-lg font-semibold">New Members</div>
								</div>
								<div className="flex flex-col py-2 w-1/4">
									<div className="highlight-figs text-2xl text-blue-500 font-bold pt-1">${monthPayments.length > 0 ? monthPayments[monthPayments.length-1]['y'].toFixed(2) : null}</div>
									<div className="highlight-desc text-blue-700 text-lg font-semibold">Revenue MTD</div>
								</div>
	            			</div>
						</div>
						<div className="w-full flex mt-3">
							<button onClick={() => setGraphInterval("Day")} className={interval == "Day" ? "interval-active" : "interval"}>Day</button>
							<button onClick={() => setGraphInterval("Month")} className={interval == "Month" ? "interval-active" : "interval"}>Month</button>
							<button onClick={() => setGraphInterval("Quarter")} className={interval == "Quarter" ? "interval-active" : "interval"}>Quarter</button>
							<button onClick={() => setGraphInterval("Year")} className={interval == "Year" ? "interval-active" : "interval"}>Year</button>
						</div>
						<div className="w-full p-4 w-1/2 bg-white rounded-lg border card">
							<div className="font-semibold card-h">Gross Revenue <span className="small-interval">by {interval}</span></div>
							<div className="card-figs"> ${dayPayments.length > 0 ? interval == "Day" ? dayPayments[dayPayments.length-1]['y'].toFixed(2) : interval == "Month" ? monthPayments[monthPayments.length-1]['y'].toFixed(2) : interval == "Quarter" ? quarterPayments[quarterPayments.length-1]['y'].toFixed(2) : quarterPayments[quarterPayments.length-1]['y'].toFixed(2) : null}<span className="card-desc">{interval == "Day" ? "Today" : interval[0] +"TD"}</span></div>
							<div className="w-full">
							<DashboardChart showNav={props.showNav} coordinates={interval == "Day" ? dayPayments : interval == "Month" ? monthPayments : interval == "Quarter" ? quarterPayments : yearPayments} unit="$"/> 
	            			</div>
						</div>
						<div className="w-full mt-8 mx-2 p-4 w-1/2 card">
							<div className="card-h">Total Members By {interval}</div>
							<div className="card-figs"> {interval == "Day" ? dayMembers[dayMembers.length-1]["y"] : interval == "Month" ? monthMembers[monthMembers.length-1]["y"] : quarterMembers[quarterMembers.length-1]["y"]}</div>
							<DashboardChart showNav={props.showNav} coordinates={interval == "Day" ? dayMembers : interval == "Month" ? monthMembers : interval == "Quarter" ? quarterMembers : yearMembers} unit=""/>
						</div>
						<div className="w-full flex justify-between">
							<div className="mt-8 w-1/2 card mr-2">
								<div className="card-h">New Members <span className="card-desc">{interval == "Day" ? "Today" : interval[0] +"TD"}</span></div>
								<div className="card-figs">{interval == "Day" ? newMembersToday : interval == "Month" ? newMembersMonth : interval == "Quarter" ? newMembersQuarter : newMembersYear}</div>
							</div>
							<div className="mt-8 w-1/2 card ml-2">
								<div className="card-h">Churned Members <span className="card-desc">{interval == "Day" ? "Today" : interval[0] +"TD"}</span></div>
								<div className="card-figs">{interval == "Day" ? churnedMembersToday : interval == "Month" ? churnedMembersMonth : interval == "Quarter" ? churnedMembersQuarter : churnedMembersYear}</div>
							</div>
						</div>

						<div className="w-full flex justify-between">
							<div className="mt-8 w-1/2 card mr-2">
								<div className="card-h">Membership Growth <span className="card-desc">{interval == "Day" ? "Today" : interval[0] +"TD"}</span></div>
								<div className="card-figs">{interval == "Day" ? dayMembers[dayMembers.length-2]["y"] > 0 ? Math.round(newMembersToday*100/dayMembers[dayMembers.length-2]["y"]) + "%" : "N/A" :
								  interval == "Month" ? monthMembers[monthMembers.length-2]["y"] > 0 ? Math.round(newMembersMonth*100/monthMembers[monthMembers.length-2]["y"]) + "%" : "N/A" :
								   interval == "Quarter" ? quarterMembers[quarterMembers.length-2]["y"] > 0 ? Math.round(newMembersQuarter*100/quarterMembers[quarterMembers.length-2]["y"]) + "%" : "N/A" :
								    yearMembers[yearMembers.length-2]["y"] > 0 ? Math.round(newMembersYear*100/yearMembers[yearMembers.length-2]["y"]) + "%" : "N/A"}</div>
							</div>
							<div className="mt-8 w-1/2 card ml-2">
								<div className="card-h">Membership Churn <span className="card-desc">{interval == "Day" ? "Today" : interval[0] +"TD"}</span></div>
								<div className="card-figs">{interval == "Day" ? dayMembers[dayMembers.length-2]["y"] > 0 ? Math.round(churnedMembersToday*100/dayMembers[dayMembers.length-2]["y"]) + "%" : "N/A" :
								 interval == "Month" ? monthMembers[monthMembers.length-2]["y"] > 0 ? Math.round(churnedMembersMonth*100/monthMembers[monthMembers.length-2]["y"]) + "%" : "N/A" :
								  interval == "Quarter" ? quarterMembers[quarterMembers.length-2]["y"] > 0 ? Math.round(churnedMembersQuarter*100/quarterMembers[quarterMembers.length-2]["y"]) + "%" : "N/A" :
								   yearMembers[yearMembers.length-2]["y"] > 0 ? Math.round(churnedMembersYear*100/yearMembers[yearMembers.length-2]["y"]) + "%" : "N/A"}</div>
							</div>
						</div>
						<div className="w-full mt-8 mx-2 p-4 w-1/2 card hidden">
							<div className="card-h">Total Benefits Usage By {interval}</div>
							<div className="card-figs"> {dayBenefits.length > 0 ? interval == "Day" ? dayBenefits[dayBenefits.length-1]["y"] : interval == "Month" ? monthBenefits[monthBenefits.length-1]["y"] : quarterBenefits[quarterBenefits.length-1]["y"] : null}</div>
							<DashboardChart showNav={props.showNav} coordinates={dayBenefits.length > 0 ? interval == "Day" ? dayBenefits : interval == "Month" ? monthBenefits : interval == "Quarter" ? quarterBenefits : yearBenefits : null} unit=""/>
						</div>
				</div>
				<div className="flex flex-col w-1/3 mx-6">
					<div className="mt-6 h-full w-full card overflow-auto scrollbar-custom">
						<div className="card-h">Live Stream</div>
						{streamPayments.slice(indexOfFirstPost, indexOfLastPost)}
						<div className=" pt-4 mb-6 w-full flex justify-around">
							<button disabled={currentPage <= 1} className="text-blue-500 font-semibold" onClick={handleStreamPageDecrease}>Previous</button>
							<button disabled={currentPage >= Math.ceil(streamPayments.length/streamsPerPage)} className="text-blue-500 font-semibold" onClick={handleStreamPageIncrease}>Next</button>	
						</div>				
					</div>
				</div>
			</div>
		</div>
		)
}

