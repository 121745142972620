import React, { useState, useEffect, useContext }  from "react";

const SelectCreditCardExpiration = (props) => {
	return(
		<div className="flex my-4 w-full">
			<p className="my-auto pr-4 w-1/3 text-right">Expiration:</p>
			<div className="w-1/2 flex">
				<select onClick={props.onClick} disabled={props.disabled} className="border w-1/2 mr-2" onChange={props.onMonthChange} value={props.monthValue} name={props.monthName} id={props.monthName}>
				    <option value=''>Month</option>
				    <option value='01'>01 - January</option>
				    <option value='02'>02 - February</option>
				    <option value='03'>03 - March</option>
				    <option value='04'>04 - April</option>
				    <option value='05'>05 - May</option>
				    <option value='06'>06 - June</option>
				    <option value='07'>07 - July</option>
				    <option value='08'>08 - August</option>
				    <option value='09'>09 - September</option>
				    <option value='10'>10 - October</option>
				    <option value='11'>11 - November</option>
				    <option value='12'>12 - December</option>
				</select> 
				<select disabled={props.disabled} className="border w-1/2" onChange={props.onYearChange} value={props.yearValue} name={props.yearName} id={props.yearName}>
				    <option value=''>Year</option>
				    <option value='2020'>2020</option>
				    <option value='2021'>2021</option>
				    <option value='2022'>2022</option>
				    <option value='2023'>2023</option>
				    <option value='2024'>2024</option>
				    <option value='2025'>2025</option>
				    <option value='2026'>2026</option>
				    <option value='2027'>2027</option>
				</select>
			</div>
		</div>
	)
}

export default SelectCreditCardExpiration	