import API, { graphqlOperation } from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";






export const signUp = async (username, password, patientName, dependent = false) => {
	let user
	try {

		//queries members with contactEmail = the email the patient has provided on signup.  This prevents patients with duplicate emails from being created
		const membersData = await API.graphql(graphqlOperation(`
        query ListMembers {
            listMembers(
                filter: {
                    contactEmail: {eq:"${username}"}
                }, 
                limit: 9999
            ) {
                items {
                    id
                    contactEmail
                }
            }
        }
        `))

		const membersList = membersData.data.listMembers.items

		//if no other members have the same email, sign the user up
		if (membersList.length == 0) {
			//user creation in cognito

			
			/// TODO: If we get a "UsernameExistsException" then we should go ahead and setup the account
			user = await Auth.signUp({
				username,
				password,
			})
			.catch(ex => {

				// TODO: Figure out how to get the right user and then return their userSub here
				if (ex.code === "UsernameExistsException") {
					console.log(`This is harmless`)
				}

				throw ex
			})
			//creates an account record for the user in dynamodb
			const createAccount = await API.graphql(graphqlOperation(
				`mutation create {
	            createAccount(input: {id: "${user.userSub}", email: "${username}", accountType: "customer"}){
	              id
				  email
	              accountType
	            }
	          }`))

		}

	} catch (error) {
		console.log('error signing up:', error);
	}

	if (user != undefined) {
		return {
			username,
			id: user.userSub
		}
	}
	else if (dependent) {
		/// Dependent already exists
		alert('Dependent already exists')
	}
	else if (window.confirm(`User ${username} already exists. Do you want to add ${patientName} as a dependent?`)) {
		const dependentUsername = `${patientName.toLowerCase().replace(/ /, '_')}+${username}`
		return signUp(dependentUsername, password, patientName, true)
	}
	else {
		throw new Error('Cancelled')
	}
	// else {
	// 	alert("An account with the given email already exists.")
	// }

}