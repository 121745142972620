import React, { useContext, useCallback, useMemo }  from "react";
import { Link } from "react-router-dom";
import {UserContext} from './UserContext'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 
import { CSVLink } from 'react-csv';

import Table from './components/Table'

import {useListQL} from './hooks/useListQL'

import {practiceSchema} from './schemas/schemas'


export default function Practices(props){
	
	const auth = useContext(UserContext)

	//list query and any record deletions are handled by the useListQL hook
	const {stateDict, handleDelete} = useListQL(
		practiceSchema, 
		useMemo(() => ({name: "office", value: auth.officeId || auth.user}), [auth.officeId, auth.user]))

	//users will be prompted to confirm any attempt to delete a practice
	  const confirmDelete = (practiceId) => {
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: 'Are you sure you want to delete?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleDelete(practiceId)}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }
	
	//prepares the list of practices for csv export functionality
	const listPracticesCsv = stateDict.map(practice => {
		return([practice.name, practice.city, practice.zip])
	})

	return(
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">All Practices</h1></div>
					<div className="w-1/2 rounded-sm text-right justify-around">
						<Link to="/createpractice" className="gradient-btn mt-4 mx-2">Add Practice</Link>
						<CSVLink className="gradient-btn mt-4 mx-2" filename={"practices.csv"} data={listPracticesCsv} >Export</CSVLink>
					</div>
				</div>

				<Table edit={true} pathPrefix={'practice'} columns={['Practice', 'City', 'Zip']}
					fields={['name', 'city', 'zip']}
					values={stateDict} delete={true} handleDelete={confirmDelete}/>

			</div>
		</div>

		)
}
