import React, { useEffect, useState } from "react";
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { Link } from "react-router-dom";

import Input from './components/Input'
import './Login.css'
import Tilt from 'react-tilt'
import logo from './imgs/QDP-Brand-Logo.png'


function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forcePassword, setForcePassword] = useState(false) //controls whether sign-in form or password change form is displayed

    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")

    const [cognitoUser, setCognitoUser] = useState({})


    async function handleSubmit(event) {
        event.preventDefault();
        try {
            //authenticate user with cognito
            const user = await Auth.signIn(email, password)
            setCognitoUser(user)

            if (user.attributes['custom:tmp_pass'] == password) {
                console.log(`NEEDS NEW PASSWORD`)
                props.setUserNeedsNewPassword(true)
                setForcePassword(true)
                return
            }
            else if (user.attributes['custom:tmp_pass']) {
                // TODO: Kinda gross, but if we have tmp_pass setting,
                //       we need to delete it and then reload to ensure a fresh cache
                await new Promise((resolve, reject) => {
                    user.deleteAttributes(['custom:tmp_pass'], (err) => {
                        console.log(`New User: `, user)
                        setCognitoUser(user)
                        if (err) { reject(err) }
                        else { resolve() }
                    })
                })
                window.location.reload()
            }

            //when user's log in for the first time, they are forced to create a new account password
            if (user.challengeName != null) {
                if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
                    props.setUserNeedsNewPassword(true)
                    setForcePassword(true)
                }
            }
            else {
                //once user is authenticated (with no need for new password), route user to home page
                props.userHasAuthenticated(true);
                props.history.push("/");
            }
        } catch (e) {

            const showAlertText = () => alert(e.message)
            console.log(e)
            // /// If we have an invalid login error, then lets' use what we had
            // if (e.code === 'NotAuthorizedException' && e.message && e.message.toLowerCase().includes('incorrect username')) {
            //     console.log('JUST AN INVALID LOGIN')
            //     // return showAlertText()
            // }

            /// If failure is because of `UserNotFound`, then lets' 
            /// see if we can find a pending account for this email
            try {
                
                const officesData = await API.graphql(graphqlOperation(`
                    query MyQuery($email: String!) {
                        listPendingOffices(filter: {
                            email: {contains: $email},
                            status: { ne: "approved" }
                        
                        }, limit: 99999) {
                            items { email, status }
                        }
                    }
                `, { email })
                )
                
                const officeItem = officesData.data?.listPendingOffices.items[0]
                if (officeItem ) {
                    console.log(`OFFICE IS PENDING `)
                    return alert('It looks like your account has not been approved yet.  Please make sure that you have completed the Usio Merchant application (email titled "Your Click To Agree Form is ready!" from no-reply@qualitydentalplan.com).')
                }

            } catch (ex2) {

            }

            showAlertText()

        }
    }

    const handleForcePasswordChange = async (event) => {
        event.preventDefault();
        if (newPassword == confirmNewPassword) {
            /// If we are doing password based on custom:tmp_pass
            /// then let's handle that separately
            if (cognitoUser.attributes['custom:tmp_pass'] == password) {
                Auth.changePassword(cognitoUser, password, newPassword)
                .then(result => {
                    return new Promise((resolve, reject) => {
                        cognitoUser.deleteAttributes(['custom:tmp_pass'], (err) => {
                            console.log(`New User: `, cognitoUser)
                            delete cognitoUser.attributes['custom:tmp_pass']
                            
                            if (err) { reject(err) }
                            else { resolve(cognitoUser) }
                        })
                    })
                })
                .then(user => {
                    window.location.reload()
                })
                .catch(ex => {
                    console.error(ex)
                    alert(`Error resetting pass: ${ex.message}`)
                })

                return
            }

            Auth.completeNewPassword(
                cognitoUser,               // the Cognito User Object
                newPassword,       // the new password
            ).then(user => {
                props.userHasAuthenticated(true);
                props.history.push("/");
                // at this time the user is logged in if no MFA required
                console.log(user);
            }).catch(e => {
                console.log(e);
            })
        }
        else {
            alert("Passwords do not match")
        }
    }



    return (
        <div id='login'>
            <div className="container flex mx-auto md:justify-between justify-center flex-row">
                <Tilt className="login100-pic js-tilt md:block hidden" data-tilt="">
                    <img alt='QDP Logo' src={logo} />
                </Tilt>
                {forcePassword ?
                    <form className="card ">
                        <h1 className="text-xl">It looks like this is your first time loggin in! Please create a new password to continue.</h1>
                        <div className="flex">
                            <div className="w-full md:w-1/2 flex flex-col">
                                <div className="flex flex-col md:flex-row my-4 w-full">
                                    <p className="my-auto pr-4 w-1/3 md:text-right">New Password:</p>
                                    <input onChange={e => setNewPassword(e.target.value)} value={newPassword} type="password" name="newPassword" className="w-full border md:w-2/3" /><br />
                                </div>
                                <div className="flex flex-col md:flex-row my-4 w-full">
                                    <p className="my-auto pr-4 w-1/3 md:text-right">Confirm Password:</p>
                                    <input onChange={e => setConfirmNewPassword(e.target.value)} value={confirmNewPassword} type="password" name="confirmNewPassword" className="w-full border md:w-2/3" /><br />
                                </div>
                            </div>
                        </div>
                        <button onClick={handleForcePasswordChange} className="mb-2 px-6 py-2 bg-blue-500 text-white rounded-sm">Submit</button>
                    </form>
                    :
                    <form className="flex flex-col items-center">
                        <h1 className="text-3xl">Member Login</h1>
                        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input onChange={e => setEmail(e.target.value)} value={email} type="email" name="email" placeholder="Email" className="input100" />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                        </div>

                        <div className="wrap-input100 validate-input" data-validate="Password is required">
                            <input onChange={e => setPassword(e.target.value)} value={password} type="password" name="password" className="input100" placeholder="Password" />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>
                        </div>

                        <div className="container-login100-form-btn">
                            <button onClick={handleSubmit} className="login100-form-btn">
                                Login
            </button>
                        </div>


                        <div className="text-center p-t-12">
                            <span className="txt1">
                                Forgot
            </span>
                            <Link className='txt2' to="/login/reset">
                                Username / Password?
            </Link>
                        </div>

                        <div className="text-center p-t-136">
                            <p className="txt2" href="/signup">
                                Don't have an Account? Sign Up
              <Link className="" to="/signup">
                                    <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                </Link>

                            </p>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

export default Login


