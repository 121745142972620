import React, { useState, useEffect, useContext, useCallback }  from "react";
import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import ChoosePlanBenefits from './ChoosePlanBenefits'

import FeeGenerator from './FeeGenerator'

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

import OfficeLogo from './OfficeLogo'

export default function PlanDocument(props){

	const styles = StyleSheet.create({
	  page: {
	    flexDirection: 'column',
	    backgroundColor: 'white',
	    padding: 20,
	    marginTop: 6,
	    marginBottom: 6,
	    marginLeft: 12,
	    fontSize: 12
	  },
	  section: {
	    margin: 10,
	    width: "100%"
	  },
	  subsection:{
	  	padding: 6
	  },
	  subsectionAdditional:{
	  	padding: 6,
	  	display: 'hidden'
	  },
	  header1: {
	  	fontSize:  28,
	  	fontWeight: 'bold',
	  	marginBottom: 16
	  },
	  header2: {
	  	fontSize:  24,
	  	fontWeight: 'bold',
	  	marginBottom: 4
	  },
	  header3: {
	  	fontSize: 20,
	  	fontWeight: 'bold'
	  },
	  pageHeader: {
	  	display: 'flex',
	  	flexDirection: 'row',
	  	justifyContent: 'space-between',
	  	alignItems: 'center',
	  	width: "100%",
	  	marginBottom: 24,
	  	marginRight: 24
	  	},
	  logo: {
	  	width: 75,
	  	height: "100%"
	  }
	});

	const printBasicBenefits = props.basicBenefits.map(benefit => {
		if(typeof benefit == "string"){
			if(benefit.includes('$$')){
				benefit = benefit.replace('$$', '$')
			}
			else if (benefit.includes('%%')){
				benefit = benefit.replace('%%', '%')
			}
		}
		return(
			<View key={benefit}>
				<Text>{benefit}</Text>
			</View>
			)
	})

	const printCustomBenefits2 = props.customBenefits.map(benefit => {
		if(typeof benefit.value == "string"){
			if(benefit.value.includes('$')){
				benefit.value = benefit.value.replace('$', '')
			}
			else if (benefit.value.includes('%')){
				benefit.value = benefit.value.replace('%', '')
			}
		}
		if(benefit.included){
			return(
				<View key={benefit.id}>
					<Text >{benefit.benefit.replace('X', benefit.value)}</Text>
				</View>
			)
		}
		return undefined

	})

	const printCustomBenefits = props.customBenefits.map(benefit => {
		if(benefit.included){
			if(benefit.benefit.includes('X')){
				if(benefit.benefit.includes('%') && !(benefit.name.includes('Additional value'))){
					if(benefit.value != undefined){
					if(benefit.value.includes('%')){
						return(
							<View key={benefit.benefit}>
								<Text>{benefit.benefit.replace('X%', benefit.value)}</Text>
							</View>
						)
					}
					else{
						return(
							<View key={benefit.benefit}>
								<Text>{benefit.benefit.replace('X', benefit.value)}</Text>
							</View>
					)
					}}
					else{
						return(
							<View key={benefit.benefit}>
								<Text>{benefit.benefit.replace('X', benefit.value)}</Text>
							</View>
					)
					}
				}
				else if(benefit.benefit.includes('$')){
					if(benefit.value != undefined){
						if(benefit.value.includes('$')){
						return(
							<View key={benefit.benefit}>
								<Text>{benefit.benefit.replace('$X', benefit.value)}</Text>
							</View>
						)
						}
						else{
							return(
								<View key={benefit.benefit}>
									<Text>{benefit.benefit.replace('X', benefit.value)}</Text>
								</View>
							)	
						}
					}
					else{
						return(
							<View key={benefit.benefit}>
								<Text>{benefit.benefit.replace('X', benefit.value)}</Text>
							</View>
						)
					}					
				} 
				else{
					return(
						<View key={benefit.benefit}>
							<Text>{benefit.benefit.replace('X', benefit.value)}</Text>
						</View>
					)	
				}

			}
			else if(benefit.benefit.includes('Additional')){
				let newValue
				if(benefit.value != undefined){
					newValue = benefit.value.replace('percent1', '%')
					newValue = newValue.replace('dollar1', '$')
				}
				else{
					newValue = benefit.value
				}
				return(
					<View key={benefit.benefit}>
						<Text>{newValue}</Text>
					</View>
				)
			}
			else if(!benefit.benefit.includes('Missed appointment')){
				return(
					<View key={benefit.benefit}>
						<Text>{benefit.benefit}</Text>
					</View>
				)
			}
		}

		return undefined
	})
	
	const printRecommendedFees = () => {
		if(props.recommendedFee != undefined){
			return(
				<View style={styles.section}>
					<Text style={styles.header2}>Our Fees:</Text>
					<View style={styles.subsection}>
						<Text style={styles.header3}>Primary Member</Text>
						<View style={styles.subsection}>
							<Text >Full Payment: ${typeof props.recommendedFee == "string" ? Number(props.recommendedFee.replace('$', '').replace(',', '')).toFixed(2) : Number(props.recommendedFee).toFixed(2)}</Text>
							<Text >Monthly Payment: ${Number(props.recommendedMonth.replace('$', '')).toFixed(2)}</Text>
							<Text>Down Payment: ${typeof props.recommendedDown == "string" ? Number(props.recommendedDown.replace('$', '').replace(',', '')).toFixed(2) : props.recommendedDown.toFixed(2)}</Text>

						</View>
					</View>
				</View>
				)
		}
		else{
			return null
		}

	}


	return(
	  <Document>
	    <Page size="A4" style={styles.page}>
	      <View style={styles.section}>
	      	<View style={styles.pageHeader}>
	        	<Text style={styles.header1}>{props.name != "" ? props.name != undefined ? props.name : props.planType : props.planType} Benefits</Text>
	        	<Image source={props.officeLogo}
    				style={styles.logo} />
	        </View>
	        <Text>We are proud to announce that we offer an In-House Savings Plan (IOP) in our dental office. Our IOP is an annual dental savings program we offer for families and individuals that allows all IOP patients to receive quality dental services at greatly reduced prices in our office. Unlike conventional insurance plans, with our IOP program there are <strong>no deductibles, no yearly maximums, and no waiting periods to begin treatment.</strong> IOP benefits and savings begin immediately upon registration.		
	        </Text>
	      </View>
	      <View>
	      	<View style={styles.section}>
	      		<Text style={styles.header2}>Our Savings Include:</Text>
	      		<View style={styles.subsection}>
		      		{printBasicBenefits}
		      		{printCustomBenefits}
	      		</View>
	      	</View>
	      	<View>
	      		{printRecommendedFees()}
	      	</View>
		  </View>
	      <View style={styles.section}>
	        <Text>The fee paid for our IOP savings plan is for included standard of care services and represents a courtesy accounting adjustment for payment, made in full, at the time of service. Eligible family members include spouse and dependent children under the age of 19 (up to age 23 if dependent child is a full-time student). Fees are due and payable when services are rendered and are non-refundable when services have been provided. Savings duration is for one year from registration date. All patient portions for services received are due at the time of services to receive IOP savings. Anniversary dates are 12 months after signing up. Interest-free payment options may be provided in 3, 6- or 12-month durations upon request and with approved credit. Repayment duration is based on service totals and procedural type. If you choose to use a repayment/financial plan, your IOP Savings will be adjusted around the third-party fee and interest free payment options will be customized for your repayment needs. Fees charged are not membership fees and all fees paid are for provided services only.
			</Text>
	      </View>
	      <View style={styles.section}>
	        <Text style={styles.header3}>QDP SAVINGS PLAN LIMITATIONS & EXCLUSIONS</Text>
			<Text>{`\u2022 Dentist provides any medications not regularly stocked by our office.`}</Text>
			<Text>{`\u2022 Any procedure that is considered to be experimental.`}</Text>


			<Text>{`\u2022 Hospitalization for any procedures.`}</Text>
			<Text>{`\u2022 Services unable to be completed due to the patient’s medical health, mental health, or other unhealthy status.
`}</Text>
			<Text>{`\u2022 Conditions or services under Worker’s Compensation or Employer’s Liability laws.
`}</Text>
			<Text>{`\u2022 Congenital malformations other than congenital anomaly of tooth/teeth from birth.
`}</Text>
			<Text>{`\u2022 Any diagnosis or treatment of myofacial pain dysfunction syndrome.
`}</Text>
			<Text>{`\u2022 Any whitening kit included complimentary with our QDP program are included once annually and nontransferable.
`}</Text>
			<Text>{`\u2022 Any alterations, restorations, or treatments of the temporomandibular joint.
`}</Text>
			<Text>{`\u2022 Any procedures for full mouth rehabilitation requiring appliances or restorations.    
`}</Text>
			<Text>{`\u2022 Any services provided for free by a county, government, municipality, or other agency.         
`}</Text>
			<Text>{`\u2022 Any appliances, diagnosis or treatments conducted by a referral made to another dentist or specialist outside of the providers of our office in order to complete treatments in connection with any dental procedure in this office.
`}</Text>

			<Text>{`\u2022 Patients cannot use their own dental insurance benefits or other dental coverage in conjunction with any part of QDP.
`}</Text>
			<Text>{`\u2022 The fee paid for our QDP program is for included services and represents a courtesy accounting adjustment for payment, made in full, at the time of services. 
`}</Text>
			<Text>{`\u2022 The Fee for our QDP program is not a membership fee.
`}</Text>
			<Text>{`\u2022 Payment plans can be made upon request and depend on the total amount due and type of dental procedure and by approved credit.  Any patient using a financial payment plan will have their QDP savings adjusted around the fee charged by the third party. Interest free payment options will be offered in lieu of QDP savings when a third party company is requested to pay for treatment.  `}</Text>
			<Text>{`\u2022 All Fees are due and payable when services are rendered and are non-refundable when services have been provided.`}</Text>
			<Text>{`\u2022 Specifically, Fees for prosthodontic (dentures) and cast restorations (crowns, in-lays, on-lays, veneers, implants, etc.) are due at the prep appointment, in order to receive QDP savings.`}</Text>
			<Text>{`\u2022 There are no refunds on your QDP Savings Plan Fee WHEN any services have been rendered.`}</Text>
			<Text>{`\u2022 Any unused services within your QDP Dental Savings Plan are nontransferable to other patients regardless of the service and do not roll over from year to year.`}</Text>
			<Text>{`\u2022 ***Please note: If you have Periodontal Disease, additional perio maintenance cleanings may be recommended for your individual treatment plan needs. Two perio maintenance appointments are included with your plan fee.`}</Text>
	        <Text style={styles.section}></Text>
	        <Text style={styles.header3}>PLEASE READ DISCLAIMER AND SIGN BELOW:</Text>
	        <Text style={styles.section}>I acknowledge that I am financially responsible for payment, in full, at time of services in order to take advantage of the savings being offered on my QDP Savings Plan. If I choose not to pay at the time of service, I understand that I shall pay the customary fees for the services delivered. Furthermore, I understand the benefits, limitations, exclusions, and requirements of my QDP program and have been given a copy for my personal records.</Text>
	        <Text style={styles.section}>I authorize regularly scheduled charges to my credit card for plan fees, yearly or monthly. The charge will appear on your credit card statement. You agree that no prior-notification will be provided unless the date or amount changes, in which case you will receive notice from us at least 10 days prior to the payment being collected.</Text>
	        <Text style={styles.section}>I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify 30 days in advance of my next billing date, in writing, of any changes in my account information or a wish to terminate my agreement. I acknowledge that the origination of Credit Card transactions to my account must comply with the provisions of U.S. law. I certify that I am an authorized user of this Credit Card and will not dispute these scheduled transactions.</Text>
	        <Text style={styles.section}>SIGNATURE: ______________________________________ DATE: ____________</Text>

	      </View>
	    </Page>
	  </Document>
	)
	};
