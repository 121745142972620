import API, { graphqlOperation } from "@aws-amplify/api"
import '../usio/payments'



/**
 * @typedef {Object} PaymentRecord
 * @property {string} paymentType - cash or card
 * @property {string} memberId - The id of the member this payment pertains to
 * @property {string} grossPay
 * @property {string} netPay - This is usually the amount charged
 * @property {string} fee - The fee for this payment
 * @property {string} practice - The practice 
 * @property {string} description
 * @property {string} patientName
 * @property {string} contactName
 * @property {string} officeId
 * @property {string} officeName
 * @property {string} cardType
 * @property {string} cardNumber
 * 
 * @param {PaymentRecord & import("../usio/payments").USIOResponse} data 
 */
export const savePaymentLog = async (data) => {

    const {
        memberId,
        practice,
        patientName,

        description,

        officeId,
        officeName,

        confirmation,
        message,
        status,

        cardType,
        cardNumber,
        contactName,

        paymentType,
        grossPay,
        fee,
        netPay
    } = data

    const isCash = paymentType === 'cash'
    const confirmationToken = (confirmation && confirmation.length > 0) ? confirmation : message
    const paymentInfo = isCash ? 'Cash' : `${cardType || ''} ${cardNumber && cardNumber.length > 0 ? '****'+cardNumber.substr(cardNumber.length - 4) : 'N/A'}`
    const stat = isCash ? 'completed' : status
    const confirmToken = isCash ? 'cash' : confirmationToken
    const result = await API.graphql(graphqlOperation(
        `mutation create {
            createPayments(input: {
                memberId: "${memberId}",
                grossPay: ${grossPay.replace('$', '')}, 
                description: "${description}", 
                status: "${stat}",
                fee: ${fee},
                cash: ${isCash},
                netPay: ${netPay.replace('$', '')}, 
                practice: "${practice}", 
                memberName: "${patientName}", 
                office: "${officeId}", 
                confirmationToken: "${confirmToken}",
                paymentInfo: "${paymentInfo}",
                contactName: "${contactName}"
            }) {
                id
            }

            createOfficePayments(input: {
                officeId: "${officeId}", 
                description: "${description}",
                grossPay: ${.83},
                status: "${stat}",
                fee: ${fee}, 
                cash: ${isCash},
                netPay: ${netPay.replace('$', '')}, 
                name: "${officeName}", 
                practice: "${practice}", 
                confirmationToken: "${confirmToken}",
                paymentInfo: "${paymentInfo}",
                contactName: "${contactName}"
            }) {
                id
            }
        }`
    ))


    console.log(`payment log results `, result)
}