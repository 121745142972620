import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';


import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import { green, red } from '@material-ui/core/colors';


export default function CreateMemberPayment(props){

	const [paymentStatus, setPaymentStatus] = useState("")
	const [description, setDescription] = useState("")
	const [grossPay, setGrossPay] = useState(0)
	const [fee, setFee] = useState(0)


	const displayPaymentLogged = props.paymentLogged ? props.paymentStatus == "failure" ? <div className="w-5/6 mx-auto mt-4 text-red-700 flex bg-white"><div className="px-2">Payment Failed </div> <CloseIcon style={{ color: red[600]}} /></div> : <div className="w-5/6 mx-auto mt-4 text-green-700 flex "><div className="px-2">Payment Completed </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null


	return(
		<div className="w-full h-full">
			<form onSubmit={props.handleLogPayment} className="card mb-8 p-8 bg-white border rounded-lg w-5/6 mx-auto">
			<h1 className="text-2xl">New Payment</h1>
			<div className="flex">
			<div className="w-1/2 flex flex-col">
				<div className="flex my-4 w-full">
					<p className="my-auto pr-4 w-1/3 text-right">Description:</p>
					<input className="border w-1/2" onChange={props.handleDescriptionChange} type="text" value={props.description} id="description"></input>
				</div>
				<div className="flex my-4 w-full">
					<p className="my-auto pr-4 w-1/3 text-right">Amount:</p>
					<input className="border w-1/2" onChange={props.handleGrossPayChange} type="text" value={props.grossPay} id="paymentAmount"></input>
				</div>
			</div>
		</div>
		{displayPaymentLogged}
		<button className="px-6 py-2 bg-blue-500 text-white rounded-sm">Create</button>
		</form>
		</div>
		)
}