import { Money } from "@material-ui/icons";
import React, { useState, useEffect, useContext, forwardRef, useRef }  from "react";
import MaskedInput from 'react-text-mask'


import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import useDebounce from "../hooks/useDebounce";

	const numberMask = createNumberMask({
	  prefix: '$',
  	  allowDecimal: true,
 // This will put the dollar sign at the end, with a space.
	})

	const percentMask = createNumberMask({
	  prefix: '',
	  suffix: '%',
  	  allowDecimal: true,
 // This will put the dollar sign at the end, with a space.
	})


const MoneyInput = forwardRef((props, ref) => {
	const dollarMask = useRef(createNumberMask({prefix: '$', allowDecimal: true})).current
	const [value, setValue] = useState(props.value)
	const debouncedValue = useDebounce(value, 250)

	useEffect(() => {
		setValue(props.value)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value])

	useEffect(() => {
		if (!debouncedValue || debouncedValue.length == 0) {
			return
		}
		console.log(`Checking value: ${debouncedValue}`)

		const cleanedValue = ((typeof debouncedValue == "string")) ? 
		debouncedValue.replace(/[$,]/g, '') : 
	((debouncedValue !== null) && (debouncedValue !== undefined)) ? 
	debouncedValue : 0;

		props.onChange && props.onChange({ target: { value: cleanedValue }})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue, props.onChange])


	const onChange = (e) => {
		setValue(e.target.value)
	}


	  return(
		  <div className="flex my-4 w-full ">
			  <p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
			  <MaskedInput ref={ref} 
				  onClick={props.onClick} 
				  mask={dollarMask} 
				  placeholder={props.placeholder} 
				  disabled={props.disabled} 
				  className="border w-1/2"
				  type="text" 
				  value={value}
				  onChange={onChange}
				  name={props.name} 
				  id={props.name}/>
		  </div>
		  )
})
	
const Input = forwardRef((props, ref) => {
	const labelCheck = props.label.toLowerCase()
	const type = props.type !== undefined ? props.type : labelCheck.includes('email') ? "email" : labelCheck.includes('password') ? "password" : "text"

	if(labelCheck.includes('zip')){
		return(
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
      			<MaskedInput placeholder={props.placeholder} disabled={props.disabled} mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={props.onChange} type="text" value={props.value} name={props.name} id={props.name}/>
			</div>
			)
	}
	else if(labelCheck.includes('phone')){
		return(
			<div className="flex my-4 w-full ">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
				<MaskedInput placeholder={props.placeholder} disabled={props.disabled} mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={props.onChange} type="text" value={props.value} name={props.name} id={props.name}/>
			</div>
			)
	}
	else if (type == "checkbox"){
		return(
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
				<input className={`my-auto ${props.className}`} onFocus={props.onFocus} disabled={props.disabled} onChange={props.onChange} type={type} checked={props.value} name={props.name} id={props.name}></input>
			</div>
			)
	}
	else if(props.mask == "$"){
		return <MoneyInput {...props} ref={ref} />
	// 	const dollarMask = createNumberMask({
	// 	  prefix: '$',
	//   	  allowDecimal: true,
	//  // This will put the dollar sign at the end, with a space.
	// 	})
		
	// 	const value = ((typeof props.value == "string")) ? 
	// 		Number(props.value.replace(/[$,]/g, '')).toFixed(2) : 
	// 		((props.value !== null) && (props.value !== undefined)) ? 
	// 				props.value.toFixed(2) : 0;
	// 	return(
	// 		<div className="flex my-4 w-full ">
	// 			<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
	// 			<MaskedInput ref={ref} 
	// 				onClick={props.onClick} 
	// 				mask={dollarMask} 
	// 				placeholder={props.placeholder} 
	// 				disabled={props.disabled} 
	// 				className="border w-1/2" 
	// 				onBlur={props.onChange}
	// 				type="text" 
	// 				value={value} 
	// 				name={props.name} id={props.name}/>
	// 		</div>
	// 		)
	}
	else if(props.mask == "%"){
		const percentMask = createNumberMask({
		  prefix: '',
		  suffix: '%',
	  	  allowDecimal: true,
	 // This will put the dollar sign at the end, with a space.
		})
		return(
			<div className="flex my-4 w-full ">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
				<MaskedInput onClick={props.onClick} mask={percentMask} placeholder={props.placeholder} disabled={props.disabled} className="border w-1/2" onChange={props.onChange} type="text" value={props.value} name={props.name} id={props.name}/>
			</div>
			)
	}
	else if(props.mask == "creditCard"){
		return(
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
	  			<MaskedInput onClick={props.onClick} onBlur={props.onBlur} disabled={props.disabled} mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={props.onChange} type="text" value={props.value} name={props.name} id={props.name}/>
			</div>
			)
	}
	else{
		return(
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/3 text-right">{props.label}:</p>
				<input className={`border w-1/2 ${props.className}`} onKeyDown={props.onKeyDown} onClick={props.onClick} onBlur={props.onBlur} disabled={props.disabled} onChange={props.onChange} type={type} value={props.value} name={props.name} id={props.name}></input>
			</div>
			)
	}
})

export default Input