import { API, graphqlOperation } from 'aws-amplify'
import React, { useContext, useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link } from 'react-router-dom'
import Table from '../../components/Table'
import { capitalizeFirstLetter } from '../../events/ReactQL'


import { useListQL } from '../../hooks/useListQL'
import { officeUsersSchema } from '../../schemas/schemas'
import { UserContext } from '../../UserContext'


export default () => {

    const auth = useContext(UserContext)
    const [users, setUsers] = useState([])


    useEffect(() => {

        (async () => {

            try {
                const result = await API.graphql(graphqlOperation(
                    `query GetAccounts {
                        listOfficeAccounts(filter: { officeId: { eq: "${auth.officeId || auth.user}" }}) {
                            items {
                                id
                                roles
                                account {
                                    id
                                    name
                                    officeId
                                    email
                                }
                            }
                        }
                    }`
                ))

                
                setUsers(result.data.listOfficeAccounts.items.map(oa => {
                    const roles = (oa.roles || []).filter(r => r && r.length > 0).map(r => {
                        return r.split(' ').map(capitalizeFirstLetter).join(' ')
                    })
                    return {
                        ...oa.account,
                        id: oa.id,
                        accountId: oa.account.id,
                        roles: roles.length == 0 ? 'Team Member' : roles
                    }
                }))
            }
            catch (ex) {
                console.error(ex)
            }

        })()

    }, [auth])


    const handleDelete = async (recordId) => {
        // TODO: Just remove the listOfficeAccount record and if the user.officeId is set to current officeId, then
        // reset it to null
        const handleDeleteConfirm = async () => {
            try {
                const result = await API.graphql(graphqlOperation(
                    `mutation DeleteUser {
                        deleteOfficeAccount(input: { id: "${recordId}" }) {
                            id
                        }
                    }`
                ))

                let tmp = [...users]
                const userIndex = tmp.findIndex(a => a.id == recordId)
                const u = users[userIndex]

                /// If the user's officeId is set to the current officeId
                if (u.officeId == (auth.officeId || auth.user)) {
                    console.log(`Need to update userId as well`)
                    const userResult = await API.graphql(graphqlOperation(
                        `mutation UpdateUser {
                            updateAccount(
                                input: { id: "${u.accountId}", officeId: null }
                            ) {
                                id
                            }
                        }`
                    ))
                    console.log(`Update user result`)
                }

                tmp.splice(userIndex, 1)
                setUsers(tmp)
                // const account = 
            }
            catch (ex) {
                console.error(ex)
            }
        }


        const record = users.find(u => u.id == recordId)
        confirmAlert({
            title: 'Are you sure?',
            message: `Are you sure you want to remove user "${record.name || record.email}" from office "${auth.currentOffice.office.name}"?`,
            buttons: [
                { label: 'Yes', onClick: () => handleDeleteConfirm() },
                { label: 'No', onClick: () => { }}
            ]
        })
    }

    const customComponents = () => {
        return (
            <span></span>
        )
    }

    // const handleDelete = async (id) => {
    //     console.log(`Delete: ${id}`)
    // }

    const canDelete = auth.currentOffice && auth.currentOffice.roles.includes('admin')

    return (
        <div className="w-full h-full">
            <div className="w-full px-10 py-6">
                <div className="flex p-6">
                    <div className="w-1/2 rounded-sm"><h1 className="ch1">All Users</h1></div>
                    <div className="w-1/2 rounded-sm text-right justify-around">
                        <Link to="/users/create" className="gradient-btn mt-4 mx-2">Add User</Link>
                        {/* <CSVLink className="gradient-btn mt-4 mx-2" filename={"plans.csv"} data={listPracticesCsv} >Export</CSVLink> */}
                    </div>
                </div>
                <Table saved={false} savedLabel={"Saved"}
                    customComponents={customComponents()}
                    columns={['Name', 'Email', 'Roles']}
                    fields={['name', 'email', 'roles']}
                    delete={canDelete}
                    handleDelete={handleDelete}
                    values={users} />
            
            </div>
        </div>
    )
}