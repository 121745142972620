import React  from "react";

import Input from '../../components/Input'
import FormCol from '../../components/FormCol'
import CustomerPayForm from '../../components/CustomerPayForm'

export default function MemberPaymentDetail(props){

	const selectPlan = props.plans.map(plan => <option key={plan.id} value={plan.name}>{plan.name}</option>)

	return(
		<div className="w-full">
			<CustomerPayForm onSubmit={e => e.preventDefault()} title={"Payment Details"} hideButton={true} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<FormCol>
					<div className="flex my-4 w-full">
					  <p className="my-auto pr-4 w-1/3 text-right">Payment Type:</p>
						<select className="border w-1/2" id="paymentType" name="paymentType" value={props.paymentType} onChange={props.handlePaymentTypeChange}>
			               <option name="card" value="card">Card</option>
			               <option name="cash" value="cash">Cash</option>
			            </select>
			        </div>
					<div className="flex my-4 w-full">
					  <p className="my-auto pr-4 w-1/3 text-right">Plan:</p>
		              <select className="border w-1/2" id="plans" name="plans" value={props.plan} onChange={props.handlePlanChange}>
		                <option name="None" value="None">None</option>
		                {selectPlan}
		              </select>
			        </div>

		            {props.paymentType != "cash" ? <Input label={"Payment In Full"} mask={"$"} onChange={props.handleTotalBalanceChange} value={props.totalBalance} name={"totalBalance"} /> : null }
		            <Input label={"Balance"} mask={"$"} onChange={props.handleRemainingBalanceChange} value={props.remainingBalance} name={"remainingBalance"} /> 
				</FormCol>
				<FormCol>
					{props.paymentType != "cash" ? <div className="flex my-4 w-full">
					  <p className="my-auto pr-4 w-1/3 text-right">Payment Interval:</p>
		              <select className="border w-1/2" id="paymentPeriod" name="paymentPeriod" value={props.paymentInterval} onChange={props.handlePaymentIntervalChange}>
		                <option name="yearly" value="yearly">Yearly</option>
		                <option name="monthly" value="monthly">Monthly</option>
		              </select>
		            </div> : null }
		           	{(props.paymentInterval == "monthly" && props.paymentType != "cash") ? <Input label={"Initial Deposit"} mask={"$"} onChange={props.handleInitialDepositChange} value={props.initialDeposit} name={"initialDeposit"} /> : null}
		            {props.paymentType != "cash" ? <Input label={"Recurring Payment"} mask={"$"} onChange={props.handlePeriodAmountChange} value={props.periodAmount} name={"periodAmount"} /> : null }
				</FormCol>
			</CustomerPayForm>
		</div>
		)
}


