import React, { useState, useEffect, useContext }  from "react";

import { Link } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import SavedAlert from './SavedAlert'

import PlanDocument	from '../PlanDocument'

import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PlanWordDocument from "./PlanWordDocument";


const Table = (props) => {

	let customComponents = props.customComponents
	let values = props.values != undefined ? props.values : []
	let newValuesList = []
	let newValue
	let rowCount = 1
  	for(let i=0; i<values.length; i++){
		newValue = {...values[i], rowId: rowCount}
		newValuesList.push(newValue)
		rowCount = rowCount + 1
	}

	values = newValuesList


	let numCols = props.columns.length
	let pathSuffix
	if((props.pathSuffix != null) && (props.pathSuffix != undefined)){
		pathSuffix = props.pathSuffix
	}
	else{
		pathSuffix = ""
	}

	let listHeaders = props.columns.map(column => {
		return(
			<th key={column} className={`w-1/${numCols} px-4 pb-4 table-h`}>{column}</th>
			)
	})

	listHeaders.push(<th key="" className={`w-1/${numCols} px-4 pb-4 table-h text-center`}></th>)



	const listRows = values.map(row =>{
		const background = row.rowId % 2 == 1 ? "bg-gray-100" : ""

		let records = []
		let record
		let recordCount = 0
		let newValue
		if(props.fields == null){
			for(record in row){
				
				newValue = record.toLowerCase().includes('pay') ? row[record].toFixed(2) : row[record]

				if(((record == 'createdAt') || (record == 'updatedAt') || (record.toLowerCase().includes('date'))) && ((row[record] != null) && (row[record] != undefined))){
					newValue = row[record].split('T')[0]
				}

				if((record != 'id') && (record != 'rowId')){
					records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
				}
				recordCount++
			}
		}
		else{
			for(let i = 0; i < props.fields.length; i++){

				record = props.fields[i]

				newValue = record.toLowerCase().includes('pay') ? row[record].toFixed(2) : row[record]


				if(((record == 'createdAt') || (record == 'updatedAt') || (record.toLowerCase().includes('date'))) && ((row[record] != null) && (row[record] != undefined))){
					newValue = row[record].split('T')[0]
				}
				if((record != 'id') && (record != 'rowId')){
					if(customComponents != undefined){
						if(customComponents[record] != undefined){
							records.push({name: record, value: customComponents[record](row[record]), link: ((recordCount == 0) && props.edit)})
						}
						else{
							records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
						}
					}
					else{
						records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
					} 
				}
				recordCount++
			}	
		}

		let listRecords = records.map(record => {
			if(typeof record.value == "boolean"){
				return(
					<td key={record.name} className="p-4 text-left"><input onChange={props.handlers[record['name']]} type="checkbox" name={row.name} id={row.id} checked={record.value ? true : false}></input></td>
					)
			}
			else if(record.link){
				return(
					<td key={record.name} className={`w-1/${numCols} p-4 text-left`}>
						<span className="text-right ">
							<Link className="text-blue-700" to={`/${props.pathPrefix}/${row.id}/${pathSuffix}`}>
								{record.value}
							</Link>
						</span>
					</td>
					)
			}
			else{
				return(
					<td key={record.name} className={`w-1/${numCols} p-4 text-left`}>{record.value}</td>
					)
			}
		})

		let feeStructure = JSON.parse(atob(row.recommendedFee))
		let customBenefits = JSON.parse(atob(row.benefits))

		let basicBenefits = customBenefits.slice(0,2)
		customBenefits = customBenefits.slice(2, customBenefits.length)


		listRecords.push(
			<td key={"download-link"} className={`w-1/${numCols} p-4 text-left`}>
						{/* <PDFDownloadLink 
							className="my-6 w-1/4 mx-2 px-2 py-2 text-blue-500 underline text-center" document={
								<PlanDocument 
								officeLogo={props.officeLogo} 
								planType={row.planType} 
								name={row.name} 
								recommendedMonth={feeStructure.recommendedMonth} 
								recommendedDown={feeStructure.downPayment} 
								recommendedFee={feeStructure.finalFee} 
								basicBenefits={basicBenefits.map(benefit => benefit.benefit)} 
								customBenefits={customBenefits} />} 
								fileName="plan.pdf">
					      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
					    </PDFDownloadLink> */}
						<PlanWordDocument 
							className="my-6 w-1/4 mx-2 px-2 py-2 text-blue-500 underline text-center" 
							officeLogo={props.officeLogo} 
							planType={row.planType} 
							name={row.name}
							buttonName="Download" 
							recommendedDown={feeStructure.downPayment} 
							recommendedFee={feeStructure.finalFee} 
							recommendedMonth={feeStructure.recommendedMonth} 
							recommendedAdditionalFee={feeStructure.recommendedAdditionalFee} 
							recommendedAdditionalMonth={feeStructure.recommendedAdditionalMonth} 
							basicBenefits={basicBenefits.map(b => b.benefit)} 
							customBenefits={customBenefits} 
							finalFee={feeStructure.finalFee} />
		
			</td>
			)

		listRecords.push(
			<td key={"actions"} className={`w-1/${numCols} p-4 text-left`}>
				<span className="text-right  flex">
					{props.edit ? <Link to={`/${props.pathPrefix}/${row.id}/${pathSuffix}`}>
						<CreateIcon className="table-icon"/>
					</Link> : null}
					{props.delete ? <button className="text-right" onClick={() => props.handleDelete(row.id)}><DeleteIcon className="table-icon"/></button> : null}

				</span>
			</td>
			)


		return(
			<tr  key={row.id} className={background}>
				{listRecords}
			</tr>
		)

	})

	return(
        <div className="card text-left">
			<SavedAlert saved={props.saved} label={props.savedLabel} />
			<table className="w-full bg-white rounded-sm table-border">	
				<thead>
					<tr>
						{listHeaders}
					</tr>
				</thead>
			<tbody>
				{listRows}
				{props.children}
			</tbody>
			
		</table>
		</div>
		)
	}

export default Table 