import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import {UserContext} from './UserContext'

export default function Welcome(props){
	return(
		<div className="w-full h-full">
			<div className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<h1 className="my-4 text-xl font-semibold">Welcome to QDP!</h1>
				<p className="my-4">We are excited to have you join our community of dental professionals. To make the most of your
				membership, take the following actions:</p>


				<p className="my-4">
					1) Review the QuickStart Guide-> located on the left-hand side of the dashboard in the app
				</p>
				<p className="my-4">
					2) Determine which preventative services you would like to include in your in-office plan (example: number of cleanings, exams, and x-rays)
				</p>
				<p className="my-4">
					3) Know what % savings you would like to offer patients for all other services  (restorative/implants/ortho)
				</p>
				<p className="my-4">
					4) Watch the [Plan Builder and Fee Calculator] video under QuickStart (located on the left-hand side of the dashboard in the app)
				</p>
				
				{/* <p className="my-4">1. If you haven't already, create a merchant account with <a className="text-blue-500 underline" href="https://usio.com/">USIO</a>. 
				USIO is QDP's payment system provider, and will allow you to collect one-time and recurring payments
				from your patients. Once your USIO account has been created, save this info to your <Link className="text-blue-500 underline" to="/settings/">payment settings</Link>.
				</p>
				<p className="my-4">2. Add information about your dental office's practice to your <Link className="text-blue-500 underline" to="/settings/">practice settings</Link>, or
				enable multiple practices and add these to the practices tab.  You'll be able to view members and payments/revenue at the
				practice level.
				</p> */}

				<p className='my-4'>Thanks again for joining the QDP community. We look forward to partnering with you!</p>
				<button onClick={props.handleChangeOfficeHasLoggedIn} className="my-4 px-6 py-2 bg-blue-500 text-white rounded-sm">Continue</button>
			</div>
		</div>
		)
}