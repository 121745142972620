import React, { useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import OfficeLogo from './OfficeLogo'
import LogoUploader from './LogoUploader'

import Input from './components/Input'
import SavedAlert from './components/SavedAlert'
import Form from './components/Form'
import FormCol from './components/FormCol'

function SignupFlow1(props) {

    return (
      <div className="w-full h-full">
        <Form onSubmit={e => e.preventDefault()} title={"Registration"} hideButton={true} className="card mt-8 px-8 pt-8 bg-white border rounded-lg w-5/6 mx-auto ">
          <FormCol>
            <Input label={"Email * "} onChange={props.handleEmailChange}  value={props.email} name={"email"} />
            <Input label={"Confirm Email * "} onChange={props.handleConfirmEmailChange}  value={props.confirmEmail} name={"confirmEmail"} />
            <Input label={"Business Name * "} onChange={props.handleNameChange}  value={props.name} name={"name"} />
            <Input label={"Primary Contact * "} onChange={props.handlePrimaryContactChange}  value={props.primaryContact} name={"primaryContact"} />
            <Input label={"Phone * "} onChange={props.handlePhoneChange}  value={props.phone} name={"phone"} />
            <div className="flex">
              <div className="my-auto pr-4 w-1/3 text-right">
                <p className="my-2">Profile Picture:</p>
              </div>
              <div className="">
                {props.logoUrl != "" ? <OfficeLogo logoUrl={props.logoUrl} /> : <LogoUploader handleLogoUpload={props.handleLogoUpload} /> }
              </div>
            </div>
          </FormCol>
          <FormCol>
            <Input label={"Address * "} onChange={props.handleAddressChange}  value={props.address} name={"address"} />
            <Input label={"City * "} onChange={props.handleCityChange} value={props.city} name={"city"} />
            <Input label={"State * "} onChange={props.handleStateChange}  value={props.state} name={"state"} />
            <Input label={"Zip Code * "} onChange={props.handleZipChange}  value={props.zip} name={"zip"} />
            <Input label={"Website"} onChange={props.handleWebsiteChange}  value={props.website} name={"website"} />
            <Input label={"Password * "} onChange={props.handlePasswordChange}  value={props.password} name={"password"} />
            <Input label={"Confirm Password * "} onChange={props.handleConfirmPasswordChange}  value={props.confirmPassword} name={"confirmPassword"} />
          </FormCol>
        </Form>
        <div className="w-5/6 mx-auto card mb-8">
          <p className="my-4">Please attach a .png, .jpg, .gif or .mpg image. By attaching an image to use as your profile picture you assert that you have the rights to use the image and it is not illegal or violent in any way.</p>
          <button onClick={props.handleSubmitFlow} className="gradient-btn my-4">Next</button>
          <p className="">Already have an account? <Link className="text-blue-700" to="/login">Sign In</Link></p>
        </div>
      </div>
    );
}

export default SignupFlow1