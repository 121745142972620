import React, { useState, useEffect, useContext, useRef, useMemo }  from "react";
import { Auth, Analytics } from 'aws-amplify';


import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import { green, red } from '@material-ui/core/colors';

import Form from './components/Form'
import FormCol from './components/FormCol'
import Input from './components/Input'
import SelectCreditCardType from './components/SelectCreditCardType'
import SelectCreditCardExpiration from './components/SelectCreditCardExpiration'
import Select from './components/Select'
import SearchBarArrow from './components/SearchBarArrow'

import {UserContext} from './UserContext'

import SavedAlert from './components/SavedAlert'

import ErrorAlert from './components/ErrorAlert'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {memberSchema, memberFinanceSchema} from './schemas/schemas'

import {useOneTimePayment} from './hooks/useOneTimePayment'
import {useListQL} from './hooks/useListQL'
import {fetchList} from './events/ReactQL'
import SelectState from "./components/SelectState";

export default function OneTimePayment(props){

  	const auth = useContext(UserContext)

  	const [swiped, setSwiped] = useState(false)
  	const [hasAmount, setHasAmount] = useState(false)
  	const [submittedTokenRequest, setSubmittedTokenRequest] = useState(false)

  	//payment field changes, posts to USIO and mutations and dynamodb for this page are controled using the useOneTimePayment hook
	const {stateDict, paymentStatus, handleInputChange, handleSwipeInputChange, handleTokenization, handlePostPayment} = useOneTimePayment({officeMerchantKey: props.officeMerchantKey,
		officeUsioLogin: props.officeUsioLogin, officeUsioPassword: props.officeUsioPassword})
	
	const memberState = useListQL(memberSchema, useMemo(() => ({name: "office", value: auth.officeId || auth.user}), [auth.officeId, auth.user]))

	const [method, setMethod] = useState('card')

	const displayPaymentLogged = props.paymentLogged ? props.paymentStatus == "failure" ? <div className="w-5/6 mx-auto mt-4 text-red-700 flex bg-white"><div className="px-2">Payment Failed </div> <CloseIcon style={{ color: red[600]}} /></div> : <div className="w-5/6 mx-auto mt-4 text-green-700 flex "><div className="px-2">Payment Completed </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null

	const [currMember, setCurrMember] = useState("")
	const [currTokenMember, setCurrTokenMember] = useState("")

	const [cardAll, setCardAll] = useState("")
	const [swipeNow, setSwipeNow] = useState(true)

	const cardRef = useRef(null)

	  const confirmPayment = (amount) => {
	  	if(stateDict.amount != "" && stateDict.amont != "$0.00"){
		    confirmAlert({
		      title: 'Confirm Payment',
		      message: `Are you sure you want to pay ${amount}?`,
		      buttons: [
		        {
		          label: 'Confirm',
		          onClick: () => {handleTokenization()}
		        },
		        {
		          label: 'Cancel',
		          onClick: () => null
		        }
		      ]
		    });
	  	}
	  	else{
	  		alert('Fields cannot be empty')
	  	}

	  }

	const handleMethodChange = (e) => {
		const newMethod = e.target.value
		setMethod(newMethod)
	}

	const handleCardAllChange = (e) => {

		//this is an event handler for a hidden input on the page intended to process card swipes

		//check if the user has swiped already
		if(!swiped){
			const newCardAll = e.target.value
			setCardAll(newCardAll)

			let swipeName = ""
			let swipeNumber = ""
			let swipeExpire = ""
			let swipeExpire2
			let swipeExpireY = ""
			let swipeExpireM = ""
			let swipeType = ""
			let swipeDig = ""
			let swipeCCV = ""

			//the card swiper sends card data in a specific pattern which is used here to identify which parts are the card number, expiration, etc.
			//the card swiper sends a series of increasingly complete data for the card, which is why the function continuously monitors for the inclusion of certain character patterns as an indication of progress 
			if((newCardAll != undefined) && (newCardAll.includes('/'))){
				swipeName = newCardAll.split('^')[1]
				let swipeNames
				try{
					swipeNames = swipeName.replace(/ /g,"").split('/')
				}
				catch{
					swipeNames = swipeName.split('/')
				}
				let fullName = [swipeNames[1], swipeNames[0]].join(' ')

				handleSwipeInputChange('name', fullName)
			}
			if(newCardAll.includes(';')){
				swipeNumber = newCardAll.split(';')[1].split('=')[0]
				swipeDig = swipeNumber[0]
				handleSwipeInputChange('cardNumber', swipeNumber)
				if(swipeDig == 4){
					swipeType = 'VISA'
				}
				else if(swipeDig == 5){
					swipeType = 'MSTR'
				}
				else if(swipeDig == 6){
					swipeType = 'DISC'
				}
				else{
					swipeType = 'AMEX'
				}
				handleSwipeInputChange('cardType', swipeType)

			}
			if(newCardAll.includes('=')){
				swipeExpire = newCardAll.split('=')[1].substring(0,4)
				if(swipeExpire.length == 4){
					swipeExpireY = "20" + swipeExpire.substring(0, 2)
					swipeExpireM = swipeExpire.substring(2, 4)
					handleSwipeInputChange('expireY', swipeExpireY)
					handleSwipeInputChange('expireM', swipeExpireM)

				}
			}
		}
		
	
	}

    const fetchPaymentToken = async (filterDict) => {
      //archived, this page no longer allows you to make a payment with a QDP patient
      let fetchedStateDict = await fetchList(memberFinanceSchema, filterDict)
      setCurrTokenMember(fetchedStateDict[0])
    }

	const handleChangeMember = (event) =>{
		//archived, this page no longer allows you to make a payment with a QDP patient
		const memberName = event.value
		const pickedMember = memberState.stateDict.filter(member => member.name == memberName)[0]
		setCurrMember(pickedMember.name)

		fetchPaymentToken({name: "memberId", value: pickedMember.id})
	}

	const resetFocus = (e) => {

		//since the card swiper uses a hidden input box to receive card info, this function makes sure that that input box is in focus on the page any time unless another textbox has been specifically focused on

		let clickType = e.target.type
		if(swiped != true){
			if((clickType != 'select-one') && (clickType != 'text') && (clickType != 'email')){
				cardRef.current.focus()
				setSwipeNow(true)
			}
		}
	}

	const resetFocus2 = (e) => {

		//since resetFocus is actively focusing on our hidden input, this function overrides that whenever a visible inbox is clicked on by the user
		setHasAmount(false)
		if(stateDict.isSwiping){
			if(cardRef.current != null){
				cardRef.current.blur()
				setSwipeNow(false)
			}
		}
	}

	const handleAmountChange = (e) => {
		setHasAmount(true)
	}

  //I think the second useEffect here could be deleted, but these are putting the hidden input in focus as soon as the component is mounted
  useEffect(
    () => {
      if(stateDict.isSwiping){
      	cardRef.current.focus()
      	setSwipeNow(true)
      }
    }, [stateDict.isSwiping, hasAmount])

  useEffect(
    () => {
      cardRef.current.focus()
      setSwipeNow(true)
    }, [])


  //prompts customer cvv after a card is swiped. I don't think we actually need this for the USIO post,so this could probably be archived
  useEffect(
    () => {
      if(swiped){
		let swipeCVV = prompt('Enter CVV')
		handleSwipeInputChange('cvv', swipeCVV)
		setSubmittedTokenRequest(true)
      }
    }, [handleSwipeInputChange, swiped])

  /*useEffect(
    () => {
      if(submittedTokenRequest){
      	handleTokenization()
      }
    }, [submittedTokenRequest])*/

    const selectMembers = memberState.stateDict.map(member => ({key: member.id, value: member.name, label: member.name}))

	return(
		<div onClick={resetFocus} className="w-full">
			
			{stateDict.isMember ?
			<form className="card p-8 bg-white border rounded-lg w-5/6 mx-auto " onSubmit={(e) => handlePostPayment(e, currTokenMember)}>
				<h1 className="text-2xl">New Payment <button className={swipeNow ? " border border-white hover:underline hover:font-bold font-bold bg-white text-blue-700 rounded-full text-xl" : " border border-white hover:underline hover:font-bold bg-white text-blue-700 rounded-full text-xl"}>{swipeNow ? "Swipe Now!" : "Click to Swipe Card"}</button></h1>
				<div className="flex">
					<FormCol>
						<Input label={"QDP Member"} type="checkbox" onChange={handleInputChange} value={stateDict.isMember} name={'isMember'} />
						<div className="w-full flex my-4 ">
							<p className="my-auto pr-4 w-1/3 text-right">Member:</p>
							<div className="w-1/2">
			           		 <SearchBarArrow options={selectMembers} onChange={handleChangeMember} />
							</div>
						</div>		            
						<Input label={"Payment Amount"} mask="$" onChange={handleInputChange} value={stateDict.amount} name={"amount"} />
						<button className="gradient-btn w-1/2">Submit Payment</button>
					</FormCol>
				</div>
			</form>
			:
			<div>
			 <form onSubmit={(e) => e.preventDefault()}>
			 	<input className="opacity-0" onChange={handleCardAllChange} ref={cardRef} value={cardAll}></input>
			 </form>
			 <form className="card p-8 bg-white border rounded-lg w-5/6 mx-auto " onSubmit={(e) => e.preventDefault()}>
				<h1 className="text-2xl">New Payment <button className={swipeNow ? " border border-white hover:underline hover:font-bold font-bold bg-white text-blue-700 rounded-full text-xl" : " border border-white hover:underline hover:font-bold bg-white text-blue-700 rounded-full text-xl"}>{swipeNow ? "Swipe Now!" : "Click to Swipe Card"}</button></h1>
				<div className="flex">
				<FormCol>
					<Input label={"Email"} onClick={resetFocus2} onChange={handleInputChange} value={stateDict.email} name={"email"} />
					<Input label={"Address"} onClick={resetFocus2} onChange={handleInputChange} value={stateDict.cardAddress} name={"cardAddress"} />
					<Input label={"City"} onClick={resetFocus2} onChange={handleInputChange} value={stateDict.cardCity} name={"cardCity"} />
					<SelectState onClick={resetFocus2} onChange={handleInputChange} value={stateDict.cardState} name={'cardState'} />
					<Input label={"Zip"} onClick={resetFocus2} onChange={handleInputChange} value={stateDict.cardZip} name={"cardZip"} />
				</FormCol>
				<FormCol>
					<Input label={"Name"} onChange={handleInputChange} value={stateDict.name} onClick={resetFocus2} name={"name"} />
					<Input label={"Card Number"} mask={"creditCard"} onChange={handleInputChange} onClick={resetFocus2} value={stateDict.cardNumber} name={"cardNumber"} />
					<SelectCreditCardType onChange={handleInputChange} value={stateDict.cardType} onClick={resetFocus2} name={'cardType'} />
					<SelectCreditCardExpiration onClick={resetFocus2} onMonthChange={handleInputChange} monthValue={stateDict.expireM} monthName={'expireM'}
					onYearChange={handleInputChange} yearValue={stateDict.expireY} yearName={'expireY'} />
					<Input onFocus={handleAmountChange} label={"Payment Amount"} mask="$" onClick={resetFocus2} 
						onBlur={() => console.log('blur')} onChange={handleInputChange} value={stateDict.amount} name={"amount"} />
				</FormCol>
				</div>
				</form>
				<div className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
					{paymentStatus != "" ? paymentStatus == "success" ? <SavedAlert saved={true} label={'Payment Completed'} /> : <ErrorAlert error={true} label={"Failure: " + paymentStatus} /> : null }
		    		<button onClick={() => confirmPayment(stateDict.amount) } className={paymentStatus != "" ? "hidden" : "gradient-btn"}>Submit Payment</button>
		    	</div>
				</div>
			}

		</div>
		)
}

//<Input className="hidden" label={"QDP Member"} type="checkbox" onChange={handleInputChange} value={stateDict.isMember} name={'isMember'} />		            
