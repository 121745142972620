import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";

import { UserContext } from './UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import SearchBar from './SearchBar'

import Table2 from "./components/Table2";
import { CSVLink } from "react-csv";
import { TextField } from "@material-ui/core";
import moment from "moment";
import MaskedInput from "react-text-mask";
import useDebounce from "./hooks/useDebounce";


function makeComparator(key, order = 'desc') {

	//sorting function

	return (a, b) => {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

		const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
		const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (aVal > bVal) comparison = 1;
		if (aVal < bVal) comparison = -1;

		return order === 'desc' ? (comparison * -1) : comparison
	};
}


const dateFormat = 'MM/DD/YY'
const today = moment()
const lastMonth = today.subtract(1, 'months').format(dateFormat)
export default function OfficePayments(props) {

	const auth = useContext(UserContext)

	const [payments, setPayments] = useState([])
	const [offices, setOffices] = useState([])

	const [timeZoneOffset] = useState(new Date().getTimezoneOffset() / 60)
	const [officeId, setOfficeId] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [startDate, setStartDate] = useState(lastMonth)
	const [endDate, setEndDate] = useState('')
	const [testsEnabled, setTestsEnabled] = useState(false)
	const debouncedSearchData = useDebounce(
		useCallback(
			() => ({
				query: searchQuery,
				date: { start: startDate, end: endDate }
			}), 
			[searchQuery, startDate, endDate]
		)
	, 500);


	async function fetchOffices() {

		//used to populate search bar for filtering
		const membersData = await API.graphql(graphqlOperation(`query ListOffices {
        listOffices(limit: 9999) {
          items {
              id
              name
              primaryContact
              phone
              email
              zip
              active
          }
        }
        }`))

		let membersList = membersData.data.listOffices.items
		let rowCount = 1
		let newMember = []

		let newMembersList = []
		for (let i = 0; i < membersList.length; i++) {
			newMember = { ...membersList[i], rowId: rowCount }
			newMembersList.push(newMember)
			rowCount = rowCount + 1
		}

		setOffices(newMembersList)
	}

	const fetchOfficePayments = useCallback(
		async () => {
		const queries = []
		if (officeId && officeId.length > 0 && officeId !== 'all') {
			queries.push(`officeId: { eq: "${officeId}" }`)
		}

		if (debouncedSearchData.date) {
			const { start, end } = debouncedSearchData.date
			const hasStart = start && start.length > 0 && moment(start, dateFormat).isValid()
			const hasEnd = end && end.length > 0 && moment(end, dateFormat).isValid()
			
			const getDate = (v) => moment(v, dateFormat)
			const getStart = () => getDate(start).format('YYYY-MM-DD')
			const getEnd = () => getDate(end).format('YYYY-MM-DD')

			if (hasStart && hasEnd) {
				queries.push(`createdAt: { between: ["${getStart()}", "${getEnd()}"] }`)
			}
			else if (hasStart) {
				const date = getStart()
				queries.push(`createdAt: { gt: "${date}" }`)
			}
			else if (hasEnd) {
				const date = getEnd()
				queries.push(`createdAt: { lt: "${date}" }`)
			}

		}

		if (debouncedSearchData.query && debouncedSearchData.query.length > 0) {
			const query = debouncedSearchData.query
			queries.push(`
				or: [
					{ description: { contains: "${query}" } }, 
					{ status: { contains: "${query}" } },
					{ paymentInfo: { contains: "${query}" } },
					{ contactName: { contains: "${query}" } } 
				]
			`)
		}


		let queryStr = ''
		if (queries.length > 0) {
			queryStr = `filter: {${queries.join(',')}}, `
		}


		const officePaymentsData = await API.graphql(graphqlOperation(`
		query ListOfficePayments {
		    listOfficePaymentss(${queryStr}limit: 9999) {
		    	items {
		          id
		          name
		          officeId
		          office
		          description
		          grossPay
		          fee
		          netPay
		          confirmationToken
		          createdAt
				  contactName
				  paymentInfo
				  status
				  cash
			    }
			}
		}`))

		const userPaymentsData = officePaymentsData.data.listOfficePaymentss.items


		let newPaymentsList = []
		let rowCount = 1
		let payDate
		for (let i = 0; i < userPaymentsData.length; i++) {
			payDate = new Date(userPaymentsData[i]['createdAt'])
			payDate = payDate.setHours(payDate.getHours() - timeZoneOffset)
			payDate = new Date(payDate)

			const newPayment = { ...userPaymentsData[i], rowId: rowCount, createdAt: payDate.toISOString(), timestamp: payDate.getTime() }
			newPaymentsList.push(newPayment)
			rowCount = rowCount + 1
		}

		const newPaymentsList2 = newPaymentsList.sort(makeComparator('timestamp'))


		newPaymentsList = []
		rowCount = 1
		for (let i = 0; i < newPaymentsList2.length; i++) {
			const newPayment = { ...newPaymentsList2[i], rowId: rowCount, timestamp: new Date(userPaymentsData[i]['createdAt']).getTime() }
			newPaymentsList.push(newPayment)
			rowCount = rowCount + 1
		}

		setPayments(newPaymentsList)
	}, [debouncedSearchData.date, debouncedSearchData.query, officeId, timeZoneOffset])


	const selectOffice2 = [
		{ key: "all", label: "All Offices", value: "all" },
		...offices.map(office => ({ key: office.id, value: office.id, label: office.name }))
	]

	useEffect(() => {
		fetchOffices()
	}, [auth])

	useEffect(() => {
		fetchOfficePayments()
	}, [fetchOfficePayments])



	const payments2 = payments.filter(p => testsEnabled || (p.confirmationToken && !p.confirmationToken.includes('TEST')))
	const columns = useRef(
		[
			{ column: 'Date', value: 'createdAt' },
			{ column: 'Office', value: 'name' },
			{ column: 'Description', value: 'description', class: 'flex' },
			// { column: 'Account Holder', value: 'contactName' },
			{ column: 'Status', value: 'status' },
			// { column: 'Practice', value: 'practice' },
			{ column: 'Payment Details', value: 'paymentInfo' },
			{ column: 'Amount', value: 'netPay' }
		]
	).current

	const listPaymentsCsv = useMemo(() => {
		return [
			columns.map(c => c.column),
			...payments2.map(payment => columns.map(c => payment[c.value]))
		]
	}, [columns, payments2])

	const totals = useMemo(() => {

		let cash = 0
		let card = 0

		payments2.forEach(p => {
			
			if (p.cash) { cash += p.netPay }
			else { card += p.netPay}
		})

		const lang = 'en-US'
		const opts = {
			style: 'currency',
			currency: 'USD'
		}

		return { 
			cash: cash.toLocaleString(lang, opts),
			card: card.toLocaleString(lang, opts)
		}
	}, [payments2])


	return (
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
				<div className="w-1/2 rounded-sm">
						<h1 className="ch1">All Payments</h1>
						Card: {totals.card}<br/>
						Cash: {totals.cash}<br/>
						Count: {payments2.length}
					</div>

					<div className='w-1/2 rounded-sm text-right'>
						<CSVLink className="my-auto gradient-btn mt-4 mx-2" filename={"payments.csv"} data={listPaymentsCsv} >Export</CSVLink>
					</div>
				</div>
				<div className="flex w-full bg-white tiny-card mb-2">
					<form className="w-1/6 mx-2">
						<SearchBar placeholder='Select Office' options={selectOffice2} onChange={e => setOfficeId(e.value)} />
					</form>
					<form className='w-1/2'>
						<TextField className='w-full'  placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
					</form>
					<form className='w-2/3 mx-2'>
					<input className='mx-2' name='testsEnabled' type='checkbox' checked={testsEnabled} onChange={e => setTestsEnabled(e.target.checked)} />
							Show Tests
					</form>
				</div>
				<div className="flex w-full bg-white tiny-card mb-2">
					<p className='mx-6'>Date Range:</p>	
					<MaskedInput
							className='border w-1/6'
							mask={[/\d/,/\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]} 
							value={startDate} 
							onChange={e => setStartDate(e.target.value)} 
							placeholder='MM/DD/YY' />
					<p className='px-1'>-</p>
					<MaskedInput 
						className='border w-1/6'
						mask={[/\d/,/\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]} 
						value={endDate} 
						onChange={e => setEndDate(e.target.value)} 
						placeholder='MM/DD/YY' />
				</div>

				<Table2
					id='admin-payments-table'
					sortField='createdAt' sortOrder='desc'
					customComponents={{ status: (field) => field === 'failure' ? <CloseIcon className="stream-icon-md" /> : <CheckIcon className="stream-icon-pc" /> }}
					columns={columns}
					fields={[...columns.map(c => c.value), 'confirmationToken']}
					values={payments2} />
			</div>
		</div>

	)
}