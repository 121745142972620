import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { UserContext } from './UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import Table2 from './components/Table2'
import Select from 'react-select'

import { useListQL } from './hooks/useListQL'
import { paymentSchema, practiceSchema } from './schemas/schemas'


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";
import { refundPayment, submitTokenPayment } from "./usio/payments";

import { TextField } from "@material-ui/core";
import moment from "moment";
import MaskedInput from "react-text-mask";
import useDebounce from "./hooks/useDebounce";
import SearchBar from "./SearchBar";
import { createNumberMask } from "text-mask-addons";


function makeComparator(key, order = 'desc') {
	//basic sorting function

	return (a, b) => {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

		const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
		const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (aVal > bVal) comparison = 1;
		if (aVal < bVal) comparison = -1;

		return order === 'desc' ? (comparison * -1) : comparison
	};
}

const dateFormat = 'MM/DD/YY'
const today = moment()
const lastMonth = today.subtract(1, 'months').format(dateFormat)
export default function Payments(props) {

	const auth = useContext(UserContext)

	const [practice, setPractice] = useState(null)
	const [payments, setPayments] = useState([])

	//payments queries and delete functionalty handled with the listQL hook
	// const { saved, stateDict, handleChange, refresh } = useListQL(paymentSchema, { name: "office", value: auth.officeId || auth.user })
	const practiceState = useListQL(
		practiceSchema,
		useMemo(() => ({ name: "office", value: auth.officeId || auth.user }), [auth.officeId, auth.user])
	)

	const [timeZoneOffset] = useState(new Date().getTimezoneOffset() / 60)
	const [searchQuery, setSearchQuery] = useState('')
	const [startDate, setStartDate] = useState(lastMonth)
	const [endDate, setEndDate] = useState('')
	const debouncedSearchData = useDebounce(
		useMemo(
			() => ({
				query: searchQuery
			}),
			[searchQuery]
		)
		, 500);


	const fetchOfficePayments = useCallback(
		async function () {
			const queries = [
				`office: { eq: "${auth.officeId || auth.user}" }`
			]
			if (practice && practice.length > 0 && practice !== 'all') {
				queries.push(`practice: { eq: "${practice}" }`)
			}

			if (startDate || endDate) {
				const { start, end } = { start: startDate, end: endDate }
				const hasStart = start && start.length > 0 && moment(start, dateFormat).isValid()
				const hasEnd = end && end.length > 0 && moment(end, dateFormat).isValid()

				const getDate = (v) => moment(v, dateFormat)
				const getStart = () => getDate(start).format('YYYY-MM-DD')
				const getEnd = () => getDate(end).format('YYYY-MM-DD')

				if (hasStart && hasEnd) {
					queries.push(`createdAt: { between: ["${getStart()}", "${getEnd()}"] }`)
				}
				else if (hasStart) {
					const date = getStart()
					queries.push(`createdAt: { gt: "${date}" }`)
				}
				else if (hasEnd) {
					const date = getEnd()
					queries.push(`createdAt: { lt: "${date}" }`)
				}

			}

			if (debouncedSearchData.query && debouncedSearchData.query.length > 0) {
				const query = debouncedSearchData.query
				queries.push(`
				or: [
					{ description: { contains: "${query}" } }, 
					{ status: { contains: "${query}" } },
					{ paymentInfo: { contains: "${query}" } },
					{ contactName: { contains: "${query}" } } 
				]
			`)
			}


			let queryStr = ''
			if (queries.length > 0) {
				queryStr = `filter: {${queries.join(',')}}, `
			}


			const officePaymentsData = await API.graphql(graphqlOperation(`
		query ListOfficePayments {
		    listPaymentss(${queryStr}limit: 9999) {
		    	items {
		          id
		          createdAt

				  practice
				  memberName
		          office
		          description
		          grossPay
		          fee
		          netPay
				 
				  planId
				  memberId
				  contactName
				  paymentInfo
				  cash

		          confirmationToken
				  status
			    }
			}
		}`))

			const userPaymentsData = officePaymentsData.data.listPaymentss.items


			let newPaymentsList = []
			let rowCount = 1
			let payDate
			for (let i = 0; i < userPaymentsData.length; i++) {
				payDate = new Date(userPaymentsData[i]['createdAt'])
				payDate = payDate.setHours(payDate.getHours() - timeZoneOffset)
				payDate = new Date(payDate)

				const paymentInfo = userPaymentsData[i].paymentInfo

				const newPayment = {
					...userPaymentsData[i],
					rowId: rowCount,
					createdAt: payDate.toISOString(),
					timestamp: payDate.getTime(),
					paymentInfo: (paymentInfo || '').replace('undefined', '')

				}
				newPaymentsList.push(newPayment)
				rowCount = rowCount + 1
			}

			const newPaymentsList2 = newPaymentsList.sort(makeComparator('timestamp'))


			newPaymentsList = []
			rowCount = 1
			for (let i = 0; i < newPaymentsList2.length; i++) {
				const newPayment = { ...newPaymentsList2[i], rowId: rowCount, timestamp: new Date(userPaymentsData[i]['createdAt']).getTime() }
				newPaymentsList.push(newPayment)
				rowCount = rowCount + 1
			}

			setPayments(newPaymentsList)
		}, [auth.officeId, auth.user, debouncedSearchData.query, endDate, practice, startDate, timeZoneOffset])



		useEffect(() => {
			fetchOfficePayments()
		}, [fetchOfficePayments])

	const handlePracticeChange = (event) => {
		const practiceName = [event.target.value][0]
		if (practiceName != "all") {
			const pickedPractice = practiceState.stateDict.filter(practice => practice.name == practiceName)[0]
			setPractice(pickedPractice.name)
		}
		else {
			setPractice("All")
		}
	}

	/// Marks any NonMemberPayments with the given `planId` 
	/// as active = false
	const cancelNonMemberFuturePayments = async (refundPayment) => {
		const planId = refundPayment['planId']

		/// If there's a valid memberId, then the user is a member.  Otherwise, they're a non-member
		const memberId = refundPayment['memberId']
		if (memberId && memberId.length > 0) { return }

		const response = await API.graphql(graphqlOperation(
			`mutation create {
				updateNonMemberFinance(
					input: { 
						id: "${planId}",
						active: false
					}) {
						id
						active
					}
			}`
		))

		console.log(`Non-member payment plan update `, response)
		return response
	}

	const handleRefund = async (record) => {

		const handleSuccess = async (status) => {
			const result = await API.graphql(graphqlOperation(
				`mutation create {
			  updatePayments(input: {id: "${record.id}", status: "${status}", confirmationToken: "${status}"}){
				id
				status
				confirmationToken
			  }
			}`))
			record['status'] = status
			record['confirmationToken'] = status

			await cancelNonMemberFuturePayments(record)

			// handleChange(record)
			fetchOfficePayments()
		}


		let confirmationToken = record.confirmationToken.replace('successsuccess', '')

		try {
			const response = await refundPayment({
				ConfirmationID: confirmationToken,
				Amount: `${record.netPay}`,

				MerchantID: props.officeMerchantKey,
				Login: props.officeUsioLogin,
				Password: props.officeUsioPassword
			})

			if (response.status && response.status.includes('success')) {
				handleSuccess('refunded')
			}
			else {
				alert(JSON.stringify(response))
			}
		}
		catch (ex) {
			alert(ex.message)
		}

	}


	const confirmRefund = (e) => {
		let token = e.target.id

		const payment = payments.filter(p => p.confirmationToken == token)[0]
		const isRepeatedPayment = payment.planId && payment.planId.length > 0
		const paymentString = `$${payment.grossPay.toFixed(2)}`

		const confirmMessage = isRepeatedPayment ?
			`Are you sure you want to refund this payment of ${paymentString} and cancel any future payments?? Any other payments already captured will need to be refunded separately?`
			:
			`Are you sure you want to refund this payment of ${paymentString}??`

		confirmAlert({
			title: 'Confirm to submit',
			message: confirmMessage,
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleRefund(payment)
				},
				{
					label: 'No',
					onClick: () => null
				}
			]
		});
	}

	const customComponents = () => {


		return {
			confirmationToken: (field, record) => {
				const isFailure = (field || "").toLowerCase().includes('fail') || field === '' || (record.status || '').toLowerCase().includes('fail')
				const isRefunded = field === "refunded"
				const isCancelled = field === 'cancelled'
				// console.log(`Checking confirmation status ${(field||'na').toLowerCase()}`)


				if (isFailure) {

					/// TODO: Check if card is expired here
					const handleUpdateCard = () => {
						/// Here we just navigate to the new card

						/// For non-members we'll need a new UI for this card update setup. We'll basically
						/// Need to regenerate the paymentToken


						/// For regular members we can push to /member/{memberId}/info
					}

					// TODO: Determine credentials
					const RetryButton = () => {
						const [paymentInfo, setPaymentInfo] = useState(null)
						const [retrying, setRetrying] = useState(false)

						const xmlResponseHandler = (resolve, reject) => {
							return function () {
								if (this.readyState != 4) { return }

								if (this.status == 200) {
									const responseObj = JSON.parse(this.responseText)
									const responseToken = responseObj['Confirmation']
									if (!responseToken || responseToken.length === 0) {
										return reject(responseObj)
									}

									resolve(responseObj)
								}
								else if (this.responseText != "") {
									const response = JSON.parse(this.responseText)
									reject(response)
								}
								else {
									alert(`Unknown state?`)
								}
							}
						}


						const handleRetry = async (paymentToken) => {
							/// We need to get the paymentToken from either NonMemberFinance or MemberFinance and
							/// attempt to reprocess using the current `record.netPay`. Show any errors if applicable.

							// - Retry the current payment amount and everything
							// - On success update the current payment response


							// https://api.securepds.com/2.0/documentation/#tokenpayment
							setRetrying(true)
							console.log(props)
							const obj = {
								MerchantID: props.officeMerchantKey,
								Login: props.officeUsioLogin,
								Password: props.officeUsioPassword,

								// Amount: '1.00',	// For testing
								Amount: record.netPay,
								Token: paymentToken
							}

							try {
								const result = await submitTokenPayment(obj)

								await API.graphql(graphqlOperation(
									`mutation UpdatePaymentLogs {
										updatePayments(input: { id: "${record.id}", confirmationToken: "${result['Confirmation']}", status: "${result['Status']}"}) {
											id
										}
									}`
								))

								fetchOfficePayments()
							}
							catch (ex) {
								console.error(ex)
								alert(`Error retrying ${ex.message}`)
							}

							setRetrying(false)
						}


						const fetchMemberFinances = async (memberId) => {
							try {
								const result = await API.graphql(graphqlOperation(
									`
									query GetPaymentInfo {
	
										listMemberFinances(filter: { memberId: { eq: "${memberId}" } }, limit: 9999) {
											items {
												expiration
												paymentToken
											}
										}
	
									}
									`
								))

								setPaymentInfo(result.data.listMemberFinances.items[0])
							}
							catch (ex) {
								console.error(ex)
								setPaymentInfo({ empty: true })
							}
						}

						const fetchNonMemberFinances = async (nonMemberId) => {
							try {
								const result = await API.graphql(graphqlOperation(
									`
									query GetPaymentInfo {
	
										listNonMemberFinances(filter: { id: { eq: "${nonMemberId}" } }, limit: 9999) {
											items {
												expiration
												paymentToken
											}
										}
	
									}
									`
								))

								setPaymentInfo(result.data.listNonMemberFinances.items[0])
							}
							catch (ex) {
								console.error(ex)
								setPaymentInfo({ empty: true })
							}
						}


						useEffect(() => {
							if (record.memberId && record.memberId.length > 0) {
								fetchMemberFinances(record.memberId)
							}
							else if (record.planId && record.planId.length > 0) {
								fetchNonMemberFinances(record.planId)
							}
							else {
								console.log(`nothing to do here`)
								setPaymentInfo({})
							}
						}, [])

						if (!paymentInfo) {
							return <div>Fetching...</div>
						}

						if (paymentInfo.empty) {
							return <p>N/A</p>
						}

						if (!paymentInfo.paymentToken) {
							return <p>Failed</p>
						}

						/// Check the expiration `MMYYYY`
						/// If expired, we can't do a retry.  We can only update the card

						const expM = Number(paymentInfo.expiration.substring(0, 2))
						const expY = Number(paymentInfo.expiration.substring(2, 6))
						const now = new Date()
						const curY = now.getFullYear()
						const curM = now.getMonth() + 1

						const isValid = (curY < expY || (curY == expY && expM >= curM))

						if (!isValid) {
							return <p>Expired</p>
						}

						return (
							<button disabled={retrying} className="gradient-btn payments-retry" id={`${field}-retry`} onClick={() => handleRetry(paymentInfo.paymentToken)}>
								Retry
							</button>
						)
					}


					if (!record.memberId || record.memberId.length == 0) {
						/// Only thing we can do is retry for now
						return <RetryButton />
					}

					return (
						<div className="flex items-center flex-col payments-status-failed-col">
							<RetryButton />
							<Link className="gradient-btn update-link" id={`${field}-update`} to={`member/${record.memberId}/info`}>
								Update Card
							</Link>
						</div>
					)
				}

				if (isFailure || isRefunded || isCancelled || record.cash == true) {
					return field
				}

				return (
					<button id={field} onClick={confirmRefund} className="gradient-btn">
						Refund
					</button>
				)
			},
			status: (field, record) => {
				const isFailure = (field || '').toLowerCase().includes('fail')
				const isRefunded = field === "refunded"
				const isCancelled = field === 'cancelled'
				const isMember = record.memberId && record.memberId.length > 0

				/// In case of failure lets' add a retry or update card button

				if (isRefunded || isCancelled) { return field.charAt(0).toUpperCase() + field.slice(1); }

				return isFailure ? <CloseIcon className="stream-icon-md" /> : <CheckIcon className="stream-icon-pc" />
			}
		}
	}


	const columns = useRef(
		[
			{ column: 'Date', value: 'createdAt' },
			{ column: 'Member', value: 'memberName' },
			{ column: 'Description', value: 'description' },
			{ column: 'Account Holder', value: 'contactName' },
			{ column: 'Status', value: 'status' },
			{ column: 'Practice', value: 'practice' },
			{ column: 'Payment Details', value: 'paymentInfo' },
			{ column: 'Amount', value: 'netPay' }
		]
	).current

	const listPaymentsCsv = useMemo(() => {
		return [
			columns.map(c => c.column),
			...payments.map(payment => columns.map(c => payment[c.value]))
		]
	}, [columns, payments])

	const totals = useMemo(() => {

		let cash = 0
		let card = 0
		let refunded = 0

		payments.forEach(p => {

			if (p.status.includes('refund')) {
				refunded += p.netPay
			}
			else if (p.cash) { cash += p.netPay }
			else { card += p.netPay }
		})

		const lang = 'en-US'
		const opts = {
			style: 'currency',
			currency: 'USD'
		}

		return {
			refunded: refunded.toLocaleString(lang, opts),
			cash: cash.toLocaleString(lang, opts),
			card: card.toLocaleString(lang, opts)
		}
	}, [payments])


	const selectPractice = [
		{ key: 'all', label: 'All Practices', value: null },
		...practiceState.stateDict.map(p => ({ key: p.id, value: p.name, label: p.name }))
	]

	return (
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm">
						<h1 className="ch1">All Payments</h1>
						Card: {totals.card}<br />
						Cash: {totals.cash}<br />
						Refunded: {totals.refunded}<br />
						Count: {payments.length}
					</div>

					{/* <div className="w-1/2 rounded-sm text-right"><Link to="/createmember/info" className="gradient-btn">Add Member</Link></div> */}
					<div className='w-1/2 rounded-sm text-right'>
						<CSVLink className="my-auto gradient-btn mt-4 mx-2" filename={"payments.csv"} data={listPaymentsCsv} >Export</CSVLink>
					</div>
				</div>
				<div className="flex w-full bg-white tiny-card p-4">
					{props.officeMultiplePractices ?
						<div className="w-1/2">
							<Select
								className='mx-4'
								styles={{
									container: (base) => ({ ...base, flex: 1 }),
									indicatorsContainer: () => ({ border: 'none' })
								}}
								onChange={e => setPractice(e.value)}
								options={selectPractice}
								value={practice}
								placeholder={practice || 'All Practices'} />
						</div> : null}
					<form className='w-1/2 mx-4'>
						<TextField className='w-full' placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
					</form>
				</div>
				<div className="flex w-full bg-white tiny-card mb-2">
					<p className='mx-6'>Date Range:</p>
					<MaskedInput
						className='border w-1/6'
						mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
						value={startDate}
						onChange={e => setStartDate(e.target.value)}
						onBlur={() => fetchOfficePayments()}
						placeholder='MM/DD/YY' />
					<p className='px-1'>-</p>
					<MaskedInput
						className='border w-1/6'
						mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
						value={endDate}
						onChange={e => setEndDate(e.target.value)}
						onBlur={() => fetchOfficePayments()}
						placeholder='MM/DD/YY' />
				</div>

				<Table2 sortField='createdAt' sortOrder='desc' saved={false} savedLabel={"Refunded"}
					customComponents={customComponents()}
					columns={columns}
					fields={[...columns.map(c => c.value), 'confirmationToken']}
					values={payments} />

			</div>
		</div>

	)
}