import React, { useState, useEffect, useMemo, useCallback }  from "react";
import {UserContext} from './UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Select from 'react-select'

import SearchBar from './SearchBar'

import {CSVLink} from 'react-csv';

import Table from './components/Table'

import {useListQL} from './hooks/useListQL'
import {memberSchema} from './schemas/schemas'

const sumPlans = (memberPlans, typeFilter=null, intervalFilter=null, practiceFilter=null) => {

	//used to summarize the number of patient plans each office currently has

	//filters for active members

	let planMembers = memberPlans.filter(member => member.active === true)
	
	//sumPlans can be used to filter through different types and intervals, so filters here are based on those input parameters
	if((typeFilter != null) && (typeFilter != "All")){
		planMembers = planMembers.filter(member => member.plan.includes(typeFilter))
	}
	if((intervalFilter != null) && (intervalFilter != "All")){
		planMembers = planMembers.filter(member => member.paymentInterval === intervalFilter)
	}
	if(practiceFilter != null){
		planMembers = planMembers.filter(member => member.practice === practiceFilter)
	}
	return planMembers.length
}

const listPlans = (memberPlans, typeFilter=null, intervalFilter=null, practiceFilter=null) => {
	
	//instead of displaying the sum total plans for an office, this function can return a list of signup dates for a given office's plans
	//in the project, we list out year plans in the offices table

	let planMembers = memberPlans.filter(member => member.active === true)
	if((typeFilter != null) && (typeFilter != "All")){
		planMembers = planMembers.filter(member => member.plan.includes(typeFilter))
	}
	if((intervalFilter != null) && (intervalFilter != "All")){
		planMembers = planMembers.filter(member => member.paymentInterval === intervalFilter)
	}
	if(practiceFilter != null){
		planMembers = planMembers.filter(member => member.practice === practiceFilter)
	}

	const planDates = planMembers.map(member => member.createdAt.split('T')[0].split('-').slice(0, 3).join('-'))

	return planDates
}

const calcPlanStats = (members, practice=null) => {

	//loops through plantypes and intervals, and passes the sumPlans and listPlans functions to
	//calculate plan activity for the provided office members

	let planTypes = ['All']
	let planIntervals = ['monthly', 'yearly']

	let statDict = {}
	let currStat
	let yearList
	for(let i =0; i<planTypes.length; i++){
		for(let j = 0; j<planIntervals.length; j++){
			if(j !== 'All'){
				currStat = sumPlans(members, planTypes[i], planIntervals[j], practice)
				if(planIntervals[j] === 'yearly'){
					yearList = listPlans(members, planTypes[i], planIntervals[j], practice).join(', ')
				}
			}
			else{
				currStat = sumPlans(members, planTypes[i], null, practice)
			}
			statDict[`${planTypes[i]} - ${planIntervals[j]}`] = currStat
		}
	}
	return [statDict, yearList]
}

export default function Offices(props) {
	//please note that offices here are represented by the members variable 
	const [members, setMembers] = useState([])
	const [office, setOffice] = useState("All")
	const memberState = useListQL(memberSchema, useMemo(() => ({name: "active", value: true}), []))
	const [active, setActive] = useState(true)

	const listOfficesWithPlans = members.map(member => {

		//maps each office, filtering for its members and calculting its plan statistics
		let officeMembers = memberState.stateDict.filter(officeMember => officeMember.office === member.id)
		let officePlanStats = calcPlanStats(officeMembers)[0]
		let officeYearPlans = calcPlanStats(officeMembers)[1]
		return({...member, "plansMn": officePlanStats['All - monthly'],
		 "plansYr": officePlanStats['All - yearly'], "yrList": officeYearPlans})
	})

	const filterOffices = (members) => {

		//handles using the office select filter to display filtered values in table
		let newMembers = members
		if(office != "All"){
			newMembers = newMembers.filter(member => member.name === office)
		}
		if(active != "All"){
			newMembers = newMembers.filter(member => member.active === active)
		}

		//newMembers = newMembers.filter(member => member.active === true)
		return newMembers
		}

	const handleChangeOffice = (event) =>{

		//similar to handleChangePractice on Members.js (see for more commentary)
		const officeName = event.value
		if(officeName != "all"){
			const pickedOffice = members.filter(office => office.name === officeName)[0]
			setOffice(pickedOffice.name)

	        let rowCount = 1
	        let newMember

	        let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  if(members[i]['practice'] === pickedOffice.name){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			  }
			  else{
			  	newMember = members[i]
			  	newMembersList.push(newMember)
			  }
			}
      		setMembers(newMembersList)
      	}
		else{
			setOffice("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			}
			setMembers(newMembersList)

		}
	}

	const confirmOfficeActiveChange = (event) => {

		//confirms activation change intent
		const targetOfficeId = event.target.id
		const targetOfficeChecked = event.target.checked
		const targetOfficeName = event.target.name
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: !event.target.checked ? "Are you sure you want to deactivate member?" : "Are you sure you want to activate member?",
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleOfficeActiveChange(targetOfficeId, targetOfficeChecked, targetOfficeName)}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }


	const handleOfficeActiveChange = async (id, checked, name) => {
		
		//similar to confirmMemberActiveChange on Members.js (see for more commentary)

		const targetMemberId = id
		const targetMemberChecked = checked
		const targetMemberName = name

		const newMembers = members.map(member => {
			if(member.id === targetMemberId){
				const newActive = targetMemberChecked
				return({...member, active: newActive})
			}
			else{
				return member
			}
		})


		const newMember = members.filter(member => member.id === targetMemberId)[0]

		//updates the offices active status in dynamodb
		const updateOffice = await API.graphql(graphqlOperation(
		      `mutation update {
		        updateOffice(input: {id: "${targetMemberId}", active: ${targetMemberChecked}}){
		          id
		          active
		        }
		      }`))


		//creates office activity log for live stream on AdminDashboard.js
		const createOfficeLog = await API.graphql(graphqlOperation(
		      `mutation create {
		        createOfficeLog(input: {officeId: "${targetMemberId}", office: "${targetMemberName}",
		    	activity: "${targetMemberChecked ? "Active Member" : "Inactive Member"}"}){
		          id
		          officeId
		          office
		          activity
		        }
		      }`))


		setMembers(newMembers)
	}

    const fetchOffices = useCallback(
	async () => {

      //fetch all offices

      const membersData = await API.graphql(graphqlOperation(`query ListOffices {
        listOffices(limit: 9999) {
          items {
              id
              name
              primaryContact
              phone
              email
              zip
              active
          }
        }
        }`))

      let membersList = membersData.data.listOffices.items
      let rowCount = 1
      let newMember = []

      let newMembersList = []
      for(let i=0; i<membersList.length; i++){
		newMember = {...membersList[i], rowId: rowCount, active: membersList[i]['active'] ? true : false}
		newMembersList.push(newMember)
		rowCount = rowCount + 1
		}

	  //note that on this component, fetched offices are saved under the member state variable!
      setMembers(newMembersList)
    }, [])

    const selectOffice2 = members.map(member => ({key:member.id, value: member.name, label: member.name}))

	const listOfficesCsv = listOfficesWithPlans.map(office => {
		return([office.name, office.active ? "active" : "inactive", office.primaryContact,
		 office.phone, office.email, office.plansMn, office.plansYr])
	})


	const handleChangeActive = (event) =>{

		//archived 

		const officeStatus = event.value
		if(officeStatus != "all"){
			setActive(officeStatus)

	        let rowCount = 1
	        let newMember

	        let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  if(members[i]['active'] === officeStatus){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			  }
			  else{
			  	newMember = members[i]
			  	newMembersList.push(newMember)
			  }
			}
      		setMembers(newMembersList)
      	}
		else{
			setActive("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
	        for(let i=0; i< members.length; i++){
			  	newMember = {...members[i], rowId: rowCount}
			  	newMembersList.push(newMember)
			  	rowCount = rowCount + 1
			}
			setMembers(newMembersList)

		}
	}

    const selectActive = [{key:"active", label:"Active", value:true}, {key:"inactive", label:"Inactive", value:false}, {key:"all", label:"All", value:"all"}]

	const customStyles = {
	  option: (provided, state) => ({
	    ...provided,
	    padding: 6,
	    textTransform: 'uppercase',
	    fontSize: 11,
	    letterSpacing: 1.2
	  }),
	  control: () => ({
	    // none of react-select's styles are passed to <Control />
	    display: 'flex'
	  }),
		container: (base) => ({
		    ...base,
		    flex: 1
		  })
		,
		placeholder: (provided) => ({
		    ...provided,
		    padding: 6,
		    textTransform: 'uppercase',
		    fontSize: 11,
		    letterSpacing: 1.2,
		    marginY: 'auto'
		}),
		indicatorsContainer: () => ({
			border: 'none',
			alignItems: 'center',
  			alignSelf: 'stretch',
  			display: 'flex'
		}),
		indicatorSeparator: () => ({
			width: 4
		}),
	    singleValue: (provided, state) => {
	    const opacity = state.isDisabled ? 0.5 : 1;
	    const transition = 'opacity 300ms';

	    return { ...provided,
	    		 opacity,
	    		  transition,
	    		   textTransform: 'uppercase',
	    		   fontSize: 11,
	    			letterSpacing: 1.2,
	    			display: 'inline'
	    		    };
	  }
	}

	useEffect(() => {

		//fetch practices is archived 
		fetchOffices()
	}, [fetchOffices])

	return(
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">Offices</h1></div>
				</div>				

		        <div className="card text-left">
		        	<div className="w-full my-2 flex justify-between">
						<form className="w-2/3 mx-2 my-auto flex">
			            	<SearchBar options={selectOffice2} onChange={handleChangeOffice} />
							<div className="mx-8"></div>
							<Select onChange={handleChangeActive} styles={customStyles} defaultValue={selectActive[0]} options={selectActive} />

			            </form>
			            <CSVLink className="my-auto gradient-btn mt-4 mx-2" filename={"offices.csv"} data={listOfficesCsv} >Export</CSVLink>
		            </div>

				</div>
				<Table pathPrefix={'admin/office'} edit={true} columns={['Name', 'Active', 'Phone', 'Email', 'Plans - Mn', 'Plans - Yr (Dates)']}
					fields={['name', 'active', 'phone', 'email', 'plansMn', 'yrList']}
					handlers={{'active': confirmOfficeActiveChange}} values={filterOffices(listOfficesWithPlans)} />
			</div>
		</div>

		)
}