import React, { useState, useEffect, useContext }  from "react";

import SearchIcon from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';

import Select from 'react-select'

const SearchBar = (props) => {
	
	const Search = () => {return(<SearchIcon style={{ color: grey[400]}} />)}

	const customStyles = {
	  option: (provided, state) => ({
	    ...provided,
	    padding: 6,
	    textTransform: 'uppercase',
	    fontSize: 11,
	    letterSpacing: 1.2
	  }),
	  control: () => ({
	    // none of react-select's styles are passed to <Control />
	    display: 'flex'
	  }),
		container: (base) => ({
		    ...base,
		    flex: 1
		  })
		,
		placeholder: (provided) => ({
		    ...provided,
		    padding: 6,
		    textTransform: 'uppercase',
		    fontSize: 11,
		    letterSpacing: 1.2,
		    marginY: 'auto'
		}),
		indicatorsContainer: () => ({
			border: 'none',
			alignItems: 'center',
  			alignSelf: 'stretch',
  			display: 'flex'
		}),
		indicatorSeparator: () => ({
			width: 4
		}),
	    singleValue: (provided, state) => {
	    const opacity = state.isDisabled ? 0.5 : 1;
	    const transition = 'opacity 300ms';

	    return { ...provided,
	    		 opacity,
	    		  transition,
	    		   textTransform: 'uppercase',
	    		   fontSize: 11,
	    			letterSpacing: 1.2,
	    			display: 'inline'
	    		    };
	  }
	}

	return(
		<Select options={props.options} onChange={props.onChange} components={{ DropdownIndicator: Search}} styles={customStyles} placeholder={props.placeholder || "Search"} />
		)
}

export default SearchBar