/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      accountType
      officeId
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      accountType
      officeId
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      accountType
      officeId
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const createOffice = /* GraphQL */ `
  mutation CreateOffice(
    $input: CreateOfficeInput!
    $condition: ModelOfficeConditionInput
  ) {
    createOffice(input: $input, condition: $condition) {
      id
      name
      primaryContact
      phone
      email
      address
      zip
      multiplePractices
      hasPractice
      hasPlan
      active
      merchantKey
      usioLogin
      usioPassword
      logo
      hasLoggedIn
      city
      state
      website
      createdAt
      isVerified
      disableReminders
      updatedAt
    }
  }
`;
export const updateOffice = /* GraphQL */ `
  mutation UpdateOffice(
    $input: UpdateOfficeInput!
    $condition: ModelOfficeConditionInput
  ) {
    updateOffice(input: $input, condition: $condition) {
      id
      name
      primaryContact
      phone
      email
      address
      zip
      multiplePractices
      hasPractice
      hasPlan
      active
      merchantKey
      usioLogin
      usioPassword
      logo
      hasLoggedIn
      city
      state
      website
      createdAt
      isVerified
      disableReminders
      updatedAt
    }
  }
`;
export const deleteOffice = /* GraphQL */ `
  mutation DeleteOffice(
    $input: DeleteOfficeInput!
    $condition: ModelOfficeConditionInput
  ) {
    deleteOffice(input: $input, condition: $condition) {
      id
      name
      primaryContact
      phone
      email
      address
      zip
      multiplePractices
      hasPractice
      hasPlan
      active
      merchantKey
      usioLogin
      usioPassword
      logo
      hasLoggedIn
      city
      state
      website
      createdAt
      isVerified
      disableReminders
      updatedAt
    }
  }
`;
export const createPendingOffice = /* GraphQL */ `
  mutation CreatePendingOffice(
    $input: CreatePendingOfficeInput!
    $condition: ModelPendingOfficeConditionInput
  ) {
    createPendingOffice(input: $input, condition: $condition) {
      id
      email
      website
      password
      name
      primaryContact
      phone
      address
      zip
      state
      logoUrl
      licensee
      by
      contractName
      title
      city
      agreementSignature
      status
      usioEmail
      usioDbaName
      usioLegalName
      usioResponse
      usioGuid
      createdAt
      updatedAt
    }
  }
`;
export const updatePendingOffice = /* GraphQL */ `
  mutation UpdatePendingOffice(
    $input: UpdatePendingOfficeInput!
    $condition: ModelPendingOfficeConditionInput
  ) {
    updatePendingOffice(input: $input, condition: $condition) {
      id
      email
      website
      password
      name
      primaryContact
      phone
      address
      zip
      state
      logoUrl
      licensee
      by
      contractName
      title
      city
      agreementSignature
      status
      usioEmail
      usioDbaName
      usioLegalName
      usioResponse
      usioGuid
      createdAt
      updatedAt
    }
  }
`;
export const deletePendingOffice = /* GraphQL */ `
  mutation DeletePendingOffice(
    $input: DeletePendingOfficeInput!
    $condition: ModelPendingOfficeConditionInput
  ) {
    deletePendingOffice(input: $input, condition: $condition) {
      id
      email
      website
      password
      name
      primaryContact
      phone
      address
      zip
      state
      logoUrl
      licensee
      by
      contractName
      title
      city
      agreementSignature
      status
      usioEmail
      usioDbaName
      usioLegalName
      usioResponse
      usioGuid
      createdAt
      updatedAt
    }
  }
`;
export const createPractice = /* GraphQL */ `
  mutation CreatePractice(
    $input: CreatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    createPractice(input: $input, condition: $condition) {
      id
      name
      businessName
      city
      zip
      office
      state
      createdAt
      updatedAt
    }
  }
`;
export const updatePractice = /* GraphQL */ `
  mutation UpdatePractice(
    $input: UpdatePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    updatePractice(input: $input, condition: $condition) {
      id
      name
      businessName
      city
      zip
      office
      state
      createdAt
      updatedAt
    }
  }
`;
export const deletePractice = /* GraphQL */ `
  mutation DeletePractice(
    $input: DeletePracticeInput!
    $condition: ModelPracticeConditionInput
  ) {
    deletePractice(input: $input, condition: $condition) {
      id
      name
      businessName
      city
      zip
      office
      state
      createdAt
      updatedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      benefits
      office
      planType
      recommendedFee
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      benefits
      office
      planType
      recommendedFee
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      benefits
      office
      planType
      recommendedFee
      createdAt
      updatedAt
    }
  }
`;
export const createAdminPlan = /* GraphQL */ `
  mutation CreateAdminPlan(
    $input: CreateAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    createAdminPlan(input: $input, condition: $condition) {
      id
      name
      basicBenefits
      customBenefits
      planType
      createdAt
      updatedAt
    }
  }
`;
export const updateAdminPlan = /* GraphQL */ `
  mutation UpdateAdminPlan(
    $input: UpdateAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    updateAdminPlan(input: $input, condition: $condition) {
      id
      name
      basicBenefits
      customBenefits
      planType
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdminPlan = /* GraphQL */ `
  mutation DeleteAdminPlan(
    $input: DeleteAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    deleteAdminPlan(input: $input, condition: $condition) {
      id
      name
      basicBenefits
      customBenefits
      planType
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name
      active
      contactName
      contactAddress
      contactCity
      contactZip
      contactPhone
      contactEmail
      planAgreement
      planAgreementDate
      customerSignature
      plan
      practice
      fixedBenefits
      office
      paymentInterval
      expiration
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name
      active
      contactName
      contactAddress
      contactCity
      contactZip
      contactPhone
      contactEmail
      planAgreement
      planAgreementDate
      customerSignature
      plan
      practice
      fixedBenefits
      office
      paymentInterval
      expiration
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      name
      active
      contactName
      contactAddress
      contactCity
      contactZip
      contactPhone
      contactEmail
      planAgreement
      planAgreementDate
      customerSignature
      plan
      practice
      fixedBenefits
      office
      paymentInterval
      expiration
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const createMemberFinance = /* GraphQL */ `
  mutation CreateMemberFinance(
    $input: CreateMemberFinanceInput!
    $condition: ModelMemberFinanceConditionInput
  ) {
    createMemberFinance(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      paymentInterval
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      createdAt
      nextRenewalDate
      renewalNotifications
      autoRenew
      member {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      officeRecord {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMemberFinance = /* GraphQL */ `
  mutation UpdateMemberFinance(
    $input: UpdateMemberFinanceInput!
    $condition: ModelMemberFinanceConditionInput
  ) {
    updateMemberFinance(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      paymentInterval
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      createdAt
      nextRenewalDate
      renewalNotifications
      autoRenew
      member {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      officeRecord {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMemberFinance = /* GraphQL */ `
  mutation DeleteMemberFinance(
    $input: DeleteMemberFinanceInput!
    $condition: ModelMemberFinanceConditionInput
  ) {
    deleteMemberFinance(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      paymentInterval
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      createdAt
      nextRenewalDate
      renewalNotifications
      autoRenew
      member {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      officeRecord {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createMemberBenefitsLog = /* GraphQL */ `
  mutation CreateMemberBenefitsLog(
    $input: CreateMemberBenefitsLogInput!
    $condition: ModelMemberBenefitsLogConditionInput
  ) {
    createMemberBenefitsLog(input: $input, condition: $condition) {
      id
      memberId
      benefit
      benefitType
      office
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberBenefitsLog = /* GraphQL */ `
  mutation UpdateMemberBenefitsLog(
    $input: UpdateMemberBenefitsLogInput!
    $condition: ModelMemberBenefitsLogConditionInput
  ) {
    updateMemberBenefitsLog(input: $input, condition: $condition) {
      id
      memberId
      benefit
      benefitType
      office
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberBenefitsLog = /* GraphQL */ `
  mutation DeleteMemberBenefitsLog(
    $input: DeleteMemberBenefitsLogInput!
    $condition: ModelMemberBenefitsLogConditionInput
  ) {
    deleteMemberBenefitsLog(input: $input, condition: $condition) {
      id
      memberId
      benefit
      benefitType
      office
      createdAt
      updatedAt
    }
  }
`;
export const createOneTimePayment = /* GraphQL */ `
  mutation CreateOneTimePayment(
    $input: CreateOneTimePaymentInput!
    $condition: ModelOneTimePaymentConditionInput
  ) {
    createOneTimePayment(input: $input, condition: $condition) {
      id
      token
      amount
      status
      createdAt
      office
      updatedAt
    }
  }
`;
export const updateOneTimePayment = /* GraphQL */ `
  mutation UpdateOneTimePayment(
    $input: UpdateOneTimePaymentInput!
    $condition: ModelOneTimePaymentConditionInput
  ) {
    updateOneTimePayment(input: $input, condition: $condition) {
      id
      token
      amount
      status
      createdAt
      office
      updatedAt
    }
  }
`;
export const deleteOneTimePayment = /* GraphQL */ `
  mutation DeleteOneTimePayment(
    $input: DeleteOneTimePaymentInput!
    $condition: ModelOneTimePaymentConditionInput
  ) {
    deleteOneTimePayment(input: $input, condition: $condition) {
      id
      token
      amount
      status
      createdAt
      office
      updatedAt
    }
  }
`;
export const createPayments = /* GraphQL */ `
  mutation CreatePayments(
    $input: CreatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    createPayments(input: $input, condition: $condition) {
      id
      memberId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      practice
      memberName
      createdAt
      office
      confirmationToken
      confirmationStatus
      cash
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const updatePayments = /* GraphQL */ `
  mutation UpdatePayments(
    $input: UpdatePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    updatePayments(input: $input, condition: $condition) {
      id
      memberId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      practice
      memberName
      createdAt
      office
      confirmationToken
      confirmationStatus
      cash
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const deletePayments = /* GraphQL */ `
  mutation DeletePayments(
    $input: DeletePaymentsInput!
    $condition: ModelPaymentsConditionInput
  ) {
    deletePayments(input: $input, condition: $condition) {
      id
      memberId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      practice
      memberName
      createdAt
      office
      confirmationToken
      confirmationStatus
      cash
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const createOfficePayments = /* GraphQL */ `
  mutation CreateOfficePayments(
    $input: CreateOfficePaymentsInput!
    $condition: ModelOfficePaymentsConditionInput
  ) {
    createOfficePayments(input: $input, condition: $condition) {
      id
      officeId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      office
      createdAt
      name
      practice
      cash
      confirmationToken
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const updateOfficePayments = /* GraphQL */ `
  mutation UpdateOfficePayments(
    $input: UpdateOfficePaymentsInput!
    $condition: ModelOfficePaymentsConditionInput
  ) {
    updateOfficePayments(input: $input, condition: $condition) {
      id
      officeId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      office
      createdAt
      name
      practice
      cash
      confirmationToken
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const deleteOfficePayments = /* GraphQL */ `
  mutation DeleteOfficePayments(
    $input: DeleteOfficePaymentsInput!
    $condition: ModelOfficePaymentsConditionInput
  ) {
    deleteOfficePayments(input: $input, condition: $condition) {
      id
      officeId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      office
      createdAt
      name
      practice
      cash
      confirmationToken
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const createOfficeLog = /* GraphQL */ `
  mutation CreateOfficeLog(
    $input: CreateOfficeLogInput!
    $condition: ModelOfficeLogConditionInput
  ) {
    createOfficeLog(input: $input, condition: $condition) {
      id
      officeId
      office
      activity
      createdAt
      updatedAt
    }
  }
`;
export const updateOfficeLog = /* GraphQL */ `
  mutation UpdateOfficeLog(
    $input: UpdateOfficeLogInput!
    $condition: ModelOfficeLogConditionInput
  ) {
    updateOfficeLog(input: $input, condition: $condition) {
      id
      officeId
      office
      activity
      createdAt
      updatedAt
    }
  }
`;
export const deleteOfficeLog = /* GraphQL */ `
  mutation DeleteOfficeLog(
    $input: DeleteOfficeLogInput!
    $condition: ModelOfficeLogConditionInput
  ) {
    deleteOfficeLog(input: $input, condition: $condition) {
      id
      officeId
      office
      activity
      createdAt
      updatedAt
    }
  }
`;
export const createMemberLog = /* GraphQL */ `
  mutation CreateMemberLog(
    $input: CreateMemberLogInput!
    $condition: ModelMemberLogConditionInput
  ) {
    createMemberLog(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      activity
      createdAt
      office
      updatedAt
    }
  }
`;
export const updateMemberLog = /* GraphQL */ `
  mutation UpdateMemberLog(
    $input: UpdateMemberLogInput!
    $condition: ModelMemberLogConditionInput
  ) {
    updateMemberLog(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      activity
      createdAt
      office
      updatedAt
    }
  }
`;
export const deleteMemberLog = /* GraphQL */ `
  mutation DeleteMemberLog(
    $input: DeleteMemberLogInput!
    $condition: ModelMemberLogConditionInput
  ) {
    deleteMemberLog(input: $input, condition: $condition) {
      id
      memberId
      memberName
      practice
      activity
      createdAt
      office
      updatedAt
    }
  }
`;
export const createNonMemberFinance = /* GraphQL */ `
  mutation CreateNonMemberFinance(
    $input: CreateNonMemberFinanceInput!
    $condition: ModelNonMemberFinanceConditionInput
  ) {
    createNonMemberFinance(input: $input, condition: $condition) {
      id
      name
      practice
      createdAt
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      totalMonths
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      updatedAt
    }
  }
`;
export const updateNonMemberFinance = /* GraphQL */ `
  mutation UpdateNonMemberFinance(
    $input: UpdateNonMemberFinanceInput!
    $condition: ModelNonMemberFinanceConditionInput
  ) {
    updateNonMemberFinance(input: $input, condition: $condition) {
      id
      name
      practice
      createdAt
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      totalMonths
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      updatedAt
    }
  }
`;
export const deleteNonMemberFinance = /* GraphQL */ `
  mutation DeleteNonMemberFinance(
    $input: DeleteNonMemberFinanceInput!
    $condition: ModelNonMemberFinanceConditionInput
  ) {
    deleteNonMemberFinance(input: $input, condition: $condition) {
      id
      name
      practice
      createdAt
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      totalMonths
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      updatedAt
    }
  }
`;
export const createOfficeAccount = /* GraphQL */ `
  mutation CreateOfficeAccount(
    $input: CreateOfficeAccountInput!
    $condition: ModelOfficeAccountConditionInput
  ) {
    createOfficeAccount(input: $input, condition: $condition) {
      id
      officeId
      accountId
      roles
      office {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      account {
        id
        accountType
        officeId
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOfficeAccount = /* GraphQL */ `
  mutation UpdateOfficeAccount(
    $input: UpdateOfficeAccountInput!
    $condition: ModelOfficeAccountConditionInput
  ) {
    updateOfficeAccount(input: $input, condition: $condition) {
      id
      officeId
      accountId
      roles
      office {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      account {
        id
        accountType
        officeId
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOfficeAccount = /* GraphQL */ `
  mutation DeleteOfficeAccount(
    $input: DeleteOfficeAccountInput!
    $condition: ModelOfficeAccountConditionInput
  ) {
    deleteOfficeAccount(input: $input, condition: $condition) {
      id
      officeId
      accountId
      roles
      office {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      account {
        id
        accountType
        officeId
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
