import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";


import { UserContext } from '../../UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Select from 'react-select'

import SearchBar from '../../SearchBar'
import Table2 from '../../components/Table2'
import { TextField } from "@material-ui/core";


export default function NonMembers(props) {

	const auth = useContext(UserContext)

	const [members, setMembers] = useState([])

	const [practice, setPractice] = useState(null)
	const [currMember, setCurrMember] = useState("All")

	const [practices, setPractices] = useState([])

    const [searchQuery, setSearchQuery] = useState('')
	const [expirationFilter, setExpirationFilter] = useState(false)
	const [activeFilter, setActiveFilter] = useState(true)

	const isMemberCardExpiring = (member) => {

		let mem = member

		// No expiration or empty expiration means non-expiring
		if (!mem.expiration || mem.expiration.length === 0) { return false }

		const expM = Number(mem.expiration.substring(0, 2))
		const expY = Number(mem.expiration.substring(2, 6))

		const now = new Date()
		const currYear = now.getFullYear()
		const currMonth = now.getMonth() + 1

		// If cur year is less than the expiration year then we're good to go
		if (currYear < expY) { return false }

		return currYear > expY || currMonth >= expM || (currMonth + 1) >= expM
	}


	const filterMembers = (members) => {
		let newMembers = [...members]
		if (practice && practice.length > 0) {
			newMembers = newMembers.filter(member => member.practice == practice)
		}
		if (currMember != "All") {
			newMembers = newMembers.filter(member => member.name == currMember)
		}
		if (expirationFilter) {
			newMembers = newMembers.filter(member => member.expiring)
		}

		if (activeFilter) {

			newMembers = newMembers.filter(m => m.active !== false)
		}
		return newMembers
	}

	const handlePracticeChange = (event) => {
		const practiceName = [event.target.value][0]
		if (practiceName != "all") {
			const pickedPractice = practices.filter(practice => practice.name == practiceName)[0]
			setPractice(pickedPractice.name)

			let rowCount = 1
			let newMember

			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				if (members[i]['practice'] == pickedPractice.name) {
					newMember = { ...members[i], rowId: rowCount }
					newMembersList.push(newMember)
					rowCount = rowCount + 1
				}
				else {
					newMember = members[i]
					newMembersList.push(newMember)
				}
			}
			setMembers(newMembersList)
		}
		else {
			setPractice("All")
			let rowCount = 1
			let newMember
			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				newMember = { ...members[i], rowId: rowCount }
				newMembersList.push(newMember)
				rowCount = rowCount + 1
			}
			setMembers(newMembersList)

		}
	}

	const handleChangeMember = (event) => {
		const memberName = event.value
		if (memberName != "all") {
			const pickedMember = members.filter(member => member.name == memberName)[0]
			setCurrMember(pickedMember.name)

			let rowCount = 1
			let newMember

			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				if (members[i]['name'] == pickedMember.name) {
					newMember = { ...members[i], rowId: rowCount }
					newMembersList.push(newMember)
					rowCount = rowCount + 1
				}
				else {
					newMember = members[i]
					newMembersList.push(newMember)
				}
			}
			setMembers(newMembersList)
		}
		else {
			let rowCount = 1
			let newMember
			let newMembersList = []
			for (let i = 0; i < members.length; i++) {
				newMember = { ...members[i], rowId: rowCount }
				newMembersList.push(newMember)
				rowCount = rowCount + 1
			}
			setMembers(newMembersList)
			setCurrMember("All")

		}
	}

	const confirmMemberActiveChange = (event) => {
		const targetMemberId = event.target.id
		const targetMemberChecked = event.target.checked
		const targetMemberName = event.target.name

		confirmAlert({
			title: 'Confirm to submit',
			message: !event.target.checked ? "Are you sure you want to deactivate member?" : "Are you sure you want to activate member?",
			buttons: [
				{
					label: 'Yes',
					onClick: () => { handleMemberActiveChange(targetMemberId, targetMemberChecked, targetMemberName) }
				},
				{
					label: 'No',
					onClick: () => null
				}
			]
		});
	}

	const handleExpirationFilter = () => {
		const newExpirationFilter = !expirationFilter
		setExpirationFilter(newExpirationFilter)
	}

	const handleMemberActiveChange = async (memberId, memberChecked, memberName) => {

		const targetMemberId = memberId
		const targetMemberChecked = memberChecked
		const targetMemberName = memberName

		const memberFinanceData = await API.graphql(graphqlOperation(`query ListMemberFinances {
		    listMemberFinances(filter: {and: [{memberId: {eq: "${targetMemberId}"}}, {office: {eq:"${auth.officeId || auth.user}"}}]}, limit: 9999){
		    items{
	          id
	          memberId
	          office
	          dayDue
	          initialDeposit
	          paymentInterval
	          periodAmount
	          remainingBalance
	          interestRate
	          totalBalance
	          paymentToken
		    }}
		}`))

		const userMemberFinanceData = memberFinanceData.data.listMemberFinances.items[0]
		const financeId = userMemberFinanceData['id']

		const newMembers = members.map(member => {
			if (member.id == targetMemberId) {
				const newActive = targetMemberChecked
				return ({ ...member, active: newActive })
			}
			else {
				return member
			}
		})


		const newMember = members.filter(member => member.id == targetMemberId)[0]

		const updateMember = await API.graphql(graphqlOperation(
			`mutation update {
		        updateMember(input: {id: "${targetMemberId}", active: ${targetMemberChecked}}){
		          id
		          active
		        }
		      }`))

		const updateMemberFinance = await API.graphql(graphqlOperation(
			`mutation update {
		        updateMemberFinance(input: {id: "${financeId}", active: ${targetMemberChecked}}){
		          id
		          active
		        }
		      }`))


		const createMemberLog = await API.graphql(graphqlOperation(
			`mutation create {
		        createMemberLog(input: {memberId: "${targetMemberId}", memberName: "${targetMemberName}" practice: "${newMember.practice}",
		    	activity: "${targetMemberChecked ? "Active Member" : "Inactive Member"}", office: "${props.officeId}"}){
		          id
		          memberId
		          memberName
		          practice
		          activity
		          office
		        }
		      }`))

		setMembers(newMembers)
	}

	const fetchMembers = useCallback(
		async () => {
			const membersData = await API.graphql(graphqlOperation(`query ListNonMembers {
        listNonMemberFinances(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
            id
            name
            practice
            dayDue
            interestRate
            totalBalance
            initialDeposit
            remainingBalance
            totalMonths
            periodAmount
            office
            paymentToken
            active
            expiration
            paymentType
            paymentDescription
            createdAt
            updatedAt
          }
        }
        }`))

			let membersList = membersData.data.listNonMemberFinances.items
			let rowCount = 1
			let newMember = []

			let newMembersList = []
			for (let i = 0; i < membersList.length; i++) {
				newMember = { ...membersList[i], rowId: rowCount, expiring: isMemberCardExpiring(membersList[i]) }
				newMembersList.push(newMember)
				rowCount = rowCount + 1
			}

			setMembers(newMembersList)
		}, [auth.user, auth.officeId])

	const fetchPractices = useCallback(
		async () => {
		const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
        listPractices(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
          items {
              id
              name
              city
              zip
          }
        }
        }`))

		let practicesList = practiceData.data.listPractices.items
		let rowCount = 1
		let newPractice = []

		let newPracticeList = []
		for (let i = 0; i < practicesList.length; i++) {
			newPractice = { ...practicesList[i], rowId: rowCount }
			newPracticeList.push(newPractice)
			rowCount = rowCount + 1
		}


		setPractices(newPracticeList)
	}, [auth.officeId, auth.user])


	const selectMembers = members.map(member => ({ key: member.id, value: member.name, label: member.name }))
    const selectPractice = 	[
		{ key: 'all', label: 'All Practices', value: null },
		...practices.map(p => ({ key: p.id, value: p.name, label: p.name }))
	]

	useEffect(() => {
		fetchPractices()
		fetchMembers()
	}, [fetchMembers, fetchPractices])

	return (
		<div className="w-full h-full">
			<div className="w-full px-10 py-6">
				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">All Members <span className={expirationFilter ? "my-auto text-sm bg-red-500 text-white" : "my-auto text-sm text-red-500"}><button onClick={handleExpirationFilter}>Expiring Cards: {members.filter(member => member.expiring == true).length}</button></span></h1></div>
					<div className="w-1/2 rounded-sm text-right"><Link to="/createmember/info" className="gradient-btn">Add Member</Link></div>
				</div>

                <div className="flex w-full items-center bg-white tiny-card p-4">
					{props.officeMultiplePractices ?
						<div className="w-1/3">
							<Select
								className='mx-4'
								styles={{
									container: (base) => ({ ...base, flex: 1 }),
									indicatorsContainer: () => ({ border: 'none' })
								}}
								onChange={e => setPractice(e.value)}
								options={selectPractice}
								value={practice}
								placeholder={practice || 'All Practices'} />
						</div> : null}
					<form className='w-1/3 mx-4'>
						<TextField className='w-full' placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
					</form>
                    <div className='w-1/3'>
						<input className='mx-2' name='active' type='checkbox' checked={activeFilter} onChange={e => setActiveFilter(e.target.checked)} />
							Hide inactive members
						</div>
				</div>

				<Table2 sortField='createdAt' sortOrder='desc'
					edit={true} 
                    pathPrefix={'member'} 
                    pathSuffix={'info'} 
                    columns={[
                        { column: 'Name', value: 'name' }, 
                        { column: 'Active', 'value': 'active' }, 
                        { column: 'Practice', value: 'practice' },
					    { column: 'Signup', value: 'createdAt' }, 
                        { column: 'Card Exp.', value: 'expiration' }
                    ]}
					fields={['name', 'active', 'practice', 'createdAt', 'expiration']}
					handlers={{ 'active': confirmMemberActiveChange }} values={filterMembers(members)} />
			</div>
		</div>

	)
}