import { API, Auth, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import Form from '../../components/Form'
import FormCol from '../../components/FormCol'
import Input from '../../components/Input'
import { useFormQL } from '../../hooks/useFormQL'
import { usersSchema } from '../../schemas/schemas'
import { UserContext } from '../../UserContext'



function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Check if an account already exists for the given email address
 * @param {string} email 
 */
const userExists = async (email) => {
    try {
        const results = await API.graphql(graphqlOperation(
            `query ListAccounts {
                listAccounts(filter: {email: {eq: "${email}"}}, limit: 9999) {
                  items {
                      id
                      email
                  }
                }
            }`
        ))

        console.log(`account results `, results)
        
        return results.data.listAccounts.items[0]
    }
    catch (ex) {
        console.error(ex)
        return null
    }
}



export default (props) => {

    const auth = useContext(UserContext)
    const params = useParams()
    const [name, setName] = useState('')
    const [roles, setRoles] = useState([])
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [saved, setSaved] = useState(false)
    const [invited, setInvited] = useState(false)


    const handleInviteUser = async (account) => {
        try {
            const officeId = auth.officeId || auth.user
            const accountId = account.id
            const result = await API.graphql(graphqlOperation(
                `mutation CreateOfficeAccount {
                    createOfficeAccount(input: { officeId: "${officeId}", accountId: "${accountId}", roles: ${JSON.stringify(roles.map(r => r.key).filter(r => r.length > 0))}}){
                        officeId,
                        accountId,
                        roles
                    }
                }`
            ))

            console.log(`Setup office user`)
            setInvited(true)
        }
        catch (ex) {
            console.error(ex)
            setInvited(false)
        }
    }


    const handleUserAlreadyExists = async (account) => {
        confirmAlert({
            title: 'User already exists.',
            message: `Do you want to add the user with email ${email} to your account?`,
            buttons: [
                { label: 'Yes', onClick: () => {
                    // Handle invite here
                    handleInviteUser(account)
                }},
                { label: 'No', onClick: () => {
                    // Do nothing
                }}
            ]
        })
    }

    const handleUserSubmit = async (event) => {
        event.preventDefault()

        console.log(`Submit: `, roles, email)
        // TODO:
        // - [ ] Check if user already exists with the given email
        //   - [ ] If exists, then show an invite modal for acceptance
        //   - [ ] On invite, create an Invitation record for the given user account and the current office
        // - [ ] If not exists, then continue with previous process. Now we also need to add an OfficeAccount for this user

    
        /// First create cognito user with email/password   
        try {

            const currentAccount = await userExists(email)

            if (currentAccount) {
                /// Handle invite instead
                return handleUserAlreadyExists(currentAccount)
            }

            /// User does not already exist. In this case we're just going to create the user
            /// and assign the current office as their default office


            const tmpPassword = makeid(10)
            
            const uResult = await Auth.signUp({ 
                username: email, 
                password: tmpPassword,
                attributes: {
                    email,
                    'custom:tmp_pass': tmpPassword
                }
            });

            const officeId = auth.officeId || auth.user
            const accountId = uResult.userSub
            const createAccount = await API.graphql(graphqlOperation(
                `mutation create {
                createAccount(input: {id: "${accountId}", accountType: "office-user", email: "${email}", name: "${name}", officeId: "${officeId}"}){
                  id
                  accountType
                  email
                  name
                  officeId
                }
                createOfficeAccount(input: { officeId: "${officeId}", accountId: "${accountId}", roles: ${JSON.stringify(roles.map(r => r.key).filter(r => r.length > 0))}}){
                    officeId,
                    accountId,
                    roles
                }
            }`))

            console.log({
                uResult,
                createAccount
            })

            setPassword(tmpPassword)
            setSaved(true)
        }
        catch (ex) {
            
            // if (ex.code === 'UsernameExistsException') {
            //     return handleDeletedAccount()
            // }

            console.error(ex)
            alert(ex.message)
        }
    }


    return (
        <div className="w-full h-full">


            {/* <form className="card p-8 bg-white border rounded-lg w-5/6 mx-auto " onSubmit={e => e.preventDefault()}> */}
                <Form title={"User"} onSubmit={handleUserSubmit} hideButton={saved || invited} saved={saved || invited} savedLabel={"User Saved"} buttonTitle={"Save"}>
                    <FormCol>
                        <Input label={"Name"} onChange={e => setName(e.target.value)} value={name} name={"name"} />
                        <Input label={"Email"} onChange={e => setEmail(e.target.value)} value={email} name={"email"} />
                        
                        <div className='flex my-4 w-full '>
                            <p className='my-auto pr-4 w-1/3 text-right'>Roles</p>
                            <Select className='w-1/2' isMulti={true} onChange={setRoles} value={roles} options={[
                                { key: '', label: 'Team Member' },
                                { key: 'admin', label: 'Admin' }
                            ]} name={"role"} />

                        </div>

                        {
                            saved && (
                                <span>The temporary password for {name} is <pre>{password}</pre></span>
                            )
                        }

                        {
                            invited && (
                                <span>User added to account</span>
                            )
                        }
                    </FormCol>
                
                </Form>

                {/* <button onClick={handleUserSubmit} className="gradient-btn">
                    Save User
                </button>

            </form> */}
        </div>
    )
}