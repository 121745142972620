import React, { useState, useEffect, useContext }  from "react";

import { Link } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import SavedAlert from './SavedAlert'

const Table = (props) => {

	let customComponents = props.customComponents
	let values = props.values != undefined ? props.values : []
	let newValuesList = []
	let newValue
	let rowCount = 1
  	for(let i=0; i<values.length; i++){
  		//row counts added to objects for background styling purposes
		newValue = {...values[i], rowId: rowCount}
		newValuesList.push(newValue)
		rowCount = rowCount + 1
	}

	values = newValuesList


	let numCols = props.columns.length
	let pathSuffix
	if((props.pathSuffix != null) && (props.pathSuffix != undefined)){
		pathSuffix = props.pathSuffix
	}
	else{
		pathSuffix = ""
	}

	//maps headers based on input columns
	let listHeaders = props.columns.map(column => {
		return(
			<th key={column} className={`w-1/${numCols} px-4 pb-4 table-h`}>{column}</th>
			)
	})

	//adds additional blank column header for edit and delete icons
	listHeaders.push(<th key="" className={`w-1/${numCols} px-4 pb-4 table-h text-center`}></th>)


	//maps a list of table rows 
	const listRows = values.map(row =>{
		const background = row.rowId % 2 == 1 ? "bg-gray-100" : ""

		let records = []
		let record
		let recordCount = 0
		let newValue

		//maps records for each field in table to the row
		//fields is an optional tag added to the payment component that maps column headers to actual values in the queried list of objects (i.e. 'Date' and createdDate)
		if(props.fields == null){
			for(record in row){
				
				newValue = record.toLowerCase().includes('pay') ? row[record].toFixed(2) : row[record]

				//formats date displays in row
				if(((record == 'createdAt') || (record == 'updatedAt') || (record.toLowerCase().includes('date'))) && ((row[record] != null) && (row[record] != undefined))){
					newValue = row[record].split('T')[0]
				}

				//id fields are not added to table
				if((record != 'id') && (record != 'rowId')){
					records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
				}
				recordCount++
			}
		}
		else{
			for(let i = 0; i < props.fields.length; i++){

				record = props.fields[i]

				newValue = record.toLowerCase().includes('pay') ? row[record].toFixed(2) : row[record]

				//formats date displays in row
				if(((record == 'createdAt') || (record == 'updatedAt') || (record.toLowerCase().includes('date'))) && ((row[record] != null) && (row[record] != undefined))){
					newValue = row[record].split('T')[0]
				}
				//id fields are not added to table
				if((record != 'id') && (record != 'rowId')){
					if(customComponents != undefined){
						if(customComponents[record] != undefined){
							records.push({name: record, value: customComponents[record](row[record], row), link: ((recordCount == 0) && props.edit)})
						}
						else{
							records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
						}
					}
					else{
						records.push({name: record, value: newValue, link: ((recordCount == 0) && props.edit)})
					} 
				}
				recordCount++
			}	
		}

		//maps the list of record objects to JSX render
		let listRecords = records.map(record => {
			//booleans become checkboxes
			if(typeof record.value == "boolean"){
				return(
					<td key={record.name} className="p-4 text-left"><input onChange={props.handlers[record['name']]} type="checkbox" name={row.name} id={row.id} checked={record.value ? true : false}></input></td>
					)
			}
			//custom handling of how card expiration needed to be displayed for members
			else if (record.name == "expiration"){
					if(row.expiring){
						return(
							<td key={record.name} className={`w-1/${numCols} p-4 text-left text-red-500`}>{record.value}</td>
							)
					}
					else{
						return(
							<td key={record.name} className={`w-1/${numCols} p-4 text-left`}>{record.value}</td>
						)
					}
					
			}
			//style links in the underline blue way
			else if(record.link){
				return(
					<td key={record.name} className={`w-1/${numCols} p-4 text-left`}>
						<span className="text-right ">
							<Link className="text-blue-700" to={`/${props.pathPrefix}/${row.id}/${pathSuffix}`}>
								{record.value}
							</Link>
						</span>
					</td>
					)
			}
			else{
				return(
					<td key={record.name} className={`w-1/${numCols} p-4 text-left`}>{record.value}</td>
					)
			}
		})

		listRecords.push(
			<td key={""} className={`w-1/${numCols} p-4 text-left`}>
				<span className="text-right  flex">
					{props.edit ? <Link to={`/${props.pathPrefix}/${row.id}/${pathSuffix}`}>
						<CreateIcon className="table-icon"/>
					</Link> : null}
					{props.delete ? <button className="text-right" onClick={() => props.handleDelete(row.id)}><DeleteIcon className="table-icon"/></button> : null}

				</span>
			</td>
			)

		//adds mapped record JSX renders as children to the row JSX render
		return(
			<tr key={row.id} className={background}>
				{listRecords}
			</tr>
		)

	})

	return(
        <div className="card text-left">
			<SavedAlert saved={props.saved} label={props.savedLabel} />
			<table className="w-full bg-white rounded-sm table-border">	
				<thead>
					<tr>
						{listHeaders}
					</tr>
				</thead>
			<tbody>
				{listRows}
				{props.children}
			</tbody>
		</table>
		</div>
		)
	}

export default Table 
