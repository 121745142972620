import React, { useState, useEffect }  from "react";
import { useParams, Link } from "react-router-dom";
import AdminViewOfficePractices from './AdminViewOfficePractices'

import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'

import {useFormQL} from './hooks/useFormQL'

import {officeSchema, practiceSchema} from './schemas/schemas'
import SettingsPracticeDetail from "./SettingsPracticeDetail3";
import { API, graphqlOperation } from "aws-amplify";
import SettingsCardDetail3 from "./SettingsCardDetail3";

export default function AdminViewOffice(props){
	const params = useParams()
  	const {saved, stateDict, handleSubmit, handleInputChange} = useFormQL(officeSchema, params.officeId) //get form state
	const [practiceId, setPracticeId] = useState(null)
	const practiceState = useFormQL(practiceSchema, practiceId)

	useEffect(() => {
		
		(async () => {

			const result = await API.graphql(graphqlOperation(`
				query FetchOfficeData {

					listPractices(filter: {office: { eq: "${params.officeId}" }}, limit: 99999) {
						items {
							id
							name
							businessName
							city
							state
							zip
						}
					}
				}
			`))
			
			setPracticeId(((result.data.listPractices.items || [])[0] || {}).id)
		})()
	}, [params.officeId])
	
	
	return (
		<div className="w-full h-full">
			<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
	      	    <Link className="text-blue-500" to="/admin/offices">{"<<"} Offices</Link>
	        </div> 
			<div className="w-5/6 mx-auto">
				<AdminViewOfficePractices officeId={params.officeId} />
			</div>
			
			<Form onSubmit={handleSubmit} title={"Office"} saved={saved} savedLabel={"Saved"} buttonTitle={"Save"}>
				<FormCol>
					<Input label={"Name"} disabled={true} value={stateDict.name} name={"name"} />
					<Input label={"Primary Contact"} onChange={handleInputChange} value={stateDict.primaryContact} name={"primaryContact"} />
					<Input label={"Phone Number"} onChange={handleInputChange} value={stateDict.phone} name={"phone"} />
					<Input label={"Email"} onChange={handleInputChange} value={stateDict.email} name={"email"} />
				</FormCol>
				<FormCol>
					<Input label={"Address"} onChange={handleInputChange} value={stateDict.address} name={"address"} />
					<Input label={"Zip"} onChange={handleInputChange} value={stateDict.zip} name={"zip"} />
					<Input label={"Date Joined"} disabled={true} value={stateDict.createdAt.split('T')[0]} name={"createdAt"} />
				</FormCol>
			</Form>

			<div className='mx-auto'>
			<SettingsPracticeDetail
				officeId={params.officeId}
				practiceId={practiceId} 
				handleOfficeMultiplePracticesChange={props.handleOfficeMultiplePracticesChange} 
				handleOfficeHasPracticeChange={props.handleOfficeHasPracticeChange} 
				practiceState={practiceState.stateDict} 
				officeState={stateDict}
				handleOfficeInputChange={handleInputChange} 
				handlePracticeInputChange={practiceState.handleInputChange}
          		handleSubmit={handleSubmit} />
			</div>

			<div className='mx-auto'>
				<SettingsCardDetail3 
					handleOfficeMerchantKeyChange={() => { }} 
					handleOfficeUsioLoginChange={() => { }} 
					handleOfficeUsioPasswordChange={() => { }} 
					state={stateDict} 
					handleInputChange={handleInputChange} 
					handleSubmit={handleSubmit} 
					/>
			</div>
		</div>
		)
}