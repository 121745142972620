import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'
import CustomerPayForm from './components/CustomerPayForm'

export default function NoMemberPaymentDetails(props) {
	
	// totalMonths, handleTotalMonthsChange
	// totalBalance, handleTotalBalanceChange

	// paymentType, handlePaymentTypeChange
	// initialDeposit, handleInitialDepositChange
	// periodAmount, handlePeriodAmountChange
	// paymentInterval

	// const selectPlan = props.plans.map(plan => <option key={plan.id} value={plan.name}>{plan.name}</option>)
    const selectPractice = props.practices.map(practice => <option key={practice.id} value={practice.name}>{practice.name}</option>)

	return(
		<div className="w-full">
			<CustomerPayForm title={"Payment Details"} hideDisclaimer={true} hideButton={true} className="card p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<FormCol>
					{ (props.practices && props.practices.length > 1) && (
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Practice:</p>
						<select className="border c-dropdown focus:outline-none p-2 " id="connections" name="connections" value={props.practice} onChange={props.handlePracticeChange}>
							{selectPractice}
						</select>
					</div>
					)}
				<Input label={"Account Holder Name"} onChange={props.handleContactNameChange} value={props.contactName} name={"contactName"} />

				<Input label={"Email"} onChange={props.handleContactEmailChange} value={props.contactEmail} name={"email"} />

					{/* Payment type is always card */ }
					{/* {props.handlePaymentTypeChange && <div className="flex my-4 w-full">
					  <p className="my-auto pr-4 w-1/3 text-right">Payment Type:</p>
						<select className="border w-1/2" id="paymentType" name="paymentType" value={props.paymentType} onChange={props.handlePaymentTypeChange}>
			               <option name="card" value="card">Card</option>
			               <option name="cash" value="cash">Cash</option>
			            </select>
					</div> */}
					
					{/* Payment should not be tied to a member plan at all
						props.handleChangePlan && (
							<div className="flex my-4 w-full">
								<p className="my-auto pr-4 w-1/3 text-right">Plan:</p>
								<select className="border w-1/2" id="plans" name="plans" value={props.plan} onChange={props.handleChangePlan}>
									<option name="None" value="None">None</option>
									{selectPlan}
								</select>
							</div>
						)
					} */}
					<div className="flex my-4 w-full">
					  <p className="my-auto pr-4 w-1/3 text-right">Number of Months</p>
						<select className="border w-1/2" id="totalMonths" name="totalMonths" value={props.totalMonths} onChange={props.handleTotalMonthsChange}>
			               <option name="3" value="3">3</option>
			               <option name="6" value="6">6</option>
			 			   <option name="9" value="9">9</option>
					       <option name="12" value="12">12</option>
			               <option name="18" value="18">18</option>
			               <option name="24" value="24">24</option>
			            </select>
			        </div>
		            <Input label={"Total Price"} mask={"$"} onChange={props.handleTotalBalanceChange} value={props.totalBalance} name={"totalBalance"} />
		           	<Input label={"Down Payment"} mask={"$"} onChange={props.handleInitialDepositChange} value={props.initialDeposit} name={"initialDeposit"} />
		            <Input disabled={true} label={"Monthly Payment"} mask={"$"} onChange={props.handlePeriodAmountChange} value={props.periodAmount} name={"periodAmount"} />
				</FormCol>
			</CustomerPayForm>
		</div>
		)
}