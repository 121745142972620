import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
import Iframe from 'react-iframe'
import ReactPlayer from "react-player"

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { blue } from '@material-ui/core/colors';

export default function InDepthTraining(props){

	const [playing, setPlaying] = useState(false)
	const [vidCount1, setVidCount1] =useState(1)
	const [vidCount2, setVidCount2] =useState(1)
	const [vidCount3, setVidCount3] =useState(1)

	const [displayVideo1, setDisplayVideo1] = useState(false)
	const [displayVideo2, setDisplayVideo2] = useState(false)
	const [displayVideo3, setDisplayVideo3] = useState(false)


	const videoDict1 = {1: "https://vimeo.com/video/351490724",
						2: "https://vimeo.com/video/351490786",
						3: "https://vimeo.com/video/351490872",
						4: "https://vimeo.com/video/351490936",
						5: "https://vimeo.com/video/363816461",
						6: "https://vimeo.com/video/351491070",
						7: "https://vimeo.com/video/351491193",
						8: "https://vimeo.com/video/351491305"}

	const videoDict2 = {1: "https://vimeo.com/video/351490724",
						2: "https://vimeo.com/video/351490936",
						3: "https://vimeo.com/video/363816461",
						4: "https://vimeo.com/video/351491070",}


	const videoDict3 = {1: "https://vimeo.com/video/415211632",
						2: "https://vimeo.com/video/415211529",
						3: "https://vimeo.com/video/415211632",}

	const previousVideo1 = () => {
		let newCount
		if(vidCount1 >= 1){
			newCount = vidCount1 - 1
		}
		else{
			newCount = 8
		}
		setVidCount1(newCount)
	}

	const nextVideo1 = () => {
		let newCount
		if(vidCount1 <= 7){
			newCount = vidCount1 + 1
		}
		else{
			newCount = 1
		}
		setVidCount1(newCount)
	}

	const previousVideo2 = () => {
		let newCount
		if(vidCount2 >= 1){
			newCount = vidCount2 - 1
		}
		else{
			newCount = 4
		}
		setVidCount2(newCount)
	}

	const nextVideo2 = () => {
		let newCount
		if(vidCount2 <= 3){
			newCount = vidCount2 + 1
		}
		else{
			newCount = 1
		}
		setVidCount2(newCount)
	}

	const previousVideo3 = () => {
		let newCount
		if(vidCount3 >= 1){
			newCount = vidCount3 - 1
		}
		else{
			newCount = 3
		}
		setVidCount3(newCount)
	}

	const nextVideo3 = () => {
		let newCount
		if(vidCount3 <= 2){
			newCount = vidCount3 + 1
		}
		else{
			newCount = 1
		}
		setVidCount3(newCount)
	}

	const handleDisplayVideo1 = () => {
		const newDisplay = !displayVideo1 
		setDisplayVideo1(newDisplay)
	}

	const handleDisplayVideo2 = () => {
		const newDisplay = !displayVideo2 
		setDisplayVideo2(newDisplay)
	}	

	const handleDisplayVideo3 = () => {
		const newDisplay = !displayVideo3
		setDisplayVideo3(newDisplay)
	}

    const video1Arrow = <div className="my-auto">{displayVideo1 ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</div>
    const video2Arrow = <div className="my-auto">{displayVideo2 ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</div>
    const video3Arrow = <div className="my-auto">{displayVideo3 ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</div>


	return(
		<div className="w-full h-full px-8 flex flex-col">
			<div className="w-1/2 rounded-sm p-2 my-6"><h1 className="ch1">In-Depth Training</h1></div>
				<div className="w-full rounded-sm p-2"><p className="font-semibold underline">Answers to most questions can quickly and easily be found in our “QDPedia knowledge base.” Click the “Resources” tab to the left and select “QDPedia – Knowledge Base”</p></div>
				<div className="w-full rounded-sm p-2 my-4"><p>Our In-depth training videos take you on a deeper dive into learning more about in-office plans (IOPs) for those that wish take the journey. Here you’ll get a more presentation-like style introduction to IOPs as well as other topics associated with IOP success. “Magic Questions and Magic Numbers” are great ways to present your IOP and included in a video in our In-Depth training module.</p></div>

			<div className="h-full pb-8 mb-12">
				<div className="w-5/6 mx-auto h-full pb-6">
					<iframe title='Training video' className="w-full h-full " src='https://vimeo.com/showcase/7425435/embed' allowFullScreen frameBorder='0' ></iframe></div>
	        </div>
        </div>

		)
} 
