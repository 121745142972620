import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from './AppliedRoute.js'

import CustomerInfo from './CustomerInfo'
import CustomerAgreement from './CustomerAgreement'
import CustomerPayments from './CustomerPayments'
import NewPassword from './NewPassword'


export default function CustomerRoutes({appProps}) {
  return (
    <Switch>
      <AppliedRoute path="/customer/password" exact component={NewPassword} appProps={appProps}/>
      <AppliedRoute path="/" exact component={CustomerPayments} appProps={appProps}/>
      <AppliedRoute path="/customer/plan" exact component={CustomerAgreement} appProps={appProps}/>
      <AppliedRoute path="/customer/info" exact component={CustomerInfo} appProps={appProps}/>

    </Switch>
  );
}