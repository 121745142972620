import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from './AppliedRoute.js'


import Dashboard from './Dashboard.js'
import CreatePlanV3 from './screens/plans/CreatePlanV3'

import ViewPlans from './screens/plans/ViewPlans'

import Members from './screens/members/Members.js'
import NonMembers from './screens/non-members/NonMembers'
import CreateMember from './screens/members/CreateNewMember'
import Payments from './Payments.js'
import Practices from './Practices.js'
import PracticeForm from './PracticeForm'

import CreatePricingRule from './CreatePricingRule'


import MemberCardDetail from './screens/members/MemberCardDetail'
import MemberAgreement from './screens/members/MemberAgreement'
import MemberBenefitTracking from './screens/members/MemberBenefitTracking'
import MemberInfoDetail from './screens/members/MemberInfoDetail'

import EditMember from './screens/members/EditMember'

import NewPassword from './NewPassword'

import Settings3 from './Settings3'

import AdminDashboard from './AdminDashboard'
import AdminOffices from './AdminOffices'
import AdminPayments from './AdminPayments'
import AdminViewOffice from './AdminViewOffice'
import AdminViewOffice2 from './AdminViewOffice2'
import AdminPlans from './AdminPlans'

import KnowledgeBase from './KnowledgeBase'
import PMSGuides from './PMSGuides'
import MarketingMaterials from './MarketingMaterials'
import InDepthTraining from './InDepthTraining'
import BusinessEnroll from './BusinessEnroll'

import OneTimePayment from './OneTimePayment'
import NoMemberPayments from './NoMemberPayments'


import Welcome from './Welcome'

import QuickStart from './QuickStart'
import UsersRoute from "./screens/users/UsersRoute.js";
import AddUser from "./screens/users/AddUser.js";
import MemberRenewals from "./screens/members/MemberRenewals.js";

export default function Routes({appProps}) {
  return (
    <Switch>
      <AppliedRoute path="/settings/password" exact component={NewPassword} appProps={appProps}/>
      <AppliedRoute path="/settings" component={Settings3} appProps={appProps}/>
      <AppliedRoute path="/plans" exact component={ViewPlans} appProps={appProps}/>
      <AppliedRoute path="/plan/:planId" exact component={CreatePlanV3} appProps={appProps}/>
      <AppliedRoute path="/plan/create" exact component={CreatePlanV3} appProps={appProps}/>
      <AppliedRoute path="/createrule" exact component={CreatePricingRule} appProps={appProps}/>
      <AppliedRoute path="/members" exact component={Members} appProps={appProps}/>
      <AppliedRoute path="/createmember" component={CreateMember} appProps={appProps}/>
      <AppliedRoute path="/createmember/info" exact component={MemberInfoDetail} appProps={appProps}/>
      <AppliedRoute path="/createmember/finance" exact component={MemberCardDetail} appProps={appProps}/>
      <AppliedRoute path="/createmember/agreement" exact component={MemberAgreement} appProps={appProps}/>
      <AppliedRoute path="/createmember/benefits" exact component={MemberBenefitTracking} appProps={appProps}/>
      <AppliedRoute path="/non-members" exact component={NonMembers} appProps={appProps}/>
      <AppliedRoute path="/newpayment" exact component={OneTimePayment} appProps={appProps}/>
      <AppliedRoute path="/member/:memberId" component={EditMember} appProps={appProps}/>
      <AppliedRoute path="/payments" exact component={Payments} appProps={appProps}/>
      <AppliedRoute path="/practices" exact component={Practices} appProps={appProps}/>
      <AppliedRoute path="/createpractice" exact component={PracticeForm} appProps={appProps}/>
      <AppliedRoute path="/practices" exact component={Practices} appProps={appProps}/>
      <AppliedRoute path="/practice/:practiceId" exact component={PracticeForm} appProps={appProps}/>
      <AppliedRoute path="/admin" exact component={AdminDashboard} appProps={appProps}/>
      <AppliedRoute path="/admin/offices" exact component={AdminOffices} appProps={appProps}/>
      <AppliedRoute path="/admin/payments" exact component={AdminPayments} appProps={appProps}/>
      <AppliedRoute path="/admin/plans" exact component={AdminPlans} appProps={appProps}/>
      <AppliedRoute path="/admin/office/edit/:officeId" component={AdminViewOffice2} appProps={appProps}/>
      <AppliedRoute path="/resources/knowledge-base" component={KnowledgeBase} appProps={appProps}/>
      <AppliedRoute path="/resources/pms" component={PMSGuides} appProps={appProps}/>
      <AppliedRoute path="/resources/marketing" component={MarketingMaterials} appProps={appProps}/>
      <AppliedRoute path="/resources/training" component={InDepthTraining} appProps={appProps}/>
      <AppliedRoute path="/resources/business-enroll" component={BusinessEnroll} appProps={appProps}/>
      <AppliedRoute path="/resources/quickstart" exact component={QuickStart} appProps={appProps}/>
      <AppliedRoute path="/paymentplan" exact component={NoMemberPayments} appProps={appProps}/>
      <AppliedRoute path='/renewals' exact component={MemberRenewals} appProps={appProps}/>


      <AppliedRoute path="/users" exact component={UsersRoute} appProps={appProps}/>
      <AppliedRoute path="/users/create" exact component={AddUser} appProps={appProps} />

      {appProps.officeHasLoggedIn !== "no" ? <AppliedRoute path="/" component={Dashboard} appProps={appProps}/> : <AppliedRoute path="/" component={Welcome} appProps={appProps}/>}

    </Switch>
  );
}