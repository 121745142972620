import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";

export default function ResetLogin(props) {
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  function validateCodeForm() {
    return email.length > 0;
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleCodeChange = (event) => {
    setCode(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
  }

  function validateResetForm() {
    return (
      code.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (error) {
      //onError(error);
      setIsSendingCode(false);
      console.error(error)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        email,
        code,
        password
      );
      setConfirmed(true);
    } catch (error) {
      //onError(error);
      setIsConfirming(false);
    }
  }

  return(
    <div className="w-full h-full">
      {!codeSent ? 
      <form onSubmit={handleSendCodeClick} className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
        <h1 className="text-2xl">Reset Login</h1>
        <div className="flex">
        <div className="w-1/2 flex flex-col">
          <div className="flex my-4 w-full">
            <p className="my-auto pr-4 w-1/3 text-right">Email:</p>
            <input className="border w-1/2" onChange={handleEmailChange} type="text" value={email} id="email"></input>
          </div>
        </div>
      </div>
        <button disabled={!validateCodeForm()} className="px-6 py-2 bg-blue-500 text-white rounded-sm">Send Confirmation</button>
      </form>
      : !confirmed ? 
      <form onSubmit={handleConfirmClick} className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
        <h1 className="text-2xl">Confirm Code</h1>
        <div className="flex">
        <div className="w-1/2 flex flex-col">
          <div className="flex my-4 w-full">
            <p className="my-auto pr-4 w-1/3 text-right">ConfirmationCode:</p>
            <input className="border w-1/2" onChange={handleCodeChange} type="text" value={code} id="code"></input>
          </div>
          <div className="flex my-4 w-full">
            <p className="my-auto pr-4 w-1/3 text-right">New Password:</p>
            <input className="border w-1/2" onChange={handlePasswordChange} type="password" value={password} id="password"></input>
          </div>
          <div className="flex my-4 w-full">
            <p className="my-auto pr-4 w-1/3 text-right">Confirm Password:</p>
            <input className="border w-1/2" onChange={handleConfirmPasswordChange} type="password" value={confirmPassword} id="confirmPassword"></input>
          </div>
          <p>Please check your email ({email}) for the confirmation code.</p>
        </div>
      </div>
        <button disabled={!validateResetForm()} className="px-6 py-2 bg-blue-500 text-white rounded-sm">Confirm</button>
      </form> :       
      <div>
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    }
    </div>
    )
}


