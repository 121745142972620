import React, { useState, useContext }  from "react";

import {UserContext} from './UserContext'

import { API, graphqlOperation } from 'aws-amplify';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'


export default function EditAccountPractice(props){
	const auth = useContext(UserContext)
  	const [savedSettings, setSavedSettings] = useState(false)

	const officeId = props.officeId || auth.officeId || auth.user

	const validateForm = async (event) =>{
		event.preventDefault()

		if((props.practiceId != "") && (props.practiceId != undefined)){
			const updatedPractice = await API.graphql(graphqlOperation(`mutation update {
			  updatePractice (input: {
			    id: "${props.practiceId}",
			    name: "${props.practiceState.name}",
			    businessName: "${props.practiceState.businessName}",
			    city: "${props.practiceState.city}"
			    zip: "${props.practiceState.zip}"
			  }){
		          id
		          name
		          businessName
		          city
		          zip
			  }
			}`))

			props.handleOfficeHasPracticeChange(true)
		    const updateOfficeData = await API.graphql(graphqlOperation(
		          `mutation update {
		            updateOffice(input: {id: "${officeId}", multiplePractices: ${false} hasPractice: ${true}}){
		            id
		            hasPractice
		            multiplePractices
		            }
		          }`))
			}
		else{
			const result = await API.graphql(graphqlOperation(
			      `mutation create {
			        createPractice(input: {
						name: "${props.practiceState.name}", 
						office: "${officeId}", 
						businessName: "${props.practiceState.businessName}", 
						city: "${props.practiceState.city}",
						zip: "${props.practiceState.zip}"
					}){
			          id
			          name
			          businessName
			          city
			          zip
			        }
			      }`))
			props.handleOfficeHasPracticeChange(true)
		    const updateOfficeData = await API.graphql(graphqlOperation(
		          `mutation update {
		            updateOffice(input: {id: "${officeId}", multiplePractices: ${false} hasPractice: ${true}}){
		            id
		            hasPractice
		            multiplePractices
		            }
		          }`))
		}
		props.handleOfficeMultiplePracticesChange(false)
		setSavedSettings(true)
	}

  const displaySettingsSaved = savedSettings ? <div className=" my-4 text-green-700 flex "><div className="px-2">Settings Saved </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null

	return(
		<div className="w-full h-full">
	        <Form onSubmit={validateForm} saved={savedSettings} savedLabel={"Saved"} title={"Practice Settings"} buttonTitle={"Save"}>
	          <FormCol>
			    <Input label={"Enable Multiple Practices"} type="checkbox" onChange={props.handleOfficeInputChange} value={props.officeState.multiplePractices} name={"multiplePractices"} />
				<Input label={"Full Name"} onChange={props.handlePracticeInputChange} value={props.practiceState.name} name={"name"} />
				<Input label={"Business Name"} onChange={props.handlePracticeInputChange} value={props.practiceState.businessName} name={"businessName"} />
				<Input label={"City"} onChange={props.handlePracticeInputChange} value={props.practiceState.city} name={"city"} />
				<Input label={"Zip"} onChange={props.handlePracticeInputChange} value={props.practiceState.zip} name={"zip"} />
	          </FormCol>
	        </Form>
		</div>
		)
}