import React, {useState, useEffect, useContext, useMemo}  from "react";

import { createFormArray, getStateDict, fetchForm } from '../events/ReactQL'
import {handleFormSubmit, handleFormInputChange} from '../events/EventQL'

import {UserContext} from '../UserContext'

import Amplify, { Storage } from 'aws-amplify';

Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev', //REQUIRED -  Amazon S3 bucket
        }
    }
});

function getRandomInt(max) {
  //generates random integer between zero and the input max parameter
  return Math.floor(Math.random() * Math.floor(max));
}

function useFormQL(schema, id=null, authField=null, imagePath=null, fieldList=null, cols=true){

  const realScheme = useMemo(() => schema, [schema])
  const realFields = useMemo(() => fieldList, [fieldList])
  const realId = useMemo(() => id, [id])
  const auth = useContext(UserContext)

  const [stateDict, setStateDict] = useState(getStateDict(realScheme, realFields, realId))
  const [inputList, setInputList] = useState(createFormArray(getStateDict(realScheme, realFields, realId)))
  const [saved, setSaved] = useState(false)
  const schemaName = realScheme.split(" ")[1].split(/(?=[A-Z])/).join(" ")

  const handleInputChange = (e) => {
    console.log('CHANGING: ', e.target.type, e.target.value)
    const [newInputList, newStateDict] = handleFormInputChange(e, stateDict, inputList) //add event handler function
    console.log(newStateDict)
    setInputList(newInputList) //setState
    setStateDict(newStateDict) //setState

  }

  const handleImageInputChange = async (e, rootImagePath=imagePath) => {
        const eventName = e.target.name
        const file = e.target.files[0];

        //generates S3 url where image will be stored. This is intended to be a general purpose version of what we've seen in handleLogoUpload() in other components
        const imagePath = `${rootImagePath} ${getRandomInt(1000)}.png`
        await Storage.put(imagePath, file, {
            acl: 'public-read',
            contentType: 'image/png',
            cors: true
        })
        .then (result => console.log(result))
        .catch(err => console.log(err));

        let imageUrl = `https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/${imagePath.split(' ').join('+')}`
        let event = {target: {type: 'file', name: eventName}}
        const [newInputList, newStateDict] = handleFormInputChange(event, stateDict, inputList, imageUrl) //add event handler function
        setInputList(newInputList) //setState
        setStateDict(newStateDict) //setState  
    }

  const handleSubmit = async (e) => { 
    await handleFormSubmit(e, stateDict, schema, id) //add event handler function
    setSaved(true) //setState
  }

  useEffect(() => {
    if (realId == null) { return }

    console.log(`Non-null state`)
    const fetchState = async () => {
      let fetchedStateDict = await fetchForm(realScheme, realId)
      setStateDict(fetchedStateDict)
    }
    fetchState()

  }, [realId, realScheme])


  useEffect(
    () => {
      if(authField != null && !stateDict[authField]){
        let newStateDict = stateDict
        newStateDict[authField] = auth.officeId || auth.user 
        setStateDict(newStateDict)
      }

  }, [authField, auth.officeId, auth.user, stateDict])

  return {schemaName, saved, stateDict, handleSubmit, handleInputChange, handleImageInputChange}
}

export { useFormQL }