import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import MemberPaymentDetail from './MemberPaymentDetail'
import MemberCardDetail from './MemberCardDetail'
import MemberPrimaryContact from './MemberPrimaryContact'
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function MemberInfoDetail(props){

      const isCardPayment = props.paymentType === 'card'
      return(
		<div className="w-full">
                  <MemberCardDetail {...props} />



                  {
                        /**
                         * Syncing checkboxes for new card members
                         */
                        !props.edit && isCardPayment &&
                        <div className='card px-8 bg-white border w-5/6 mx-auto'>
                              <FormControlLabel
                                    control={
                                          <Checkbox 
                                          title='Member (Patient) is same as cardholder'
                                          checked={props.patientSameAsCardHolder}
                                          onChange={props.handlePatientSameAsCardHolderChange} />
                                    }
                                    label="Member (Patient) is same as cardholder" />
                              <br/>
                              <FormControlLabel
                                    control={
                                          <Checkbox 
                                          title='Account Holder as cardholder'
                                          checked={props.accountHolderSameAsCardHolder}
                                          onChange={props.handleAccountHolderSameAsCardHolderChange} />
                                    }
                                    label="Account Holder same as cardholder" />
                        </div>
                  }

                  <MemberPrimaryContact {...props} />
			<MemberPaymentDetail {...props} />
		</div>
		)
}