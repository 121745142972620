import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import {UserContext} from './UserContext'

import SettingsCardDetail3 from './SettingsCardDetail3'
import SettingsAccountDetail3 from './SettingsAccountDetail3'
import SettingsPracticeDetail3 from './SettingsPracticeDetail3'

import {useFormQL} from './hooks/useFormQL'

import {officeSchema, practiceSchema} from './schemas/schemas'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev', //REQUIRED -  Amazon S3 bucket
        }
    }
});

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export default function Settings(props) {

  const auth = useContext(UserContext)
  const [currentPage, setCurrentPage] = useState("UserSettings")
  const {schemaName, saved, stateDict, handleSubmit, handleInputChange, handleImageInputChange} = useFormQL(officeSchema, auth.officeId || auth.user, null, props.officeName) //get form state
  const practiceState = useFormQL(practiceSchema, props.officeFirstPracticeId) //get form state
  const [isVerifying, setIsVerifying] = useState(false)
  const [completedVerification, setCompletedVerification] = useState(false)

  const displaySettingsSaved = saved ? <div className="w-5/6 mx-auto mt-4 text-green-700 flex content-center"><div className="px-2">Settings Saved </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null

  let verifying
  if(props.location.state != undefined){
    verifying = props.location.state.verifying
  }

  const handleVerifyEmail = () =>{
    //emails users verification code
    Auth.verifyCurrentUserAttribute('email')
    .then(() => {
         console.log('a verification code is sent');
    }).catch((e) => {
         console.log('failed with error', e);
    });  
    setIsVerifying(true)
    } 

  const handleSubmitVerification = async (code) => {
    //receives email verification confirmation code and verifies via cognito
    Auth.verifyCurrentUserAttributeSubmit('email', code)
      .then(async () => {
        //if successful, office object is updated in dynamodb and in local state to reflect that the user's email is now verified
        const updateOffice = await API.graphql(graphqlOperation(
              `mutation update {
                updateOffice(input: {id: "${props.officeId}", isVerified: ${true}}){
                  id
                  isVerified
                }
              }`))
        alert('Email Verified');
        props.setOfficeIsVerified(true)
        setCompletedVerification(true)
      }).catch(e => {
           console.log('failed with error', e);
      });
  }

   const confirmVerified = (event) => {
    //When users get to the settingsaccountdetail page via the home page verify email link, a confirm alert popup instructs them on how to complete verification
    confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="bg-white rounded-lg p-6 text-gray-700">
          <h1>Warning: Email not yet verified</h1>
          <br></br>
          <p>Verifications are important for things like password resets.</p>
          <br></br>
          <p>How to verify:</p>
          <p>1) Check email (address listed in account settings)</p>
        <p>2) Enter the code sent to your email (may need to check spam/junk)</p>
          <br></br>
          <button className="text-white bg-blue-500 px-4 py-2 font-semibold rounded-lg" onClick={onClose}>Ok</button>
        </div>
      )
    }
  })

    }

    useEffect(() => {
      //if the user is verifying by clicking the verify email link on the homepage, verifying will be passed from Link props and set to true
      if(verifying){
        //sends the verification email
        handleVerifyEmail()
        //receives verification confirmation code as input and verifies email via cognito
        confirmVerified()
      }
  }, [verifying]) 
  
  return (
    <div className="w-full h-full">
      <div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
        <Link className="text-blue-500" to="/">{"<<"} Home</Link>
      </div>
      <div className="w-full bg-white">
        <div className="mx-auto w-2/3 flex justify-between p-4border-b text-blue-700">
            <button onClick={() => setCurrentPage("UserSettings")} className={currentPage == "UserSettings"? "font-bold p-2 hover:underline" : " p-2 hover:underline"}>Account</button>
            <button onClick={() => setCurrentPage("PaymentSettings")} className={currentPage == "PaymentSettings" ? "font-bold p-2 hover:underline" : "p-2 hover:underline"}>Payment Settings</button>
            <button onClick={() => setCurrentPage("PracticeSettings")} className={currentPage == "PracticeSettings"? "font-bold p-2 hover:underline" : "p-2 hover:underline"}>Practice(s)</button>
        </div>
      </div>
      {displaySettingsSaved}
      <div className="w-full flex">
        {currentPage == "UserSettings" ? 
          <SettingsAccountDetail3 
            officeIsVerified={props.officeIsVerified} 
            completedVerification={completedVerification} 
            verifying={verifying} 
            handleSubmitVerification={handleSubmitVerification} 
            isVerifying={isVerifying} 
            state={stateDict} 
            handleInputChange={handleInputChange} 
            handleLogoUpload={handleImageInputChange} 
            logoUrl={stateDict.logo}
            handleSubmit={handleSubmit} handleVerifyEmail={handleVerifyEmail } /> 
          : currentPage == "PaymentSettings" ? 
          <SettingsCardDetail3 handleOfficeMerchantKeyChange={props.handleOfficeMerchantKeyChange} handleOfficeUsioLoginChange={props.handleOfficeUsioLoginChange} handleOfficeUsioPasswordChange={props.handleOfficeUsioPasswordChange} state={stateDict} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
          : 
          <SettingsPracticeDetail3 practiceId={props.officeFirstPracticeId} handleOfficeMultiplePracticesChange={props.handleOfficeMultiplePracticesChange} handleOfficeHasPracticeChange={props.handleOfficeHasPracticeChange} practiceState={practiceState.stateDict} officeState={stateDict}
         handleOfficeInputChange={handleInputChange} handlePracticeInputChange={practiceState.handleInputChange}
          handleSubmit={handleSubmit} />
        }
      </div>
    </div>
    );
  }
