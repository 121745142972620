import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

export default function ChoosePlanBenefits(props){

	const [customBenefits, setCustomBenefits] = useState(props.customBenefits)

	return(
		<div className="w-full h-full mb-6">
			<div className="w-5/6 flex mt-3 mx-auto">
				<button onClick={props.handleAdultPlanType} className={props.planType == "Adult" ? "interval-active" : "interval"}>Adult</button>
				<button onClick={props.handlePerioPlanType} className={props.planType == "Perio" ? "interval-active" : "interval"}>Perio</button>
				<button onClick={props.handleChildPlanType} className={props.planType == "Child" ? "interval-active" : "interval"}>Child</button>
			</div>
			<form onSubmit={props.validateForm} className="card flex flex-col mb-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<h1 className="text-lg my-4 font-semibold">Basic options included in all plans:</h1>
				<div className="flex my-4 w-full justify between">
					<p className="my-auto pr-4">Description:</p>
					<input className="border w-1/2 my-auto" onChange={props.handleNewBasicBenefitChange} type="text" value={props.newBasicBenefit} id="newBasicBenefit"></input>
					<button onClick={props.handleAddBasicBenefit} className="gradient-btn mx-2">Add</button>
				</div>
					{props.listBasicBenefits}
				<h1 className="text-lg my-4 font-semibold">Check options to include in your plan:</h1>
				<div className="flex my-4 w-full">
					<p className="my-auto pr-4">Description:</p>
					<input className="border w-1/2 my-auto" onChange={props.handleNewCustomBenefitDescriptionChange} type="text" value={props.newCustomBenefitDescription} id="newCustomBenefitDescription"></input>
					<button onClick={props.handleAddCustomBenefit} className="gradient-btn mx-2">Add</button>
				</div>
				{props.listCustomBenefits}
				{props.displayPlanSaved}
              	<button onClick={props.handleSaveBenefits} className="my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Save</button>
			</form>
		</div>
		)
	}
