import React, { useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

const LogoUploader = (props) => {

  const [hidden, setHidden] = useState(false)
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setHidden(true)
  };
  return (
    <div>
      <button className={hidden ? "hidden" : "px-2 py-6 text- xl bg-white text-gray-400 border-2 border-dashed border-400-gray w-2/3 rounded-lg mx-auto"} onClick={handleClick}>
        Upload Logo
      </button>
        <input ref={hiddenFileInput} className="hidden" name="logo"
            type="file" accept='image/png'
            onChange={props.handleLogoUpload}
        ></input>
    </div>
  );
};

export default LogoUploader;