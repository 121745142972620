import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Amplify, { API, graphqlOperation, Auth, Analytics, Storage } from 'aws-amplify';

import SignupFlow1 from './SignupFlow1'
import SignupFlow2 from './SignupFlow2'
import SignupFlow3 from './SignupFlow3'

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
const SignupFlow = (props, ref) => {

  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [name, setName] = useState("")
  const [primaryContact, setPrimaryContact] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [zip, setZip] = useState("")
  const [city, setCity] = useState("")
  const [id, setId] = useState("")
  const [state, setState] = useState("")
  const [logoUrl, setLogoUrl] = useState("")

  const [licensee, setLicensee] = useState("")
  const [by, setBy] = useState("")
  const [contractName, setContractName] = useState("")
  const [title, setTitle] = useState("")
  const [agreementSignature, setAgreementSignature] = useState("")

  const [usioMerchantId, setUsioMerchantId] = useState("")
  const [usioLogin, setUsioLogin] = useState("") 
  const [usioPassword, setUsioPassword] = useState("")
  const [usioEmail, setUsioEmail] = useState("")
  const [usioDbaName, setUsioDbaName] = useState("")
  const [usioLegalName, setUsioLegalName] = useState("")
  const [submittedUsio, setSubmittedUsio] = useState(false)

  const [completedFlow1, setCompletedFlow1] = useState(false)
  const [completedFlow2, setCompletedFlow2] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const sigRef = useRef(null)

  const handleUsioLoginChange = (e) => setUsioLogin(e.target.value)
  const handleUsioPasswordChange = (e) => setUsioPassword(e.target.value)  
  const handleUsioEmailChange = (e) => setUsioEmail(e.target.value)
  const handleUsioDbaNameChange = (e) => setUsioDbaName(e.target.value)
  const handleUsioLegalNameChange = (e) => setUsioLegalName(e.target.value)
  const handleLicenseeChange = (e) => setLicensee(e.target.value)
  const handleByChange = (e) => setBy(e.target.value)
  const handleContractNameChange = (e) => setContractName(e.target.value)
  const handleTitleChange = (e) => setTitle(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handleConfirmEmailChange = (e) => setConfirmEmail(e.target.value)
  const handleWebsiteChange = (e) => setWebsite(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value)
  const handleNameChange = (e) => setName(e.target.value)
  const handlePrimaryContactChange = (e) => setPrimaryContact(e.target.value)
  const handlePhoneChange = (e) => setPhone(e.target.value)
  const handleAddressChange = (e) => setAddress(e.target.value)
  const handleZipChange = (e) => setZip(e.target.value)
  const handleStateChange = (e) => setState(e.target.value)
  const handleCityChange = (e) => setCity(e.target.value)
  const handleLogoUpload = async (e) => {
      
      const file = e.target.files[0];
      //create random url for S3 storage
      const imagePath = `${name} ${getRandomInt(1000)} Logo.png`
      
      //upload the image to the content management bucket in S3 using the amplify library
      await Storage.put(imagePath, file, {
          acl: 'public-read',
          contentType: 'image/png',
      })
      .then (result => console.log(result))
      .catch(err => console.log(err));
      
      let imageUrl = `https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/${imagePath.split(' ').join('+')}`
      
      //logoUrl is set to display the image on page immediately and to later store the url in dynamodb
      setLogoUrl(imageUrl)
  	}

  
    const handleSubmitFlow1 = async (e) => {

      e.preventDefault()

      //query all current|pending offices (verifying that none of them use the applying offices email)
      const officesData = await API.graphql(graphqlOperation(`
        query MyQuery($email: String!) {
          listPendingOffices(filter: {email: {contains: $email}}) {
            items { email }
          }
          listOffices(filter: {email: {contains: $email}}) {
            items { email }
          }
        }
      `, { email }))
      let officesList = [
        ...officesData.data.listOffices.items,
        ...officesData.data.listPendingOffices.items
      ]

      //the website input can be left empty, but everything else must be filled out
      const required = [
        email,
        confirmEmail,
        primaryContact,
        phone,
        address, state, city, zip,
        password
      ]

      const invalid = required.findIndex(v => (v || v).length == 0)
	    console.log(`Invalid: ${invalid}`)
      if(invalid != -1) {
        
	    	alert("Fields cannot be empty")
	    }
	    else if(email != confirmEmail){
	    		alert("Email do not match")
	    	}
	    else if (password != confirmPassword){
	    	alert('Passwords do not match')
	    }
      //verifying that office email hasn't been used previously
      else if(officesList.length > 0){
        alert('An account with the given email already exists.')
      }
	    else{
        //moves user to SignupFlow2
	    	setCompletedFlow1(true)
	    }
    }
	
	const handleSubmitFlow2 = (e) => {
	    e.preventDefault()
      //verifies that fields aren't empty
	    if((licensee == "") /* || (by == "") */ || (contractName == "") || (title == "")){
	    	alert("Fields cannot be empty.")
	    }
      //verifies that signature is not blank
	    else if(sigRef.current.isEmpty()){
	    	alert("Please sign to continue.")
	    }
	    else{
        //sets agreementSignature image state variable for storage in dynamodb for later viewing
	    	setAgreementSignature(sigRef.current.toDataURL())
	    	//moves user to SignupFlow3
        setCompletedFlow2(true)
	    }
	  }

	const handlePrevFlow2 = (e) => setCompletedFlow1(e.target.value)
	const handlePrevFlow3 = (e) => setCompletedFlow2(e.target.value)
	const handleSubmitFlow3 = async (e) => {
		e.preventDefault()

    setSubmitting(true)
    if((usioEmail == "") || (usioDbaName == "") || (usioLegalName == "")){
      alert("USIO Registration fields cannot be empty.")
    }
    else{
      //create a new pending office record in dynamodb using the amplify api
      const result = await API.graphql(graphqlOperation(
      `mutation create {
        createPendingOffice(input: {name: "${name}", primaryContact: "${primaryContact}", phone: "${phone}", email: "${email}",
        website: "${website}", address: "${address}", city: "${city}", zip: "${zip}", state: "${state}", logoUrl: "${logoUrl}",
        licensee: "${licensee}", by: "${by}", contractName: "${contractName}", title: "${title}",
        agreementSignature: "${agreementSignature}", status: "pending", password: "${password}", usioEmail: "${usioEmail}",
        usioDbaName: "${usioDbaName}", usioLegalName: "${usioLegalName}"}){
        id
        name
        primaryContact
        phone
        email
        website
        address
        city
        zip
        state
        logoUrl
        licensee
        by
        contractName
        title
        agreementSignature
        status
        password
        usioEmail
        usioDbaName
        usioLegalName
        }
      }`))

    //controls when the saved icon and message display on SignupFlow3
    setSubmitted(true)
    }
	}

	return(
		<div>
		{completedFlow1 ? completedFlow2 ? 
			<SignupFlow3 submitting={submitting} submittedUsio={submittedUsio} usioLogin={usioLogin} usioPassword={usioPassword} usioEmail={usioEmail}
			usioDbaName={usioDbaName} usioLegalName={usioLegalName} handleUsioLoginChange={handleUsioLoginChange}
			handleUsioEmailChange={handleUsioEmailChange} handleUsioPasswordChange={handleUsioPasswordChange}
			handleUsioDbaNameChange={handleUsioDbaNameChange} handleUsioLegalNameChange={handleUsioLegalNameChange}
			submitted={submitted} handlePrevFlow={handlePrevFlow3} handleSubmitFlow={handleSubmitFlow3} /> : 
			<SignupFlow2 ref={sigRef} licensee={licensee} by={by} contractName={contractName} title={title} handleLicenseeChange={handleLicenseeChange}
			handleByChange={handleByChange} handleContractNameChange={handleContractNameChange}
			handleTitleChange={handleTitleChange} handlePrevFlow={handlePrevFlow2} handleSubmitFlow={handleSubmitFlow2} /> : 
			<SignupFlow1 email={email} confirmEmail={confirmEmail} website={website} password={password} confirmPassword={confirmPassword}
      name={name} primaryContact={primaryContact} phone={phone} address={address} zip={zip} state={state} logoUrl={logoUrl} city={city}
      handleCityChange={handleCityChange}
			handleEmailChange={handleEmailChange} handleConfirmEmailChange={handleConfirmEmailChange} handleWebsiteChange={handleWebsiteChange}
			handlePasswordChange={handlePasswordChange} handleConfirmPasswordChange={handleConfirmPasswordChange} handleNameChange={handleNameChange} handlePrimaryContactChange={handlePrimaryContactChange}
			handlePhoneChange={handlePhoneChange} handleAddressChange={handleAddressChange} handleZipChange={handleZipChange} handleStateChange={handleStateChange}
			handleSubmitFlow={handleSubmitFlow1} handleLogoUpload={handleLogoUpload}/>}
		</div>
		)
}

export default SignupFlow
