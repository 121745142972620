import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from './AppliedRoute.js'



import AdminDashboard from './AdminDashboard'
import AdminOffices from './AdminOffices'
import AdminPayments from './AdminPayments'
import AdminViewOffice from './AdminViewOffice'
import AdminViewOffice3 from './AdminViewOffice3'

import AdminPlans from './AdminPlans'
import AdminApplications from './AdminApplications'
import AdminViewApplication from './AdminViewApplication'

import NewPassword from './NewPassword'

export default function AdminRoutes({appProps}) {
  return (
    <Switch>
      <AppliedRoute path="/admin/password" exact component={NewPassword} appProps={appProps}/>
      <AppliedRoute path="/admin/offices" exact component={AdminOffices} appProps={appProps}/>
      <AppliedRoute path="/admin/payments" exact component={AdminPayments} appProps={appProps}/>
      <AppliedRoute path="/admin/plans" exact component={AdminPlans} appProps={appProps}/>
      <AppliedRoute path="/admin/office/:officeId" component={AdminViewOffice3} appProps={appProps}/>
      <AppliedRoute path="/admin/applications" exact component={AdminApplications} appProps={appProps}/>
      <AppliedRoute path="/admin/application/:applicationId" exact component={AdminViewApplication} appProps={appProps}/>
      <AppliedRoute path="/admin" exact component={AdminDashboard} appProps={appProps}/>
      <AppliedRoute path="/" component={AdminDashboard} appProps={appProps}/>

    </Switch>
  );
}