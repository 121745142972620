import {useState, useEffect, useContext, useCallback}  from "react";

import { createFormArray } from '../events/ReactQL'
import { handleFormInputChange} from '../events/EventQL'

import {UserContext} from '../UserContext'

import Amplify, { API, graphqlOperation } from 'aws-amplify';

Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev', //REQUIRED -  Amazon S3 bucket
        }
    }
});


function useOneTimePayment(props){

  const cardDict = {name: '', email: '', accountNumber: '', routingNumber: '',
  cardNumber: '', cardType: '', expireM: '', expireY: '',
  cardAddress: '', cardCity: '', cardState: '', cardZip: '',
  isMember: false, country: 'US', amount: '', method: 'card',
  token: '', isSwiping: true, cvv: ''}

  const auth = useContext(UserContext)

  const [stateDict, setStateDict] = useState(cardDict)
  const [inputList, setInputList] = useState(createFormArray(cardDict))

  const [responseCount, setResponseCount] = useState(0)

  const [paymentToken, setPaymentToken] = useState("")
  const [paymentStatus, setPaymentStatus] = useState("")

  const [cardAll, setCardAll] = useState("")
  const [swiped, setSwiped] = useState(false)

const handleCardAllChange = (e) => {
    if(!swiped){
      const newCardAll = e.target.value
      setCardAll(newCardAll)

      let swipeName = ""
      let swipeNumber = ""
      let swipeExpire = ""
      let swipeExpire2
      let swipeExpireY = ""
      let swipeExpireM = ""
      let swipeType = ""
      let swipeDig = ""
      let swipeCCV = ""

      if((newCardAll != undefined) && (newCardAll.includes('/'))){
        swipeName = newCardAll.split('^')[1]
        handleSwipeInputChange('name', swipeName.split('/').join(' '))
      }
      if(newCardAll.includes(';')){
        swipeNumber = newCardAll.split(';')[1].split('=')[0]
        swipeDig = swipeNumber[0]
        handleSwipeInputChange('cardNumber', swipeNumber)
        if(swipeDig == 4){
          swipeType = 'VISA'
        }
        else if(swipeDig == 5){
          swipeType = 'MSTR'
        }
        else if(swipeDig == 6){
          swipeType = 'DISC'
        }
        else{
          swipeType = 'AMEX'
        }
        handleSwipeInputChange('cardType', swipeType)

      }
      if(newCardAll.includes('=')){
        swipeExpire = newCardAll.split('=')[1].substring(0,4)
        if(swipeExpire.length == 4){
          swipeExpireY = "20" + swipeExpire.substring(0, 2)
          swipeExpireM = swipeExpire.substring(2, 4)
          handleSwipeInputChange('expireY', swipeExpireY)
          handleSwipeInputChange('expireM', swipeExpireM)

        }
      }
    }
    
  
  }

  const handleInputChange = useCallback(
    (e) => {
      let event = e

      if(e.target.value.substring(0,2) == "%B"){
          handleCardAllChange(event)
      }

      const [newInputList, newStateDict] = handleFormInputChange(e, stateDict, inputList) //add event handler function

      if((e.target.value.substring(0,2) == "%B") && (e.target.value.slice(-1) == '?') && e.target.value.includes('=')) {
        newStateDict[event.target.name] = ""
      }    

      setInputList(newInputList) //setState
      setStateDict(newStateDict) //setState
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  const handleSwipeInputChange = useCallback(
    (key, val) => {
    const newStateDict = stateDict
    newStateDict[key] = val
    setStateDict(newStateDict)

    }, [stateDict])

    const handleTokenization = async (submit=false, e) => {
      if(e != undefined){
        e.preventDefault()
      }
      
      //expiration month and date are combined into a single string
      const expirationT = stateDict.expireM + "/" + stateDict.expireY.substring(2, 4)
      const expiration = stateDict.expireM + stateDict.expireY

      const firstName = stateDict.name.split(" ")[1]
      const lastName = stateDict.name.split(" ")[0]

      //api post parameters https://api.securepds.com/2.0/documentation/#creditcardpayment
      //submit amount of $0.00 to tokenize the card info
      let path = "https://api.securepds.com/2.0/payments.svc/JSON/SubmitCCPayment"
      let obj = `{"MerchantID":"${props.officeMerchantKey}",
                "Login":"${props.officeUsioLogin}",
                "Password":"${props.officeUsioPassword}",
                "CardNumber": "${stateDict.cardNumber.split(' ').join('').replace('_', '')}", 
                "CardType": "${stateDict.cardType}",
                "ExpDate" : "${expiration}",
                "Address1": "${stateDict.cardAddress}",
                "City": "${stateDict.cardCity}",
                "State": "${stateDict.cardState}",
                "Country": "US",
                "Amount": "0.00",  
                "FirstName": "${firstName}",
              "LastName": "${lastName}",
              "EmailAddress": "${stateDict.email}",
              "Zip": "${stateDict.cardZip}"}`

      let token = paymentToken

    //post the payment info to USIO
    if((stateDict.amount == "") || (token == undefined) || (token == "") || (token == "undefined")){
      var xhttp = new XMLHttpRequest();
         xhttp.onreadystatechange = function () {
           if (this.readyState != 4) { return }

             if (this.status == 200) {
                 setPaymentToken(JSON.parse(this.responseText)['Confirmation'])
                 //alert(this.responseText);
             }
             else{
                try{
                  token = JSON.parse(this.responseText)['Confirmation']
                  setPaymentStatus(JSON.parse(this.responseText)['Message'])
                }
                catch{
                  setPaymentStatus(JSON.parse(this.responseText)['Message'])
                }
             }
         };

         if((stateDict.amount == "") || (stateDict.amount == "$0.00")){
          alert("Fields cannot be empty")
         }
         else{
           xhttp.open("POST", path, true);
           xhttp.setRequestHeader("Content-type", "application/json");

           xhttp.send(obj);

           var resultText = xhttp.statusText;
         }
    }

    setPaymentToken(token)
  }

  const handlePostPayment = useCallback(async (e, tokenMember=null) => {
    if(e != undefined){
      e.preventDefault()
    }
    let count = 0

    let responseStatus = ""
    let responseConfirmation = ""
    let responseText = ""

    //post the payment to USIO https://api.securepds.com/2.0/documentation/#tokenpayment
    var xhttp = new XMLHttpRequest();
       xhttp.onreadystatechange = async function () {
        if(count == 0){
           if (this.readyState == 4 && this.status == 200) {
               responseStatus = await JSON.parse(this.responseText)['Status']
               responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
               responseText = await JSON.parse(this.responseText)['Message']
               setPaymentStatus(responseStatus)
           }
           else{
               if(this.responseText != ""){         
               responseStatus = await JSON.parse(this.responseText)['Status']
               responseConfirmation = await JSON.parse(this.responseText)['Confirmation']
               responseText = await JSON.parse(this.responseText)['Message']
               setPaymentStatus(JSON.parse(this.responseText)['Status'])
               }

           }

            if((responseStatus != "") && (count == 0)){
              count++
              let result
              let resultOffice
              let amt= Number(stateDict.amount.replace('$', ''))
          
              //if the USIO post is successful, add payment (and office payment) record to dynamodb via the amplify api
              if(tokenMember == null){

                result = await API.graphql(graphqlOperation(
                      `mutation create {
                        createPayments(input: {
                          memberId: "", 
                          description: "One Time Payment", 
                          grossPay: ${amt}, 
                          status: "${responseStatus}",
                          netPay: ${amt}, 
                          memberName: "Non-Member", 
                          contactName: "${stateDict.email}", 
                          paymentInfo: "${cardDict.cardType} ****${cardDict.cardNumber.substr(cardDict.cardNumber.length - 4)}",
                          office: "${auth.officeId || auth.user}", 
                          confirmationToken: "${responseStatus + responseText + responseConfirmation}"}){
                          id
                          memberId
                          memberName
                          description
                          grossPay
                          fee
                          netPay
                          office
                          confirmationToken
                        }
                      }`))
                
                resultOffice = await API.graphql(graphqlOperation(
                      `mutation create {
                        createOfficePayments(input: {
                          officeId: "${auth.officeId || auth.user}", 
                          description: "One Time Payment", 
                          grossPay: ${.83}, 
                          status: "${responseStatus}",
                        fee: ${0}, 
                        netPay: ${amt}, 
                        name: "${props.officeName}", 
                        confirmationToken: "${responseStatus + responseText + responseConfirmation}",
                        contactName: "${stateDict.email}", 
                        paymentInfo: "${cardDict.cardType} ****${cardDict.cardNumber.substr(cardDict.cardNumber.length - 4)}",
                      
                      }){
                          id
                          officeId
                          description
                          grossPay
                          fee
                          netPay
                          name
                          practice
                          confirmationToken
                        }
                      }`))
              }
              else{
                result = await API.graphql(graphqlOperation(
                      `mutation create {
                        createPayments(input: {
                          memberId: "${tokenMember.memberId}", 
                          description: "One Time Payment", 
                          grossPay: ${amt}, 
                          status: "${responseStatus}",
                        netPay: ${amt}, 
                        memberName: "${tokenMember.memberName}", 
                        practice:"${tokenMember.practice}",
                        contactName: "${stateDict.email}", 
                        paymentInfo: "${tokenMember.paymentDescription}", 
                        office: "${auth.officeId || auth.user}", 
                        confirmationToken : "${responseText + responseConfirmation}"
                      }){
                          id
                          memberId
                          memberName
                          description
                          grossPay
                          fee
                          netPay
                          office
                          practice
                          confirmationToken
                        }
                      }`))
                
                resultOffice = await API.graphql(graphqlOperation(
                      `mutation create {
                        createOfficePayments(input: {
                          officeId: "${auth.officeId || auth.user}", 
                          description: "One Time Payment", 
                          grossPay: ${.83}, 
                          status: "${responseStatus}",
                        fee: ${0}, 
                        netPay: ${amt}, 
                        name: "${props.officeName}", 
                        practice: "${tokenMember.practice}",
                        contactName: "${tokenMember.contactName}", 
                        paymentInfo: "${tokenMember.paymentDescription}"
                      }){
                          id
                          officeId
                          description
                          grossPay
                          fee
                          netPay
                          name
                          practice
                        }
                      }`))                
              }

          count++
            }
            let newResponseCount = responseCount + 1
            setResponseCount(newResponseCount)
       }
    }
       xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitTokenPayment", true);
       xhttp.setRequestHeader("Content-type", "application/json");


            var obj = `{"MerchantID":"${props.officeMerchantKey}",
            "Login":"${props.officeUsioLogin}",
            "Password":"${props.officeUsioPassword}",
              "Token": "${tokenMember == null ? paymentToken : tokenMember.paymentToken}",
            "Amount": "${stateDict.amount.replace('$', '')}"
            }`

       if(count == 0){
        xhttp.send(obj);
       }


       var resultText = xhttp.statusText;

  }, [auth.officeId, auth.user, cardDict.cardNumber, cardDict.cardType, paymentToken, props.officeMerchantKey, props.officeName, props.officeUsioLogin, props.officeUsioPassword, responseCount, stateDict.amount, stateDict.email])

  useEffect(
    () => {
      if((paymentToken != null) && (paymentToken != "")){
        handlePostPayment()
      }
    }, [handlePostPayment, paymentToken])

  return {stateDict, paymentStatus, handleInputChange, handleSwipeInputChange, handleTokenization, handlePostPayment}
}

export { useOneTimePayment }