import React, { useState, useEffect }  from "react";

import { PDFDownloadLink } from '@react-pdf/renderer';
import PlanDocument from './PlanDocument'

import OfficeLogo from './OfficeLogo'


export default function CustomerAgreement(props){

	const [basicBenefits] = useState(["INCLUDED: teeth cleaning benefits (2/yr)",
		"INCLUDED: All x-rays needed to complete annual exam"])


	const reviewBasicBenefits = basicBenefits.map(benefit => {
		return(
			<div key={benefit}>
				<p>{benefit}</p>
			</div>
			)
	})

	const reviewCustomBenefits = props.customBenefits.map(benefit => {
		if(benefit.included){
			return(
				<div key={benefit.benefit}>
					<p>{benefit.benefit.replace('X', benefit.value)}</p>
				</div>
			)
		}
		return null
	})


	useEffect(() => {
		let userPlanId
		try{
		userPlanId = props.plans.filter(planF => planF.name == props.plan)[0]['id']
		props.fetchPlan(userPlanId)
		}
		catch{
			console.log("")
		}


	}, [props, props.plan])



	return(
		<div className="w-full"> 
			<div className="card flex flex-col my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
					<div className="flex w-full justify-between mb-4">
						<h1 className="text-2xl my-4 font-semibold">{props.plan} Benefits</h1>
						<OfficeLogo logoUrl={props.officeLogo} />
					</div>					
					<div>
						We are proud to announce that we offer an In-House Savings Plan (IOP) in our dental office. Our IOP is an annual dental savings program we offer for families and individuals that allows all IOP patients to receive quality dental services at greatly reduced prices in our office. Unlike conventional insurance plans, with our IOP program there are <strong>no deductibles, no yearly maximums, and no waiting periods to begin treatment.</strong> IOP benefits and savings begin immediately upon registration.		
					</div>
					<h2 className="text-lg my-4 font-semibold">Our Savings Include:</h2>
						{reviewBasicBenefits}
						{reviewCustomBenefits}
					<div className="my-4">
						The fee paid for our IOP savings plan is for included standard of care services and represents a courtesy 
						accounting adjustment for payment, made in full, at the time of service. An initial fee is $[calculated in next step] for one individual… and only $[calculated in next step] for 
						each additional family member, which represents an additional savings of $[calculated in next step]. Eligible family members include spouse and dependent children under the age of 19 
						(up to age 23 if dependent child is a full-time student). Fees are due and payable when services are rendered and are non-refundable when services have been provided. Savings duration 
						is for one year from registration date. All patient portions for services received are due at the time of services to receive IOP savings. 
						Anniversary dates are 12 months after signing up. Interest-free payment options may be provided in 3, 6- or 12-month durations upon request and with approved credit. 
						Repayment duration is based on service totals and procedural type. If you choose to use a repayment/financial plan, your IOP Savings will be adjusted around the third-party fee and interest
						 free payment options will be customized for your repayment needs. {props.customBenefits.filter(benefit => benefit.name == "Missed appointment fee")[0] != undefined ? props.customBenefits.filter(benefit => benefit.name == "Missed appointment fee")[0]['included'] ? props.customBenefits.filter(benefit => benefit.name == "Missed appointment fee")[0]['value'] : null : null} Fees charged are not membership fees and all fees paid are for provided services only.
	    			</div>
	    			<div className="w-full flex justify-around">
	    				<div className="w-1/2 flex justify-around">
	    					<p>Signature:</p>
							<button disabled={props.agreed} className={props.agreed ? "bg-white border border-gray-100 rounded-sm w-2/3" : "px-2 text-white bg-white border border-gray-100 rounded-sm"} onClick={props.handleESignature}>{props.contactName}</button>
						</div>
						<div className="w-1/2 flex justify-around">
							<p>Date:</p>
							<input className="px-2 w-2/3 border border-gray-100 rounded-sm" type="text" value={props.agreementDate} disabled={true}></input>
						</div>
					</div>
					<PDFDownloadLink className="my-6 w-1/4 mx-2 px-2 py-2 bg-blue-500 text-white text-center" document={<PlanDocument officeLogo={props.officeLogo} planType={props.plan} basicBenefits={basicBenefits} customBenefits={props.customBenefits} />} fileName="plan.pdf">
				      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
				    </PDFDownloadLink>
				</div>
		</div>
		)
}