import React, { }  from "react";
//import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

//import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import Input from './components/Input'

export default function FeeGenerator(props){

	const validateForm = async (event) =>{
		event.preventDefault()

		/*if(props.edit){
			const updatedPractice = await API.graphql(graphqlOperation(`mutation update {
			  updatePractice (input: {
			    id: "${props.practiceId}",
			    name: "${fullName}",
			    businessName: "${businessName}",
			    city: "${city}"
			    zip: "${parseInt(zip)}"
			  }){
		          id
		          name
		          businessName
		          city
		          zip
			  }
			}`))
			setEditedForm(true)
		}
		else{
			const result = await API.graphql(graphqlOperation(
			      `mutation create {
			        createPractice(input: {name: "${fullName}", office: "${auth.officeId || auth.user}", businessName: "${businessName}", city: "${city}",
			        zip: "${parseInt(zip)}"}){
			          id
			          name
			          businessName
			          city
			          zip
			        }
			      }`))
			
			if(!props.officeHasPractice){
				props.handleOfficeHasPracticeChange(true)
			    const updateOfficeData = await API.graphql(graphqlOperation(
			          `mutation update {
			            updateOffice(input: {id: "${auth.officeId || auth.user}", hasPractice: ${true}}){
			            id
			            hasPractice
			            }
			          }`))
			    console.log("Office Account Updated")
			}
			setCreatedForm(true)
		}
		*/
	}

	const numberMask = createNumberMask({
	  prefix: '$',
  	  allowDecimal: true,
 	// This will put the dollar sign at the end, with a space.
	})

	const additionalChild = () => {

		//archived
		return(
			<div className="hidden">
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Your recommended additional family member plan fee if paid in full:</p>
          			<MaskedInput mask={numberMask} disabled={true} className="border w-1/4" type="text" value={props.recommendedFee} id="recommendedAdditionalFee"/>
				
				</div>
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Your recommended additional family member plan fee if paid monthly:</p>
          			<MaskedInput mask={numberMask} disabled={true} className="border w-1/4" type="text" value={props.recommendedMonth} id="recommendedAdditionalMonth"/>
				</div>
			</div>
			)
	}

	const additionalAdult = () => {

		//archived
		return(
			<div className="hidden">
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Your recommended additional family member plan fee if paid in full:</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcAdditionalMonthFee} className="border w-1/4" onChange={props.handleRecommendedAdditionalFeeChange} type="text" value={props.recommendedAdditionalFee} id="recommendedAdditionalFee"/>
				
				</div>
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Your recommended additional family member plan fee if paid monthly:</p>
          			<MaskedInput mask={numberMask} disabled={true} className="border w-1/4" onChange={props.handleRecommendedAdditionalMonthChange} type="text" value={props.recommendedAdditionalMonth} id="recommendedAdditionalMonth"/>
				
				</div>
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Additional plan down payment (leave $0 if not required):</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcAdditionalMonthFee} className="border w-1/4" onChange={props.handleRecommendedAdditionalDownChange} type="text" value={props.recommendedAdditionalDown} id="recommendedAdditionalDown"/>
				
				</div>
			</div>
			)
	}

	const perioMaintenance = () => {

		//perio maintenance is the fee input differentiating perio calculation from adult/child calc, so is separated here
		return (
			<>
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Perio Maintenance (Enter your fee for one perio maintenance):</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcFee} className="border w-1/4" onChange={props.handlePerioMaintenanceChange} type="text" value={props.perioMaintenance} id="perioMaintenance"/>
				</div>

				<div className="flex flex-col my-4 w-full">
				<p className="my-auto pr-4">Adult plan fee:</p>
				<MaskedInput mask={numberMask} onBlur={props.recalcFee} className="border w-1/4" onChange={props.handleAdultPlanFeeChange} type="text" value={props.adultPlanFee} id="adultFee"/>
				</div>
			</>
			)
	}

	const nonPerioInputs = () => {
		//the only fee calclating inputs used for adult and child plans
		return (
			<div>
				<div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">Prophy (enter your fee for one simple cleaning):</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcFee} className="border w-1/4" onChange={props.handleProphyChange} type="text" value={props.prophy} id="prophy"/>
				</div>
				{((props.planType == "Perio") && (props.hasAdultPlan)) ? null : <div className="flex flex-col my-4 w-full">
					<p className="my-auto pr-4">New patient and periodic exams (enter the total fee for all of the exams you normally perform in a year).</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcFee} className="border w-1/4" onChange={props.handleExamChange} type="text" value={props.exam} id="exam"/>
					<p className=''>*Use your standard: If you only perform one exam per year, only include one.  If your standard is two, include two.</p>
				</div>}
				{((props.planType == "Perio") && (props.hasAdultPlan)) ? null : <div className="flex flex-col my-4 w-full ">
					<p className="my-auto pr-4">All new patient x-rays (enter the total fee for all x-rays needed for a new patient).  Ex. 4 bitewings and a pano.</p>
          			<MaskedInput mask={numberMask} onBlur={props.recalcFee} className="border w-1/4" onChange={props.handleXRayChange} type="text" value={props.xRay} id="xRay"/>
				</div>}
			</div>
			)
	}

	return(
		<div className="w-full h-full">
			<form onSubmit={validateForm} className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<button onClick={props.handleRouteToMainPlans} className="text-left my-2 text-blue-500 w-full">{"<< Plans"}</button>
				<h1 className="text-2xl">{props.planType} Plan Calculation</h1>
				<p className="text-lg">Be sure to use an updated fee schedule.</p>
				{props.planType == "Perio" ? <p className="text-lg my-4 font-bold underline">Complete the adult plan first.</p> : null}

				<div className="w-full flex flex-col">
					<h1 className="text-lg mt-8 font-semibold">What Does Your Office Currently Charge?</h1>
					<p>
						Enter your fees for the following services.  Please note, we recommend creating one plan to use for both new and existing patients.
						<br/>
						<i>*The algorithm suggests a fee that can work for both types of patients (new and existing).</i>
					</p>

					{props.planType == "Perio" ? perioMaintenance() : nonPerioInputs()}
					
					<div className="flex flex-col my-4 w-full">
						<p className="my-auto pr-4">Recommended Fee:</p>
              			<MaskedInput 
						  disabled={true} 
						  mask={numberMask} 
						  onBlur={props.recalcMonthFee} className="border w-1/4" 
						  onChange={props.handleRecommendedFeeChange} 
						  type="text" 
						  value={`${parseFloat(String(props.recommendedFee || '').replace(/ $,/i, '')).toFixed(2)}`} 
						  id="recommendedFee"/>
					
					</div>

					<h1 className="text-2xl my-2 hidden">Additional {props.planType} Family Members</h1>
					{props.planType == "Child" ? additionalChild() : additionalAdult()}
				</div>
				<div className="w-full flex flex-col mt-8">
					<h1 className="text-lg mt-4 font-semibold">Set Final Fees</h1>

					<div className="flex flex-col my-4 w-full">
						<p className="my-auto pr-4 font-bold">Plan fee if paid in full:</p>
              			<MaskedInput mask={numberMask} onBlur={props.recalcMonthFee} className="border w-1/3" onChange={props.handleFinalFeeChange} type="text" value={props.finalFee} id="finalFee"/>
					</div>
					<div className="flex flex-col my-4 w-full font-bold">
						<p className="my-auto pr-4">Monthly plan down payment (leave $0 if not required):</p>
              			<MaskedInput mask={numberMask} onBlur={props.recalcMonthFee} className="border w-1/3" onChange={props.handleRecommendedDownChange} type="text" value={props.recommendedDown} id="recommendedDown"/>
					
					</div>
					<div className="flex flex-col my-4 w-full">
						<p className="my-auto pr-4 ">
							<p className='font-bold'>Your plan fee if paid monthly:</p><br/>
						</p>
              			<MaskedInput mask={numberMask} className="border w-1/3" disabled={true} onChange={props.handleRecommendedMonthChange} type="text" value={props.recommendedMonth} id="recommendedMonth"/>
						  <i><small>*Please note: an extra $50 is included in the total for monthly plans. This is additional money for your practice to compensate you for offering monthly payments.</small></i> 

					</div>
				</div>

			<div className="my-2 hidden">
				Although you will determine your own definition of “family member” we suggest spouse and underage children. Some offices may want to expand this definition to include “domestic partners” or the primary member’s child still in college. All that really matters in formulating this portion of the program is that your definition be written and consistent within your office so that there is no confusion.
We suggest decreasing the fee of your first member by $50, $75, or $100. For example, if your initial member fee is $299 subtract $50 and your additional member fee is $249. The reason we recommend this is that it keeps the math simple for patients to understand and easy for your team to explain.
			</div>
			<div className="my-2 hidden">
For example, “Mrs. Jones your fee is $249 and for each family member you sign up on your plan you’ll save $50 and the fee will be only $199 for all of the same benefits. Do you have any family members you’d like to join?”
			</div>
			<div className="my-2 hidden">
You can see how powerful this can be and it will serve to get more QDP patients to your office as well as paying for those services!
			</div>
			<div className="flex">
				<button onClick={props.handlePreviousPage} className="mx-2 my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Previous</button>
				<button onClick={props.handleSubmitFee} className="my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Next</button>
			</div>
			</form>
		</div>
		)
}