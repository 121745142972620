import React, { useState, useEffect, useContext }  from "react";


const Select = (props) => {
	const options = props.options.map(option =>{
		return(
			<option key={option.id} value={option[props.displayField]}>{option[props.displayField]}</option>
			)
	})


		return(
			<div className="w-full">
				<select className="c-dropdown focus:outline-none p-2 ml-4" onChange={props.onChange} value={props.value} name={props.name} id={props.name}>
				  	{props.defaultAll == false ? null : <option value="all">All</option>}
				    {options}
				</select> 					
			</div>
			)
}

export default Select
