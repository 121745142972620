import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { API, graphqlOperation } from 'aws-amplify';
import { UserContext } from './UserContext'

import MemberCardDetail from './screens/members/MemberCardDetail'
import NoMemberPaymentDetails from './NoMemberPaymentDetails'
import Moment from 'moment'

const debugCard = {
	name: "Test Card",
	number: "4111111111111111",
	type: "VISA",
	expireM: "10",
	expireY: "2022",
	address: "123 TEst Rd",
	city: "Beverly Hills",
	state: "CA",
	zip: "90210"
}

export default function NoMemberPayments(props) {
	const auth = useContext(UserContext)
	const [card, setCard] = useState({
		name: "",
		number: "",
		type: "",
		expireM: "",
		expireY: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		// ...debugCard
	})
	const paymentType = 'card'
	const [contactName, setContactName] = useState("")
	const [contactEmail, setContactEmail] = useState("")
	const [totalBalance, setTotalBalance] = useState(0)
	const [initialDeposit, setInitialDeposit] = useState(0)
	const [totalMonths, setTotalMonths] = useState(3)

	const [periodAmount, setPeriodAmount] = useState(0)
	const [submitting, setSubmitting] = useState(false)

	const cardRef = useRef(null)
	const [cardAll, setCardAll] = useState("")
	const [swipeNow, setSwipeNow] = useState(false)
	const [swiped, setSwiped] = useState(false)
	const [practice, setPractice] = useState("")
	const [practices, setPractices] = useState([])
	

	const handlePracticeChange = (event) =>{
		const practiceName = [event.target.value][0]
		if(practiceName != "all"){
			setPractice(practiceName)
		}
		else{
			setPractice("")
		}
	}

	const fetchPractices = useCallback(
		async () => {
		const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
		  listPractices(filter: {office: {eq:"${auth.officeId || auth.user}"}}, limit: 9999) {
			items {
				id
				name
				city
				zip
			}
		  }
		  }`))
  
		let practicesList = practiceData.data.listPractices.items
		let rowCount = 1
		let newPractice = []
  
		let newPracticeList = []
		for(let i=0; i<practicesList.length; i++){
		  newPractice = {...practicesList[i], rowId: rowCount}
		  newPracticeList.push(newPractice)
		  rowCount = rowCount + 1
		  }
  
	  
		setPractices(newPracticeList)
		if(!props.edit){
			if(newPracticeList.length > 0){
				setPractice(newPracticeList[0]['name'])
			}
		}
	  }, [auth.officeId, auth.user, props.edit])

	const handleTotalBalanceChange = (event) => {
		setTotalBalance(event.target.value)
	}

	const handleInitialDepositChange = (event) => {
		let deposit = event.target.value
		setInitialDeposit(deposit)
	}


	//// Authorize the card here
	const xmlResponseHandler = (resolve, reject) =>  {
		return function () {
			if (this.readyState != 4) { return }

			if (this.status == 200) {
				const responseObj = JSON.parse(this.responseText)
				const responseToken = responseObj['Confirmation']
				if (!responseToken || responseToken.length === 0) {
					return reject(responseObj)
				}

				resolve(responseToken)
			}
			else if (this.responseText != "") {
				const response = JSON.parse(this.responseText)
				reject(response)
			}
			else {
				alert(`Unknown state?`)
			}
		}
	}

	const handleTokenization = async () => {
		const { expireY, expireM } = card
		const newExpiration = expireM + expireY

		const nameParts = card.name.split(' ')
		const firstName = nameParts.shift()
		const lastName = nameParts.shift()


		/// Different amounts will cause different responses
		/// 1.01 - insufficient funds
		/// 1.02 - do not honor
		/// 1.03 - invalid credit card number
		/// 1.04 - cvvresponse
		/// 1.05 - mark payment with "N" for the AVS

		const obj = {
			MerchantID: props.officeMerchantKey,
			Login: props.officeUsioLogin, 
			Password: props.officeUsioPassword,

			CardNumber: `${card.number.split(' ').join('').replace('_', '')}`,
			CardType: card.type,
			ExpDate: newExpiration,
			Address1: card.address,
			City: card.city,
			State: card.state,
			Country: "US",

			// We don't need to authorize.  We just need to get a payment token
			Amount: `0.00`,	
			FirstName: firstName,
			LastName: lastName,
			EmailAddress: contactEmail,
			Zip: card.zip
		}

		return new Promise((resolve, reject) => {
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = xmlResponseHandler(resolve, reject)
			xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitCCPayment", true);
			xhttp.setRequestHeader("Content-type", "application/json");
			xhttp.send(JSON.stringify(obj));
		})
	}

	const handleDeposit = async (cardToken) => {


		// https://api.securepds.com/2.0/documentation/#tokenpayment
		const obj = {
			MerchantID: props.officeMerchantKey,
			Login: props.officeUsioLogin, 
			Password: props.officeUsioPassword,

			Amount:  `${initialDeposit}`.replace(/[$,]/g, ''),
			Token: cardToken
		}

		return new Promise((resolve, reject) => {
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = xmlResponseHandler(resolve, reject)
			xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitTokenPayment", true);
			xhttp.setRequestHeader("Content-type", "application/json");
			xhttp.send(JSON.stringify(obj));
		})
	}

	const handleRepeatedPayment = async (paymentToken) => {

		const { expireY, expireM } = card
		const newExpiration = expireM + expireY

		const deposit = Number(`${initialDeposit}`.replace(/[$,]/g, ''))
		const amountDue = Number(`${totalBalance}`.replace(/[$,]/g, ''))
		const remainingBalance = amountDue - deposit

		
		const dueDate = Moment().add(1, 'day')

		//// Save a non-member-finance
		const result = await API.graphql(graphqlOperation(
		`mutation create {
			createNonMemberFinance(input: {
				name: "${contactEmail}", 
				practice: "${practice}" 
				office: "${auth.officeId || auth.user}", 
				dayDue: ${dueDate.get('date')},
				interestRate: 0,
				totalBalance: ${String(totalBalance).replace('$', '')}, 
				remainingBalance: ${String(remainingBalance).replace('$', '')},
				totalMonths: ${totalMonths},
				periodAmount: ${String(periodAmount).replace('$', '')},
				paymentToken: "${paymentToken}", 
				initialDeposit: ${String(initialDeposit).replace('$', '')}, 
				expiration: "${newExpiration}", 
				paymentType: "${paymentType}",
				paymentDescription: "****${card.number.substr(card.number.length - 4)}",
				contactName: "${contactName}",
			}) {
			id
			name
			practice
			office
			dayDue
			initialDeposit
			totalMonths
			periodAmount
			remainingBalance
			interestRate
			totalBalance
			paymentToken
			expiration
			paymentType
			}
		}`))

		console.log(result)
		return result.data.createNonMemberFinance
		// const { expireY, expireM } = card
		// const newExpiration = expireM + expireY

		// const nameParts = card.name.split(' ')
		// const firstName = nameParts.shift()
		// const lastName = nameParts.shift()

		// const obj = {
		// 	MerchantID: props.officeMerchantKey,
		// 	Login: props.officeUsioLogin, 
		// 	Password: props.officeUsioPassword,

		// 	FirstPaymentDate: Moment().add(1, 'month').format('MM/DD/YYYY'), // TODO:
		// 	PaymentFrequency: "4", // Monthly
		// 	NumberOfPayments: totalMonths,
		// 	// FinalPaymentAmount: ,	// TODO: wtf
		// 	Amount: periodAmount,	// Not sure what
		// 	// SecondaryAmount: ,	// Do we need it
		// 	CardNumber: `${card.number.split(' ').join('').replace('_', '')}`,
		// 	CardType: card.type,
		// 	ExpDate: newExpiration,
		// 	Address1: card.address,
		// 	City: card.city,
		// 	State: card.state,
		// 	Country: "US",
		// 	FirstName: firstName,
		// 	LastName: lastName,
		// 	EmailAddress: contactEmail,
		// 	Zip: card.zip
		// }

		// return new Promise((resolve, reject) => {
		// 	var xhttp = new XMLHttpRequest();
		// 	xhttp.onreadystatechange = xmlResponseHandler(resolve, reject)
		// 	xhttp.open("POST", "https://api.securepds.com/2.0/payments.svc/JSON/SubmitRepeatPayment", true);
		// 	xhttp.setRequestHeader("Content-type", "application/json");
		// 	xhttp.send(JSON.stringify(obj));
		// })
	}


	/// Handle period amount calculation here
	useEffect(() => {
		const deposit = Number(`${initialDeposit}`.replace(/[$,]/g, ''))
		const amountDue = Number(`${totalBalance}`.replace(/[$,]/g, ''))
		if (amountDue === 0) {
			if (periodAmount !== 0) { setPeriodAmount(0) }
			return
		}
		const payment = ((amountDue - deposit) / totalMonths).toFixed(2)
		setPeriodAmount(payment)
	}, [totalBalance, initialDeposit, totalMonths, periodAmount])

	useEffect(() => {
		fetchPractices()
	}, [fetchPractices])

	const savePaymentRecords = async (confirmation, description, amount, practice, status, planId) => {
		
		const result = await API.graphql(graphqlOperation(
			`mutation create {
			  createPayments(input: {
				  practice: "${practice}", 
				  memberId: "", 
				  description: "${description}", 
				  grossPay: ${amount}, 
				  status: "${status}",
			  netPay: ${amount}, 
			  memberName: "Non-Member", 
			  paymentInfo: "${card.type} ****${card.number.substr(card.number.length - 4)}",
			  contactName: "${contactEmail}",
			  office: "${auth.officeId || auth.user}", 
			  confirmationToken: "${confirmation}", 
			  planId: "${planId}"
			}){
				id
				practice
				memberId
				memberName
				description
				grossPay
				fee
				netPay
				office
				confirmationToken
				planId
			  }
			}`))
	  
		  const resultOffice = await API.graphql(graphqlOperation(
			`mutation create {
			  createOfficePayments(input: {
				  practice: "${practice}",
				  officeId: "${auth.officeId || auth.user}", 
				  description: "${description}", 
				  grossPay: ${.83}, 
				  status: "${status}",
			  	 fee: ${0}, 
			 	  netPay: ${amount}, 
				   name: "${props.officeName}", 
				   confirmationToken: "${confirmation}", 
				   planId: "${planId}"
				   paymentInfo: "${card.type} ****${card.number.substr(card.number.length - 4)}",
				   contactName: "${contactEmail}",
				}){
				id
				practice
				officeId
				description
				grossPay
				fee
				netPay
				name
				practice
				confirmationToken
				planId
			  }
			}`))
	}

	const handlePayment = async () => {
		setSubmitting(true)
		try {

			const depositAmount = `${initialDeposit}`.replace(/[$,]/g, '')
			const token = await handleTokenization()
			const planResult = await handleRepeatedPayment(token)

			/// Save deposit if applicable
			if (Number(depositAmount) > 0) {
				console.log(`Saving deposit record`)

				// TODO: What happens if the deposit fails here?  We should prob cancel the repeated payment
				const depositConfirm = await handleDeposit(token).catch(ex => {
					console.error(`Deposit error.  Go ahead and set the payment`)
					return ''
				})

				await savePaymentRecords(
					`${depositConfirm}`,
					`Payment Plan - Deposit`,
					depositAmount,
					practice,
					depositConfirm == '' ? 'failure' : 'success',
					planResult['id']
				)
			}
			
			
			// await savePaymentRecords(
			// 	`rpt:${planConfirmation}`,
			// 	`Payment Plan - ${totalMonths} mos`,
			// 	periodAmount,
			// 	'success'
			// )

			alert('Success')
		}
		catch (ex) {
			console.error(ex)
			alert(`Error: ` + JSON.stringify(ex))
		}

		setSubmitting(false)
	}


	//// Used for Card swiper
	const resetFocus = (e) => {
		let clickType = e.target.type
		if(swiped != true){
			if((clickType != 'select-one') && (clickType != 'text') && (clickType != 'email')){
				cardRef.current.focus()
				setSwipeNow(true)
			}
		}
	}

	const resetFocus2 = (e) => {
		if(cardRef.current != null){
			cardRef.current.blur()
			setSwipeNow(false)
		}
	}

	const handleCardAllChange = (e) => {
		
		/// If swiped, then ignore
		if (swiped) { return }

		const newCardAll = e.target.value
		setCardAll(newCardAll)

		let tmpCard = {...card}

		if((newCardAll != undefined) && (newCardAll.includes('/'))) {
			const swipeName = newCardAll.split('^')[1]
			const swipeNames = swipeName.replace(/ /g,"").split('/')
			let firstName = swipeNames[0]

			const fullName = [swipeNames[1], firstName].join(' ')
			// setCardName(fullName)
			tmpCard.name = fullName
		}

		/// Card number and type
		if(newCardAll.includes(';')) {
			tmpCard.number = newCardAll.split(';')[1].split('=')[0]
			
			let swipeType = ""
			switch (tmpCard.number[0]) {
				case 4: swipeType = 'VISA'; break;
				case 5: swipeType = 'MSTR'; break;
				case 6: swipeType = 'DISC'; break;
				default: swipeType = 'AMEX'; break;
			}
			tmpCard.type = swipeType
		}

		/// Expiration
		if(newCardAll.includes('=')){
			const swipeExpire = newCardAll.split('=')[1].substring(0,4)
			if(swipeExpire.length == 4) {
				tmpCard.expireY = "20" + swipeExpire.substring(0, 2)
				tmpCard.expireM = swipeExpire.substring(2, 4)
			}
		}

		/// Final run
		if(tmpCard.number.length >= 15 && tmpCard.expireY.length == 4) {
			setCard({...tmpCard})
			setSwiped(true)
		}
	}


	return (
		<div>
			<input ref={cardRef} className="opacity-0" onChange={handleCardAllChange} value={cardAll}></input>

			<MemberCardDetail 
				title='Payment Plan | Card Details'
				resetFocus={resetFocus}
				resetFocus2={resetFocus2}
				swipeNow={swipeNow}
				paymentType={paymentType}
				cardName={card.name} 
				cardNumber={card.number} 
				expireM={card.expireM} 
				expireY={card.expireY}
				handleCardNameChange={e => setCard({...card, name: e.target.value})}
				handleCardNumberChange={e => setCard({...card, number: e.target.value})} 
				handleExpireMChange={e => setCard({...card, expireM: e.target.value})}
				handleExpireYChange={e => setCard({...card, expireY: e.target.value})} 
				cardType={card.type}
				cardAddress={card.address} cardCity={card.city} cardState={card.state} cardZip={card.zip}
				handleCardTypeChange={e => setCard({...card, type: (e.target || e).value})} 
				handleCardAddressChange={e => setCard({...card, address: e.target.value})}
				handleCardCityChange={e => setCard({...card, city: e.target.value})} 
				handleCardStateChange={e => setCard({...card, state: e.target.value})} 
				handleCardZipChange={e => setCard({...card, zip: e.target.value})} />

			<NoMemberPaymentDetails
				practices={practices} practice={practice} handlePracticeChange={handlePracticeChange}
				contactEmail={contactEmail}
				handleContactEmailChange={e => setContactEmail(e.target.value) }
				contactName={contactName}
				handleContactNameChange={e => setContactName(e.target.value)}				
				paymentInterval={"monthly"}
				periodAmount={periodAmount}
				totalBalance={totalBalance}
				initialDeposit={initialDeposit}
				totalMonths={totalMonths}
				handleTotalMonthsChange={e => setTotalMonths(e.target.value)}
				handleTotalBalanceChange={handleTotalBalanceChange}
				handleInitialDepositChange={handleInitialDepositChange} />


				<div className='w-full'>
					<div className='card p-8 bg-white border rounded-lg w-5/6 mx-auto '>

					<button 
						disabled={submitting} 
						className="gradient-btn" 
						onClick={handlePayment} 
						title={submitting ? 'Submitting...' : 'Submit'}>
							{submitting ? 'Submitting...' : 'Submit'}
					</button>

					<p className='pt-8'>
					Disclaimer: Certain numbers are rounded up or down. There may therefore be discrepancies between the actual totals of the individual amounts recorded and the totals shown as well as between the numbers in the fields and the numbers given in the corresponding text of the billing report. All percentage changes and key figures are calculated based on price inputs.  The bank statement reconciliation of payments is the actual record of payments for the account.
					</p>
					</div>
				</div>
				{/* <Button onClick={handlePayment}>Submit</Button> */}
		</div>
	)
}