import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
import MaskedInput from 'react-text-mask'

import ImageUploader from "react-images-upload";

import OfficeLogo from './OfficeLogo'

import Input from './components/Input'

import Form from './components/Form'
import FormCol from './components/FormCol'


export default function SettingsAccountDetail(props){
  
  const [verificationCode, setVerificationCode] = useState("")

  return(
    <div className="w-full">
      <Form onSubmit={props.handleSubmit} title={"Account"} buttonTitle={"Save"}>
          <FormCol>
            <Input disabled={true} label={"Business Name"} onChange={props.handleInputChange} value={props.state.name} name={"name"} />
            <Input label={"Primary Contact"} onChange={props.handleInputChange} value={props.state.primaryContact} name={"primaryContact"} />
            <Input label={"Phone"} onChange={props.handleInputChange} value={props.state.phone} name={"phone"} />
            <Input label={"Zip"} onChange={props.handleInputChange} value={props.state.zip} name={"zip"} />
            <div>
              <h1 className="my-2 text-lg w-1/2">Upload Logo</h1>
              <input name="logo"
                  type="file" accept='image/png'
                  onChange={(e) => props.handleLogoUpload(e)}
              />
              <OfficeLogo logoUrl={props.logoUrl} />
            </div>
          </FormCol>
          <FormCol>
            <Input label={"Address"} onChange={props.handleInputChange} value={props.state.address} name={"address"} />
            <Input label={"State"} onChange={props.handleInputChange} value={props.state.state} name={"state"} />
            <Input label={"Email"} disabled={true} value={props.state.email} name={"email"}  />
            <Input label={"Password"} disabled={true} value={"abcdefghijklmnop"} name={"password"}  />
            <Input type="checkbox" 
              name={'disableReminders'}
              label={"Disable Auto-Renewal Reminders"} 
              value={props.state.disableReminders || false} 
              onChange={props.handleInputChange} />
            {(props.officeIsVerified || props.completedVerification) ? null : (props.isVerifying || props.verifying) ? <div className="px-12 mt-2 mb-4 text-right"><Input label={"Verification Code"} onChange={(e) => setVerificationCode(e.target.value)} value={verificationCode} name={"verificationCode"} /><button onClick={() => props.handleSubmitVerification(verificationCode)} className="gradient-btn">Verify</button></div> : <div className="px-12 mt-2 mb-4 text-right"><button onClick={props.handleVerifyEmail} className="mr-4 text-blue-700">Verify Email</button></div>}      
            <div className="px-12 mt-2 mb-4 text-right"><Link to="settings/password" className="mr-4 text-blue-700">Change Password</Link></div>         
          </FormCol>
        </Form>
    </div>
      )
}