import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { blue } from '@material-ui/core/colors';

import Select from 'react-select'

export default function KnowledgeBase(props){

const [pageCount, setPageCount] = useState(1)
const [faqQuestion, setFaqQuestion] = useState("All FAQs")
const [faqs, setFaqs] = useState([
	{
		"id": 1,
		"question": "How do I order a card reader?",
		"answer": "Please visit <a href='https://qdpdentist.com/ocr/'>https://qdpdentist.com/ocr/</a> to order card reader (credit/debit card terminal). "
	  },
	  {
		"id": 2,
		"question": "How do I inactivate a member? ",
		"answer": "Go to [Members]->[View Members] and uncheck the box under [Active] for any member you would like inactivate. "
	  },
	  {
		"id": 3,
		"question": "What happens if a recurring payment fails? ",
		"answer": "Go to [Payments] and you will see [Retry] [Update Card] buttons next to the failed payment.  Select the appropriate button to process the payment.  You may have to contact the patient to get new card information.  "
	  },
	  {
		"id": 4,
		"question": "Is it possible to edit a plan document? ",
		"answer": "Yes, please download the Word document from the [Plans] section and edit as needed."
	  },
	  {
		"id": 5,
		"question": "What if a patient wants to pay cash/check for the plan? ",
		"answer": "Select the Cash tab at the top of the screen when adding a new member."
	  },
	  {
		"id": 6,
		"question": "How do I know if a patient is paying with a HSA, FSA or HRA form of payment?",
		"answer": "You likely won't know unless the patient tells you up front. This is why the best billing practices are always for you to provide and show full disclosure walkout statements."
	  },
	  {
		"id": 7,
		"question": "Can we offer just the annual payment option? ",
		"answer": "Definitely, to remove the monthly payment options from the plan documents, please download the Word document and edit as needed.  You will still see the option to run monthly payments when adding a member so be sure to select ANNUAL payment option."
	  },

	{id: 5, question: "CAN PATIENTS USE OUR PLAN IN CONJUNCTION WITH OTHER DENTAL COVERAGE",
	answer: "IOPs can be used only on new procedures that no longer can be billed through a patient’s insurance company because their insurance benefits have been exhausted. Some offices offer IOPs to insured patients only after they have maxed out all of their benefits for the year as a way of encouraging patients to follow through with treatment recommendations rather than wait until their insurance renews, allowing insurance to dictate what they will have done.  In no case, however; can a patient use IOPs to pick up the balance insurance did not cover. IOPs can be used only on new procedures that no longer can be billed through a patient’s insurance company because their insurance benefits have been exhausted. Remember... you are in complete control of all benefits offered.  Be sure to review any insurance contracts your office is a part of in order to understand the contractual obligations required by your office."},
	{id: 6, question: "Can patients use third party financing to pay for their IOP fee?", answer: "Yes, if they use it as a regular credit card. No if they are looking for an interest free option."},
	{id: 7, question: " CAN PATIENTS WITH INSURANCE BECOME PLAN PARTICIPANTS?", answer: "Only if YOUR OFFICE IS NOT IN CONTRACT WITH THE PPO."},
	{id: 8, question: "CAN PLAN BENEFITS BE CARRIED OVER FROM ONE YEAR TO THE NEXT?", answer: "Benefits are for a 12 month period only. However, you may choose to extend any date based extenuating circumstances."},
	{id: 9, question: "How do I account for my patients?", answer: "https://billpay.singularbillpay.com/Login.aspx"},
	{id: 10, question: "HOW DO I ASK FOR REFERRALS?", answer: `Word-of-mouth recommendations will spread like wildfire because this program offers tremendous value to the patient/consumer. Be sure your team is actively asking for referrals each day and mentioning the plan. This will lower your marketing budget over time and decrease your overhead. 

	Sample walk out script:

	 

	Team member: “Mrs. Jones, you saved $150 on your crown today by being part of our savings plan.”

	 

	Patient: “Wow. That’s great. I can get a new pair of shoes.”

	 

	Team member: “Mrs. Jones, if you know anyone who would like to save on quality dentistry, please let them know about our office and our savings plan. Here are three business cards.” (Team member gives three office business cards.)

	 

	Patient: “Thank you so much. I already know people who can benefit from your office and your plan.”

	 

	Quick Point:  Consumers who are part of a loyalty/reward program are 70% more likely to refer others than non-participants. What does this mean for your office? Your savings plan patients will refer more than non-members. Take advantage of this and ASK for referrals.`},
	{id: 11, question: "How do I compensate my hygienist and/or associate a percentage of my IOP fee if I compensate them on their collections?", answer: `We highly recommend you convert any provider being compensated based on collections to compensate on Net Production. There are two major issues when compensating providers based on collections.

	Payments need to be properly allocated to the correct provider and to the correct procedure.
	Collections for services may be received after the period in which it was earned. A huge example of this is insurance reimbursements or if you’ve offered a payment plan or monthly payment options for your IOP.
	However, the best way to compensate providers a percentage of your IOP plan fee is as follows:  

	Create a dummy provider for IOP plan fees. Temporarily assign all IOP payments to the dummy provider while the work is in progress. Once the services have been completed, reallocate the amounts to the proper providers. This is usually done by entering a zero sum payment with two balancing pay splits using transfers. See your PMS system on how to properly use income transfers or credit/debits.`},
	{id: 12, question: 'How do I compensate my hygienist and/or associate a percentage of my IOP fee if I compensate them on their Net Production?', answer: `In general, we don’t recommend compensating providers a % of the plan fee because the patient may return to see a different provider at recall and the provider will have been paid for work that they didn’t complete. However, we do recommend compensating the provider a % of the delivered service. This is best accomplished by determining what your adjusted services are that make up your plan fee. In general, an IOP fee is about 65% of your Master Fee. You will always use your Net production amount to determine your providers compensation.

	 

	Your IOP compensation model is treated the exact same way as you treat any adjusted service. Great examples of this are PPO write offs or FFS cash pay discounts you offer patients now.

	 

	For example, if you pay your provider 30% of Net Production, you would pay them 30% of the 65% service. As another example: If your IOP adjusted exam fee is $65 ($100 master fee), then your provider would be paid $19.50 for your IOP exam ($65X.30). `},
	{id: 13, question: "How do I compensate my hygienist if I compensate them off Gross Productions?", answer: "You can’t compensate for what you don’t collect. We recommend you immediately convert your provider to compensation based on Net Production."},
	{id: 14, question: "How do I determine the fee for my In office plan?", answer: "Use the Plan Fee Calculator"},
	{id: 15, question: "How do I enforce a short-term cancellation?", answer: `Your office knows those patients that are repeat no-showers and those who constantly cancel short notice. For savings plan patients, you can use the example (or create your own) in your EOB that states that your office reserves the right to charge a service fee for cancelling short notice or for missed appointments. Put a time frame such as 24 of 48 hours notice. In our own practice we enforce this policy two ways: At scheduling, we will inform our patients that if for any reason, they are unable to keep their appointment to please call us 48 hours in advance. If that patient happens to call giving us less than 48 hours notice, we might say: 
	 

	“Ms. Jones, are you sure you can’t keep your appointment today? Dr. X reserved X amount of time for you today.” 

	 

	If you believe you are receiving a valid reason for cancelling, simply say something like: “Okay Ms. Jones, I’d be happy to get you rescheduled since I have you on the phone. It sounds like this came up unexpectedly. In the future if you can, we would really appreciate 48 hours notice.” 

	 

	Now, let’s say that same person gave you an excuse like, “Oh, I just really don’t want to come in today.”

	You can say, “Wow, Ms. Jones, I really would hate to charge you a short notice cancellation fee today. Are you sure you can’t make it today?”

	 

	 Most patients will go ahead and come in. If this is a person who has done this more than once, we say automatically charge their account but get them to reschedule before you get off the phone if possible. Most patients when warned will know the rules moving forward and will be respectful of the time you have specifically customized for them. Others will continue to miss appointments and cancel short notice. A charge 25% of what was scheduled that day for repeat offenders is not uncommon. Meaning, they’ve been warned once and it was documented. Some patients seem so cavalier when cancelling but your office knows how much money and time is wasted when this happens. Charging a fee is a great way to hold patients accountable.  If a patient is a repeat offender and gets upset with you that they’ve been charged, they are likely a patient you really don’t want in your practice anyway.`},
	 {id: 16, question: "How do I market my plan? ", answer: "Market your plan in any way you market your dental office."},
	 {id: 17, question: "How do I prepare my office to introduce my plan?", answer: `Steps to Take Before Implementation

	 

	We recommend the following steps:

	 

	Introduce the plan to your office team- Explain to your team why you are implementing the program and share the benefits for both the patients and for the office.  Encourage all team members to ask questions and... later in the discussion... offer suggestions.  

	Review your fee schedule- We suggest setting your fees in the 80 - 100% range of UCR for your area. There are many services offering this comparative pricing service.  

	Decide what benefits you would like to offer- Remember... you have complete control of all program benefits and the fees you charge. 

	Decide the fee that you will charge for services you are including - Keep in mind that this is a fee that you set specifically for your office... and that you keep 100% of all fees collected.`},
	{id: 18, question: " How do my patients sign up for our program?", answer: "Registration is simple.  See the Patient Registration Process section on page 26 in this Implementation Guide for a step-by-step description."},
	{id: 19, question: "How do patients pay for services?", answer: "Patients can pay either in advance of treatment or at the time of service using the exact same payment methods your office currently uses to record sales of services and products. "},
	{id: 20, question: "How do we define “family member” as it applies to our program?", answer: "Although you will determine your own definition of “family member” we suggest spouse and underage children.  Some offices may want to expand this definition to include “domestic partners” or the primary member’s child still in college.  All that really matters in formulating this portion of the program is that your definition be written and consistent within your office so that there is no confusion."},
	{id: 21, question: "HOW DOES OUR PLAN HELP WITH COLLECTIONS?", answer: "Savings are provided when treatment is paid for in full or payment arrangement made for full payment at time of service."},
	{id: 22, question: "How is a Member’s Procedural Savings affected if the patient needs 3rd party financing?", answer: `This is a great question.  Using 3rd party financing with your procedural savings is not something we recommend. Here’s why: If you offer a 20% savings on dental treatment and the 3rd party financer (Care Credit) charges you 10%, you are then writing off 30%.  
	What we recommend doing is customizing your procedural savings around the 3rd party fee. For example, if you offer a 20% procedural savings when a patient pays by cash, check or credit card, if the patient chooses a 12month interest free option through Care Credit, then the patient would receive a 10% savings upfront and 12 months interest free through Care Credit. In this way, you still have a flexible payment option for your patients but are customizing their level of benefit around what the 3rd party financer charges the practice. 

	 

	Patient: “I have $5000 in treatment from the doctor, I am a savings plan patient but I don’t have all the financing together to get all of this done now. Do you offer financing?”

	 

	Business Team:  “Absolutely! We have a financing option available and here's how it works: We can help you apply for 3rd party financing through a company we use called Care Credit. They offer to our patients, where approved, 12 months interest free financing but they charge a fee for it.  So we are able to offer this to our patients, we do reduce your savings to 10% because your other 10% is used to pay Care Credit directly in order to get the interest free payment option. Is that something you would be interested in?”

	 

	In this example, the patient merely leverages a percentage of their savings in order to finance. They still receive their savings, just in a different way.

	 

	Many times patients will find the money to pay for their treatment up-front rather than finance in order to save the full 20% (as used in our example)`},
	{id: 23, question: "How much should I be collecting from my IOP patients for dental treatment? ", answer: "100% of the Net Production delivered."},
	{id: 24, question: "How much should I charge for my plan?", answer: "Use the online Fee Calculator to calculate a recommended fee for your area. You want your fee to be high enough to cover your expenses, but low enough to attract as many patients as possible. Keep in mind that your In-office Plan (IOP) has been designed specifically to help you cut overhead costs, maximize patient compliance with treatment plans and to better encourage the patient to be a full participant in their on-going dental care. The more patients your office registers, the more revenue your office will see, not just in fees, but by the increased treatment that the plan encourages."},
	{id: 25, question: "I have a patient that wants to take the IOP paperwork home and come in later in the week to pay for it and receive the benefits. How can I handle that?", answer: `I would recommend saying, “Absolutely Mrs. Jones, I understand you wanting to look through all of this. Here is our EOB, it explains the program in its entirety. I do want you to know that benefits are applied at the time your program takes effect so if you want us to apply anything you’ve had done today towards the benefits, I have to have you enroll and pay today. If you decide you want to hold off, no problem, I just want you to know I can’t apply benefits retroactively.”
	 

	You can also offer the patient to call you back with a credit card or to come back in by 4pm to enroll and you’d be happy to apply their benefits the same day. Remember, keep this simple for you and the office`},
	{id: 26, question: "I have patients that are on a 3 or 4 month perio recall schedule. How can the benefit apply to them?", answer: `Under your plan, all patients receive 2 Simple Cleaning benefits. All you have to do is apply your prophy benefit towards their perio maintenance cleaning.  For example, if your perio maintenance fee is $150.00 and your prophy fee is $90.00. Apply the $90.00 prophy benefit towards their perio maintenance fee and the patients balance will be $60.00 at time of service. At their next visit do the same thing. At their 3rd and 4th visit, you may apply your procedure savings towards their perio maintenance fee if you wish but this is optional. This is still a great benefit because Perio cleanings require more time and they should cost more. A savings plan patient is still receiving a great benefit by having the prophy fee applied because they are receiving high value services at a lower fee. They are more likely to keep up their 3 & 4 month regimes because the fees are lower than normal not to mention all of the other benefits they are getting by being a plan patient at your office.`},
	{id: 27, question: "I offer additional savings to my IOP patients when they pay in full at time of service. Can I offer my IOP patient 3rd party financing through Care Credit?", answer: "Absolutely."},
	{id: 28, question: " IF A PATIENT WITH INSURANCE JOINS YOUR PLAN, WHAT ARE THEIR ADDED BENEFITS?", answer: "They receive all benefits of your IOP once they have exhausted their insurance benefits."},
	{id: 29, question: "If I offer 15% savings to my IOP patients when they pay me in full at time of services by cash, check, MC, Visa or AMEX, what savings do I offer if they use Care Credit?", answer: `We recommend you augment your benefit savings to the payment option a patient chooses.

	 

	For example: if they pay in full at time of services by cash, check, mastercard, visa or AMEX, then they receive 15% at time of service savings adjustment.

	 

	With Care Credit 6 month interest free payment: Their IOP savings would be 10% with 6 months interest free through Care Credit.

	 

	With Care Credit 12 month interest free payment: Their IOP savings would be 5% with 12 months interest free through Care Credit.

	 

	In any of the three payment options your patient chooses, they save 15% total for being an IOP patient.  The only difference between regular forms of payment and care credit is the interest free option portion as you, their provider, are paying Care Credit a percentage of your treatment on behalf of the patient.`},
	{id: 30, question: " IS OUR PLAN A DENTAL DISCOUNT CARD?", answer: "No."},
	{id: 31, question: "IS OUR PLAN AN INSURANCE PLAN?", answer: "No."},
	{id: 32, question: "IS OUR PLAN EASIER TO MANAGE THAN INSURANCE?", answer: "Yes."},
	{id: 33, question: "Is there a reduced rate plan for family members?", answer: "Yes.  This will be determined by your office and part of the fee calculator.  Fee Calculator Link Keep in mind that you are in complete control to define the terms of the program that best suit your market. "},
	{id: 34, question: "Is this an insurance plan?", answer: "No! This is not an insurance plan. It’s a dental savings plan that helps make dentistry more affordable for patients, while eliminating many of the added dental office administrative costs associated with accepting third party payer payments. There are no claim forms, no benefit limitations, and no deductibles."},
	{id: 35, question: "Phone Scripts -How do I handle phone calls?", answer: `Another good idea is to have your team ask all patients who call to make an appointment if they are patients of your dental savings plan. 

	That call should go something like this:

	Prospective patient: “Hi, I’d like to schedule to become a patient at your office. I don’t have insurance, I’ll be paying with cash.”

	Office team member: “Fantastic! We have a dental savings plan for our patients without insurance to assist them on saving money on their dentistry, would you like to know more about it?”

	Prospective patient: “Yeah, tell me about it?”

	Office team member: Explain your dental savings plan

	 

	This will usually generate interest and additional questions... which offers a great opportunity to explain the benefits of becoming a patient.  Along these same lines, when patients ask about fees for services... always first ask if they are a client of your savings plan and explain the cost savings benefits that day.  

	That call should go something like this:

	Prospective patient: “Hi, what is your fee for a crown?”

	Office team member: “Are you a member of our (name of your plan)?”

	Prospective patient: “No, what’s that?”

	Office team member: Explain benefits and fees.

	 

	Even if the patient does not become a member that day, your team will have planted a valuable seed that will encourage patients to join on their next visit.  In general, patients will realize the benefits and cost savings of the program and will most likely join either at or before their next appointment.`},
	{id: 36, question: "Should I create a separate fee schedule for my patients?", answer: `We recommend you DO NOT create a separate fee schedule for patients. The reason is you want patients to see what they are saving in real dollars from a “usual and customary fee”. This has a very positive impact on your patients because they can understand the amount saved and see it on their walk-out statement. We recommend just decreasing your master fee schedule by the percentage you have decided to offer as a benefit.  For example, your walkout statement should appear something like this:
	 

	Crown fee:   $1000

	Plan savings: $200

	 

	Your fee: $800

	 

	This is very powerful and signals savings to patients. So powerful, that many patients will tell their friends about the program at your office. Patients like to see how much they are saving more than how much they are paying. `},
	{id: 37, question: "Should my patients pay for the plan before coming in for the next appointment? Or... can they pay when they arrive for treatment?", answer: "We recommend collecting plan fees when a patient's services are also delivered on the same day. The patient receives savings when they pay in full at time of service."},
	{id: 38, question: "What about a patient that ends up being a debridement. How can the benefit apply to them?", answer: "We consider a Full Mouth Debridement a procedure, so we recommend applying the procedural savings towards this service. The patient will still have 2 simple cleanings coming to them during the year. In fact, after the debridement, we recommend rescheduling their next prophy visit within a month after the debridement has been completed. "},
	{id: 39, question: "What about doing that on a regular visa, MC, AMEX or Discover transactions? Do we customize the patient’s savings around those as well?", answer: "We offer the best processing rate and the rate is the cost of doing business and a convenience for both you and your patient. Using a 3rd party financing company is different because you are utilizing the resources from the 3rd party to carry financing on behalf of your patients."},
	{id: 40, question: "What are some external marketing tips?", answer: "Consumers who are part of a loyalty/reward program are 70% more likely to refer others than non-participants. What does this mean for your office? Your savings plan patients will refer more than non-members. Take advantage of this and ASK for referrals."},
	{id: 41, question: "What are the patient benefits?", answer: "Your local office will create your own benefits menu based on the needs of your local community. Remember, with your In-office Plan (IOP) you set the fee and your own fee structure. You keep all fees collected."},
	{id: 42, question: "What benefits do you recommend?", answer: `A recommended list of “core/foundational” benefits is listed below: 

	Included initial exam/s and exam x-rays
	Included simple teeth cleanings - prophies (two per year)
	Included whitening (take home appliance)
	Significant savings on all dental procedures (recommend between 10-20%)`},
	{id: 43, question: "What does good internal marketing look like? ", answer: `Presenting the plan in your office

	 

	A good internal marketing plan would include:

	Daily, identify every non-insured patient already on your schedule and identify who you will offer your IOP to. The most successful IOP offices have this as a total team approach because it will make it easy for your office to sign patients up.
	Offer your IOP to anyone who compliments your practice. IOP patients are 70% more likely to refer others to your practice when asked. Many times, patients don't really know you are actively seeking new patients.
	Financial Policy: Augment your financial policy to reflect details about your IOP.
	Send a special letter/email to your patients who do not have an insurance plan. Be sure to include your office EOB and patient registration form with the letter.
	Send a special letter/email to patients with any unscheduled treatment plans, those who haven’t presented for recare, or returned after emergency visits. As above, be sure to include your office EOB and patient registration form with the letter.
	Have forms (EOB and registration) available and ready to go as patients are checking out and paying for services. Make it a point to introduce the benefits to all patients... even those with dental insurance. If a patient does not sign up be sure to send them home with information and the program registration form.
	Announce your IOP launch on your office FB page
	Announce your IOP on your office web page
	If you have an on-hold messaging system, your on-hold message is a great way to add info about your IOP in the loop of the on-hold.
	Display brochures/posters, etc prominently in your front office area.
	Outflow equals inflow. The more marketing materials you present to your patients the more your patients will respond.
	Be sure to explain to all current patients that the benefits start from the day of registration. Your team may be surprised at the number of people who will sign up immediately to get the benefits and savings for their appointment that day. Make certain that they understand that program benefits are not retroactive.

	Another good idea is to have your team ask all patients who call to make an appointment if they are patients of your dental savings plan.
	That call should go something like this:
	Prospective patient: “Hi, I’d like to schedule to become a patient at your office. I don’t have insurance, I’ll be paying with cash.”
	Office team member: “Fantastic! We have a dental savings plan for our patients without insurance to assist them on saving money on their dentistry, would you like to know more about it?”
	Prospective patient: “Yeah, tell me about it?”
	Office team member: Explain your dental savings plan

	This will usually generate interest and additional questions... which offers a great opportunity to explain the benefits of becoming a patient. Along these same lines, when patients ask about fees for services... always first ask if they are a client of your savings plan and explain the cost savings benefits that day.
	That call should go something like this:
	Prospective patient: “Hi, what is your fee for a crown?”
	Office team member: “Are you a member of our (name of your plan)?”
	Prospective patient: “No, what’s that?”
	Office team member: Explain benefits and fees.

	Even if the patient does not become a member that day, your team will have planted a valuable seed that will encourage patients to join on their next visit. In general, patients will realize the benefits and cost savings of the program and will most likely join either at or before their next appointment.

	Benefits are only available to savings plan patients...and only available on the day of service and only when payment for services is made in full. Keep clearly in mind- If a patient chooses not to join your program on the day of service... but then joins at a later date... there is no going back to adjust the account with the lower fee. Your In-office Plan (IOP) works so well at decreasing overhead because the terms and benefits are very clear... it is simple... and there is very minimal paperwork. This is a proven approach and proven system- do not create more work for yourself!`},
	{id: 44, question: " What if a patient needs additional financing to pay for treatment? How do I handle this situation?", answer: `Either you or a team member should explain to the patient that financing (such as Care Credit) is available however; that their savings will be customized around the fee incurred by the practice. We have found that most patients will adjust their finances to be able to pay in full so as to maintain the full savings. 
	Using 3rd party financing with your procedural savings is not something we recommend. Here’s why: If you offer a 20% savings on dental treatment and the 3rd party financer (Care Credit) charges you 10%, you are then writing off 30%.  

	What we recommend doing is customizing your procedural savings around the 3rd party fee. For example, if you offer a 20% procedural savings when a patient pays by cash, check or credit card, if the patient chooses a 12month interest free option through Care Credit, then the patient would receive a 10% savings upfront and 12 months interest free through Care Credit. In this way, you still have a flexible payment option for your patients but are customizing their level of benefit around what the 3rd party financer charges the practice. 

	 

	Patient: “I have $5000 in treatment from the doctor, I am a savings plan patient but I don’t have all the financing together to get all of this done now. Do you offer financing?”

	 

	Business Team:  “Absolutely! We have a financing option available and here's how it works: We can help you apply for 3rd party financing through a company we use called Care Credit. They offer to our patients, where approved, 12 months interest free financing but they charge a fee for it.  So we are able to offer this to our patients, we do reduce your savings to 10% because your other 10% is used to pay Care Credit directly in order to get the interest free payment option. Is that something you would be interested in?”

	 

	In this example, the patient merely leverages a percentage of their savings in order to finance. They still receive their savings, just in a different way.

	 

	Many times patients will find the money to pay for their treatment up-front rather than finance in order to save the full 20% (as used in our example).`},
	{id: 45, question: "What if a patient needs additional financing to pay for treatment? How do I handle this situation?", answer: `Either you or a team member should explain to the patient that financing (such as Care Credit) is available however; that their savings will be customized around the fee incurred by the practice. We have found that most patients will adjust their finances to be able to pay in full so as to maintain the full savings. 
	Using 3rd party financing with your procedural savings is not something we recommend. Here’s why: If you offer a 20% savings on dental treatment and the 3rd party financer (Care Credit) charges you 10%, you are then writing off 30%.  

	What we recommend doing is customizing your procedural savings around the 3rd party fee. For example, if you offer a 20% procedural savings when a patient pays by cash, check or credit card, if the patient chooses a 12month interest free option through Care Credit, then the patient would receive a 10% savings upfront and 12 months interest free through Care Credit. In this way, you still have a flexible payment option for your patients but are customizing their level of benefit around what the 3rd party financer charges the practice. 

	 

	Patient: “I have $5000 in treatment from the doctor, I am a savings plan patient but I don’t have all the financing together to get all of this done now. Do you offer financing?”

	 

	Business Team:  “Absolutely! We have a financing option available and here's how it works: We can help you apply for 3rd party financing through a company we use called Care Credit. They offer to our patients, where approved, 12 months interest free financing but they charge a fee for it.  So we are able to offer this to our patients, we do reduce your savings to 10% because your other 10% is used to pay Care Credit directly in order to get the interest free payment option. Is that something you would be interested in?”

	 

	In this example, the patient merely leverages a percentage of their savings in order to finance. They still receive their savings, just in a different way.

	 

	Many times patients will find the money to pay for their treatment up-front rather than finance in order to save the full 20% (as used in our example).`},
	{id: 46, question: "What if I want to augment my IOP fees?", answer: "You can change your fees at any time; however, we do not recommend that a patient's fee be changed during their plan year."},
	{id: 47, question: "What is an IOP?", answer: "An IOP is an In-Office Plan. It is NOT Insurance and it is NOT a Dental Discount Plan."},
	{id: 48, question: "WHAT IS THE MAXIMUM BENEFIT THAT A PATIENT CAN RECEIVE?", answer: "All benefits are determined by your practice. There are no maximums when it comes to dollar amounts that can be saved. Patients save on dental work included in your plan no matter the amount needed when they pay for delivered care at time of services."},
	{id: 49, question: "WHAT LOCAL BUSINESSES WOULD BENEFIT FROM YOUR PLAN?", answer: "All local businesses would save with your IOP"},
	{id: 50, question: "What percentile do you recommend setting our office fees?", answer: "Review your fee schedule- We suggest setting your fees in the 80 - 100% range of UCR for your area. There are many services offering this comparative pricing service.  If you find that you are currently well below the 80% mark, slowly increase your fees incrementally every three months until they are up to the 80% for your area"},
	{id: 51, question: "When do the savings go into effect?", answer: "The plan is effective once the patient has registered and paid your fee for services."},
	{id: 52, question: "WHEN IS A PATIENT ELIGIBLE FOR BENEFITS?", answer: "A patient is immediately eligible at the time of signup."},
	{id: 53, question: "WHICH PATIENTS SHOULD BE TOLD ABOUT OUR PLAN?", answer: "Tell all patients about your plan. Many people know someone without insurance that could benefit from you IOP and some patients are self insured."},
	{id: 54, question: "WHO IS ELIGIBLE TO BE CONSIDERED AN “ADDITIONAL FAMILY MEMBER” FOR A PRIMARY REGISTRANT?", answer: "This is determined by your office. Although you will determine your own definition of “family member” we suggest spouse and underage children. Some offices may want to expand this definition to include “domestic partners” or the primary member’s child still in college. All that really matters in formulating this portion of the program is that your definition be written and consistent within your office so there is no confusion."},
	{id: 55, question: "Why can’t my patient be extended interest free options to pay for their IOP fee?", answer: "They can, we just don’t recommend it. The reason is, you, the provider is charged a large fee by Care Credit to extend financing to patients. If you allow a patient to use Care Credit to pay for their IOP fee, you will be charged a fee to allow this that you can not pass onto the patient."},
	{id: 56, question: "Will my patients know the cost of their treatment under the program?", answer: "Yes.  Your office will provide each patient with this information."}
].map((kb, idx) => ({ ...kb, id: idx+1})))

	const handleDisplayFAQ = (id) => {
		const newFAQs = faqs.map(faq => {
			if(faq.id == id){
				return({...faq, display: faq.display != undefined ? !faq.display : true})
			}
			else{
				return faq
			}
		})
		setFaqs(newFAQs)
	}

	const previousPage = () => {
		let newCount
		if(pageCount >= 1){
			newCount = pageCount - 1
		}
		else{
			newCount = 6
		}
		setPageCount(newCount)
		setFaqQuestion("All FAQs")
	}

	const nextPage = () => {
		let newCount
		if(pageCount <= 5){
			newCount = pageCount + 1
		}
		else{
			newCount = 1
		}
		setPageCount(newCount)
		setFaqQuestion("All FAQs")
	}



	const displayFaqs = faqs.map(faq => {

		if(faqQuestion != "All FAQs"){
			if(faq.question == faqQuestion){
				return(
					<div key={faq.id}>
					<button key={faq.id} id={faq.id} name={faq.id} className="my-4 text-left" onClick={() => handleDisplayFAQ(faq.id)}>{faq.display ? <ArrowDropDownIcon /> : <ArrowRightIcon />} {faq.question}</button>
					  <div className={faq.display != undefined ? faq.display ? "" : "hidden" : "hidden"}>
						  <div className="p-2 font-bold" dangerouslySetInnerHTML={{__html: faq.answer}}>
						  	{/* {faq.answer} */}
						  </div>
						</div>
					</div>
					)
			}
		}
		else{
			return(
				<div key={faq.id}>
				<button key={faq.id} id={faq.id} name={faq.id} className="my-4" onClick={() => handleDisplayFAQ(faq.id)}>{faq.display ? <ArrowDropDownIcon /> : <ArrowRightIcon />} {faq.question}</button>
				  <div className={faq.display != undefined ? faq.display ? "" : "hidden" : "hidden"}>
					  <div className="p-2 font-bold" dangerouslySetInnerHTML={{__html: faq.answer}}>
					  	{/* {faq.answer} */}
					  </div>
					</div>
				</div>
				)
			}

			return undefined
	})

	const displayPages = [1, 2, 3, 4, 5, 6].map(page => {
		if(page == pageCount){
			return(
				<div key={page} className="text-blue-500 font-bold mr-2 my-auto">{page}</div>
				)
			}
		else{
			return(
				<div key={page} className="text-blue-500 mr-2 my-auto">{page}</div>
			)
		}
	})

	const customStyles = {
	  option: (provided, state) => ({
	    ...provided,
	    padding: 6,
	    textTransform: 'uppercase',
	    fontSize: 11,
	    letterSpacing: 1.2
	  }),
	  control: () => ({
	    // none of react-select's styles are passed to <Control />
	    display: 'flex'
	  }),
		container: (base) => ({
		    ...base,
		    flex: 1
		  })
		,
	    singleValue: (provided, state) => {
	    const opacity = state.isDisabled ? 0.5 : 1;
	    const transition = 'opacity 300ms';

	    return { ...provided,
	    		 opacity,
	    		  transition,
	    		   textTransform: 'uppercase',
	    		   fontSize: 11,
	    			letterSpacing: 1.2,
	    			display: 'inline'
	    		    };
	  }
	}

	const handleChangeFaq = (event) =>{
		const faqQuestion = event.value
		if(faqQuestion != "all"){
			const pickedFaq = faqs.filter(faq => faq.question == faqQuestion)[0]
			setFaqQuestion(pickedFaq.question)
		}
		else{
			setFaqQuestion("All FAQs")
		}

	}

	const selectFaqs = faqs.map(faq => <option key={faq.id} value={faq.question}>{faq.question}</option>)
    const selectFaqs2 = faqs.map(faq => ({key:faq.id, value: faq.question, label: faq.question}))
    const selectFaqs3 = selectFaqs2.unshift({key:"all", label:"All FAQs", value:"all"})

    console.log(faqQuestion)

	return(
		<div className="w-full h-full  m-auto">
			<div className="w-5/6 mx-auto rounded-sm my-8"><h1 className="ch1">Knowledge Base - QDPedia</h1></div>
			<div className="card w-5/6 mx-auto">
				<div className="flex">
					<form className="w-1/2">
						<Select onChange={handleChangeFaq} styles={customStyles} defaultValue={selectFaqs2[0]} options={selectFaqs2} />
		            </form>
					<button className="my-auto flex ml-6 mr-2 text-blue-500 hover:underline" onClick={previousPage}><ArrowLeftIcon styles={{color: blue[500]}} /> Previous</button>
					<button className="my-auto flex mr-2 text-blue-500 hover:underline" onClick={nextPage}>Next <ArrowRightIcon styles={{color: blue[500]}} /></button>
					{displayPages}
				</div>
				{faqQuestion == "All FAQs" ? displayFaqs.slice(pageCount*10-10, (pageCount+1)*10-10) : displayFaqs}
			</div>
        </div>
		)
} 




