import React, { useState, useEffect, useContext, useMemo }  from "react";
import { Link } from "react-router-dom";
import {UserContext} from '../../UserContext'


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
 
import {CSVLink} from 'react-csv';

import PlanTable from '../../components/PlanTable'

import {useListQL} from '../../hooks/useListQL'

import {planSchema} from '../../schemas/schemas'


import './styles.css'

export default function Plans(props){	
	
	const auth = useContext(UserContext)

	//list query and any record deletions are handled by the useListQL hook
	const {stateDict, handleDelete} = useListQL(planSchema, useMemo(() => ({name: "office", value: auth.officeId || auth.user}), [auth.user, auth.officeId]))

	//users will be prompted to confirm any attempt to delete a practice
	  const confirmDelete = (planId) => {
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: 'Are you sure you want to delete?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleDelete(planId)}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }

	//prepares the list of plans for csv export functionality
	const listPracticesCsv = stateDict.map(practice => {
		return([practice.name, practice.city, practice.zip])
	})

	return(
		<div className="w-full h-full">
			
			<div className="w-full px-10 py-6">

				<div className="w-1/2 rounded-sm"><h1 className="ch1">Add Plan</h1></div>

				<div className="p-6 bg-white text-center">
					
					<div className="flex justify-center ">
						<Link to="/plan/create?plan=Adult" className="plan-btn shadow-lg bg-lime-600 rounded-md">
							<img src={require("./imgs/adult_transparent.png")} alt='Create Adult Plan' />
							<span>ADULT</span>
						</Link>

						<Link to="/plan/create?plan=Perio" className="plan-btn shadow-lg bg-orange-500 rounded-md">
							<img src={require("./imgs/perio_transparent.png")} alt='Create Perio Plan' />
							<span>PERIO</span>
						</Link>

						<Link to="/plan/create?plan=Child" className="plan-btn shadow-lg bg-gray-500 rounded-md">
							<img src={require("./imgs/child_transparent.png")} alt='Create Child Plan' />
							<span>CHILD</span>
						</Link>

						<Link to="/plan/create?plan=Adult" className="plan-btn shadow-lg bg-blue-500 rounded-md">
							<img src={require("./imgs/other_transparent.png")} alt='Create Other Plan' />
							<span>OTHER</span>
						</Link>
					</div>

				</div>

				<div className="flex p-6">
					<div className="w-1/2 rounded-sm"><h1 className="ch1">All Plans</h1></div>
					<div className="w-1/2 rounded-sm text-right justify-around">
						{/* <Link to="/plan/create" className="gradient-btn mt-4 mx-2">Add Plan</Link> */}
						<CSVLink className="gradient-btn mt-4 mx-2" filename={"plans.csv"} data={listPracticesCsv} >Export</CSVLink>
					</div>
				</div>

				<PlanTable officeLogo={props.officeLogo} edit={true} columns={['Name', 'Type']}
					fields={['name', 'planType']} pathPrefix={'plan'}
					values={stateDict} delete={true} handleDelete={confirmDelete}/>

			</div>
		</div>

		)
}