

/**
 * @typedef {Object} USIOCredentials
 * @property {string} MerchantID - The merchant iD for the account
 * @property {string} Login - The main login
 * @property {string} Password - The main password
 */

/**
 * @typedef {Object} USIOResponse
 * @property {string} status - "success" or "failure"
 * @property {string} message - On failure will contain the error message
 * @property {string} confirmation - On success will contain the confirmation ID
 */


/**
 * This wraps some boilerplate logic for the USIO payments api
 */

const jsonObjToUSIOResponse = (responseObj) => {
    return Object.keys(responseObj).reduceRight((bag, key) => {
        return {
            ...bag,
            [key.toLowerCase()]: responseObj[key]
        }
    }, {})
}

 const xmlResponseHandler = (resolve, reject) =>  {
    return function () {
        if (this.readyState != 4) { return }

        if (this.status == 200) {
            const responseObj = JSON.parse(this.responseText)
            const responseToken = responseObj['Confirmation']
            if (!responseToken || responseToken.length === 0) {
                return reject(jsonObjToUSIOResponse(responseObj))
            }

            resolve(jsonObjToUSIOResponse(responseObj))
        }
        else if (this.responseText != "") {
            const response = JSON.parse(this.responseText)
            reject(jsonObjToUSIOResponse(response))
        }
        else {
            reject(new Error(`Unknown payment gateway response: ${this.status} => ${this.readyState}`))
        }
    }
}

/**
 * Given a path and data, returns a response from the payments gateway
 * 
 * @param {String} path 
 * @param {Object} data 
 * @returns 
 */
const makeApiCall = async (path, data) => {

    return new Promise((resolve, reject) => {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = xmlResponseHandler(resolve, reject)
        xhttp.open("POST", `https://api.securepds.com${path}`, true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send(JSON.stringify(data));
    })
}



/**
 * Tokenizes a payment for later use. This will not authorize the amount. To authorize, pass in an amount of > 0
 * 
 * Reference: https://api.securepds.com/2.0/documentation/#creditcardpayment
 * 
 * @typedef {Object} TokenizeData
 * @property {string} CardNumber
 * @property {string} CardType
 * @property {string} ExpDate
 * @property {string} Address1
 * @property {string} City
 * @property {string} State
 * @property {string} [Country]
 * @property {string} Amount
 * @property {string} FirstName
 * @property {string} LastName
 * @property {string} EmailAddress
 * @property {string} Zip
 * @property {string} [CVV]
 * 
 * 
 * @param {USIOCredentials & TokenizeData} data
 * 
 * @returns {Promise<USIOResponse>}
*/
export const tokenizePayment = async (data) => {
    return makeApiCall('/2.0/payments.svc/JSON/SubmitCCPayment', {
        Country: 'US',
        Amount: '0.00',
        ...data
    })
}


/**
 * @typedef {Object} TokenPaymentData
 * @property {string} Token - The token obtained from `tokenizePayment`
 * @property {string} Amount - The amount to charge
 * 
 *  
 * @param {USIOCredentials & TokenPaymentData} data
 * @param {String} [cvv] - The cvv if required
 *  
 * @returns {Promise<USIOResponse>}
 */
export const submitTokenPayment = async (data, cvv='') => {
    return makeApiCall('/2.0/payments.svc/JSON/SubmitTokenPayment', {
        ...data
    })
    .catch(ex => {
        console.error(ex)
        if ((!cvv || cvv.length === 0) && (ex.message) && ex.message.includes('cvv')) {
            console.log(`Ask for cvv and retry?`)
            const cvv = prompt('CVV is required for verification')
            return submitTokenPayment(data, cvv)
        }
        throw ex
    })
}



/**
 * @typedef {Object} RefundPaymentData
 * @property {string} ConfirmationID - The confirmation id of the payment to refund
 * @property {string} Amount - The amount for the transaction.  Must match the amount charged
 * 
 * Refunds a payment record with the given confirmationId and amount.
 * 
 * @param {USIOCredentials & RefundPaymentData} data
 * @returns {Promise<USIOResponse>}
 */
export const refundPayment = async (data) => {
    return makeApiCall('/2.0/payments.svc/JSON/SubmitCCVoid', {
        ...data
    })
}