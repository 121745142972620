import React, { useState, useEffect, useContext }  from "react";
import { useParams, Link } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import AdminViewOfficePractices from './AdminViewOfficePractices'

import MaskedInput from 'react-text-mask'

import {useFormQL} from './hooks/useFormQL'

import Input from './components/Input'


const officeSchema = `type Office @model {
  id: String
  name: String
  primaryContact: String
  phone: String
  email: String
  address: String
  zip: String
  multiplePractices: Boolean
  hasPractice: Boolean
  hasPlan: Boolean
  active: Boolean
  merchantKey: String
  usioLogin: String
  usioPassword: String
  logo: String
  hasLoggedIn: String
  state: String
  website: String
  createdAt: String
}`

export default function AdminViewOffice2(props){
	const auth = useContext(UserContext)

	const params = useParams()
  	const {schemaName, saved, state, handleSubmit, handleInputChange} = useFormQL(officeSchema, params.officeId) //get form state

	const displaySaved = saved ? <div className="mx-auto my-4 text-green-700 flex content-center"><div className="px-2">Office Saved </div> <CheckCircleOutlineIcon style={{ color: green[600]}} /></div> : null

	return(
		<div className="w-full h-full">
			<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
	      	    <Link className="text-blue-500" to="/admin/offices">{"<<"} Offices</Link>
	        </div> 
			<form onSubmit={handleSubmit} className="card my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				<h1 className="text-2xl">Office</h1>
				<div className="flex">
				<div className="w-1/2 flex flex-col">
					<Input label={"Name"} disabled={true} value={state.name} name={"name"} />
					<Input label={"Primary Contact"} onChange={handleInputChange} value={state.primaryContact} name={"primaryContact"} />
					<Input label={"Phone Number"} onChange={handleInputChange} value={state.phone} name={"phone"} />
					<Input label={"Email"} onChange={handleInputChange} value={state.email} name={"email"} />

					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Name:</p>
						<input disabled={true} className="border w-1/2" type="text" value={state.name} name="name" id="name"></input>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Primary Contact:</p>
						<input className="border w-1/2" type="text" onChange={handleInputChange} value={state.primaryContact} name="primaryContact" id="primaryContact"></input>
					</div>
					<div className="flex my-4 w-full ">
						<p className="my-auto pr-4 w-1/3 text-right">Phone:</p>
						<MaskedInput mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={handleInputChange} type="text" value={state.phone} name="phone" id="phone"/>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Email:</p>
						<input onChange={handleInputChange} className="border w-1/2" type="text" value={state.email} name="email" id="email"></input>
					</div>
				</div>
				<div className="w-1/2 flex flex-col">
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Address:</p>
						<input className="border w-1/2" type="text" onChange={handleInputChange} value={state.address} name="address" id="address"></input>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Zip:</p>
              			<MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} className="border w-1/2" onChange={handleInputChange} type="text" value={state.zip} name="zip" id="zip"/>
					</div>
					<div className="flex my-4 w-full">
						<p className="my-auto pr-4 w-1/3 text-right">Date Joined:</p>
						<input className="border w-1/2" type="text" disabled={true} value={state.createdAt.split('T')[0]} name="createdAt" id="createdAt"></input>
					</div>
				</div>
			</div>
	        {saved}

			<button className="px-6 py-2 bg-blue-500 text-white rounded-sm">Save</button>
			</form>
			<div className="w-5/6 mx-auto">
				<AdminViewOfficePractices officeId={params.officeId} />
			</div>
		</div>
		)
}