/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      accountType
      officeId
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountType
        officeId
        name
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffice = /* GraphQL */ `
  query GetOffice($id: ID!) {
    getOffice(id: $id) {
      id
      name
      primaryContact
      phone
      email
      address
      zip
      multiplePractices
      hasPractice
      hasPlan
      active
      merchantKey
      usioLogin
      usioPassword
      logo
      hasLoggedIn
      city
      state
      website
      createdAt
      isVerified
      disableReminders
      updatedAt
    }
  }
`;
export const listOffices = /* GraphQL */ `
  query ListOffices(
    $filter: ModelOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPendingOffice = /* GraphQL */ `
  query GetPendingOffice($id: ID!) {
    getPendingOffice(id: $id) {
      id
      email
      website
      password
      name
      primaryContact
      phone
      address
      zip
      state
      logoUrl
      licensee
      by
      contractName
      title
      city
      agreementSignature
      status
      usioEmail
      usioDbaName
      usioLegalName
      usioResponse
      usioGuid
      createdAt
      updatedAt
    }
  }
`;
export const listPendingOffices = /* GraphQL */ `
  query ListPendingOffices(
    $filter: ModelPendingOfficeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        website
        password
        name
        primaryContact
        phone
        address
        zip
        state
        logoUrl
        licensee
        by
        contractName
        title
        city
        agreementSignature
        status
        usioEmail
        usioDbaName
        usioLegalName
        usioResponse
        usioGuid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($id: ID!) {
    getPractice(id: $id) {
      id
      name
      businessName
      city
      zip
      office
      state
      createdAt
      updatedAt
    }
  }
`;
export const listPractices = /* GraphQL */ `
  query ListPractices(
    $filter: ModelPracticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        businessName
        city
        zip
        office
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      benefits
      office
      planType
      recommendedFee
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        benefits
        office
        planType
        recommendedFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdminPlan = /* GraphQL */ `
  query GetAdminPlan($id: ID!) {
    getAdminPlan(id: $id) {
      id
      name
      basicBenefits
      customBenefits
      planType
      createdAt
      updatedAt
    }
  }
`;
export const listAdminPlans = /* GraphQL */ `
  query ListAdminPlans(
    $filter: ModelAdminPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        basicBenefits
        customBenefits
        planType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name
      active
      contactName
      contactAddress
      contactCity
      contactZip
      contactPhone
      contactEmail
      planAgreement
      planAgreementDate
      customerSignature
      plan
      practice
      fixedBenefits
      office
      paymentInterval
      expiration
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberFinance = /* GraphQL */ `
  query GetMemberFinance($id: ID!) {
    getMemberFinance(id: $id) {
      id
      memberId
      memberName
      practice
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      paymentInterval
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      createdAt
      nextRenewalDate
      renewalNotifications
      autoRenew
      member {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      officeRecord {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listMemberFinances = /* GraphQL */ `
  query ListMemberFinances(
    $filter: ModelMemberFinanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberFinances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberId
        memberName
        practice
        dayDue
        interestRate
        totalBalance
        initialDeposit
        remainingBalance
        paymentInterval
        periodAmount
        office
        paymentToken
        active
        expiration
        paymentType
        paymentDescription
        contactName
        createdAt
        nextRenewalDate
        renewalNotifications
        autoRenew
        member {
          id
          name
          active
          contactName
          contactAddress
          contactCity
          contactZip
          contactPhone
          contactEmail
          planAgreement
          planAgreementDate
          customerSignature
          plan
          practice
          fixedBenefits
          office
          paymentInterval
          expiration
          paymentType
          createdAt
          updatedAt
        }
        officeRecord {
          id
          name
          primaryContact
          phone
          email
          address
          zip
          multiplePractices
          hasPractice
          hasPlan
          active
          merchantKey
          usioLogin
          usioPassword
          logo
          hasLoggedIn
          city
          state
          website
          createdAt
          isVerified
          disableReminders
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberBenefitsLog = /* GraphQL */ `
  query GetMemberBenefitsLog($id: ID!) {
    getMemberBenefitsLog(id: $id) {
      id
      memberId
      benefit
      benefitType
      office
      createdAt
      updatedAt
    }
  }
`;
export const listMemberBenefitsLogs = /* GraphQL */ `
  query ListMemberBenefitsLogs(
    $filter: ModelMemberBenefitsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberBenefitsLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberId
        benefit
        benefitType
        office
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOneTimePayment = /* GraphQL */ `
  query GetOneTimePayment($id: ID!) {
    getOneTimePayment(id: $id) {
      id
      token
      amount
      status
      createdAt
      office
      updatedAt
    }
  }
`;
export const listOneTimePayments = /* GraphQL */ `
  query ListOneTimePayments(
    $filter: ModelOneTimePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOneTimePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        amount
        status
        createdAt
        office
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayments = /* GraphQL */ `
  query GetPayments($id: ID!) {
    getPayments(id: $id) {
      id
      memberId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      practice
      memberName
      createdAt
      office
      confirmationToken
      confirmationStatus
      cash
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const listPaymentss = /* GraphQL */ `
  query ListPaymentss(
    $filter: ModelPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberId
        paymentNumber
        status
        description
        grossPay
        fee
        netPay
        practice
        memberName
        createdAt
        office
        confirmationToken
        confirmationStatus
        cash
        planId
        paymentInfo
        contactName
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOfficePayments = /* GraphQL */ `
  query GetOfficePayments($id: ID!) {
    getOfficePayments(id: $id) {
      id
      officeId
      paymentNumber
      status
      description
      grossPay
      fee
      netPay
      office
      createdAt
      name
      practice
      cash
      confirmationToken
      planId
      paymentInfo
      contactName
      updatedAt
    }
  }
`;
export const listOfficePaymentss = /* GraphQL */ `
  query ListOfficePaymentss(
    $filter: ModelOfficePaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfficePaymentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        officeId
        paymentNumber
        status
        description
        grossPay
        fee
        netPay
        office
        createdAt
        name
        practice
        cash
        confirmationToken
        planId
        paymentInfo
        contactName
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOfficeLog = /* GraphQL */ `
  query GetOfficeLog($id: ID!) {
    getOfficeLog(id: $id) {
      id
      officeId
      office
      activity
      createdAt
      updatedAt
    }
  }
`;
export const listOfficeLogs = /* GraphQL */ `
  query ListOfficeLogs(
    $filter: ModelOfficeLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfficeLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        officeId
        office
        activity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberLog = /* GraphQL */ `
  query GetMemberLog($id: ID!) {
    getMemberLog(id: $id) {
      id
      memberId
      memberName
      practice
      activity
      createdAt
      office
      updatedAt
    }
  }
`;
export const listMemberLogs = /* GraphQL */ `
  query ListMemberLogs(
    $filter: ModelMemberLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberId
        memberName
        practice
        activity
        createdAt
        office
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNonMemberFinance = /* GraphQL */ `
  query GetNonMemberFinance($id: ID!) {
    getNonMemberFinance(id: $id) {
      id
      name
      practice
      createdAt
      dayDue
      interestRate
      totalBalance
      initialDeposit
      remainingBalance
      totalMonths
      periodAmount
      office
      paymentToken
      active
      expiration
      paymentType
      paymentDescription
      contactName
      updatedAt
    }
  }
`;
export const listNonMemberFinances = /* GraphQL */ `
  query ListNonMemberFinances(
    $filter: ModelNonMemberFinanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonMemberFinances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        practice
        createdAt
        dayDue
        interestRate
        totalBalance
        initialDeposit
        remainingBalance
        totalMonths
        periodAmount
        office
        paymentToken
        active
        expiration
        paymentType
        paymentDescription
        contactName
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOfficeAccount = /* GraphQL */ `
  query GetOfficeAccount($id: ID!) {
    getOfficeAccount(id: $id) {
      id
      officeId
      accountId
      roles
      office {
        id
        name
        primaryContact
        phone
        email
        address
        zip
        multiplePractices
        hasPractice
        hasPlan
        active
        merchantKey
        usioLogin
        usioPassword
        logo
        hasLoggedIn
        city
        state
        website
        createdAt
        isVerified
        disableReminders
        updatedAt
      }
      account {
        id
        accountType
        officeId
        name
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOfficeAccounts = /* GraphQL */ `
  query ListOfficeAccounts(
    $filter: ModelOfficeAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfficeAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        officeId
        accountId
        roles
        office {
          id
          name
          primaryContact
          phone
          email
          address
          zip
          multiplePractices
          hasPractice
          hasPlan
          active
          merchantKey
          usioLogin
          usioPassword
          logo
          hasLoggedIn
          city
          state
          website
          createdAt
          isVerified
          disableReminders
          updatedAt
        }
        account {
          id
          accountType
          officeId
          name
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMembersByOffice = /* GraphQL */ `
  query ListMembersByOffice(
    $office: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembersByOffice(
      office: $office
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        contactName
        contactAddress
        contactCity
        contactZip
        contactPhone
        contactEmail
        planAgreement
        planAgreementDate
        customerSignature
        plan
        practice
        fixedBenefits
        office
        paymentInterval
        expiration
        paymentType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const financesByDate = /* GraphQL */ `
  query FinancesByDate(
    $office: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFinanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    financesByDate(
      office: $office
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberId
        memberName
        practice
        dayDue
        interestRate
        totalBalance
        initialDeposit
        remainingBalance
        paymentInterval
        periodAmount
        office
        paymentToken
        active
        expiration
        paymentType
        paymentDescription
        contactName
        createdAt
        nextRenewalDate
        renewalNotifications
        autoRenew
        member {
          id
          name
          active
          contactName
          contactAddress
          contactCity
          contactZip
          contactPhone
          contactEmail
          planAgreement
          planAgreementDate
          customerSignature
          plan
          practice
          fixedBenefits
          office
          paymentInterval
          expiration
          paymentType
          createdAt
          updatedAt
        }
        officeRecord {
          id
          name
          primaryContact
          phone
          email
          address
          zip
          multiplePractices
          hasPractice
          hasPlan
          active
          merchantKey
          usioLogin
          usioPassword
          logo
          hasLoggedIn
          city
          state
          website
          createdAt
          isVerified
          disableReminders
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
