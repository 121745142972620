import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'

export default function SettingsCardDetail3(props){

	return(
		<div className="w-full">
	      <Form onSubmit={(e) =>{
	      	e.preventDefault()
	      	props.handleSubmit(e)
	      	props.handleOfficeMerchantKeyChange(props.state.merchantKey)
	      	props.handleOfficeUsioLoginChange(props.state.usioLogin)
	      	props.handleOfficeUsioPasswordChange(props.state.usioPassword)
	      }} title={"Payment Settings"} buttonTitle={"Save"}>
	      	<FormCol>
				<Input label={"USIO Merchant ID"} onChange={props.handleInputChange} value={props.state.merchantKey} name={"merchantKey"} />
	            <Input label={"USIO Login"} onChange={props.handleInputChange} value={props.state.usioLogin} name={"usioLogin"} />
	            <Input label={"USIO Password"} onChange={props.handleInputChange} value={props.state.usioPassword} name={"usioPassword"} />
	       	</FormCol>
	      </Form>
	</div>
		)
}