import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import Input from './components/Input'
import SavedAlert from './components/SavedAlert'
import Form from './components/Form'
import FormCol from './components/FormCol'

export default function CustomerInfo(props){   

	const [saved, setSaved] = useState(false)

    const selectPractice = props.practices.map(practice => <option value={practice.name}>{practice.name}</option>)

    const saveCustomerInfo = async (event) => {
      event.preventDefault()
      console.log(props.memberId)
      console.log(props.auth.officeId || props.auth.user)
      const updateMember = await API.graphql(graphqlOperation(
            `mutation update {
              updateMember(input: {id: "${props.auth.officeId || props.auth.user}", contactName: "${props.contactName}",
              contactAddress: "${props.contactAddress}", contactCity: "${props.contactCity}", contactZip: "${props.contactZip}",
            contactPhone: "${props.contactPhone}"}){
                id
                contactName
                contactAddress
                contactCity
                contactZip
                contactPhone
              }
            }`))

      setSaved(true)

    }

	return(
		<div className="w-full">
			<Form title={"Patient"} onSubmit={saveCustomerInfo} hideButton={true} className="card mt-8 p-8 bg-white border rounded-lg w-5/6 mx-auto" >
				<FormCol>
					<Input label={"Practice"} disabled={true} value={props.practice} name={"practice"} />
					<Input label={"Name"} disabled={true} value={props.patientName} name={"patientName"} />
					<Input label={"Address"} onChange={props.handleContactAddressChange}  value={props.contactAddress} name={"contactAddress"} />
				</FormCol>
				<FormCol>
					<Input label={"City"} onChange={props.handleContactCityChange}  value={props.contactCity} name={"contactCity"} />
					<Input label={"Zip Code"} onChange={props.handleContactZipChange}  value={props.contactZip} name={"contactZip"} />
				</FormCol>
			</Form>
			<Form title={"PrimaryContact"} onSubmit={saveCustomerInfo} buttonTitle={"Save"} saved={saved} savedLabel={"Saved"} className="card mb-8 p-8 bg-white border rounded-lg w-5/6 mx-auto" >
				<FormCol>
					<Input label={"Name"} onChange={props.handleContactNameChange}  value={props.contactName} name={"contactName"} />
					<Input label={"Email"} onChange={props.handleContactEmailChange}  value={props.contactEmail} name={"contactEmail"} />
				</FormCol>
				<FormCol>
					<Input label={"Phone Number"} onChange={props.handleContactPhoneChange}  value={props.contactPhone} name={"contactPhone"} />
				</FormCol>
			</Form>
		</div>
		)
}