import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
import Iframe from 'react-iframe'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function ResourceCard(props){
	return(
		<div className={props.pms ? "card w-full m-2 flex" : "w-full m-2 flex justify-center content-center"}>
			<a href={`${props.s3Prefix}${props.link}`} target="_blank" rel="noopener noreferrer">
				<div className="w-full h-full flex flex-col justify-center content-center">
					{props.pms ? <PictureAsPdfIcon styles={{width: 400, height:400}} /> : <div className="flex justify-center">
						<img alt={props.description} className="inline justify-center" src={`${props.s3Prefix}${props.image}`}></img></div>}
					<p className="inline text-center font-semibold">{props.description}</p>
				</div>
			</a>
		</div>
	)
}