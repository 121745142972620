import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useLocation } from 'react-router'
import { UserContext } from '../../UserContext'

import { API, graphqlOperation, Storage } from 'aws-amplify';
import queryString from 'query-string'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

import ChoosePlanBenefits from '../../ChoosePlanBenefits'

import FeeGenerator from '../../FeeGenerator'

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import ReactPDF from '@react-pdf/renderer';

import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PlanDocument from '../../PlanDocument'

import OfficeLogo from '../../OfficeLogo'

import LogoUploader from '../../LogoUploader'

import { useFormQL } from '../../hooks/useFormQL'

import { planSchema } from '../../schemas/schemas'

import PlanWordDocument from '../../components/PlanWordDocument'


function getRandomInt(max) {
	//creates random integer between zero and the max input
	return Math.floor(Math.random() * Math.floor(max));
}

function uuidv4() {
	//creates random 16 digit id 
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		// eslint-disable-next-line no-mixed-operators
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export default function CreatePlan(props) {

	const auth = useContext(UserContext)
	const loc = useLocation()
	const queryParams = queryString.parse(loc.search)
	const [name, setName] = useState("")

	const [hasAdultPlan, setHasAdultPlan] = useState(false)
	const [hasPerioPlan, setHasPerioPlan] = useState(false)
	const [hasChildPlan, setHasChildPlan] = useState(false)

	const [adultPlanName, setAdultPlanName] = useState("")
	const [perioPlanName, setPerioPlanName] = useState("")
	const [childPlanName, setChildPlanName] = useState("")
	const [planName, setPlanName] = useState("")


	const [editedForm, setEditedForm] = useState(false)
	const [createdForm, setCreatedForm] = useState(false)

	const [planType, setPlanType] = useState(queryParams['plan'] || "Adult")

	const [plans, setPlans] = useState([])

	const [fetchedPlans, setFetchedPlans] = useState(false)

	const [basicBenefits, setBasicBenefits] = useState(props.basicBenefits)

	const [adultCustomBenefits, setAdultCustomBenefits] = useState(props.adultCustomBenefits)

	const [childCustomBenefits, setChildCustomBenefits] = useState(props.childCustomBenefits)

	const [perioCustomBenefits, setPerioCustomBenefits] = useState(props.perioCustomBenefits)

	const [customBenefits, setCustomBenefits] = useState(props.customBenefits)

	const [selectedBenefits, setSelectedBenefits] = useState(false)
	const [selectedFees, setSelectedFees] = useState(false)

	const [adultFeeStructure, setAdultFeeStructure] = useState({ prophy: 0, exam: 0, xRay: 0, perioMaintenance: 0, recommendedFee: 0, recommendedMonth: 0, downPayment: 0, recommendedAdditionalFee: 0, recommendedAdditionalMonth: 0, recommendedAdditionalDown: 0 })
	const [perioFeeStructure, setPerioFeeStructure] = useState({ prophy: 0, exam: 0, xRay: 0, perioMaintenance: 0, recommendedFee: 0, recommendedMonth: 0, downPayment: 0, recommendedAdditionalFee: 0, recommendedAdditionalMonth: 0, recommendedAdditionalDown: 0 })
	const [childFeeStructure, setChildFeeStructure] = useState({ prophy: 0, exam: 0, xRay: 0, perioMaintenance: 0, recommendedFee: 0, recommendedMonth: 0, downPayment: 0, recommendedAdditionalFee: 0, recommendedAdditionalMonth: 0, recommendedAdditionalDown: 0 })

	const [logoUrl, setLogoUrl] = useState(props.officeLogo)

	let newChangeCount

	const handleLogoUpload = async (e) => {
		//used to store an office logo that can be displayed on the plan (if a logo hasn't been uploaded in settings)
		//see duplicate component in Settings.js for more comments

		const file = e.target.files[0];
		const imagePath = `${props.officeName} ${getRandomInt(1000)} Logo.png`
		await Storage.put(imagePath, file, {
			acl: 'public-read',
			contentType: 'image/png',
		})
			.then(result => console.log(result))
			.catch(err => console.log(err));

		let imageUrl = `https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/${imagePath.split(' ').join('+')}`
		setLogoUrl(imageUrl)
		props.handleOfficeLogoChange(imageUrl)
	}

	const handleAdultPlanType = () => {

		//when creating a new plan, offices are prompted to toggle between an Adult, Perio or Child plan type.
		//this function handles the adult toggle


		setPlanType("Adult")
		//set all current form fields to adult plan values
		setCustomBenefits(adultCustomBenefits)
		setName(adultPlanName)

		let feeStructure = adultFeeStructure
		setProphy(String(feeStructure['prophy']))
		setExam(String(feeStructure['exam']))
		setXRay(String(feeStructure['xRay']))
		setPerioMaintenance(String(feeStructure['perioMaintenance']))
		setRecommendedFee(String(feeStructure['recommendedFee']))
		setRecommendedMonth(String(feeStructure['recommendedMonth']))
		setRecommendedDown(String(feeStructure['downPayment']))
		setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
		setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
		setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))

		setFinalFee(String(feeStructure['finalFee']))
	}

	const handlePerioPlanType = () => {

		//set all current form fields to perio plan values

		setPlanType("Perio")
		setCustomBenefits(perioCustomBenefits)
		setName(perioPlanName)

		let feeStructure = perioFeeStructure
		setProphy(String(feeStructure['prophy']))
		setExam(String(feeStructure['exam']))
		setXRay(String(feeStructure['xRay']))
		setPerioMaintenance(String(feeStructure['perioMaintenance']))
		setRecommendedFee(String(feeStructure['recommendedFee']))
		setRecommendedMonth(String(feeStructure['recommendedMonth']))
		setRecommendedDown(String(feeStructure['downPayment']))
		setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
		setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
		setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
		setFinalFee(String(feeStructure['finalFee']))

	}

	const handleChildPlanType = () => {

		//set all current form fields to child plan values

		setPlanType("Child")
		setCustomBenefits(childCustomBenefits)
		setName(childPlanName)

		let feeStructure = childFeeStructure
		setProphy(String(feeStructure['prophy']))
		setExam(String(feeStructure['exam']))
		setXRay(String(feeStructure['xRay']))
		setPerioMaintenance(String(feeStructure['perioMaintenance']))
		setRecommendedFee(String(feeStructure['recommendedFee']))
		setRecommendedMonth(String(feeStructure['recommendedMonth']))
		setRecommendedDown(String(feeStructure['downPayment']))
		setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
		setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
		setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
		setFinalFee(String(feeStructure['finalFee']))
	}

	const handleNameChange = (event) => {
		setName(event.target.value)
	}



	const handleCustomBenefitsChange = id => {

		//handles checks/unchecks to the plan's custom benefits
		//custom benefits options are derived from a list created in the Admin view of the software
		//office's get to choose which benefits they use, and add custom text to some benefits

		const newCustomBenefits = customBenefits.map(benefit => {

			if (benefit.id == id) {
				//checkboxes are controlled by the included field in each individual custom benefit's object
				return ({ ...benefit, included: !benefit.included })
			} else {
				return benefit;
			}
		});

		setCustomBenefits(newCustomBenefits);
		if (planType == "Adult") {
			setAdultCustomBenefits(newCustomBenefits)
		}
		else if (planType == "Perio") {
			setPerioCustomBenefits(newCustomBenefits)
		}
		else {
			setChildCustomBenefits(newCustomBenefits)
		}
		setCustomBenefits(newCustomBenefits)
	};



	/*	const handleCustomBenefitsChange = (event) =>{
			event.preventDefault()
			const newCustomBenefits = customBenefits.map(benefit =>{
				if(benefit.id == event.target.id){
					const newCheck = [event.target.checked][0]
					return({...benefit,  included: newCheck})
				}
				else{
					return benefit
				}
			})
	
			if(planType == "Adult"){
				setAdultCustomBenefits(newCustomBenefits)
			}
			else if (planType == "Perio"){
				setPerioCustomBenefits(newCustomBenefits)
			}
			else{
				setChildCustomBenefits(newCustomBenefits)
			}
			setCustomBenefits(newCustomBenefits)
		}*/

	const handleCustomBenefitsValueChange = (event) => {

		//fields starting with 'Additional value' are known to prompt a custom $ or % value from the office, so this function looks for the text 
		//prompt and changes the input values accordingly
		event.preventDefault()
		const newCustomBenefits = customBenefits.map(benefit => {
			let newValue
			if (benefit.id == event.target.id) {
				if (benefit.name.includes('Additional value')) {
					//had some logic overlapping causing % and $ characters to show up twice on entry, so this logic handles storing these values in an interpretable way for the software
					newValue = event.target.value.replace('%', 'percent1')
					newValue = newValue.replace('$', 'dollar1')
				}
				else {
					newValue = event.target.value
				}
				return ({ ...benefit, value: newValue })
			}
			else {
				return benefit
			}
		})


		if (planType == "Adult") {
			setAdultCustomBenefits(newCustomBenefits)
		}
		else if (planType == "Perio") {
			setPerioCustomBenefits(newCustomBenefits)
		}
		else {
			setChildCustomBenefits(newCustomBenefits)
		}
		setCustomBenefits(newCustomBenefits)
	}

	const handlePreviousPage = () => {
		setSelectedBenefits(false)
	}

	const handlePreviousPageFee = () => {
		setSelectedFees(false)
	}

	const handleSubmitFee = () => {
		setSelectedFees(true)
	}

	const [prophy, setProphy] = useState("12")
	const [exam, setExam] = useState("15")
	const [xRay, setXRay] = useState("15")
	const [recommendedFee, setRecommendedFee] = useState("42")
	const [recommendedMonth, setRecommendedMonth] = useState("7")
	const [recommendedDown, setRecommendedDown] = useState("0")

	const [recommendedAdditionalFee, setRecommendedAdditionalFee] = useState("0")
	const [recommendedAdditionalMonth, setRecommendedAdditionalMonth] = useState("0")
	const [recommendedAdditionalDown, setRecommendedAdditionalDown] = useState("0")

	const [finalFee, setFinalFee] = useState("0")
	const [finalMonth, setFinalMonth] = useState("0")

	const [perioMaintenance, setPerioMaintenance] = useState("0")
	const [adultFee, setAdultFee] = useState(adultFeeStructure.recommendedFee)

	const [changeCount, setChangeCount] = useState(0)

	const params = useParams()
	const { stateDict } = useFormQL(planSchema, params.planId, "office") //get form state



	const handleAdultPlanFeeChange = (e) => {
		setChangeCount(changeCount + 1)
		setAdultFee(e.target.value)
	}

	useEffect(() => {
		setAdultFee(adultFeeStructure.recommendedFee || '0')
	}, [adultFeeStructure.recommendedFee])


	const recalcFee = () => {
		//since recommended fee and underlying fee items are directly related, a change to any of these values prompts changes to all affected related values

		//admittedly this is kind of messy, but fee values are controlled by input components that add $ to inputs in the display. That being said,
		//this has caused the state variables to take on the $ character, and this has to be diligently replaced on each field changes or the page will read errors



		//recalcs are prompted by activity like "onBlur" on the page, but we only want to
		//make changes when a user has actually attempted to change an input. The "changeCount"
		//state variable tracks if any changes have been made since the last state update
		if (changeCount > 0) {
			let newRecommendedFee = (Number(prophy.replace('$', '').replace(',', '')) + Number(exam.replace('$', '').replace(',', '')) + Number(xRay.replace('$', '').replace(',', ''))) * .8

			if (planType == "Perio") {
				// 04/23/21 - New perio calculation: ([Perio Maintenance] X 2) X .8 + [Adult Plan Fee]

				//perio is intended to use a previously set adult plan fee to calculate the perio value
				//Customers are notified on screen to create an adult plan first, though it is not required by the system
				const maintNum = Number(perioMaintenance.replace('$', '').replace(',', ''))
				const adultExtra = Number(adultFee.replace('$', '').replace(',', ''))
				newRecommendedFee = Math.max(((maintNum * 2) * 0.8) + adultExtra, 0)

				// if(hasAdultPlan){
				// 	newRecommendedFee = (maintNum*4 - Number(prophy.replace('$', '').replace(',', ''))*2) + Number(adultFeeStructure.recommendedFee)
				// 	newRecommendedFee = newRecommendedFee > 0 ? newRecommendedFee : 0

				// }
				// else{
				// 	newRecommendedFee = (Number(perioMaintenance.replace('$', '').replace(',', ''))*4 - Number(prophy.replace('$', '').replace(',', ''))*2) + Number(adultFeeStructure.recommendedFee)
				// 	newRecommendedFee = newRecommendedFee > 0 ? newRecommendedFee : 0
				// }

			}

			//recalculates monthly payment
			let newRecommendedMonth = ((newRecommendedFee + 50 - Number(recommendedDown.replace('$', '').replace(',', ''))) / 12).toFixed(2)
			setRecommendedFee(newRecommendedFee)
			setRecommendedMonth(newRecommendedMonth)

			setFinalFee(newRecommendedFee)
			setFinalMonth(newRecommendedMonth)

			//all recommended additional values are archived
			let newRecommendedAdditionalFee = newRecommendedFee - 50
			newRecommendedAdditionalFee = newRecommendedAdditionalFee >= 0 ? newRecommendedAdditionalFee : 0
			setRecommendedAdditionalFee(newRecommendedAdditionalFee)

			//all recommended additional values are archived
			let newRecommendedAdditionalDown = typeof recommendedAdditionalDown == 'string' ? Number(recommendedAdditionalDown.replace('$', '')) : recommendedAdditionalDown
			let newRecommendedAdditionalMonth = ((newRecommendedAdditionalFee + 50 - newRecommendedAdditionalDown) / 12).toFixed(2)
			setRecommendedAdditionalMonth(newRecommendedAdditionalMonth)



		}
		//all changes updated
		setChangeCount(0)
	}

	const recalcMonthFee = () => {

		//either recalcMonthFee or recalcFee is rendered depending on what value is changed on the Fee Generator page

		let newRecommendedMonth
		let newRecommendedAdditionalFee

		//recalcs are prompted by activity like "onBlur" on the page, but we only want to
		//make changes when a user has actually attempted to change an input. The "changeCount"
		//state variable tracks if any changes have been made since the last state update
		if (changeCount > 0) {

			//admittedly this is kind of messy, but fee values are controlled by input components that add $ to inputs in the display. That being said,
			//this has caused the state variables to take on the $ character, and this has to be diligently replaced on each field changes or the page will read errors
			if (typeof finalFee == 'string') {
				newRecommendedMonth = ((Number(finalFee.replace('$', '').replace(',', '')) + 50 - Number(recommendedDown.replace('$', '').replace(',', ''))) / 12).toFixed(2)
				newRecommendedAdditionalFee = Number(finalFee.replace('$', '')) - 50
			}
			else {
				newRecommendedMonth = ((Number(finalFee) + 50 - Number(recommendedDown.replace('$', '').replace(',', ''))) / 12).toFixed(2)
				newRecommendedAdditionalFee = Number(finalFee) - 50
			}
			setRecommendedMonth(newRecommendedMonth)
			newRecommendedAdditionalFee = newRecommendedAdditionalFee > 0 ? newRecommendedAdditionalFee : 0
			setRecommendedAdditionalFee(newRecommendedAdditionalFee)

			setFinalMonth(newRecommendedMonth)

		}
		//all changes updated
		setChangeCount(0)
	}

	const recalcAdditionalMonthFee = () => {

		//archived

		let newRecommendedAdditionalMonth
		let newRecommendedAdditionalDown = typeof recommendedAdditionalDown == "string" ? Number(recommendedAdditionalDown.replace('$', '')) : recommendedAdditionalDown
		if (typeof recommendedAdditionalFee == "string") {
			newRecommendedAdditionalMonth = ((Number(recommendedAdditionalFee.replace('$', '')) + 50 - newRecommendedAdditionalDown) / 12).toFixed(2)
		}
		else {
			newRecommendedAdditionalMonth = ((recommendedAdditionalFee + 50 - newRecommendedAdditionalDown) / 12).toFixed(2)
		}
		setRecommendedAdditionalMonth(newRecommendedAdditionalMonth)

		setChangeCount(0)
	}


	const handleProphyChange = (event) => {
		setProphy(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleExamChange = (event) => {
		setExam(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleXRayChange = (event) => {
		setXRay(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handlePerioMaintenanceChange = (event) => {
		setPerioMaintenance(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleRecommendedFeeChange = (event) => {
		setRecommendedFee(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleFinalFeeChange = (event) => {
		setFinalFee(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)

	}

	const handleRecommendedMonthChange = (event) => {
		setRecommendedMonth(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleRecommendedDownChange = (event) => {
		setRecommendedDown(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleFinalMonthChange = (event) => {
		setFinalMonth(event.target.value)

	}

	const handleRecommendedAdditionalFeeChange = (event) => {
		setRecommendedAdditionalFee(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleRecommendedAdditionalMonthChange = (event) => {
		setRecommendedAdditionalMonth(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}

	const handleRecommendedAdditionalDownChange = (event) => {
		setRecommendedAdditionalDown(event.target.value)

		let newChangeCount = changeCount + 1
		setChangeCount(newChangeCount)
	}


	const validateForm = (event) => {

		//moves office from choosebenefits component to fee gnerator component

		event.preventDefault()

		setSelectedBenefits(true)

		if (!editedForm) {

			//sets all of the current or default fees for the selected plan
			if (planType == "Adult") {
				let feeStructure = adultFeeStructure
				setProphy(String(feeStructure['prophy']))
				setExam(String(feeStructure['exam']))
				setXRay(String(feeStructure['xRay']))
				setPerioMaintenance(String(feeStructure['perioMaintenance']))
				setRecommendedFee(String(feeStructure['recommendedFee']))
				setRecommendedMonth(String(feeStructure['recommendedMonth']))
				setRecommendedDown(String(feeStructure['downPayment']))
				setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
				setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
				setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))

				setFinalFee(String(feeStructure['finalFee']))
			}
			else if (planType == "Perio") {
				let feeStructure = perioFeeStructure
				setProphy(String(feeStructure['prophy']))
				setExam(String(feeStructure['exam']))
				setXRay(String(feeStructure['xRay']))
				setPerioMaintenance(String(feeStructure['perioMaintenance']))
				setRecommendedFee(String(feeStructure['recommendedFee']))
				setRecommendedMonth(String(feeStructure['recommendedMonth']))
				setRecommendedDown(String(feeStructure['downPayment']))
				setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
				setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
				setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
				setFinalFee(String(feeStructure['finalFee']))
			}
			else if (planType == "Child") {
				let feeStructure = childFeeStructure
				setProphy(String(feeStructure['prophy']))
				setExam(String(feeStructure['exam']))
				setXRay(String(feeStructure['xRay']))
				setPerioMaintenance(String(feeStructure['perioMaintenance']))
				setRecommendedFee(String(feeStructure['recommendedFee']))
				setRecommendedMonth(String(feeStructure['recommendedMonth']))
				setRecommendedDown(String(feeStructure['downPayment']))
				setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
				setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
				setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
				setFinalFee(String(feeStructure['finalFee']))

			}
		}

	}


	const submitPlan = async (event) => {
		event.preventDefault()

		let planId = uuidv4()

		//this is the json object that will be decoded and stored in recommendedFees
		let feeDict = {
			prophy: prophy,
			exam: exam,
			xRay: xRay,
			perioMaintenance: perioMaintenance,
			recommendedFee: recommendedFee,
			recommendedMonth: recommendedMonth,
			downPayment: recommendedDown,
			recommendedAdditionalFee: recommendedAdditionalFee,
			recommendedAdditionalMonth: recommendedAdditionalMonth,
			recommendedAdditionalDown: recommendedAdditionalDown,
			finalFee: finalFee
		}

		//either update an existing plan record or create a new one
		if (planType == "Adult") {
			if (hasAdultPlan) {
				const updatedPlan = await API.graphql(graphqlOperation(`mutation update {
				  updatePlan (input: {
				    id: "${params.planId}",
				    name: "${name}",
				    benefits: "${btoa(JSON.stringify(customBenefits))}"
				    recommendedFee: "${btoa(JSON.stringify(feeDict))}"
				  }){
			          id
			          name
			          benefits
				  }
				}`))
				setEditedForm(true)
			}
			else {
				const createdPlan = await API.graphql(graphqlOperation(
					`mutation create {
				        createPlan(input: {id: "${planId}", name: "${name}", office: "${auth.officeId || auth.user}", planType: "Adult", benefits: "${btoa(JSON.stringify(customBenefits))}", recommendedFee: "${btoa(JSON.stringify(feeDict))}"}){
				          id
				          name
				          planType
				          benefits
				          office
				        }
				      }`))
				setCreatedForm(true)
				setHasAdultPlan(true)
			}
		}
		else if (planType == "Perio") {
			if (hasPerioPlan) {
				const updatedPlan = await API.graphql(graphqlOperation(`mutation update {
				  updatePlan (input: {
				    id: "${params.planId}",
				    name: "${name}",
				    benefits: "${btoa(JSON.stringify(customBenefits))}"
					recommendedFee: "${btoa(JSON.stringify(feeDict))}"
				  }){
			          id
			          name
			          benefits
				  }
				}`))
				setEditedForm(true)
			}
			else {
				const createdPlan = await API.graphql(graphqlOperation(
					`mutation create {
			        createPlan(input: {id: "${planId}", name: "${name}", office: "${auth.officeId || auth.user}", planType: "Perio", benefits: "${btoa(JSON.stringify(customBenefits))}", recommendedFee: "${btoa(JSON.stringify(feeDict))}"}){
			          id
			          name
			          planType
			          benefits
			          office
			        }
			      }`))
				setCreatedForm(true)
				setHasPerioPlan(true)
			}
		}
		else {
			if (hasChildPlan) {
				const updatedPlan = await API.graphql(graphqlOperation(`mutation update {
				  updatePlan (input: {
				    id: "${params.planId}",
				    name: "${name}",
				    benefits: "${btoa(JSON.stringify(customBenefits))}"
				    recommendedFee: "${btoa(JSON.stringify(feeDict))}"
				  }){
			          id
			          name
			          benefits
				  }
				}`))
				setEditedForm(true)
			}
			else {
				const createdPlan = await API.graphql(graphqlOperation(
					`mutation create {
				        createPlan(input: {id: "${planId}", name: "${name}", office: "${auth.officeId || auth.user}", planType: "Child", benefits: "${btoa(JSON.stringify(customBenefits))}", recommendedFee: "${btoa(JSON.stringify(feeDict))}"}){
				          id
				          name
				          planType
				          benefits
				          office
				        }
				      }`))
				setCreatedForm(true)
				setHasChildPlan(true)
			}
		}

		//updates office logo field in case one was updated when creating plan
		const updateOfficeData = await API.graphql(graphqlOperation(
			`mutation update {
            updateOffice(input: {id: "${auth.officeId || auth.user}", logo: "${logoUrl}"}){
            id
            logo
            }
          }`))

	}



	//maps basic benefits in form
	const listBasicBenefits = basicBenefits.map(benefit => {
		return (
			<div key={benefit} className="flex">
				<input disabled={true} className="my-auto" type="checkbox" checked={true}></input>
				<p className="my-auto px-2">{benefit}</p>
			</div>
		)
	})

	//maps custom benefits in form
	const listCustomBenefits = () => customBenefits.map(benefit => {
		return (
			<div key={benefit.benefit} className="flex">
				<input onChange={(event) => { handleCustomBenefitsChange(event) }} id={benefit.id} key={benefit.id} className="my-auto" type="checkbox" checked={benefit.included}></input>
				<p className="my-auto px-2">{benefit.benefit}</p>
			</div>
		)
	})

	//maps inputs for custom benefits that have one again, minig for additional value
	const listCustomBenefitInputs = () => customBenefits.map(benefit => {
		let displayStyle
		if ((benefit.benefit.includes("$X")) && !(benefit.name.includes('Additional value'))) {
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return (
				<div className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + " price $"}</p>
						<input onChange={handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
					<div className="w-2/3 flex-col text-sm">
						<p className="my-2 font-bold">{benefit.name + " text"}</p>
						<input onChange={handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.text} className="border w-full" type="text"></input>
					</div>
				</div>
			)
		}
		else if (benefit.benefit.includes("X%") && !(benefit.name.includes('Additional value'))) {
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return (
				<div className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + '%'}</p>
						<input onChange={handleCustomBenefitsValueChange} key={benefit.id} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
					<div className="w-2/3 flex-col">
						<p className="my-2 font-bold text-sm">{benefit.name + " text"}</p>
						<input onChange={handleCustomBenefitsValueChange} key={benefit.id} id={benefit.id} name={benefit.name} value={benefit.value} className="border w-full" type="text"></input>
					</div>
				</div>
			)
		}
		else if (benefit.benefit.includes("Additional")) {
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			let newValue
			if (benefit.value != undefined) {
				newValue = benefit.value.replace('percent1', '%')
				newValue = newValue.replace('dollar1', '$')
			}
			else {
				newValue = ""
			}

			return (
				<div className={displayStyle}>
					<div className="w-full flex-col">
						<p className="my-2 font-bold text-sm">{benefit.name}</p>
						<input id={benefit.id} name={benefit.name} value={benefit.value != undefined ? benefit.value.replace('percent1', '%') : benefit.value} onChange={handleCustomBenefitsValueChange} className="border w-full" type="text"></input>
					</div>
				</div>
			)
		}
		return undefined
	})

	//saved icons
	const displayPlanSaved = editedForm ? <div className="w-5/6 py-2 bg-white text-green-700 flex content-center mb-4"><div className="px-2">Plan Saved </div> <CheckCircleOutlineIcon style={{ color: green[600] }} /></div> : null
	const displayPlanCreated = createdForm ? <div className="w-5/6 py-2 bg-white text-green-700 flex content-center mb-4"><div className="px-2">Plan Created </div> <CheckCircleOutlineIcon style={{ color: green[600] }} /></div> : null

	//maps basic benefits on the final review screen
	const reviewBasicBenefits = basicBenefits.map(benefit => {
		return (
			<div key={benefit}>
				<p>{benefit}</p>
			</div>
		)
	})

	//maps custom benefits on the final review screen
	const reviewCustomBenefits = customBenefits.map(benefit => {
		if (benefit.included) {
			if (benefit.benefit.includes('X')) {
				if (benefit.benefit.includes('%') && !(benefit.name.includes('Additional value'))) {
					if (benefit.value != undefined) {
						if (benefit.value.includes('%')) {
							return (
								<div key={benefit.benefit}>
									<p>{benefit.benefit.replace('X%', benefit.value)}</p>
								</div>
							)
						}
						else {
							return (
								<div key={benefit.benefit}>
									<p>{benefit.benefit.replace('X', benefit.value)}</p>
								</div>
							)
						}
					}
					else {
						return (
							<div key={benefit.benefit}>
								<p>{benefit.benefit.replace('X', benefit.value)}</p>
							</div>
						)
					}
				}
				else if (benefit.benefit.includes('$')) {
					if (benefit.value != undefined) {
						if (benefit.value.includes('$')) {
							return (
								<div key={benefit.benefit}>
									<p>{benefit.benefit.replace('$X', benefit.value)}</p>
								</div>
							)
						}
						else {
							return (
								<div key={benefit.benefit}>
									<p>{benefit.benefit.replace('X', benefit.value)}</p>
								</div>
							)
						}
					}
					else {
						return (
							<div key={benefit.benefit}>
								<p>{benefit.benefit.replace('X', benefit.value)}</p>
							</div>
						)
					}
				}
				else {
					return (
						<div key={benefit.benefit}>
							<p>{benefit.benefit.replace('X', benefit.value)}</p>
						</div>
					)
				}

			}
			else if (benefit.benefit.includes('Additional')) {
				let newValue
				if (benefit.value != undefined) {
					newValue = benefit.value.replace('percent1', '%')
					newValue = newValue.replace('dollar1', '$')
				}
				else {
					newValue = benefit.value
				}
				return (
					<div key={benefit.benefit}>
						<p>{newValue}</p>
					</div>
				)
			}
			else if (!benefit.benefit.includes('Missed appointment')) {
				return (
					<div key={benefit.benefit}>
						<p>{benefit.benefit}</p>
					</div>
				)
			}
		}
		return undefined

	})

	//maps recommended fees on the final review screen
	const reviewRecommendedFees = () => {
		return (
			<div>
				<h1 className="font-semibold">Our Fee:</h1>
				<div className="my-2 px-2">
					{/* <h1 className="text-lg font-semibold">Primary Member</h1> */}
					<p className="px-2">Full Payment: ${typeof finalFee == "string" ? Number(finalFee.replace('$', '').replace(',', '')).toFixed(2) : finalFee.toFixed(2)}</p>
					<p className="px-2">Monthly Payment: ${Number(recommendedMonth.replace('$', '')).toFixed(2)}</p>
					<p className="px-2">Down Payment: ${typeof recommendedDown == "string" ? Number(recommendedDown.replace('$', '').replace(',', '')).toFixed(2) : recommendedDown.toFixed(2)}</p>

				</div>
				<div className="my-2 px-2 hidden">
					<h1 className="font-semibold">Additional Members</h1>
					<p className="px-2">Full Payment: ${typeof recommendedAdditionalFee == "string" ? Number(recommendedAdditionalFee.replace('$', '')).toFixed(2) : recommendedAdditionalFee.toFixed(2)}</p>
					<p className="px-2">Monthly Payment: ${Number(recommendedAdditionalMonth.replace('$', '')).toFixed(2)}</p>
				</div>
			</div>
		)
	}

	const handleRouteToMainPlans = () => {
		//moves back to first screen
		setSelectedBenefits(false)
		setSelectedFees(false)
	}

	useEffect(
		() => {

			//if benefits aren't defined, decode and parse the fee structure and set plan type
			if (stateDict.benefits != "" && stateDict.benefits != undefined && stateDict.recommendedFee != "" && stateDict.recommendedFee != undefined) {
				let feeStructure = JSON.parse(atob(stateDict.recommendedFee))
				setPlanType(stateDict.planType)

				//set benefits and fee structure for relevant plan
				if (stateDict.planType == "Adult") {
					setHasAdultPlan(true)
					setAdultCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setAdultPlanName(stateDict.name)
					setName(stateDict.name)

					setAdultFeeStructure(feeStructure)
					setProphy(String(feeStructure['prophy']))
					setExam(String(feeStructure['exam']))
					setXRay(String(feeStructure['xRay']))
					setPerioMaintenance(String(feeStructure['perioMaintenance']))
					setRecommendedFee(String(feeStructure['recommendedFee']))
					setRecommendedMonth(String(feeStructure['recommendedMonth']))
					setRecommendedDown(String(feeStructure['downPayment']))
					setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
					setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
					setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
					setFinalFee(String(feeStructure['finalFee']))
				}
				else if (stateDict.planType == "Perio") {
					setHasPerioPlan(true)
					setPerioCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setPerioPlanName(stateDict.name)
					setName(stateDict.name)
					let feeStructure = JSON.parse(atob(stateDict.recommendedFee))
					setPerioFeeStructure(feeStructure)
					setProphy(String(feeStructure['prophy']))
					setExam(String(feeStructure['exam']))
					setXRay(String(feeStructure['xRay']))
					setPerioMaintenance(String(feeStructure['perioMaintenance']))
					setRecommendedFee(String(feeStructure['recommendedFee']))
					setRecommendedMonth(String(feeStructure['recommendedMonth']))
					setRecommendedDown(String(feeStructure['downPayment']))
					setRecommendedAdditionalFee(String(feeStructure['recommendedAdditionalFee']))
					setRecommendedAdditionalMonth(String(feeStructure['recommendedAdditionalMonth']))
					setRecommendedAdditionalDown(String(feeStructure['recommendedAdditionalDown']))
					setFinalFee(String(feeStructure['finalFee']))

				}
				else if (stateDict.planType == "Child") {
					setHasChildPlan(true)
					setChildCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setCustomBenefits(JSON.parse(atob(stateDict.benefits)))
					setChildPlanName(stateDict.name)
					setName(stateDict.name)

					let feeStructure = JSON.parse(atob(stateDict.recommendedFee))
					setChildFeeStructure(feeStructure)
				}
			}
		}, [stateDict])

	useEffect(
		() => {

			//finalizes selected benefits in state when offie moves from ChoosePlanBenefits to FeeGenrator screen
			const newCustomBenefits = customBenefits.map(benefit => {
				let newValue
				if (benefit.name.includes('Additional value')) {
					if (benefit.value != undefined) {
						newValue = benefit.value.replace('%', 'percent1')
						newValue = newValue.replace('$', 'dollar1')
					}
					else {
						newValue = benefit.value
					}
					return ({ ...benefit, value: newValue })
				}
				else {
					return benefit
				}
			})

			if (planType == "Adult") {
				setAdultCustomBenefits(newCustomBenefits)
			}
			else if (planType == "Perio") {
				setPerioCustomBenefits(newCustomBenefits)
			}
			else {
				setChildCustomBenefits(newCustomBenefits)
			}
			setCustomBenefits(newCustomBenefits)

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectedBenefits])


	return (
		<div className="w-full h-full">
			<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
				<Link className="text-blue-500" to="/plans">{"<<"} Plans</Link>
			</div>
			{selectedFees ?
				<div id="planDoc" className="card flex flex-col my-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
					<button onClick={handleRouteToMainPlans} className="text-left my-2 text-blue-500 w-full">{"<< Back"}</button>
					<div className="flex w-full justify-between mb-4">
						<h1 className="my-auto text-2xl font-semibold">{name != "" ? name : planType} Benefits</h1>
						<div className="">
							{((logoUrl != "") && (logoUrl != undefined)) ? <OfficeLogo logoUrl={logoUrl} /> : <LogoUploader handleLogoUpload={handleLogoUpload} />}
						</div>
					</div>
					<div className="">
						We are proud to announce that we offer an In-House Savings Plan (IOP) in our dental office. Our IOP is an annual dental savings program we offer for families and individuals that allows all IOP patients to receive quality dental services at greatly reduced prices in our office. Unlike conventional insurance plans, with our IOP program there are <strong>no deductibles, no yearly maximums, and no waiting periods to begin treatment.</strong> IOP benefits and savings begin immediately upon registration.
					</div>
					<h2 className="text-lg my-4 font-semibold">Our Savings Include:</h2>
					{reviewBasicBenefits}
					{reviewCustomBenefits}
					{reviewRecommendedFees()}
					<div className="my-4">
						The fee paid for our IOP savings plan is for included standard of care services and represents a courtesy accounting adjustment for payment, made in full, at the time of service. Eligible family members include spouse and dependent children under the age of 19 (up to age 23 if dependent child is a full-time student). Fees are due and payable when services are rendered and are non-refundable when services have been provided. Savings duration is for one year from registration date. All patient portions for services received are due at the time of services to receive IOP savings. Anniversary dates are 12 months after signing up. Interest-free payment options may be provided in 3, 6- or 12-month durations upon request and with approved credit. Repayment duration is based on service totals and procedural type. If you choose to use a repayment/financial plan, your IOP Savings will be adjusted around the third-party fee and interest free payment options will be customized for your repayment needs. Fees charged are not membership fees and all fees paid are for provided services only.
					</div>
					{displayPlanSaved}
					{displayPlanCreated}
					<div className="flex">
						<button onClick={handlePreviousPageFee} className="mx-2 my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Previous</button>
						<button onClick={submitPlan} className="my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Save</button>
						{/* <PDFDownloadLink className="my-6 w-1/4 mx-2 px-2 py-2 bg-blue-500 text-white text-center" document={<PlanDocument officeLogo={props.officeLogo} planType={planType} name={name} recommendedDown={recommendedDown} recommendedFee={finalFee} recommendedMonth={recommendedMonth} recommendedAdditionalFee={recommendedAdditionalFee} recommendedAdditionalMonth={recommendedAdditionalMonth} basicBenefits={basicBenefits} customBenefits={customBenefits} />} fileName="plan.pdf">
							{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
						</PDFDownloadLink> */}
						<PlanWordDocument className="my-6 w-1/4 mx-2 px-2 py-2 bg-blue-500 text-white text-center" officeLogo={props.officeLogo} planType={planType} name={name} recommendedDown={recommendedDown} recommendedFee={finalFee} recommendedMonth={recommendedMonth} recommendedAdditionalFee={recommendedAdditionalFee} recommendedAdditionalMonth={recommendedAdditionalMonth} basicBenefits={basicBenefits} customBenefits={customBenefits} finalFee={finalFee} />
					</div>
				</div>


				: selectedBenefits ? <FeeGenerator
					hasAdultPlan={hasAdultPlan}
					adultFeeStructure={adultFeeStructure}
					handleFinalFeeChange={handleFinalFeeChange}
					handleFinalMonthChange={handleFinalMonthChange}
					finalFee={finalFee}
					finalMonth={finalMonth}
					customBenefits={planType == "Adult" ? adultCustomBenefits : planType == "Child" ? childCustomBenefits : perioCustomBenefits}
					handleRouteToMainPlans={handleRouteToMainPlans}
					recalcMonthFee={recalcMonthFee}
					recalcAdditionalMonthFee={recalcAdditionalMonthFee}
					prophy={prophy}
					exam={exam} xRay={xRay}
					recommendedFee={recommendedFee}
					recommendedMonth={recommendedMonth} recommendedDown={recommendedDown}

					adultPlanFee={adultFee} handleAdultPlanFeeChange={handleAdultPlanFeeChange}
					handleProphyChange={handleProphyChange}
					handleExamChange={handleExamChange} handleXRayChange={handleXRayChange} handleRecommendedFeeChange={handleRecommendedFeeChange}
					handleRecommendedMonthChange={handleRecommendedMonthChange} handleRecommendedDownChange={handleRecommendedDownChange}
					recommendedAdditionalFee={recommendedAdditionalFee} recommendedAdditionalDown={recommendedAdditionalDown} recommendedAdditionalMonth={recommendedAdditionalMonth}
					handleRecommendedAdditionalFeeChange={handleRecommendedAdditionalFeeChange} handleRecommendedAdditionalMonthChange={handleRecommendedAdditionalMonthChange}
					handleRecommendedAdditionalDownChange={handleRecommendedAdditionalDownChange} recalcFee={recalcFee} handlePreviousPage={handlePreviousPage}
					handleSubmitFee={handleSubmitFee} planType={planType}
					perioMaintenance={perioMaintenance} handlePerioMaintenanceChange={handlePerioMaintenanceChange} /> :
					<ChoosePlanBenefits finalFee={finalFee} planType={planType} params={params} recommendedDown={recommendedDown} recommendedMonth={recommendedMonth} recommendedFee={recommendedFee} officeLogo={props.officeLogo} handleAdultPlanType={handleAdultPlanType} handlePerioPlanType={handlePerioPlanType} handleChildPlanType={handleChildPlanType} validateForm={validateForm} handleNameChange={handleNameChange} name={name}
						basicBenefits={basicBenefits} customBenefits={planType == "Adult" ? adultCustomBenefits : planType == "Child" ? childCustomBenefits : perioCustomBenefits} listBasicBenefits={listBasicBenefits} listCustomBenefits={listCustomBenefits}
						listCustomBenefitInputs={listCustomBenefitInputs}
						handleCustomBenefitsChange={handleCustomBenefitsChange} handleCustomBenefitsValueChange={handleCustomBenefitsValueChange}
						hasAdultPlan={hasAdultPlan} hasChildPlan={hasChildPlan} hasPerioPlan={hasPerioPlan} />}

		</div>
	)
}

