import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { green, red, blue } from '@material-ui/core/colors';

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import Table from './components/Table'

const monthDict = {0: "January",
	1: "February",
	2: "March",
	3: "April",
	4: "May",
	5: "June",
	6: "July",
	7: "August",
	8: "September",
	9: "October",
	10: "November",
	11: "December"
}

export default function CustomerPayments(props){
	const [currentDay, setCurrentDay] = useState(new Date().getDate())
	const [paymentMonth, setPaymentMonth] = useState("")


  useEffect(() => {
  	if(props.paymentInterval != "monthly"){
  		const createdAt = props.createdAt !== undefined ? new Date(props.createdAt.split('T')[0]) : props.createdAt
  		setPaymentMonth(monthDict[createdAt.getMonth()])
  	}
	else if(currentDay > props.dueDay){
		setPaymentMonth(monthDict[new Date().getMonth() + 2])
	}
	else{
		setPaymentMonth(monthDict[new Date().getMonth()])
	}
  }, [currentDay, props])

	return(
		<div className="w-full h-full">
			<div className="card-highlight mx-8 mt-8">
				<div className="w-full flex justify-between">
					<div className="flex flex-col py-2 w-1/2">
						<div className="text-2xl text-blue-500 font-bold pt-1 highlight-figs">${props.periodAmount}</div>
						<div className="text-blue-700 text-lg font-semibold highlight-desc">Next Payment Amount</div>
					</div>
					<div className="flex flex-col py-2 w-1/2">
						<div className="text-2xl text-blue-500 font-bold pt-1 highlight-figs">{`${paymentMonth} ${props.dueDay}`}</div>
						<div className="text-blue-700 text-lg font-semibold highlight-desc">Next Payment Date</div>
					</div>
				</div>
			</div>
			<div className="w-full p-8 ">

				<h1 className="font-semibold card-h mb-4">Payment History</h1>
				<Table customComponents={{status: (field) => field == "failure" ? <CloseIcon className="stream-icon-md" /> : <CheckIcon className="stream-icon-pc" />}}
					columns={['Description', 'Status', 'Date', 'Net Pay ($)']}
					fields={['description', 'status', 'createdAt', 'netPay']}
					values={props.payments} />

			</div>
		</div>

		)
}
