import React, { useState, useEffect, useContext, useCallback } from "react";
import './App.css';
import './Curran.css';

import Routes from './Routes'

import { useParams, Link, Redirect } from "react-router-dom"

import usePath from 'react-use-path';


import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DehazeIcon from '@material-ui/icons/Dehaze';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { grey } from '@material-ui/core/colors';

import { UserContext } from './UserContext'

import CreatePractice from './CreatePractice'

import FreshdeskWidget from '@personare/react-freshdesk-widget';

import ScriptTag from 'react-script-tag';

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


/*import awsconfig from './aws-exports';

Amplify.configure(awsconfig);*/


import HelpWidget from './components/HelpWidget'


function App(props) {

  const [path, setPath] = usePath()
  const auth = useContext(UserContext)

  const [showedExpiring, setShowedExpiring] = useState(false)
  const [expiringMembers, setExpiringMembers] = useState([])

  const [showedVerified, setShowedVerified] = useState(false)
  const [officeIsVerified, setOfficeIsVerified] = useState(true)

  const [accountType, setAccountType] = useState(props.accountType)

  const [isAuthenticated, userHasAuthenticated] = useState(props.isAuthenticated);
  const [currentPage, setCurrentPage] = useState(path.path.includes('plan') ? "Plans" : path.path.includes('practice') ? "Connections" : path.path.includes('member') ? "Members" : path.path.includes('payment') ? "Payments" : "Dashboard")
  const [showNav, setShowNav] = useState(true)

  const [showConnectionsDropdown, setShowConnectionsDropdown] = useState(false)
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false)
  const [showPlansDropdown, setShowPlansDropdown] = useState(false)
  const [showMembersDropdown, setShowMembersDropdown] = useState(false)
  const [showPaymentsDropdown, setShowPaymentsDropdown] = useState(false)

  const [showProfileDropdown, setShowProfileDropdown] = useState(false)
  const [officeDropdown, setOfficeDropdown] = useState(false)

  const [officeName, setOfficeName] = useState("")
  const [officePrimaryContact, setOfficePrimaryContact] = useState("")
  const [officePhone, setOfficePhone] = useState("")
  const [officeEmail, setOfficeEmail] = useState("")
  const [officeAddress, setOfficeAddress] = useState("")
  const [officeZip, setOfficeZip] = useState("")
  const [officeMultiplePractices, setOfficeMultiplePractices] = useState(false)
  const [officeHasPractice, setOfficeHasPractice] = useState(false)
  const [officeHasPlan, setOfficeHasPlan] = useState(false)
  const [officeFetched, setOfficeFetched] = useState('')
  const [officeId, setOfficeId] = useState(false)
  const [officeHasLoggedIn, setOfficeHasLoggedIn] = useState("")
  const [officeState, setOfficeState] = useState("")
  const [officeCreatedAt, setOfficeCreatedAt] = useState("")

  const [officeMerchantKey, setOfficeMerchantKey] = useState("")
  const [officeUsioLogin, setOfficeUsioLogin] = useState("")
  const [officeUsioPassword, setOfficeUsioPassword] = useState("")

  const [officeLogo, setOfficeLogo] = useState("")

  const [basicBenefits, setBasicBenefits] = useState([])
  const [customBenefits, setCustomBenefits] = useState([])
  const [adultCustomBenefits, setAdultCustomBenefits] = useState([])
  const [childCustomBenefits, setChildCustomBenefits] = useState([])
  const [perioCustomBenefits, setPerioCustomBenefits] = useState([])

  const [officeFirstPracticeId, setOfficeFirstPracticeId] = useState("")

  const [plans, setPlans] = useState([])

  const [showQuickStartDropdown, setShowQuickStartDropdown] = useState(false)

  const c = ''

  const confirmExpiringCards = useCallback(
    () => {

      confirmAlert({
        title: `Warning: Expiring Cards`,
        message: `${expiringMembers.length} patients have cards expiring during the current or next month. Please edit patient card info (Members -> Edit Member -> Update Card Info) to ensure payments don't decline.`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => { setShowedExpiring(true) }
          }
        ]
      });
    }, [expiringMembers])


  const handleChangeOfficeHasLoggedIn = async () => {
    setOfficeHasLoggedIn("yes")
    const updateOfficeData = await API.graphql(graphqlOperation(
      `mutation update {
          updateOffice(input: {id: "${props.auth.officeId || props.auth.user}", hasLoggedIn: "yes"}){
          id
          hasLoggedIn
          }
        }`))
  }

  const handleOfficeMerchantKeyChange = (event) => {
    setOfficeMerchantKey(event)
  }

  const handleOfficeUsioLoginChange = (event) => {
    setOfficeUsioLogin(event)
  }

  const handleOfficeUsioPasswordChange = (event) => {
    setOfficeUsioPassword(event)
  }

  const handleOfficeNameChange = (event) => {
    setOfficeName(event)
  }

  const handleOfficePrimaryContactChange = (event) => {
    setOfficePrimaryContact(event)
  }

  const handleOfficePhoneChange = (event) => {
    setOfficePhone(event)
  }

  const handleOfficeEmailChange = (event) => {
    setOfficeEmail(event)
  }

  const handleOfficeAddressChange = (event) => {
    setOfficeAddress(event)
  }

  const handleOfficeZipChange = (event) => {
    setOfficeZip(event)
  }

  const handleOfficeStateChange = (event) => {
    setOfficeState(event)
  }

  const handleOfficeMultiplePracticesChange = (event) => {
    setOfficeMultiplePractices(event)
  }

  const handleOfficeHasPracticeChange = (bool) => {
    setOfficeHasPractice(bool)
  }

  const handleOfficeHasPlanChange = (bool) => {
    setOfficeHasPlan(bool)
  }

  const handleOfficeLogoChange = (url) => {
    setOfficeLogo(url)
  }



  async function handleLogout() {
    Auth.signOut()
    props.userHasAuthenticated(false);
    setOfficeHasPractice(false)
    props.setAccountType("")
    props.setUser(null)

  }

  const handleNavChange = () => {
    const newShowNav = !showNav
    setShowNav(newShowNav)
    console.info('show nav updated')
  }

  const handleMultiplePracticesChange = (event) => {
    setOfficeMultiplePractices([event.target.checked][0])
  }

  const handleConnectionsDropdownChange = () => {
    const newDropdown = !showConnectionsDropdown
    setShowConnectionsDropdown(newDropdown)
  }

  const handleQuickStartDropdownChange = () => {
    const newDropdown = !showQuickStartDropdown
    setShowQuickStartDropdown(newDropdown)
  }

  const handleResourcesDropdownChange = () => {
    const newDropdown = !showResourcesDropdown
    setShowResourcesDropdown(newDropdown)
  }

  const handlePlansDropdownChange = () => {
    const newDropdown = !showPlansDropdown
    setShowPlansDropdown(newDropdown)
  }

  const handleMembersDropdownChange = () => {
    const newDropdown = !showMembersDropdown
    setShowMembersDropdown(newDropdown)
  }

  const handleProfileDropdown = () => {
    const newDropdown = !showProfileDropdown
    setShowProfileDropdown(newDropdown)
  }

  const handleOfficeDropdown = () => {
    setOfficeDropdown(!officeDropdown)
  }

  const handleProfileUndropdown = () => {
    if (showProfileDropdown) setShowProfileDropdown(false)
    if (officeDropdown) setOfficeDropdown(false)
  }


  const assessmentGray = <AssessmentIcon style={{ color: grey[500] }} />
  const assessmentWhite = <AssessmentIcon />

  const addCircleGray = <AddCircleOutlineIcon style={{ color: grey[500] }} />
  const addCircleWhite = <AddCircleOutlineIcon />

  const assignmentGray = <AssignmentIcon style={{ color: grey[500] }} />
  const assignmentWhite = <AssignmentIcon />

  const assignmentIndGray = <AssignmentIndIcon style={{ color: grey[500] }} />
  const assignmentIndWhite = <AssignmentIndIcon />

  const attachMoneyGray = <AttachMoneyIcon style={{ color: grey[500] }} />
  const attachMoneyWhite = <AttachMoneyIcon />

  const arrowRightGray = <ArrowRightIcon style={{ color: grey[500] }} />
  const arrowRightWhite = <ArrowRightIcon />

  const arrowDownGray = <ArrowDropDownIcon style={{ color: grey[500] }} />
  const arrowDownWhite = <ArrowDropDownIcon />

  const playGray = <PlayCircleOutlineIcon style={{ color: grey[500] }} />
  const playWhite = <PlayCircleOutlineIcon />

  const accountBalanceGray = <AccountBalanceIcon style={{ color: grey[500] }} />
  const accountBalanceWhite = <AccountBalanceIcon />



  const fetchOffice = useCallback(
    async () => {
      //queries office object from dynamodb using auth credentials passed from AllApp
      const officeData = await API.graphql(graphqlOperation(`query GetOffice {
          getOffice(id: "${props.auth.officeId || props.auth.user}"){
            id
            name
            primaryContact
            phone
            email
            address
            zip
            multiplePractices
            hasPractice
            hasPlan
            merchantKey
            usioLogin
            usioPassword
            logo
            hasLoggedIn
            createdAt
            isVerified
            state
            }
          }`))

      const userOfficeData = officeData.data.getOffice
      //sets profile state variables for office
      try {
        setOfficeId(userOfficeData['id'])
        setOfficeName(userOfficeData['name'])
        setOfficePrimaryContact(userOfficeData['primaryContact'])
        setOfficePhone(userOfficeData['phone'])
        setOfficeEmail(userOfficeData['email'])
        setOfficeAddress(userOfficeData['address'])
        setOfficeZip(userOfficeData['zip'])
        setOfficeMultiplePractices(userOfficeData['multiplePractices'])
        setOfficeHasPractice(userOfficeData['hasPractice'])
        setOfficeHasPlan(userOfficeData['hasPlan'])
        setOfficeMerchantKey(userOfficeData['merchantKey'])
        setOfficeUsioLogin(userOfficeData['usioLogin'])
        setOfficeUsioPassword(userOfficeData['usioPassword'])
        setOfficeLogo(userOfficeData['logo'])
        setOfficeHasLoggedIn(userOfficeData['hasLoggedIn'])
        setOfficeState(userOfficeData['state'])
        setOfficeCreatedAt(userOfficeData['createdAt'])
        setOfficeIsVerified(userOfficeData['isVerified'])
      }
      catch {
        console.info("Practice Not Created")
      }

      return userOfficeData
    }, [props.auth])

  const isMemberCardExpiring = (member) => {

    let mem = member

    // No expiration or empty expiration means non-expiring
    if (!mem.expiration || mem.expiration.length === 0) { return false }

    const expM = Number(mem.expiration.substring(0, 2))
    const expY = Number(mem.expiration.substring(2, 6))

    const now = new Date()
    const currYear = now.getFullYear()
    const currMonth = now.getMonth() + 1

    // If cur year is less than the expiration year then we're good to go
    if (currYear < expY) { return false }

    return currYear > expY || currMonth >= expM || (currMonth + 1) >= expM
  }

  const fetchMembers = useCallback(
    async () => {

      //queries all patients for the given office to see if any have an expiring card saved with USIO.
      //if so, a popup appears notifying the office  

      //queries all users for this office
      const membersData = await API.graphql(graphqlOperation(`query ListMembers {
        listMembers(filter: {office: {eq:"${props.auth.officeId || props.auth.user}"}}, limit: 9999) {
          items {
              id
              name
              active
              practice
              plan
              planAgreement
              expiration
              createdAt
              planAgreementDate
          }
        }
        }`))

      let membersList = membersData.data.listMembers.items
      let rowCount = 1
      let newMember = []

      let newMembersList = []
      for (let i = 0; i < membersList.length; i++) {
        //checks which users are expiring using handleExpiringCards
        newMember = { ...membersList[i], rowId: rowCount, expiring: isMemberCardExpiring(membersList[i]) }
        newMembersList.push(newMember)
        rowCount = rowCount + 1
      }

      const newExpiringMembers = newMembersList.filter(member => member.expiring == true)
      setExpiringMembers(newExpiringMembers)
      return newExpiringMembers
    }, [props.auth])

  const fetchPractice = useCallback(
    async () => {
      //queries the offices "first" practice.  Many offices will only have one practice, so data here will be displayed for it in settings.
      //Some users will "enable multiple practices" in settings, in which case this practice object variable is less relevant, because their will be a whole "Practices" page accessible in the nav bar
      const practiceData = await API.graphql(graphqlOperation(`query ListPractices {
      listPractices(filter: {office: {eq:"${props.auth.officeId || props.auth.user}"}}, limit: 9999) {
        items {
            id
            businessName
            name
            city
            zip
        }
      }
      }`))

      if (practiceData.data.listPractices.items.length > 0) {
        let accountPractice = practiceData.data.listPractices.items[0]
        setOfficeFirstPracticeId(accountPractice['id'])
      }
      return practiceData.data.listPractices.items[0]
    }, [props.auth])

  async function fetchAdminPlans() {

    //"Admin" plans simply refer to the default plan benefits that are displayed for adult, perio and child plans in the QDP pan builder
    //fetched plans in the App component are passed to the CreatePlanV3 component on the plan creation page
    //If an office is creating a new plan, the plan benefit options will be populated using "AdminPlans" fetched here
    //If an office is reviewing an existing plan, that previously saved plan will be displayed (with custom benefit selection, etc.) instead of one of these  
    //more on this on the plans page

    const adminPlansData = await API.graphql(graphqlOperation(`query ListAdminPlans {
        listAdminPlans(limit: 9999) {
          items {
              id
              name
              customBenefits
              basicBenefits
              planType
          }
        }
        }`))

    let plansList = adminPlansData.data.listAdminPlans.items
    let rowCount = 1
    let newPlan = []

    let newPlansList = []
    for (let i = 0; i < plansList.length; i++) {
      newPlan = { ...plansList[i], rowId: rowCount }
      newPlansList.push(newPlan)
      rowCount = rowCount + 1
    }

    //admin should only hold one version of Adult, perio and child plans, so the first falue is taken

    //all plans use the same basic benefits, so this  is just set based on the adult plan, but they can have different custom benefits
    if (plansList.filter(plan => plan.planType == "Adult").length > 0) {
      setBasicBenefits(atob(plansList.filter(plan => plan.planType == "Adult")[0].basicBenefits).split(','))
      setAdultCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Adult")[0].customBenefits)))
      setCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Adult")[0].customBenefits)))
    }

    if (plansList.filter(plan => plan.planType == "Perio").length > 0) {
      setPerioCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Perio")[0].customBenefits)))
    }

    if (plansList.filter(plan => plan.planType == "Child").length > 0) {
      setChildCustomBenefits(JSON.parse(atob(plansList.filter(plan => plan.planType == "Child")[0].customBenefits)))
    }

    setPlans(newPlansList)
  }

  useEffect(() => {
    if (!props.auth) { return }
    if (!props.auth.officeId && !props.auth.user) { return }
    if (officeFetched == (props.auth.officeId || props.auth.user)) { return }

    (async () => {

      try {
        setOfficeFetched(props.auth.officeId || props.auth.user)
        const res = await Promise.all([
          fetchMembers(),
          fetchOffice(),
          fetchPractice()
        ])

        if (!res[1]) {
          setOfficeFetched(false)
        }
      }
      catch (ex) {
        console.error(ex)
        setOfficeFetched('')
      }

    })()


    // //members fetched to check for expiring cards
    // fetchMembers()
    // //office checked to populate office profile data
    // fetchOffice()
    // //set to true so queries don't take place again even if auth or officeHasPractice changes
    // setOfficeFetched(true)
    // fetchPractice()
  }, [props.auth, officeHasPractice, officeFetched, fetchMembers, fetchOffice, fetchPractice])

  useEffect(() => {
    //admin plans or "blank template" plans are fetched and ultimately passed to the Create Plan page via props
    fetchAdminPlans()
  }, [])

  useEffect(() => {
    if (!showedExpiring) {
      //if any patients have saved cards with USIO that will soon expire, a popup alert will appear notifying the office
      if (expiringMembers.length > 0) {
        confirmExpiringCards()
      }
    }

  }, [confirmExpiringCards, expiringMembers, showedExpiring])

  const renderCreateFirstPractice = () => {
    return (
      <div className="w-full h-full">
        <Link to="/login" className="flex my-auto py-4 px-2 menu-item" >Login (shown for amplify demo only)</Link>
        <h1 className="text-xl text-center my-6">Welcome to QDP! Please create a practice to continue</h1>
        <CreatePractice officeHasPractice={officeHasPractice} handleOfficeHasPracticeChange={handleOfficeHasPracticeChange} />
      </div>
    )
  }

  const updateOffice = async (officeId) => {
    auth.setOfficeId(officeId)
    setOfficeDropdown(false)
  }

  const offices = props.auth.offices || []
  const showOfficesDropdown = offices.length > 1
  return (
    <div onClick={handleProfileUndropdown} className="flex w-full h-full bg-gray-100">
      <div className={showNav ? officeHasLoggedIn !== "no" ? "h-full bg-white border-r nav-sidebar" : "hidden" : "hidden"}>
        <div className={showNav ? "flex flex-col" : "hidden"}>
          <div className="text-blue-500 p-8"><Link to="/login"><img alt='Login Logo' src="https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDP-Brand-Logo-jpg.jpg"></img></Link></div>
          <div className={currentPage == "Dashboard" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"} >
            <div className="my-auto nav-icon">{currentPage == "Dashboard" ? assessmentWhite : assessmentGray}</div>
            <Link  onClick={() => setCurrentPage("Dashboard")} className="menu-item flex my-auto py-4 px-2" to="/">Dashboard</Link>
          </div>
          <button className="focus:outline-none" onClick={handleQuickStartDropdownChange}>
            <div className="flex-col">
              <div className={currentPage == "Quick Start" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : " menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Quick Start" ? playWhite : playGray}</div>
                <div className="flex my-auto py-4 px-2 menu-item">Quick Start</div>
                <div className="my-auto">{showQuickStartDropdown ? currentPage == "Quick Start" ? arrowDownWhite : arrowDownGray : currentPage == "Quick Start" ? arrowRightWhite : arrowRightGray}</div>
              </div>
              <div className={showQuickStartDropdown ? "submenu-box" : "hidden"}>
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Quick Start")} to="/resources/quickstart" className="text-gray-700">Video Guide</Link></div>
                <div className="submenu-item py-2 hover:text-underline"><a className="text-gray-700" href={`https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDP_Onboarding.pdf`} target="_blank" rel="noopener noreferrer">Onboarding Checklist</a></div>
              </div>
            </div>
          </button>


          {officeMultiplePractices ?
            <button className="focus:outline-none" onClick={handleConnectionsDropdownChange}>
              <div className="flex-col">
                <div className={currentPage == "Connections" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : " menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                  <div className="my-auto nav-icon">{currentPage == "Connections" ? addCircleWhite : addCircleGray}</div>
                  <div className="flex my-auto py-4 px-2 menu-item">Practices</div>
                  <div className="my-auto">{showConnectionsDropdown ? currentPage == "Connections" ? arrowDownWhite : arrowDownGray : currentPage == "Connections" ? arrowRightWhite : arrowRightGray}</div>
                </div>
                <div className={showConnectionsDropdown ? "submenu-box" : "hidden"}>
                  <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Connections")} to="/practices" className="text-gray-700">View Practices</Link></div>
                  <div className="submenu-item py-2 hover:text-underline"><Link onClick={() => setCurrentPage("Connections")} to="/createpractice" className="text-gray-700">Add Practice</Link></div>
                </div>
              </div>
            </button> : null}
          <button className="focus:outline-none" onClick={handlePlansDropdownChange}>
            <div className={currentPage == "Plans" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
              <div className="my-auto nav-icon">{currentPage == "Plans" ? assignmentWhite : assignmentGray}</div>
              <Link onClick={() => setCurrentPage("Plans")} className="flex my-auto py-4 px-2 menu-item" to="/plans">Plans</Link>
            </div>
          </button>

          <div className={currentPage == "Renewals" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
            <div className="my-auto nav-icon">{currentPage == "Renewals" ? assignmentIndWhite : assignmentIndGray}</div>
            <Link onClick={() => setCurrentPage("Renewals")} className="flex my-auto py-4 px-2 menu-item" to="/renewals">Renewals</Link>
          </div>

          <button className="focus:outline-none" onClick={handleMembersDropdownChange}>
            <div className="flex-col">
              <div className={currentPage == "Members" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Members" ? assignmentIndWhite : assignmentIndGray}</div>
                <div className="flex my-auto py-4 px-2 menu-item">Members</div>
                <div className="my-auto">{showMembersDropdown ? currentPage == "Members" ? arrowDownWhite : arrowDownGray : currentPage == "Members" ? arrowRightWhite : arrowRightGray}</div>
              </div>
              <div className={showMembersDropdown ? "submenu-box" : "hidden"}>
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Members")} to="/members" className="text-gray-700">View Members</Link></div>
                {/* <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Members")} to="/non-members" className="text-gray-700">Other Plans</Link></div> */}
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Members")} to="/createmember/info" className="text-gray-700">Add Member</Link></div>
              </div>
            </div>
          </button>
          <div className={currentPage == "Payments" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
            <div className="my-auto nav-icon">{currentPage == "Payments" ? attachMoneyWhite : attachMoneyGray}</div>
            <Link onClick={() => setCurrentPage("Payments")} className="flex my-auto py-4 px-2 menu-item" to="/payments">Payments</Link>
          </div>
          <div className={currentPage == "Business Enroll" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
            <div className="my-auto nav-icon">{currentPage == "Business Enroll" ? accountBalanceWhite : accountBalanceGray}</div>
            <Link onClick={() => setCurrentPage("Business Enroll")} className="flex my-auto py-4 px-2 menu-item" to="/resources/business-enroll">Business Enroll</Link>
          </div>
          <button className="focus:outline-none" onClick={handleResourcesDropdownChange}>
            <div className="flex-col">
              <div className={currentPage == "Resources" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : " menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
                <div className="my-auto nav-icon">{currentPage == "Resources" ? addCircleWhite : addCircleGray}</div>
                <div className="flex my-auto py-4 px-2 menu-item">Resources</div>
                <div className="my-auto">{showResourcesDropdown ? currentPage == "Connections" ? arrowDownWhite : arrowDownGray : currentPage == "Resources" ? arrowRightWhite : arrowRightGray}</div>
              </div>
              <div className={showResourcesDropdown ? "submenu-box" : "hidden"}>
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Resources")} to="/resources/quickstart" className="text-gray-700">Quick Start</Link></div>
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Resources")} to="/resources/knowledge-base" className="text-gray-700">QDPedia – Knowledge Base</Link></div>
                <div className="submenu-item py-2 hover:text-underline"><Link onClick={() => setCurrentPage("Resources")} to="/resources/pms" className="text-gray-700">PMS Guides</Link></div>
                <div className="submenu-item py-2 hover:text-underline"><Link onClick={() => setCurrentPage("Resources")} to="/resources/marketing" className="text-gray-700">Marketing</Link></div>
                <div className="submenu-item py-2 hover:text-underline"><Link onClick={() => setCurrentPage("Resources")} to="/resources/business-enroll" className="text-gray-700">Business Enroll</Link></div>
                <div className="py-2 hover:text-underline submenu-item"><Link onClick={() => setCurrentPage("Resources")} to="/resources/training" className="text-gray-700">In-Depth Training</Link></div>

              </div>
            </div>
          </button>
          <HelpWidget />
        </div>
        <div className={currentPage == "Users" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
          <div className="my-auto nav-icon">{currentPage == "Users" ? assignmentIndWhite : assignmentIndGray}</div>
          <Link onClick={() => setCurrentPage("Users")} className="flex my-auto py-4 px-2 menu-item" to="/users">Users</Link>
        </div>
        <div className={currentPage == "Settings" ? "menu-item-box-a text-white px-4 py-2 flex justify-between my-auto bg-blue-500" : "menu-item-box text-gray-700 px-4 py-2  flex justify-between my-auto"}>
          <div className="my-auto nav-icon">{currentPage == "Settings" ? assignmentWhite : assignmentGray}</div>
          <Link onClick={() => setCurrentPage("Settings")} className="flex my-auto py-4 px-2 menu-item" to="/settings">Settings</Link>
        </div>
      </div>

      <div className="w-full h-full bg-gray-100 overflow-auto ">
        <div className="w-full p-4 py-2 profile-banner">
          <div className='flex justify-end'>
            <p>Logged in as: &nbsp;</p>
            <button onClick={handleProfileDropdown} className="">
              {props.user.attributes.email}
            </button>
          </div>

          <div className='flex justify-end'>
            <p>Office:&nbsp;</p>
            <button onClick={showOfficesDropdown ? handleOfficeDropdown : () => { }} className="">
              {officeName || "--"}
            </button>
            {showOfficesDropdown && arrowDownGray}
          </div>
          <div className={showProfileDropdown ? "profile-dropdown" : "hidden"}>
            <div className="profile-mi"><Link to="/settings" className="profile-mi">Settings</Link></div>
            <button onClick={handleLogout} className="profile-mi">Logout</button>
          </div>

          {
            showOfficesDropdown && (
              <div className={officeDropdown ? "office-dropdown" : "hidden"}>
                <div className='inner'>
                  {offices.map(o => {
                    return (<button key={o.office.id} onClick={() => updateOffice(o.office.id)}>{o.office.name}</button>)
                  })}
                </div>
              </div>
            )
          }
        </div>
        <div className="w-full flex justify-between p-4 bg-gradient text-white">
          <button onClick={handleNavChange} className="bg-blue-500 hover:bg-blue-500"><DehazeIcon /></button>
          <div className="flex justify-end">
            <Link to="/createmember/info" className="blue-btn mx-2 text-center w-1/4">Add New Member</Link>
            <Link to="/paymentplan" className="py-auto gray-btn mx-2 text-center w-1/4">Other Payment Plan</Link>
            <Link to="/newpayment" className="white-btn text-blue-700 mx-2 text-center w-1/4">Other Services Payment</Link>
          </div>
        </div>
        <Routes appProps={{
          setOfficeIsVerified, showedExpiring, setShowedExpiring, officeFirstPracticeId, officeCreatedAt, handleOfficeStateChange, officeState, handleChangeOfficeHasLoggedIn, officeHasLoggedIn, officeId, handleOfficeLogoChange, officeLogo, accountType, officeFetched, officeName, officePrimaryContact, officePhone, officeEmail,
          officeIsVerified, officeZip, officeAddress, officeHasPractice, officeHasPlan, officeMerchantKey, officeUsioLogin, officeUsioPassword,
          officeMultiplePractices, isAuthenticated, userHasAuthenticated,
          handleOfficeNameChange, handleOfficeZipChange, handleOfficePrimaryContactChange, handleOfficePhoneChange,
          handleOfficeEmailChange, handleOfficeAddressChange, handleOfficeMultiplePracticesChange,
          handleOfficeHasPlanChange, handleOfficeHasPracticeChange, handleOfficeMerchantKeyChange,
          handleOfficeUsioLoginChange, handleOfficeUsioPasswordChange, showNav, basicBenefits, customBenefits, adultCustomBenefits, childCustomBenefits, perioCustomBenefits, plans
        }} />
      </div>
    </div>
  );
}

export default App;

