import React, { useState, useEffect, useContext }  from "react";


const SelectCreditCardType = (props) => {
		return(
			<div className="flex my-4 w-full">
				<p className="my-auto pr-4 w-1/3 text-right">Card Type:</p>
				<select onClick={props.onClick} disabled={props.disabled} className="border w-1/2 mr-2" onChange={props.onChange} value={props.value} name={props.name} id={props.name}>
				    <option value=''>Card Type</option>
				    <option value='VISA'>Visa</option>
				    <option value='MSTR'>Mastercard</option>
				    <option value='DISC'>Discover</option>
				    <option value='AMEX'>American Express</option>
				</select> 					
			</div>
			)
}

export default SelectCreditCardType
