import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green, red, blue } from '@material-ui/core/colors';


import Table from '../../components/Table'

export default function MemberPayments(props){

    useEffect(
	    () => {
	   		props.fetchMemberPayments()
	    }, [props])
	return(
		<div className="w-full h-full">
			<div className="w-5/6 mx-auto p-4 bg-white">

				{props.paymentToken != "" ? 
					<button className="mb-6 hidden" onClick={props.handleAddPaymentsPage}><Link to={`/member/${props.memberId}/payments/add`} className="gradient-btn mx-2">New Payment</Link></button>
					: <div className="mb-6">Save new member info and card details to post a payment.</div> }

			<Table customComponents={{status: (field) => field == "failure" ? <CloseIcon className="stream-icon-md" /> : <CheckIcon className="stream-icon-pc" />}} columns={['Member', 'Description', 'Status', 'Practice', 'Date', 'Net Pay ($)']}
				fields={['memberName', 'description', 'status', 'practice', 'createdAt', 'netPay']}
				values={props.payments} />
			</div>


		</div>

		)
}