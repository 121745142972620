import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import React, { useState, useEffect, useContext, useCallback }  from "react";
import './App.css';
import './Curran.css';

import App from './App'
import AdminApp from './AdminApp'
import CustomerApp from './CustomerApp'

import {UserContext} from './UserContext'

import LoginRoutes from './LoginRoutes'

Amplify.configure(awsconfig);



function AllApp() {

  const auth = useContext(UserContext)
  const [accountType, setAccountType] = useState("")
  
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userNeedsNewPassword, setUserNeedsNewPassword] = useState(false)
  
  const [user, setUser] = useState("")

  const fetchAccount = useCallback(
    async () => {
    // TODO: Use logic in `UserContext` instead of here 
        const accountData = await API.graphql(graphqlOperation(`query GetAccount {
          getAccount(id: "${auth.user}"){
            id
            accountType
            }
          }`))

        const userAccountData = accountData.data.getAccount
        try{
          setAccountType(userAccountData['accountType'])
        }
        catch{

          console.info("Account not fetched")
          await Auth.signOut()

        }

    }, [auth])

  useEffect(() => {
    let updateUser = async authUser => {
      //userSess is the same as auth.officeId || auth.user, but isn't actually getting used anywhere
      try{
        let userSess = await Auth.currentAuthenticatedUser({ bypassCache: true })
        setUser(userSess)
      }
      catch(e){
        console.log(e)
        setUser(null)
      }
      }
    if(auth != undefined){
      if(auth.officeId || auth.user != undefined){
        fetchAccount()
      }
    }
    updateUser()

  }, [auth, fetchAccount])

  return (
    <div className="w-full h-full bg-gray-100">
      {(user != null) && (user != "") ? accountType.indexOf("office") === 0 ? <App user={user} setUser={setUser} auth={auth} isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated}
       accountType={accountType} setAccountType={setAccountType} /> 
          : accountType == "customer" ? <CustomerApp user={user} setUser={setUser} auth={auth} isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated}
          accountType={accountType} setAccountType={setAccountType} /> : accountType == "admin" ? <AdminApp user={user} setUser={setUser} auth={auth} isAuthenticated={isAuthenticated} 
          userHasAuthenticated={userHasAuthenticated} accountType={accountType} setAccountType={setAccountType} /> : null  : 
        <div className="w-full">
          <div className="w-full flex justify-between p-4 bg-white text-white">
            <button className="bg-white ">
              <img alt='Auth Background' className="w-1/6" src="https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/QDP-Brand-Logo-jpg.jpg"></img></button>
            <div className="flex">
            </div>
          </div>
        {user != "" ? <LoginRoutes appProps={{isAuthenticated, userHasAuthenticated, userNeedsNewPassword, setUserNeedsNewPassword, user, setUser}} /> : null}
      </div>
      }

    </div>
  );
}

export default AllApp;
