import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import EditAccountPractice from './EditAccountPractice'
import Input from './components/Input'
import Form from './components/Form'
import FormCol from './components/FormCol'


export default function SettingsPracticeDetail(props){

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    props.handleSubmit(e)
    props.handleOfficeMultiplePracticesChange(true)
  }

  return(
    <div className="w-full h-full">
      {props.officeState.multiplePractices ? 
        <Form onSubmit={handleFormSubmit} title={"Practice Settings"} buttonTitle={"Save"}>
          <FormCol>
            <Input label={"Enable Multiple Practices"} type="checkbox" onChange={props.handleOfficeInputChange} value={props.officeState.multiplePractices} name={"multiplePractices"} />
            <p className="mb-8">Multiple Practices Enabled. <Link to="practices" className="text-blue-700">View practices.</Link></p>
          </FormCol>
        </Form>
        : <EditAccountPractice 
          officeId={props.officeId}
          practiceId={props.practiceId} 
          practiceState={props.practiceState} 
          officeState={props.officeState}
          handlePracticeInputChange={props.handlePracticeInputChange} 
          handleOfficeInputChange={props.handleOfficeInputChange}
          handleOfficeHasPracticeChange={props.handleOfficeHasPracticeChange}
          handleOfficeMultiplePracticesChange={props.handleOfficeMultiplePracticesChange} 
          handleSubmit={props.handleSubmit} />}
      </div>
      )
  }