import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";

import {UserContext} from './UserContext'

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PlanDocument from './PlanDocument'
import PlanWordDocument from './components/PlanWordDocument'


export default function ChoosePlanBenefits(props){

	const [customBenefits, setCustomBenefits] = useState(props.customBenefits)

	const numberMask = createNumberMask({
	  prefix: '$',
  	  allowDecimal: true,
 	// This will put the dollar sign at the end, with a space.
	})

	const percentMask = createNumberMask({
	  prefix: '',
	  suffix: '%',
  	  allowDecimal: true,
 	// This will put the dollar sign at the end, with a space.
	})


	//custom benefit values are received from CreatePlanV3 and used to render form UI
	const listCustomBenefitInputs = props.customBenefits.map(benefit => {
		let displayStyle
		if(benefit.benefit.includes("$X")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit.benefit} className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + " price $"}</p>
              			<MaskedInput mask={numberMask} onChange={props.handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.value != undefined ? benefit.value : ""} className="border w-full" type="text"/>
					</div>
				</div>
				)	
		}
		else if (benefit.benefit.includes("X%")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit.benefit} className={displayStyle}>
					<div className="w-1/3 flex-col mr-2">
						<p className="my-2 font-bold text-sm">{benefit.name + " %"}</p>
              			<MaskedInput mask={percentMask} onChange={props.handleCustomBenefitsValueChange} id={benefit.id} name={benefit.name} value={benefit.value != undefined ? benefit.value : ""} className="border w-full" type="text"/>
					</div>
				</div>
				)
		}
		else if (benefit.benefit.includes("Additional")){
			displayStyle = benefit.included ? "flex my-2" : "flex my-2 hidden"

			return(
				<div key={benefit.benefit} className={displayStyle}>
					<div className="w-full flex-col">
						<p className="my-2 font-bold text-sm">{benefit.name}</p>
						<input id={benefit.id} name={benefit.name} value={benefit.value != undefined ? benefit.value.replace('percent1', '%').replace('dollar1', '$') : ""} onChange={props.handleCustomBenefitsValueChange} className="border w-full" type="text"></input>
					</div>
				</div>
				)
		}
		else {
			return undefined
		}
	})


	const listCustomBenefits = props.customBenefits.map(benefit => {
		return(
			<div key={benefit.benefit} className="flex">
				<input onChange={() => props.handleCustomBenefitsChange(benefit.id)} id={benefit.id} key={benefit.id} className="my-auto" type="checkbox" checked={benefit.included}></input>
				<p className="my-auto px-2">{benefit.benefit}</p>
			</div>
			)
	})

	let displayDownload
	if(((props.planType == "Adult") && (props.hasAdultPlan)) || ((props.planType == "Perio") && (props.hasPerioPlan)) || ((props.planType == "Child") && (props.hasChildPlan))){
		displayDownload = true
	}
	else{
		displayDownload = false
	}
	return(
		<div className="w-full h-full">
			<div className="hidden">{props.customBenefits[0] != undefined ? props.customBenefits[0]['benefit'] : null}</div>
			{(props.params.planId == "create") ? <div className="w-5/6 flex mt-3 mx-auto">
				<button onClick={props.handleAdultPlanType} className={props.planType == "Adult" ? "interval-active" : "interval"}>Adult</button>
				<button onClick={props.handlePerioPlanType} className={props.planType == "Perio" ? "interval-active" : "interval"}>Perio</button>
				<button onClick={props.handleChildPlanType} className={props.planType == "Child" ? "interval-active" : "interval"}>Child</button>
			</div> : 
			<div className="w-5/6 flex mt-3 mx-auto">
				<button className={"interval-active"}>{props.planType}</button>
			</div>
			}
			<form onSubmit={props.validateForm} className="card flex flex-col mb-8 p-8 bg-white border rounded-lg w-5/6 mx-auto ">
				{displayDownload ? <div className="w-full flex">
					
					{/*<PDFDownloadLink className="my-6 w-1/4 mx-2 px-2 py-2 bg-blue-500 text-white text-center" 
						document={<PlanDocument name={props.name} officeLogo={props.officeLogo} 
						planType={props.planType} recommendedDown={props.recommendedDown} 
						recommendedMonth={props.recommendedMonth} recommendedFee={props.recommendedFee} 
						recommendedAdditionalFee={props.recommendedAdditionalFee} 
						recommendedAdditionalMonth={props.recommendedAdditionalMonth}
					 	basicBenefits={props.basicBenefits} 
						customBenefits={props.customBenefits} />} fileName="plan.pdf">
				      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
					</PDFDownloadLink>*/}
				    <PlanWordDocument className="my-6 w-1/4 mx-2 px-2 py-2 bg-blue-500 text-white text-center" finalFee={props.finalFee} customBenefits={props.customBenefits} basicBenefits={props.basicBenefits} name={props.name} officeLogo={props.officeLogo} planType={props.planType} recommendedDown={props.recommendedDown} recommendedMonth={props.recommendedMonth} recommendedFee={props.recommendedFee} recommendedAdditionalFee={props.recommendedAdditionalFee} recommendedAdditionalMonth={props.recommendedAdditionalMonth}/>
 					</div> : null }
				<div className="flex my-4 w-full">
					<p className="pr-4">Plan Name:</p>
					<input className="border" onChange={props.handleNameChange} type="text" value={props.name} id="name"></input>
				</div>
				<h1 className="text-lg my-4 font-semibold">Basic options included in all plans:</h1>
					{props.listBasicBenefits}
				<h1 className="text-lg my-4 font-semibold">Check options to include in your plan:</h1>
					{listCustomBenefits}
				<div className="my-6">
					{listCustomBenefitInputs}
				</div>
              	<button className="my-6 w-1/4 px-2 py-2 bg-blue-500 text-white">Next</button>
			</form>
		</div>
		)
	}
