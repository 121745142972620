import API, { graphqlOperation } from '@aws-amplify/api';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { savePaymentLog } from '../../helpers/savePaymentLog';
import { signUp } from '../../helpers/signUp';
import { getCardTypeFromNumber } from '../../hooks/useCardType';
import { UserContext } from '../../UserContext';
import { submitTokenPayment, tokenizePayment, USIOResponse } from '../../usio/payments';
import MemberInfoDetail from './MemberInfoDetail';


/**
 * This screen is intended to create a brand new member
 */


 const debugData = {
	contactEmail: `test${moment().unix()}@email.com`,
	contactName: "Test Member",
	patientName: "Kid LastName",
	contactAddress: "123 TEst Rd.",
	contactCity: "Beverly Hills",
	contactZip: "90210",
	contactPhone: "8675309",
	contactState: "CA",

	cardName: "Test Card",
	cardNumber: "4111111111111111",
	cardType: "VISA",
	expireM: "02",
	expireY: "2022",
	cardAddress: "123 Test Rd.",
	cardCity: "Beverly Hills",
	cardState: "CA",
	cardZip: "90210",
}

const data = {
    cardAll: '',
	contactEmail: "",
	contactName: "",
	patientName: "",
	contactAddress: "",
	contactCity: "",
	contactZip: "",
	contactPhone: "",
	contactState: "",

    initialDeposit: '0',
    paymentInterval: 'yearly',
    paymentType: 'card',
    plan: '',
    plans: [],
    practice: null,

    patientSameAsCardHolder: true,
    accountHolderSameAsCardHolder: true,

	cardName: "",
	cardNumber: "",
	cardType: "",
	expireM: "",
	expireY: "",
	cardAddress: "",
	cardCity: "",
	cardState: "",
	cardZip: "",
	...(
		localStorage.getItem('@wellify/debug-data') === 'YES_PLEASE' ? debugData : {}
	)
	// ...debugData
}

/**
 * @typedef {Object} ProcessingState
 * @property {String} status - The current processing status
 * @property {String} paymentToken - The current paymentToken
 * @property {String} memberId - The current signed up user
 * @property {String} username - The current signed up username
 * @property {USIOResponse} paymentResult - The result of the initial deposit or first annual payment
 * 
 * 
 * @typedef {Function} ProcessingStateSetter - Updates the processingState  
 */

/**
 * @typedef {Object} CreateNewMemberProps
 * @property {string} officeName
 * 
 * 
 * @param {CreateNewMemberProps} props
 */
export default (props) => {
    const history = useHistory()
    const cardRef = useRef(null)
    
    // const plans = useCurrentOfficePlans()
    const { user, officeId, officeRecord } = useContext(UserContext)
    const currentOfficeId = officeId || user

    const [state, dispatch] = useReducer((state, action) => {
        // console.log(`Updating data: `, action)
        let returnData = {...state, ...action}

        if (action.cardAll && !returnData.swiped) {
            const newCardAll = returnData.cardAll

			let swipeNumber = ""
			let swipeExpireY = ""
			let swipeExpireM = ""

			if ((newCardAll != undefined) && (newCardAll.includes('/'))) {
				const swipeName = newCardAll.split('^')[1]
				let swipeNames
				
                try { swipeNames = swipeName.replace(/ /g, "").split('/') }
				catch { swipeNames = swipeName.split('/') }

				const firstName = swipeNames[0].replaceAll(' ', '')
				returnData.cardName = [swipeNames[1], firstName].join(' ')
			}

			if (newCardAll.includes(';')) {
				swipeNumber = newCardAll.split(';')[1].split('=')[0]
                returnData.cardNumber = swipeNumber
                returnData.cardType = getCardTypeFromNumber(swipeNumber)
			}

			if (newCardAll.includes('=')) {
				const swipeExpire = newCardAll.split('=')[1].substring(0, 4)
				if (swipeExpire.length == 4) {
                    const getFullYear = `${(new Date()).getFullYear()}`
					swipeExpireY = getFullYear.substr(0,2) + swipeExpire.substring(0, 2)
					swipeExpireM = swipeExpire.substring(2, 4)

                    returnData.expireY = swipeExpireY
                    returnData.expireM = swipeExpireM
                    console.log(returnData)
				}
			}

			if (swipeNumber.length >= 15 && swipeExpireY.length == 4) {
				returnData.swiped = true
			}
        }

        const isCash = returnData.paymentType === 'cash'

        if (returnData.patientSameAsCardHolder && !isCash) {
            /// Copy the current cardHolder info into the
            /// Patient info
            returnData = {
                ...returnData,
                patientName: returnData.cardName,
                contactAddress: returnData.cardAddress,
                contactCity: returnData.cardCity,
                contactState: returnData.cardState,
                contactZip: returnData.cardZip
            }
        }

        if (returnData.accountHolderSameAsCardHolder && !isCash) {
            /// Copy the current cardHolder info into the
            /// Patient info
            returnData = {
                ...returnData,
                contactName: returnData.cardName,
                name: returnData.name
            }
        }


        /// Update the payment calculations here
        /// Maybe create a handful of fields that will trigger this logic
        /// instead of doing it on every single field change
        const newPlan = returnData.plans.filter(plan => plan.name === returnData.plan)[0]
        if (newPlan) {
            const atobFees = JSON.parse(atob(newPlan.recommendedFee))
            let finalFee = typeof atobFees.finalFee == 'string' ? atobFees.finalFee.replace('$', '') : atobFees.finalfee
            if (typeof atobFees.finalFee == 'number') {
                finalFee = atobFees.finalFee
            }
            
            let { initialDeposit } = returnData
            const downPay = (typeof initialDeposit == "string" ? initialDeposit.replace('$', '') : initialDeposit) || 0
            const isAnnual = returnData.paymentInterval === 'yearly'
            const remainingBalance = isAnnual ? finalFee : Number(finalFee) + 50
            let periodAmount = isAnnual ? finalFee : atobFees.recommendedMonth
            
            /// If changing paymentInterval, then we should update the
            /// periodAmount based of calculations
            if (action.plan) {
                initialDeposit = (atobFees.downPayment.replace('$', '') || '0')
            }
            if ((action.paymentInterval || action.initialDeposit) && !isAnnual) {
                periodAmount = ((finalFee - downPay + 50) / 12).toFixed(2)
            }

            const atobBenefits = JSON.parse(atob(newPlan.benefits))
            let newFixedBenefits = atobBenefits
			for (let i = 0; i < atobBenefits.length; i++) {
				if (atobBenefits[i]['included']) {
					if (atobBenefits[i]['benefit'].includes("INCLUDED")) {
						newFixedBenefits = newFixedBenefits.concat({ ...atobBenefits[i], redeemed: false, redeemDate: "" })
					}
				}
			}

            returnData = {
                ...returnData,
                totalBalance: finalFee,
                initialDeposit,
                remainingBalance,
                periodAmount,
                fixedBenefits: newFixedBenefits
            }    
        }
        
        return returnData
    }, data)

    /**
     * @type {[ProcessingState, ProcessingStateSetter]} ProcessingStateReducer
     */
    const [processingState, dispatchProcess] = useReducer((state, action) => {
        const data = {...state, ...action}

        return data
    }, {
        status: null
    })
    
    /**
     * Generate "handlers" for events down the chain. These are essentially just for updating
     * the state. We only need this to be created on the first run, so using useRef
     * 
     * e.g
     * ```
     * "handleCardTypeChange": (e) => dispatch({ "cardType": e.target.value })
     * ```
     */
    const dispatchHandlers = useRef(Object.keys(data).reduce((bag, key) => {
        return {
            ...bag,
            [`handle${key.charAt(0).toUpperCase() + key.slice(1)}Change`]: (e) => {
                console.log(`Changed: ${key} => ${typeof(e)}`)
                const value = (typeof(e) === 'string') ? e : (e.target.checked || e.target.value)

                dispatch({ [key]: value })
            }
        }
    }, {})).current


    /**
     * Takes the card information and creates a token based off the input. 
     */
    const handleTokenization = async (e, shouldReturn = false) => {
        e && e.preventDefault()
        
        try {

            const { 
                expireM, 
                expireY, 
                cardName, 
                cardNumber, 
                cardType: CardType,
                cardAddress: Address1,
                cardCity: City,
                cardState: State,
                cardZip: Zip,
                contactEmail: EmailAddress
            } = state
            const ExpDate = expireM + expireY
            const FirstName = cardName.split(" ")[0]
            const LastName = cardName.split(" ")[1]

            const result = await tokenizePayment({
                MerchantID: props.officeMerchantKey,
                Login: props.officeUsioLogin, 
                Password: props.officeUsioPassword,

                CardNumber: cardNumber.replace(/( |_)/g, ''),
                CardType,
                ExpDate,
                Address1,
                City, State,

                FirstName,
                LastName, 
                EmailAddress,
                Zip
            })


            if (result.confirmation && result.confirmation.length > 0) {
                dispatch({
                    paymentStatus: 'success'
                })

                return result.confirmation
            }
            else {
                dispatch({
                    paymentStatus: result.message,
                    paymentToken: undefined
                })
                
                throw new Error(result.message)
            }
        }
        catch (ex) {
            console.error(ex)

            if (shouldReturn) {

                throw ex
            }
            else {
                alert(`Error authorizing card: ${ex.message}`)
            }
        }
    }


    const resetFocus = (e) => {

		//see OneTimePayment.js and One Time Payment documentation for more details

		let clickType = e.target.type
        const { swiped } = state

        if (swiped === true) { return }

        if ((clickType != 'select-one') && (clickType != 'text') && (clickType != 'email')) {
            cardRef.current.focus()
            dispatch({ swipeNow: true })
        }
	}

	const resetFocus2 = (e) => {
		//see OneTimePayment.js and One Time Payment documentation for more details

		if (cardRef.current != null) {
			cardRef.current.blur()
            dispatch({ swipeNow: false })
		}
	}


    const togglePaymentType = (paymentType) => dispatch({ paymentType })


    // TODO: Implement
    const createMember = async (e) => {
        e.preventDefault()

        if (processingState.status) { return }

        /// Run validations to ensure everything is all good
        const {
            practice,
            patientName,
            contactName,
            contactAddress,
            contactCity,
            contactZip,
            contactPhone,
            contactEmail,
            plan, remainingBalance
        } = state

        const valuesToCheck = {
            currentOfficeId,
            practice,
            patientName,
            contactName,
            contactAddress,
            contactCity, contactZip,
            contactPhone, contactEmail,
            plan
        }

        const invalidKeys = Object.keys(valuesToCheck).filter(k => {
            const val = valuesToCheck[k]
            if (val && val.length > 0) { return false }
            return true
        })

        if (invalidKeys.length > 0) {
            alert(`Please check the followingFields: ${invalidKeys.join(', ')}`)
            return
        }



        dispatchProcess({ status: 'Submitting...' })
        try {
            const { paymentInterval, paymentType, initialDeposit } = state
            const isAnnual = paymentInterval === 'yearly'
            const isCash = paymentType === 'cash'

            const usioCreds = {
                MerchantID: props.officeMerchantKey,
                Login: props.officeUsioLogin,
                Password: props.officeUsioPassword
            }

            // Monthly first payment is billed next day
            // Annual due date is today.  This way the auto-payments work as expected
            const dueDate = moment().add(isAnnual ? 0 : 1, 'day')
            const dueDay = dueDate.get('date')

            /// Only necessary if Card payment
            let paymentToken = processingState.paymentToken || ''
            if (!isCash && paymentToken.length === 0) {
                dispatchProcess({ status: 'Validating Card...' })
                paymentToken = await handleTokenization(null, true)
                dispatchProcess({ paymentToken })
            }
            else {
                console.log(`Skipping tokenization ${paymentToken}`)
            }


            /// Attempt to process the payment (if applicable)
            let deposit = Number(String(initialDeposit).replace('$', ''))
            if (isNaN(deposit)) {
                deposit = 0
            }
            const remaining = Number(String(remainingBalance).replace('$', ''))

            const amount = isAnnual ? remaining : (isNaN(deposit) ? 0 : deposit)
            const description = isAnnual ? 'Annual Payment' : 'Initial Deposit'
            const grossPay = `${amount}`
            const netPay = `${amount}`
            const fee = 0
            let paymentResult = processingState.paymentResult


            if (!isCash && amount > 0 && !paymentResult) {
                dispatchProcess({
                    status: `Processing ${isAnnual ? 'first payment' : 'downpayment'}...` 
                })

                const debugAmount = localStorage.getItem('@wellify/debug-charge-amount')
                paymentResult = await submitTokenPayment({
                    ...usioCreds,
                    Token: paymentToken,
                    Amount: (debugAmount && debugAmount.length > 0) ? debugAmount : amount
                })

                dispatchProcess({ paymentResult })
            }
            else {
                console.log(`Skipping payment processing ${paymentResult}`)
            }

            
            // creates a new patient account with the password QPDmember1234 that can be changed on login
            // patients account view is not currently being used
            const { contactEmail, patientName, contactName } = state
            let { memberId, username } = processingState
            if (!memberId || memberId.length === 0) {
                dispatchProcess({ status: 'Creating User...' })
                const awsUser = await signUp(contactEmail, 'QDPMember1234', patientName)
                memberId = awsUser.id
                username = awsUser.username
                
                dispatchProcess({ memberId, username })
            }
            else {
                console.log(`User already created? ${memberId}`)
            }
            
            const officeId = currentOfficeId
            const officeName = props.officeName || officeRecord.office.name
            const { practice, cardType, cardNumber } = state
            
            if (amount && !processingState.paymentLogSaved) {
                dispatchProcess({ status: 'Saving Payment Logs...' })
                await savePaymentLog({
                    ...(paymentResult || {}),
                    paymentType,
                    memberId, patientName, contactName,
                    grossPay, netPay, fee,
                    description,
                    officeId, officeName, practice,
                    cardType, cardNumber
                })
                dispatchProcess({ paymentLogSaved: true })
            }
            else {
                console.log(`Already saved payment log`)
            }

            const interestRate = '0'
            const {
                fixedBenefits,
                expireM, expireY,


                totalBalance,
                periodAmount
            } = state
            const expiration = expireM + expireY

            dispatchProcess({ status: 'Saving member records...'})
            await API.graphql(graphqlOperation(
                `
                mutation CreateMember {

                    createMember(input: {
                        id: "${memberId}",
                        name: "${patientName}", 
                        office: "${currentOfficeId}", 
                        active: true, 
                        contactName: "${contactName}",
					    contactAddress: "${contactAddress}", 
                        contactCity: "${contactCity}", 
                        contactZip: "${contactZip}",
					    contactPhone: "${contactPhone}", 
                        contactEmail: "${username}", 
                        planAgreement: ${true}, 
                        customerSignature: ${false},
					    plan: "${plan}",
                        practice: "${practice}", 
                        fixedBenefits: "${btoa(JSON.stringify(fixedBenefits))}",
					    paymentInterval: "${paymentInterval}", 
                        expiration: "${expiration}", 
                        paymentType: "${paymentType}"
                    }) {
					    id
                    }
                    createMemberFinance(input: {
                        memberId: "${memberId}", 
                        memberName: "${patientName}", 
                        practice: "${practice}",
                        office: "${currentOfficeId}", 
                        dayDue: ${dueDay},
                        interestRate: ${String(interestRate).replace('%', '')}, 
                        totalBalance: ${String(totalBalance).replace('$', '')}, 
                        remainingBalance: ${String(remainingBalance).replace('$', '')},
                        paymentInterval: "${paymentInterval}", 
                        periodAmount: ${String(periodAmount).replace('$', '')},
                        paymentToken: "${paymentToken}", 
                        initialDeposit: ${String(deposit).replace('$', '')}, 
                        expiration: "${expiration}", 
                        paymentType: "${paymentType}",
                        paymentDescription: "****${cardNumber.substr(cardNumber.length - 4)}",
                        contactName: "${contactName}"
                    }) {
                        id
                    }


                    createMemberLog(input: {
                        memberId: "${memberId}", 
                        office: "${currentOfficeId}", 
                        memberName: "${patientName}",
                        practice: "${practice}",
                        activity: "Active Member"
                    }) {
                        id
                    }
                }
                `
            ))

            
            /// Navigate to the new member
            history.push(`/member/${memberId}/info`)

            alert('Success: Member Created and Payment Processed')
        }
        catch (ex) {
            /// Cancelled comes from the signUp routine. In the case of dependent cancel
            if (ex.message !== 'Cancelled') {
                /// We can ignore this as an error
                alert(`Error during ${processingState.status}: ${ex.message}`)
            }
            
            console.log(ex)
            console.error(ex)
        }

        dispatchProcess({ status: null })
    }



    /// Load practices and plans
    useEffect(() => {

        (async () => {
            const currentOfficeId = officeId || user
            const results = await API.graphql(graphqlOperation(`query ListData {
                listPlans(filter: {office: {eq:"${currentOfficeId}"}}, limit: 9999) {
                  items {
                      id
                      name
                      benefits
                      recommendedFee
                  }
                }
    
                listPractices(filter: {office: {eq:"${currentOfficeId}"}}, limit: 9999) {
                    items {
                        id
                        name
                        city
                        zip
                    }
                  }
    
            }`))
    
            const planData = results.data.listPlans.items
            const practiceData = results.data.listPractices.items
    
            dispatch({
                plans: planData.map((p,idx) => ({...p, rowId: idx+1 })),
                practices: practiceData.map((p,idx) => ({...p, rowId: idx+1})),
                practice: (practiceData && practiceData.length > 0 ? practiceData[0].name : undefined)
            })
        })()

    }, [officeId, user])

    return (
        <div className='relative'>
            <div className='card p-8 bg-white border rounded-lg w-5/6 mx-auto'>
                <button 
                    onClick={(e) => {
                        e.preventDefault(); 
                        togglePaymentType('card')
                    }} 
                    className={`w-1/2 ${state.paymentType == 'card' && 'gradient-btn'}`}>Card</button>
                <button 
                    onClick={(e) => {
                        e.preventDefault()
                        togglePaymentType('cash')
                    }} 
                    className={`w-1/2 ${state.paymentType == 'cash' && 'gradient-btn'}`}>Cash</button>
            </div>


            <form onSubmit={(e) => e.preventDefault()}>
				<input ref={cardRef} className="opacity-0" onChange={dispatchHandlers.handleCardAllChange} value={state.cardAll||''}></input>
			</form>


            <MemberInfoDetail
                {...state}
                {...dispatchHandlers}

                resetFocus={resetFocus}
                resetFocus2={resetFocus2}
            />

            <div className='w-5/6 mx-auto bg-white px-6 py-8 mb-20'>
                {
                    processingState.status ? (
                        <p>{processingState.status}</p>
                    ) : (
                        <button onClick={createMember} className="gradient-btn">
                            Create Member
                        </button>
                    )
                }
            </div>
            
            { processingState.status && (
                <div className='fixed inset-0 backdrop-blur-md bg-gray-100' style={{zIndex: 9999999}}>
                    <div className='h-full w-full flex items-center justify-center'>
                        <div className='shadow-md px-12 py-20 rounded-md bg-white items-center flex flex-col'>
                            <i className="fa fa-lock fa-5x text-blue-500"></i>
                            <p className='text-blue-500 text-3xl'>{processingState.status}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}