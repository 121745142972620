import React, { useState, useEffect, useContext }  from "react";
import { useParams, Link } from "react-router-dom";

import CreateMember from './CreateMember'

export default function EditMember(props){
	const params = useParams()

	return(
		<div className="h-full w-full">
			<CreateMember officeMerchantKey={props.officeMerchantKey} officeUsioLogin={props.officeUsioLogin} 
			officeUsioPassword={props.officeUsioPassword} officeId={props.officeId} 
			officeLogo={props.officeLogo} memberId={params.memberId} edit={true} 
			officeName={props.officeName} />
		</div>
	)
}