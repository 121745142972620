import React, { useState, useEffect, useContext, useRef, useCallback }  from "react";
import { useParams, Link } from "react-router-dom";

import {UserContext} from './UserContext'

import Amplify, { API, graphqlOperation, Auth, Analytics } from 'aws-amplify';

import AdminViewOfficePractices from './AdminViewOfficePractices'

import OfficeLogo from './OfficeLogo'
import LogoUploader from './LogoUploader'

import SignatureCanvas from 'react-signature-canvas'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Input from './components/Input'
import SavedAlert from './components/SavedAlert'
import Form from './components/Form'
import FormCol from './components/FormCol'

async function signUp(username, password) {

	//creates a new user in cognito using the amplify api

    let user
    try {
        user = await Auth.signUp({
            username,
            password,
        });

        //once user is created, create a account record of the user in dynamodb
	    const createAccount = await API.graphql(graphqlOperation(
	          `mutation create {
	            createAccount(input: {id: "${user.userSub}", email: "${username}", accountType: "office"}){
	              id
				  email
	              accountType
	            }
	          }`))
    } catch (error) {
    	alert(error.message)
        console.log('error signing up:', error);
    }

    return user.userSub

}

export default function AdminViewOffice(props){
	const auth = useContext(UserContext)

	const params = useParams()

	  const [email, setEmail] = useState("")
	  const [confirmEmail, setConfirmEmail] = useState("")
	  const [website, setWebsite] = useState("")
	  const [password, setPassword] = useState("")
	  const [confirmPassword, setConfirmPassword] = useState("")
	  const [name, setName] = useState("")
	  const [city, setCity] = useState("")
	  const [primaryContact, setPrimaryContact] = useState("")
	  const [phone, setPhone] = useState("")
	  const [address, setAddress] = useState("")
	  const [zip, setZip] = useState("")
	  const [id, setId] = useState("")
	  const [state, setState] = useState("")
	  const [logo, setLogo] = useState("")

	  const [licensee, setLicensee] = useState("")
	  const [by, setBy] = useState("")
	  const [contractName, setContractName] = useState("")
	  const [title, setTitle] = useState("")
	  const [agreementSignature, setAgreementSignature] = useState("")
	  const [status, setStatus] = useState("")
	  const [createdAt, setCreatedAt] = useState("")

	  const [merchantKey, setMerchantKey] = useState("")
	  const [usioLogin, setUsioLogin] = useState("")
	  const [usioPassword, setUsioPassword] = useState("")
	  const [confirmUsioPassword, setConfirmUsioPassword] = useState("")

	const [rejected, setRejected] = useState(false)
	const [approved, setApproved] = useState(false)

	//used to display application signature in signature box
	const sigRef = useRef(null)

	  const handleMerchantKeyChange = (event) => {
	    setMerchantKey(event.target.value)
	  }

	  const handleUsioLoginChange = (event) => {
	    setUsioLogin(event.target.value)
	  }

	  const handleUsioPasswordChange = (event) => {
	    setUsioPassword(event.target.value)
	  }

	const confirmRejectOffice = () => {

		//if office is rejected, handle it!
	    confirmAlert({
	      title: 'Confirm to submit',
	      message: "Are you sure you want to reject this application?",
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleRejectOffice()}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }

	const confirmApproveOffice = () => {

		//if office is approved, handle it!

	    confirmAlert({
	      title: 'Confirm to submit',
	      message: "Are you sure you want to approve this application?",
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {handleApproveOffice()}
	        },
	        {
	          label: 'No',
	          onClick: () => null
	        }
	      ]
	    });
	  }

	const handleRejectOffice = async () => {

		//rejected offices have their PendingOFfice record updated to rejected, but no signup takes place
        const result = await API.graphql(graphqlOperation(
          `mutation update {
            updatePendingOffice(input: {id: "${params.applicationId}", status: "rejected"}){
            id
            status
            }
          }`))

        setRejected(true)
	}

	const handleApproveOffice = async () => {

			//approved offices are signed up with cognito, and have Office/OfficeLog records created in addition to updating the application status to approved

			//sign the user up with cognito (and create account record in dynamodb)
			const username = await signUp(email, password)

			//update PendingOffice record
	        const updateApplicationStatus = await API.graphql(graphqlOperation(
	          `mutation update {
	            updatePendingOffice(input: {id: "${params.applicationId}", status: "approved"}){
	            id
	            status
	            }
	          }`))


			/// Create a practice
			const result = await API.graphql(graphqlOperation(
			`mutation create {
				createPractice(input: {name: "${contractName}", office: "${username}", businessName: "${name}", city: "${city}",
				zip: "${zip}"}){
				id
				name
				businessName
				city
				zip
				}
			}`))

	        //create office record
	      const createOffice = await API.graphql(graphqlOperation(
	          `mutation create {
	            createOffice(input: {id: "${username}", name: "${name}", primaryContact: "${primaryContact}", phone: "${phone}", email: "${email}",
	            address: "${address}", zip: "${zip}", state: "${state}", logo: "${logo}",
	            website: "${website}", multiplePractices: ${false}, hasPractice: ${false}, hasPlan: ${true},
	            hasLoggedIn: "no", merchantKey: "${merchantKey}", usioLogin: "${usioLogin}",
	            usioPassword: "${usioPassword}", active: ${true}}){
	            id
	            name
	            primaryContact
	            phone
	            email
	            address
	            zip
	            state 
	            logo
	            website
	            multiplePractices
	            hasPractice
	            hasPlan
	            hasLoggedIn
	            merchantKey
	            usioLogin
	            usioPassword
	            active
	            }
	          }`))

	      	//create OfficeLog record
	        const createOfficeLog = await API.graphql(graphqlOperation(
	              `mutation create {
	                createOfficeLog(input: {officeId: "${username}", office: "${name}",
	              activity: "Active Member"}){
	                  id
	                  officeId
	                  office
	                  activity
	                }
	              }`))

	        setApproved(true)
		}

    const fetchApplication = useCallback(
		async() => {

    	//fetch the application from dynamodb

        const officeData = await API.graphql(graphqlOperation(`query GetPendingOffice {
	    getPendingOffice(id: "${params.applicationId}"){
            id
            name
            primaryContact
            phone
            email
            website
            address
            zip
			city
            state
            logoUrl
            licensee
            by
            contractName
            title
            agreementSignature
            status
            createdAt
            password
	    }
	}`))
        const userOfficeData = officeData.data.getPendingOffice
        setName(userOfficeData.name)
		setCity(userOfficeData.city)
		setPrimaryContact(userOfficeData.primaryContact)
		setPhone(userOfficeData.phone)
		setZip(userOfficeData.zip)
		setEmail(userOfficeData.email)
		setAddress(userOfficeData.address)
		setWebsite(userOfficeData.website)
		setState(userOfficeData.state)
		setLogo(userOfficeData.logoUrl)
		setLicensee(userOfficeData.licensee)
		setBy(userOfficeData.by)
		setContractName(userOfficeData.contractName)
		setTitle(userOfficeData.title)
		setAgreementSignature(userOfficeData.agreementSignature)
		setStatus(userOfficeData.status)
		setCreatedAt(userOfficeData.createdAt)
		setPassword(userOfficeData.password)

		if(userOfficeData.status == "approved"){
			setApproved(true)
		} 
		else if(userOfficeData.status == "rejected"){
			setRejected(true)
		}
	}, [params.applicationId])

	const handleChangeApplicationStatus = (e) => {
		//archived, does nothing
		e.preventDefault()
	}

    useEffect(
	    () => {
	   		fetchApplication()

	   		//set the sigRef value to the application's signature image value
	   		sigRef.current.fromDataURL(agreementSignature)
	    }, [agreementSignature, fetchApplication])

	return(
      <div className="w-full h-full">
		<div className="w-full p-4 bg-white shadow-xl border-t border-l border-r">
      	    <Link className="text-blue-500" to="/admin/applications">{"<<"} Applications</Link>
        </div>
        <div className="my-8 card w-5/6 mx-auto">
		<Form title={"Office Application"} hideButton={true} className="card p-8 bg-white border rounded-lg w-full mx-auto ">
			<FormCol>
   				<Input label={"Email"} disabled={true} value={email} name={"email"} />
      			<Input label={"Business Name"} disabled={true} value={name} name={"name"} />
      			<Input label={"Primary Contact"} disabled={true} value={primaryContact} name={"primaryContact"} />
      			<Input label={"Phone"} disabled={true} value={phone} name={"phone"} />
		        <div className="flex">
		          <div className="my-auto pr-4 w-1/3 text-right">
		            <p className="my-2">Profile Picture:</p>
		          </div>
			        <div className="">
			          {logo != "" ? <OfficeLogo logoUrl={logo} /> : <p>Did not upload.</p> }
			        </div>
		        </div>
			</FormCol>
			<FormCol>
	            <Input label={"Address"} disabled={true} value={address} name={"address"} />
	            <Input label={"State"} disabled={true} value={state} name={"state"} />
	            <Input label={"Zip"} disabled={true} value={zip} name={"zip"} />
	            <Input label={"Website"} disabled={true} value={website} name={"website"} />
			</FormCol>
		</Form>
		<Form title={"Membership Agreement"} hideButton={true} className="p-8 bg-white  w-full mx-auto ">
			<FormCol>
	            <Input label={"Licensee"} disabled={true} value={licensee} name={"licensee"} />
	            <Input label={"By"} disabled={true} value={by} name={"by"} />
	            <Input label={"Name"} disabled={true} value={contractName} name={"contractName"} />
		        <div className="flex my-4 w-full ">
		          <p className="my-auto pr-4 w-1/3 text-right">Signature:</p>
		          <div className="flex flex-col">
		            <SignatureCanvas ref={sigRef} penColor='green'
		              canvasProps={{width: 250, height: 100, className: 'sigCanvas border'}} />
		          </div>
		        </div>
			</FormCol>
			<FormCol>
				<Input label={"Title"} disabled={true} value={title} name={"title"} />
	            <Input label={"Date Signed"} disabled={true} value={createdAt.split('T')[0]} name={"date"} />
	        </FormCol>
		</Form>
		<Form className="" title={"Payment Settings"} hideButton={true}>
			<FormCol>
			    <Input label={"USIO Merchant ID"} onChange={handleMerchantKeyChange} value={merchantKey} name={"merchantKey"} />
			    <Input label={"USIO Login"} onChange={handleUsioLoginChange} value={usioLogin} name={"usioLogin"} />
			    <Input label={"USIO Password"} onChange={handleUsioPasswordChange} value={usioPassword} name={"usioPassword"} />
			</FormCol>
		</Form>
		<SavedAlert saved={approved} label={"Approved"} />
		<SavedAlert saved={rejected} label={"Rejected"} />
		<button onClick={confirmRejectOffice} className="gradient-btn mr-2">Reject</button>
		<button onClick={confirmApproveOffice} className="gradient-btn">Approve</button>

        </div>
        </div>
		)
}




