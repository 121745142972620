import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";

import { capitalizeFirstLetter, createFormArray, getStateList, getFormInputState, listSchema, createSchema, deleteSchema, updateSchema, fetchList } from '../events/ReactQL'

import { UserContext } from '../UserContext'

import Amplify, { Storage, API, graphqlOperation } from 'aws-amplify';

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: 'dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev', //REQUIRED -  Amazon S3 bucket
    }
  }
});

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function useListQL(schema, filterDict, authField = null, fieldList = null) {

  const auth = useContext(UserContext)
  
  const memoScheme = useMemo(() => schema, [schema])
  const memoFilter = useMemo(() => filterDict, [filterDict])
  const [stateDict, setStateDict] = useState([])
  const [inputList, setInputList] = useState(createFormArray(getStateList(schema, fieldList)))
  const [saved, setSaved] = useState(false)
  const schemaName = schema.split(" ")[1].split(/(?=[A-Z])/).join(" ")

  const handleChange = (newItem) => {

    //inputs a new item to be swapped for old item in list. maps through all items in list and compares ids to the input id until it finds the same one, then replaces current item.

    const newStateDict = stateDict.map(item => {

      if (item != undefined && newItem != undefined) {
        if (newItem.id == item.id) {
          return newItem
        }
        else {
          return item
        }
      }
      else {
        return item
      }
    })
    setStateDict(newStateDict)
    setSaved(true)
  }

  const handleDelete = async (id) => {
    //deletes the object in dynamodb using the amplify api
    const deletedObj = await API.graphql(graphqlOperation(`mutation delete {
        delete${schemaName} (input: {id: "${id}"}){id}}`))

    //deletes the object in local state
    const updatedStateDict = stateDict.map(obj => {
      if (obj.id == id) {
        return { ...obj, deleted: true }
      }
      else {
        return { ...obj }
      }
    })

    let newStateList = []
    for (let i = 0; i < updatedStateDict.length; i++) {
      if (updatedStateDict[i]['deleted'] != true) {
        newStateList.push(updatedStateDict[i])
      }
    }
    setStateDict(newStateList)
  };

  const refresh = useCallback(async () => {
    console.log(`Refresh - ${memoScheme}`, memoFilter)
    let fetchedStateDict = await fetchList(memoScheme, memoFilter)
    setStateDict(fetchedStateDict)
  }, [memoScheme, memoFilter])

  useEffect(
    () => {
      console.log(`Refresh changed`)
      refresh()

    }, [refresh])

  return { schemaName, saved, stateDict, handleDelete, handleChange, refresh }
}

export { useListQL }