import React, { useState, useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
	import Iframe from 'react-iframe'

import ResourceCard from './ResourceCard'

export default function MarketingMaterials(props){
	const s3Prefix = "https://dentaladminaea9b1cbcd384e2e983b32678006c1a394617-dev.s3-us-west-2.amazonaws.com/public/"
	
	const materials = [{id:1, link:"qdp-consumer-video-for-doctors-2014version3.mov", image:"QDP-1.png", description: "QDP Video"},
	{id:2, link:"qdp-consumer-video-for-doctors-spanish-2014version3.mov", image:"QDP-Spanish-Vid2.png", description: "Spanish QDP Video"},
	{id:3, link:"qdp-marketing-buttons.zip", image:"buttons.png", description: "Marketing Buttons"},
	{id:4, link:"no-insurance-female-copy.mov", image:"Female-Cartoon-Vid-150x150.png", description: "No Insurance Female"},
	{id:5, link:"no-insurance-male-copy.mov", image:"Male-Cartoon-Vid-150x150.png", description: "No Insurance Male"},
	{id:6, link:"NEW-QDP-Logos-Horizontal-and-Vertical-Zip-File.zip", image:"QDP-Brand-Logo-jpg.jpg", description: "QDP Logos"},
	{id:7, link:"QDP-ENGLISH-BROCHURE-WITHOUT-FEES.zip", image:"QDP-Brochure-English-150x150.png", description: "QDP ENGLISH Brochures WITHOUT areas for fee placement"},
	{id:8, link:"QDP-SPANISH-BROCHURE-WITHOUT-FEES.zip", image:"QDP-Brochure-Spanish-150x150.png", description: "QDP SPANISH Brochures WITHOUT areas for fee placement"},
	{id:9, link:"NEW-ENGLISH-QDP-Brochures-with-Printer-Instructions.zip", image:"QDP-LOGO-ENGLISH-Brochures-WITH-areas-for-fee-placement-150x150.png", description: "QDP ENGLISH Brochures WITH areas for fee placement"},
	{id:10, link:"QDP-SPANISH-BROCHURE-WITHOUT-FEES.zip", image:"QDP-LOGO-ENGLISH-Brochures-WITH-areas-for-fee-placement-150x150.png", description: "QDP SPANISH Brochures WITH areas for fee placement"},
	{id:11, link:"NEW-QDP-Logo-Posters-Printers-Files.zip", image:"qdp_poster-ophcacb1itvy0vkxxkvu6aknqeeaml4sbiislim7vg.png", description: "QDP Logo Posters"},
	{id:12, link:"QDP_Postcard-4x6-Folder-1.zip", image:"qdp_postcard.png", description: "QDP Postcards"},
	{id:13, link:"QDP-office-Website-Copy-Final.doc", image:"QDP-Web-Copy-150x150.png", description: "QDP Web Copy"},
	{id:14, link:"QDP-B2C-email-marketing-template.doc", image:"QDP-Email-MaRKETING-ophctgcwhk1u3ftibuaqvgy2kg6z510ay427w2a1cs.png", description: "QDP Email Marketing"},
	{id:15, link:"QDP-Radio-TV.docx", image:"QDP-Radio-ophcvs0jdt8csqfzrcgjjdu3fqqn638exm4flotzz0.png", description: "QDP Radio/TV Script"},
	{id:16, link:"QDP-On-Hold-Script-Final.doc", image:"QDP-on-hold.png", description: "QDP On Hold"},
	{id:17, link:"QDP-FB.zip", image:"fbook.png", description: "Facebook Like Button"},
	{id:18, link:"QDP_Press.zip", image:"QDP-PR-Release-ophd63asddcm6pgojay6ea562xwmmj6e0nt85zjhp8.png", description: "QDP PR Release"},
	{id:19, link:"QDP-marketing-tips-V2.0.docx", image:"QDP-on-hold.png", description: "Marketing Tips"},
	{id:20, link:"QDP_internal_marketing_strategies.docx", image:"QDP-internal-150x150.png", description: "Internal Marketing Strategies"},
	{id:21, link:"QDP_Membership-Card.pdf", image:"QDP-Cards-ophdbpe36r19ivb4r8aysd59ss8jlggogg2mch7ujg.png", description: "Membership Card"}]

	const displayMaterials = materials.map(material => {
		return(
				<div key={material.id} className="border border-green-200 card marketing content-center p-4 m-2 text-center"><ResourceCard key={material.id} s3Prefix={s3Prefix} link={material.link} image={material.image} description={material.description}/></div>
			)
	})

	return(
		<div className="w-full h-full m-auto">
			<div className="w-1/2 rounded-sm p-2 my-6 mx-6">
				<h1 className="ch1">Marketing Materials</h1>
				<h1 className="text-lg my-6">Three ways to use the marketing materials:</h1>
					<ol className="pl-4">1. For easy online customization and ordering: <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" href="http://www.printingforless1.com/qdp/">CLICK HERE</a></ol>
	       			<ol className="pl-4">2. Download and use the files at any local print shop in your area or online</ol>
       				<ol className="pl-4">3. Download any and make the changes yourself</ol>
			</div>

			<div className="flex flex-wrap justify-around mx-2">	
				{displayMaterials}
			</div>
        </div>
		)
} 