import React, { useState, useContext, useEffect } from "react";

const officeSchema = `type Office @model {
    id: String
    name: String
    primaryContact: String
    phone: String
    email: String
    address: String
    zip: String
    multiplePractices: Boolean
    hasPractice: Boolean
    hasPlan: Boolean
    active: Boolean
    merchantKey: String
    usioLogin: String
    usioPassword: String
    logo: String
    hasLoggedIn: String
    state: String
    isVerified: Boolean
    website: String
    createdAt: String

    disableReminders: Boolean
  }`

const practiceSchema = `type Practice @model {
  id: ID!
  name: String
  businessName: String
  city: String
  zip: String
  state: String
  office: String
}`

const memberSchema = `type Member @model{
  id: ID!
  name: String
  active: Boolean
  contactName: String
  contactAddress: String
  contactCity: String
  contactZip: String
  contactPhone: String
  contactEmail: String
  planAgreement: Boolean
  planAgreementDate: String
  customerSignature: Boolean
  plan: String
  practice: String
  fixedBenefits: String
  office: String
  paymentInterval: String
  expiration: String
  createdAt: String
}`

const paymentSchema = `type Payments @model{
  id: ID!
  memberId: ID!
  paymentNumber: Int
  status: String
  description: String
  grossPay: Float
  fee: Float
  netPay: Float
  practice: String
  memberName: String
  createdAt: String
  office: String
  confirmationToken: String
  planId: ID

  paymentInfo: String
  contactName: String
}`

const memberFinanceSchema = `type MemberFinance @model{
  id: ID!
  memberId: ID!
  memberName: String
  practice: String
  dayDue: Int
  interestRate: Float
  totalBalance: Float
  initialDeposit: Float
  remainingBalance: Float
  paymentInterval: String
  periodAmount: Float
  office: String
  paymentToken: String
  active: Boolean
  paymentDescription: String
  contactName: String
}`

const planSchema =  `type Plan @model {
  id: ID!
  name: String
  benefits: String
  office: String
  planType: String
  recommendedFee: String
}`

const usersSchema = `type Account @model {
  id: ID!
  accountType: String
  name: String
  officeId: String
}`

const officeUsersSchema = `type OfficeAccount @model {
  id: ID!
  officeId: String
  accountId: String

  office: Office @connection(fields: ["officeId"])
  account: Account @connection(fields: ["accountId"])
}`

export {officeSchema, practiceSchema, memberSchema, memberFinanceSchema, paymentSchema, planSchema, usersSchema, officeUsersSchema}